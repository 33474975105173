import styled from 'styled-components'
import { FaRegBell, FaBell } from 'react-icons/fa'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Notifications = styled.button`
    margin: 0;
    padding: 3px 5px;
    border: none;
    outline: none;
    position: relative;

    background-color: inherit;
    cursor: pointer;
`

export const NotificationsAlertBox = styled.div`
    margin:  0;
    padding: 0;
    width:   0;
    height:  0;
    position: relative;
    bottom: 6px;
    left: 12px;
`

export const NotificationsAlert = styled.div`
    margin: 0;
    padding:0;
    z-index:100;
    font-weight:bold;
    background-color: ${(props) => props.theme.colors.warning};
    color: ${(props) => props.theme.colors.white};
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    width: ${(props) => props.size ? props.size : "22px"};
    height:${(props) => props.size ? props.size : "22px"};
    cursor: pointer;
`

export const NotificationsIconBorder = styled(FaRegBell)`
    margin: 5px;
    width: 1.5rem;
    height: auto;

    color: ${(props) => props.theme.colors.primary};
`

export const NotificationsIconFilled = styled(FaBell)`
    margin: 5px;
    width: 1.5rem;
    height: auto;

    color: ${(props) => props.theme.colors.primary};
`

export const NotificationContainer = styled.div`
    width: 350px;
    max-height: 500px;
    border: 1px solid #ddd;
    border-radius: 10px;

    padding: 15px;
    padding-bottom: 30px;
    overflow-y: scroll;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

    background-color: ${(props) => props.theme.colors.white};

    position: absolute;
    z-index: 1;
    top: 70px;
    right: 180px;
    transform: translateX(30%);

	@media screen and (max-width: 719px) {
		right: 100px;
        width: 300px;
	}
`

export const NotificationList = styled.ul`
    list-style: none;
    padding: 5px;
    margin: 10px 0px;
`

export const Title = styled.h1`
    margin: 0;
    padding: 0;
    margin-right: auto;
    font-size: 1.2rem;
`

export const Header = styled.header`
    display: flex;
    align-items: center;
`
