/** @format */

module.exports = {
	type: "Select the pairs",
	instruction: "Add the pairs that your students must match correctly.",
	firstElement: "First element...",
	secondElement: "Second element...",
	add: "Add",
	save: "Save",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
