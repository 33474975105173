/** @format */

import { React, useContext } from "react";
import Modal2 from "../Modal2/Modal2"; // Importa o componente Modal2
import NewAchievementMessage from "./NewAchievement/NewAchievementMessage";
import FinishedMission from "./FinishedMission/FinishedMission";
import CoinsObtained from "./CoinsObtained/CoinsObtained";
import LevelUp from "./LevelUp/LevelUp";
import TeamSuccess from "./TeamSuccess/TeamSuccess";
import SocialPressure from "./SocialPressure/SocialPressure";
import TeamPoke from "./TeamPoke/TeamPoke";
import Welcome from "./Welcome/Welcome";
import NewTeam from "./NewTeam/NewTeam";
import WelcomeBack from "./WelcomeBack/WelcomeBack";
import { EagleContext } from "../../../contexts/EagleContext";
import { FaUnderline } from "react-icons/fa";
import UpgradeInstructorSubscriptionPlan from "./UpgradeInstructorSubscriptionPlan/UpgradeInstructorSubscriptionPlan";

export default function Message({ message, closeModal }) {
	const { getCurrentCourse, user } = useContext(EagleContext);

	const course = getCurrentCourse();
	// Função para obter o conteúdo da mensagem com base no tipo
	function getMessage() {
		let title = "";
		let content = null;
		if (message.type == 1) {
			title = NewAchievementMessage.title;
			content = <NewAchievementMessage.content value={message.value} />;
		} else if (message.type == 2) {
			title = FinishedMission.title;
			content = (
				<FinishedMission.content
					subType={message.subType}
					extraData={message.extraData}
				/>
			);
		} else if (message.type == 3) {
			title = CoinsObtained.title;
			content = (
				<CoinsObtained.content
					subType={message.subType}
					value={message.value}
					extraData={{
						course: {
							title: course.courseInfo.title,
							id: course.courseInfo.id,
						},
					}}
				/>
			);
		} else if (message.type == 4) {
			title = LevelUp.title;
			content = <LevelUp.content value={message.value} />;
		} else if (message.type == 5) {
			title = TeamSuccess.title;
			content = (
				<TeamSuccess.content
					extraData={{
						course: {
							title: course.courseInfo.title,
							id: course.courseInfo.id,
						},
					}}
				/>
			);
		} else if (message.type == 11) {
			title = SocialPressure.title;
			content = <SocialPressure.content extraData={message.extraData} />;
		} else if (message.type == 12) {
			title = TeamPoke.title;
			content = (
				<TeamPoke.content
					extraData={{
						course: {
							title: course.courseInfo.title,
							id: course.courseInfo.id,
						},
					}}
				/>
			);
		} else if (message.type == 21) {
			title = Welcome.title;
			content = (
				<Welcome.content
					extraData={{
						user: {
							name: user.name !== undefined ? `${user.name} ${user.lastName}` : "",
							gender: user.gender
						},
					}}
				/>
			);
		} else if (message.type == 22) {
			title = NewTeam.title;
			content = (
				<NewTeam.content
					extraData={{
						course: {
							title: course.courseInfo.title,
							id: course.courseInfo.id,
						},
					}}
				/>
			);
		} else if (message.type == 23) {
			title = WelcomeBack.title;
			content = <WelcomeBack.content />;
		}else if (message.type == 31) {
			title = UpgradeInstructorSubscriptionPlan.title;
			content = <UpgradeInstructorSubscriptionPlan.content />;
		}
		

		return { title, content };
	}

	const { title, content } = getMessage();

	return (
		<Modal2
			show={message.type != undefined}
			disableFooter={true}
			onClose={closeModal}
			title={title}
		>
			<h2>{content}</h2>
		</Modal2>
	);
}
