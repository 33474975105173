/** @format */

module.exports = {
	missionsMenu: "Menu de missões",
	newTask: "Nova tarefa",
	noQuestions:
		'Não há questões nesta missão! Crie uma questão clicando no botão de "+" acima.',
	save: "Salvar",
	confirmDelete: "Deseja realmente excluir esta questão?",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
