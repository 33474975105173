module.exports = {
    yourStudents: 'Your students',
    newRegistrations: 'New registrations',
    instructionCurrent: 'See the students who are enrolled in this course.',
    instructionNew: 'See new students who have applied for enrollment in this course.',
    noStudents: 'You have no students in this course! Send the registration key to your students so they can join the course.',
    noRegistrations: 'You do not have any pending enrollment requests for this course.',
    access: 'Access',
    accept: 'Accept',
    reject: 'Reject',
    acceptAll: 'Accept all',
    rejectAll: 'Reject all',
    country:"Country",
    email:"Email",
    name:"Name",
    picture:"Picture",
    acceptColumn:"Accept?",
    gender:"Gender",
    lastAccess:"Last Access",
    studentPage:"Student Page"
}