const punctuation = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'

export function removePunctuation(string) {
	return string
		.split('')
		.filter(function (letter) {
			return punctuation.indexOf(letter) === -1
		})
		.join('')
}
