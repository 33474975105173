/** @format */

import styled from "styled-components";
import { FaChevronDown } from "react-icons/fa";

export const Wrapper = styled.ul`
	list-style: none;
	margin-bottom: 15px;
`;

export const ItemWrapper = styled.div`
	display: flex;
	align-items: center;

	width: 100%;
	margin: 5px 0;
	cursor: pointer;

	${(props) =>
		props.level && { width: `${100 - props.level * 2}%`, margin: "5px auto" }};

    @media screen and (max-width: 1024px) {
        ${(props) =>
            props.level && { width: `${100 - props.level * 3}%`, margin: "5px auto" }};
    }
    @media screen and (max-width: 768px) {
        ${(props) =>
            props.level && { width: `${100 - props.level * 4}%`, margin: "5px auto" }};
    }
    @media screen and (max-width: 425px) {
        ${(props) =>
            props.level && { width: `${100 - props.level * 5}%`, margin: "5px auto" }};
    }
    @media screen and (max-width: 320px) {
        ${(props) =>
            props.level && { width: `${100 - props.level * 6}%`, margin: "5px auto" }};
    }
    

	min-height: 50px;
	padding: 10px;
	border: 1px solid ${(props) => props.theme.colors.mediumGray};
	border-radius: 5px;

	color: ${(props) => props.theme.colors.black};

	${
		"" /* Background and text colors selection based on the mission or question status */
	}

	${(props) => {
		let status = props.status;

		if (!status) {
			return;
		}
		if (status === "pending") {
			return {
				background: props.theme.colors.white,
			};
		} else if (status === "wrong" || status === "not done" || status === "not finished") {
			return {
				background: props.theme.colors.warning,
				color: props.theme.colors.white,
				border: "none",
			};
		} else if (status === "done" || status === "correct") {
			return {
				background: props.theme.colors.green,
				color: props.theme.colors.white,
				border: "none",
			};
		}
        else if(status == "in progress"){
            return {
				background: props.theme.colors.lightBlue,
				color: props.theme.colors.white,
				border: "none",
			};
        }
	}}

    > h3 {
		font-weight: 500;
		font-size: 1.1rem;
		margin-right: auto;
		text-align: justify;
	}

	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 5px;

		> p {
			white-space: nowrap;
		}
	}
`;

export const FaChevron = styled(FaChevronDown)`
	transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
	transition: transform 0.2s;
`;
