import styled from 'styled-components'
import { Button } from '../../GenericComponents'

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`

export const TextCount = styled.span`
    font-size: 0.9rem;
    margin-bottom: 10px;
    color: ${(props) => props.theme.colors.darkGray};
`

export const CreateButton = styled(Button)`
    max-width: 110px;
`
