/** @format */

import Background from "../../environments/entrance/Background";
import Footer from "../../environments/entrance/Footer";
import Header from "../../environments/entrance/Header";
import EntraceAccessManager from "../../common/EntraceAccessManager";
import {
	Title,
	Paragraph,
	SubTitle,
	TitleContainer,
	VerificationContentBox,
} from "./Unsubscribe-styled";
import { useParams } from "react-router-dom";
import { useEffect, useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";

import language from "../../../services/defineLanguage.js";
const texts = require(`../../../texts/${language}/unsubscribe.js`);

function Unsubscribe({}) {
	const { stopAccountVerification } = useContext(EagleContext);
	let { code } = useParams();

	useEffect(async () => {
		await stopAccountVerification(code);
	}, []);

	return (
		<Background>
			<EntraceAccessManager>
				<Header />
				<VerificationContentBox>
					<TitleContainer>
						<Title> {texts.title}</Title>
					</TitleContainer>
					<Paragraph>{texts.description}</Paragraph>
				</VerificationContentBox>
				<Footer />
			</EntraceAccessManager>
		</Background>
	);
}

export default Unsubscribe;
