let createCookiePermission = false
//Try to catch a cookie with de lang
const regex1 = new RegExp(/lang=(..);/)
const regex2 = new RegExp(/lang=(..)/)

let lang = regex1.exec(document.cookie)
lang =  (lang===null) ? regex2.exec(document.cookie) : lang

let language = (lang === null)?  null : lang[1] 

//if don't exists this cookie, get the browser lang
if (language === null || (language !=="en" && language !=="pt")){
    let browserLang = (navigator.browserLanguage!==undefined)?  navigator.browserLanguage : navigator.language;
    let newLang = browserLang.substring(0,2);
    if(newLang === "en" || newLang ==="pt")
        language = newLang
    else //if the browser lang is not defined, set English
        language = "en"
    
    //if the user give us the permission to create cookies, create the lang cookie
    if(createCookiePermission)
        document.cookie = `lang=${language};`
}


    

export default language;