/** @format */
import { Wrapper, BadgeContainer, Description } from "../Message-styled";
import { Button } from "../../../../globalStyles";
import { Link } from "react-router-dom";

import language from "../../../../services/defineLanguage";

const messagesTexts = require(`../../../../texts/${language}/messages`);

function TeamPokeContent({extraData}) {
	return (
		<Wrapper>
			<BadgeContainer>
				<Description>
					<span>
						{messagesTexts.poke_1} {extraData.course.title}{" "}
						{messagesTexts.poke_2}
					</span>
				</Description>
			</BadgeContainer>
			<Button
				as={Link}
				to={`/course/${extraData.course.id}`}
			>
				{" "}
				{messagesTexts.goToCourse}
			</Button>
		</Wrapper>
	);
}

export default {
	content: TeamPokeContent,
	title: messagesTexts.teamWork,
};
