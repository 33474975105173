import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 30px;
    height: 30px;
`

export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #ddd;
    background-color: inherit;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;

    &:hover {
        background-color: #ddd;
    }
`

export const OptionsDropDown = styled.ul`
    display: ${(props) => (props.toggleOptions ? 'block' : 'none')};
    position: absolute;
    background-color: #fff;
    width: 320px;
    z-index: 1;

    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    
    top: 40px;  
    right: 155px;
    transform: translateX(50%);

    @media screen and (max-width: 660px) {
		width: 250px;
        right: 125px;
	}
`

export const OptionItem = styled.li`
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: #ddd;
    }
`
