/** @format */

import Background from "../../../environments/entrance/Background";
import Footer from "../../../environments/entrance/Footer";
import Header from "../../../environments/entrance/Header";
import EntraceAccessManager from "../../../common/EntraceAccessManager";
import { Title, Paragraph, DivTitle } from "./AccountRecoveryRequest-styled";
import { FormBox, FormButton } from "../../../forms/FormsElements";
import TextInput from "../../../forms/input/TextInput";
import { useState, useContext } from "react";
import { EagleContext } from "../../../../contexts/EagleContext";
import { useHistory } from "react-router-dom";

import language from "../../../../services/defineLanguage";
const texts = require(`../../../../texts/${language}/accountRecovery.js`)

function AccountRecoveryRequest({ children }) {
	const [email, setEmail] = useState("");
	const { requestAccountRecovery } = useContext(EagleContext);
	const history = useHistory();

	function onChange(event) {
		setEmail(event.target.value);
	}

	async function handleStartAccountRecovery(event) {
		event.preventDefault();

		try {
			await requestAccountRecovery(email);

			history.push("/accountrecovery/warning");
		} catch (err) {
			console.log("Unknow Error... Try later");
		}
	}
	return (
		<Background>
			<EntraceAccessManager>
				<Header />
				{children}
				<FormBox onSubmit={handleStartAccountRecovery}>
					<DivTitle>
						<Title>{texts.title}</Title>
					</DivTitle>
					<TextInput
						placeholder={texts.email}
						icon="FaEnvelope"
						name="email"
						type="email"
						onChange={onChange}
						value={email}
					/>

					<Paragraph>
						{texts.description}
					</Paragraph>

					<FormButton>{texts.recoveryButton}</FormButton>
				</FormBox>
				<Footer />
			</EntraceAccessManager>
		</Background>
	);
}

export default AccountRecoveryRequest;

// Inserir campo para usuário inserir o email
// Inserir botão para enviar o email
// Inserir onSubmit no botão para enviar email
