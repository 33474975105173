/** @format */

import styled from "styled-components";

export const ErrorMessage = styled.div`
	color: ${(props) => props.theme.colors.darkRed};
	background-color: ${(props) => props.theme.colors.lightRed};
	font-weight: 600;
	font-size: 1.2em;
	padding: 10px;
	border-radius: 5px;
	margin: ${({ margin }) => margin ?? "0"};
	width: 100%;
`;

export const SuccessMessage = styled.div`
	color: ${(props) => props.theme.colors.darkGreen};
	background-color: ${(props) => props.theme.colors.lightGreen};
	font-weight: 600;
	font-size: 1.2em;
	padding: 10px;
	border-radius: 5px;
	margin: ${({ margin }) => margin ?? "0"};
	width: 100%;
`;
