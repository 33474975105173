import { useEffect } from 'react'
import AchievementsGrid from '../../../environments/courses-board/Achievements/AchievementsGrid/AchievementsGrid'
import { AchievementsContainer } from '../../../environments/courses-board/BoardSectionContainers'

import language from '../../../../services/defineLanguage'
const texts = require(`../../../../texts/${language}/course`)

const Achievements = ({ achievementsAchieved }) => {
  useEffect(() => {
    try {
    } catch (error) {
      console.error('')
    }
  })

  return (
    <AchievementsContainer>
      <h3>{texts.achievementsTitle}</h3>
      <AchievementsGrid achievementsAchieved={achievementsAchieved}/>
    </AchievementsContainer>
  )
}

export default Achievements
