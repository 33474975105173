import { useState } from 'react'
import { Wrapper } from './OpenQuestion-styled'
import { QuestionType, QuestionInstruction } from '../Question-styled'
import { FaQuoteLeft } from 'react-icons/fa'
import TextArea from '../../../../common/TextArea/TextArea'

import language from '../../../../../services/defineLanguage'
const texts = require(`../../../../../texts/${language}/instructor/openQuestion`)

export default function OpenQuestion() {
    const [question, setQuestion] = useState('')

    return (
        <>
            <FaQuoteLeft size={50} />
            <QuestionType>{texts.type}</QuestionType>
            <QuestionInstruction>{texts.instruction}</QuestionInstruction>
            <Wrapper>
                <TextArea
                    placeholder={texts.typeHere}
                    value={question}
                    setValue={setQuestion}
                />
            </Wrapper>
        </>
    )
}
