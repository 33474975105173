import { useState } from 'react'
import SettingsContent from '../SettingsContent/SettingsContent'
import { MainContentInner } from '../SettingsContent/SettingsContent-styled'
import ToggleButton from '../../../common/ToggleButton/ToggleButton'

import language from '../../../../services/defineLanguage'
const texts = require(`../../../../texts/${language}/gamificationAdvanced`)

export default function SettingsGamification() {
    const [acknowledgement, setAcknowledgement] = useState(true)
    const [level, setLevel] = useState(true)
    const [point, setPoint] = useState(true)
    const [progression, setProgression] = useState(true)
    const [stats, setStats] = useState(true)
    const [chance, setChance] = useState(true)
    const [economy, setEconomy] = useState(true)
    const [imposedChoice, setImposedChoice] = useState(true)
    const [rarity, setRarity] = useState(true)
    const [timePressure, setTimePressure] = useState(true)
    const [competition, setCompetition] = useState(true)
    const [cooperation, setCooperation] = useState(true)
    const [reputation, setReputation] = useState(true)
    const [socialPressure, setSocialPressure] = useState(true)
    const [novelty, setNovelty] = useState(true)
    const [objectives, setObjectives] = useState(true)
    const [puzzles, setPuzzles] = useState(true)
    const [renovation, setRenovation] = useState(true)
    const [sensation, setSensation] = useState(true)
    const [narrative, setNarrative] = useState(true)
    const [storytelling, setStorytelling] = useState(true)

    return (
            <SettingsContent title={texts.gamification}>
                <MainContentInner margin="0 0 0 10px">
                    <ToggleButton
                        label={texts.acknowledgement.title}
                        checked={acknowledgement}
                        setChecked={setAcknowledgement}
                        useModal
                        modalContent={texts.acknowledgement}
                    />
                    <ToggleButton
                        label={texts.level.title}
                        checked={level}
                        setChecked={setLevel}
                        useModal
                        modalContent={texts.level}
                    />
                    <ToggleButton
                        label={texts.point.title}
                        checked={point}
                        setChecked={setPoint}
                        useModal
                        modalContent={texts.point}
                    />
                    <ToggleButton
                        label={texts.progression.title}
                        checked={progression}
                        setChecked={setProgression}
                        useModal
                        modalContent={texts.progression}
                    />
                    <ToggleButton
                        label={texts.stats.title}
                        checked={stats}
                        setChecked={setStats}
                        useModal
                        modalContent={texts.stats}
                    />
                    <ToggleButton
                        label={texts.chance.title}
                        checked={chance}
                        setChecked={setChance}
                        useModal
                        modalContent={texts.chance}
                    />
                    <ToggleButton
                        label={texts.economy.title}
                        checked={economy}
                        setChecked={setEconomy}
                        useModal
                        modalContent={texts.economy}
                    />
                </MainContentInner>
                <MainContentInner margin="0 0 0 10px">
                    <ToggleButton
                        label={texts.imposedChoice.title}
                        checked={imposedChoice}
                        setChecked={setImposedChoice}
                        useModal
                        modalContent={texts.imposedChoice}
                    />
                    <ToggleButton
                        label={texts.rarity.title}
                        checked={rarity}
                        setChecked={setRarity}
                        useModal
                        modalContent={texts.rarity}
                    />
                    <ToggleButton
                        label={texts.timePressure.title}
                        checked={timePressure}
                        setChecked={setTimePressure}
                        useModal
                        modalContent={texts.timePressure}
                    />
                    <ToggleButton
                        label={texts.competition.title}
                        checked={competition}
                        setChecked={setCompetition}
                        useModal
                        modalContent={texts.competition}
                    />
                    <ToggleButton
                        label={texts.cooperation.title}
                        checked={cooperation}
                        setChecked={setCooperation}
                        useModal
                        modalContent={texts.cooperation}
                    />
                    <ToggleButton
                        label={texts.reputation.title}
                        checked={reputation}
                        setChecked={setReputation}
                        useModal
                        modalContent={texts.reputation}
                    />
                    <ToggleButton
                        label={texts.socialPressure.title}
                        checked={socialPressure}
                        setChecked={setSocialPressure}
                        useModal
                        modalContent={texts.socialPressure}
                    />
                </MainContentInner>
                <MainContentInner margin="0 0 0 10px">
                    <ToggleButton
                        label={texts.novelty.title}
                        checked={novelty}
                        setChecked={setNovelty}
                        useModal
                        modalContent={texts.novelty}
                    />
                    <ToggleButton
                        label={texts.objectives.title}
                        checked={objectives}
                        setChecked={setObjectives}
                        useModal
                        modalContent={texts.objectives}
                    />
                    <ToggleButton
                        label={texts.puzzles.title}
                        checked={puzzles}
                        setChecked={setPuzzles}
                        useModal
                        modalContent={texts.puzzles}
                    />
                    <ToggleButton
                        label={texts.renovation.title}
                        checked={renovation}
                        setChecked={setRenovation}
                        useModal
                        modalContent={texts.renovation}
                    />
                    <ToggleButton
                        label={texts.sensation.title}
                        checked={sensation}
                        setChecked={setSensation}
                        useModal
                        modalContent={texts.sensation}
                    />
                    <ToggleButton
                        label={texts.narrative.title}
                        checked={narrative}
                        setChecked={setNarrative}
                        useModal
                        modalContent={texts.narrative}
                    />
                    <ToggleButton
                        label={texts.storytelling.title}
                        checked={storytelling}
                        setChecked={setStorytelling}
                        useModal
                        modalContent={texts.storytelling}
                    />
                </MainContentInner>
            </SettingsContent>
    )
}
