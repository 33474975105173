/** @format */
import {
	Wrapper,
	EmojiImage,
	Description,
} from "../Message-styled";
import StatusMessage from "../StatusMessage/StatusMessage";
import coin from "../../../../assets/images/coin.svg";

import language from '../../../../services/defineLanguage'

const messagesTexts = require(`../../../../texts/${language}/messages`)

let subTypeMap = {
	1: {
		originBefore: (extraData) => {return <>{messagesTexts.economy_origin_1} <b>{messagesTexts.soYouEarned}</b></>},
		originAfter: <b>!</b>,
	},
	2: {
		originBefore:(extraData) => {return <>{messagesTexts.economy_origin_2} <b>{messagesTexts.soYouEarned}</b></>},
		originAfter: <b>!</b>,
	},
	3: {
		originBefore:(extraData) => {return <>{messagesTexts.economy_origin_3} <b>{messagesTexts.soYouEarned}</b></>},
		originAfter: <b>!</b>,	
	},
	4: {
		originBefore: (extraData) => {return <>{messagesTexts["economy_origin_4.1"]}  <b>{messagesTexts.youHaveEarned}</b></>},
		originAfter: <><b>! </b>{messagesTexts["economy_origin_4.2"]}</>,
	},
	5: {
		originBefore: (extraData) => {return <>{messagesTexts["economy_origin_5.1"]}  <b>{messagesTexts.youHaveEarned}</b></>},
		originAfter: <><b>! </b>{messagesTexts["economy_origin_5.2"]}</>,
	},
	6: {
		originBefore: (extraData) => {return <>{messagesTexts["economy_origin_6.1"]}  <b>{messagesTexts.soYouEarned}</b></>},
		originAfter: <><b>! </b>{messagesTexts["economy_origin_6.2"]}</>,
	},
	7: {
		originBefore: (extraData) => {return <>{messagesTexts["economy_origin_7.1"]}  <b>{messagesTexts.soYouEarned}</b></>},
		originAfter: <><b>! </b>{messagesTexts["economy_origin_7.2"]}</>,
	},
	8: {
		originBefore: (extraData) => {return <>{messagesTexts["economy_origin_8.1"]} {extraData.course.title} {messagesTexts["economy_origin_8.2"]} <b>{messagesTexts["economy_origin_8.3"]}</b></>},
		originAfter: <b>! </b>,
	}
};

function CoinsObtained({ subType, value, extraData }) {
	let currentMessage = subTypeMap[`${subType}`];
	return (
		<Wrapper>
			<EmojiImage src={coin} />
			<Description>
				{currentMessage.originBefore(extraData)}{" "}
				
					
						{" "}
						<StatusMessage
							value={value}
							type={"economy"}
						/>
					
				{currentMessage.originAfter}
			</Description>
		</Wrapper>
	);
}

export default {
	content: CoinsObtained,
	title: messagesTexts.congratulations,
};
