/** @format */

module.exports = {
	success: "Nova imagem de perfil enviada com sucesso!",
	tryAgain:"Tentar novamente",
	finish: "Finish",

	errors: {
		FileNotSelected: "Por favor selecione o arquivo",
		FileTooLarge: "O tamanho do arquivo não pode exceder 5MB",
		default:"A imagem de perfil não pode ser enviada... Tente mais tarde!",
	},
};
