import styled from "styled-components"
import { FaChevronDown } from "react-icons/fa"

export const ProfileDropdown = styled.button`
	margin: 0 0 0 auto;
	padding: 3px 5px;
	border: none;
	outline: none;
	position: relative;

	color: ${(props) => props.theme.colors.black};
	background-color: inherit;
	cursor: pointer;

	z-index: 2;
`

export const DropdownTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const DropIcon = styled(FaChevronDown)`
	margin-left: 5px;
`

export const DropdownContent = styled.div`
	display: ${(props) => (props.isShown ? "flex" : "none")};
	flex-direction: column;
	position: absolute;
	right: 10px;
	top: 60px;

	min-width: 190px;
	margin-top: 10px;
	background-color: inherit;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
`

export const DropdownLink = styled.a`
	width: 100%;
	padding: 10px;
	text-decoration: none;
	color: inherit;
	text-align: left;
	font-size: 1rem;

	box-sizing: border-box;

	&:hover {
		background-color: ${(props) => props.theme.hover.primary};
		color: ${(props) => props.theme.colors.white};
	}

	&:first-child {
		border-radius: 10px 10px 0 0;
	}

	&:last-child {
		border-radius: 0 0 10px 10px;
	}
`