import OneOff from '../assets/images/achievements/1_off.svg'
import OneOn from '../assets/images/achievements/1_on.svg'
import TwoOff from '../assets/images/achievements/2_off.svg'
import TwoOn from '../assets/images/achievements/2_on.svg'
import ThreeOff from '../assets/images/achievements/3_off.svg'
import ThreeOn from '../assets/images/achievements/3_on.svg'
import FourOff from '../assets/images/achievements/4_off.svg'
import FourOn from '../assets/images/achievements/4_on.svg'
import FiveOff from '../assets/images/achievements/5_off.svg'
import FiveOn from '../assets/images/achievements/5_on.svg'
import SixOff from '../assets/images/achievements/6_off.svg'
import SixOn from '../assets/images/achievements/6_on.svg'

const achievementsPatentImgMap =[
    OneOff,
    OneOn,
    TwoOff,
    TwoOn,
    ThreeOff,
    ThreeOn,
    FourOff,
    FourOn,
    FiveOff,
    FiveOn,
    SixOff,
    SixOn
]

export default achievementsPatentImgMap
