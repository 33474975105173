import goldenEagle1 from "../assets/images/avatars/GoldenEagle/1.svg";
import harpia1 from "../assets/images/avatars/Harpia/1.svg";
import stellerEagle1 from "../assets/images/avatars/StellerEagle/1.svg";

import goldenEagle2 from "../assets/images/avatars/GoldenEagle/2.svg";
import harpia2 from "../assets/images/avatars/Harpia/2.svg";
import stellerEagle2 from "../assets/images/avatars/StellerEagle/2.svg";

import goldenEagle3 from "../assets/images/avatars/GoldenEagle/3.svg";
import harpia3 from "../assets/images/avatars/Harpia/3.svg";
import stellerEagle3 from "../assets/images/avatars/StellerEagle/3.svg";

import goldenEagle4 from "../assets/images/avatars/GoldenEagle/4.svg";
import harpia4 from "../assets/images/avatars/Harpia/4.svg";
import stellerEagle4 from "../assets/images/avatars/StellerEagle/4.svg";

import goldenEagle5 from "../assets/images/avatars/GoldenEagle/5.svg";
import harpia5 from "../assets/images/avatars/Harpia/5.svg";
import stellerEagle5 from "../assets/images/avatars/StellerEagle/5.svg";

import goldenEagle6 from "../assets/images/avatars/GoldenEagle/6.svg";
import harpia6 from "../assets/images/avatars/Harpia/6.svg";
import stellerEagle6 from "../assets/images/avatars/StellerEagle/6.svg";

import goldenEagle7 from "../assets/images/avatars/GoldenEagle/7.svg";
import harpia7 from "../assets/images/avatars/Harpia/7.svg";
import stellerEagle7 from "../assets/images/avatars/StellerEagle/7.svg";


const avatarsImages = {
	GoldenEagle:{
		1:goldenEagle1,
		2:goldenEagle2,
		3:goldenEagle3,
		4:goldenEagle4,
		5:goldenEagle5,
		6:goldenEagle6,
		7:goldenEagle7
	},
	Harpia:{
		1:harpia1,
		2:harpia2,
		3:harpia3,
		4:harpia4,
		5:harpia5,
		6:harpia6,
		7:harpia7
	},
	StellerEagle:{
		1:stellerEagle1,
		2:stellerEagle2,
		3:stellerEagle3,
		4:stellerEagle4,
		5:stellerEagle5,
		6:stellerEagle6,
		7:stellerEagle7
	}
}
export default avatarsImages
