/** @format */

import React, { useState, useContext } from "react";
import {
	ButtonSearchCourse,
	InputSearchCourse,
	SearchResults,
	TitleSearchCourse,
	AddCourseWrapper,
	SubTitleSearchCourse,
	DivLine,
	ButtonConfirmInscription,
	ButtonCancelInscription,
	ButtonsContainer,
	ResultMessage,
    InputWrapper,
} from "./AddCourse-styled";
import TextInput from "../../forms/input/TextInput";
import { theme } from "../../../contexts/theme/Theme";
import { IconContext } from "react-icons";

import { EagleContext } from "../../../contexts/EagleContext";

import language from "../../../services/defineLanguage";
const texts = require(`../../../texts/${language}/addCourse`);

function AddCourse({ disableTitle }) {
	const { searchCourse, enrollCourse } = useContext(EagleContext);
	const [courseInfo, setCourseInfo] = useState({ registrationKey: "" });
	const [enrollResult, setEnrollResult] = useState(null);

	const confirmInscription = async () => {
		let result = await enrollCourse(courseInfo.registrationKey);
		setEnrollResult(result);
	};

	return (
		<AddCourseWrapper>
			{!disableTitle && (
				<TitleSearchCourse>{texts.addCourse}</TitleSearchCourse>
			)}
			<DivLine />

			<SubTitleSearchCourse>{texts.description}</SubTitleSearchCourse>
			<IconContext.Provider
				value={{
					color: theme.colors.primary,
					size: "30",
				}}
			>
				<InputWrapper>
					<TextInput
						placeholder={texts.registrationKey}
						icon="FaKey"
						name="registrationKey"
						value={courseInfo.registrationKey}
						onChange={(e) => {
							setCourseInfo({ registrationKey: e.target.value });
							setEnrollResult(null);
						}}
					/>
				</InputWrapper>
			</IconContext.Provider>
            
            { (courseInfo.title !== undefined || courseInfo.error !== undefined) &&
			<SearchResults courseFound={courseInfo.title !== undefined}>
				{courseInfo.title && <ResultMessage>{texts.courseFound}</ResultMessage>}
				{courseInfo.title && (
					<ResultMessage>
						{texts.courseName}
						{courseInfo.title}
					</ResultMessage>
				)}
				{courseInfo.error && <ResultMessage>{texts.errors[courseInfo.error]}</ResultMessage>}
			</SearchResults>
            }
            { enrollResult &&
			<SearchResults courseFound={enrollResult && enrollResult.success}>
				{enrollResult && enrollResult.error && (
					<ResultMessage>{texts.errors[enrollResult.error]}</ResultMessage>
				)}
				{enrollResult && enrollResult.success && (
					<ResultMessage>{texts.enrolledSuccessfuly}</ResultMessage>
				)}
			</SearchResults>
}

			{enrollResult === null &&
				(courseInfo.title ? (
					<ButtonsContainer>
						<ButtonConfirmInscription onClick={confirmInscription}>
							{texts.confirmRegistration}
						</ButtonConfirmInscription>
						<ButtonCancelInscription
							onClick={() => {
								setEnrollResult(null);
								setCourseInfo({ registrationKey: "" });
							}}
						>
							{texts.cancel}
						</ButtonCancelInscription>
					</ButtonsContainer>
				) : (
					<ButtonSearchCourse marginTop={(courseInfo.title !== undefined || courseInfo.error !== undefined)? "10px" : "20px"}
						onClick={async () => {
							let newCourseInfo = await searchCourse(
								courseInfo.registrationKey
							);
							setCourseInfo((prevState) => {
								let newState = { ...prevState, ...newCourseInfo };
								return newState;
							});
						}}
					>
						{texts.searchCourse}
					</ButtonSearchCourse>
				))}
		</AddCourseWrapper>
	);
}

export default AddCourse;
