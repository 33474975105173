import styled from 'styled-components';
import { CenterBox } from "../../../GenericComponents";

export const VerificationContentBox = styled(CenterBox)`
  height: 30vh;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  // background-color: purple;
 `;

 export const TitleContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   width: 100%;
   margin-bottom: 20px;
   padding: 0;
  //  background-color: red;
 `;

export const Title = styled.h2`
  width: 100%;
  height: 100%;
  color: black;
  // margin-top: -90px;
  // margin-bottom: 0;
  // padding: 0;
  text-align: center;
  // background-color: green;
`;

export const SubTitle = styled.p`
    color: grey;
    font-size: 1.1rem;
    margin-bottom: 20px;
    text-align: center;
`;