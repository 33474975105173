import styled from 'styled-components'

export const PokeIcon = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

export const TeamHeaderWrapper = styled.div`
	display: grid;
	grid-template-columns: ${(props) => props.socialPressure? "40% 20% 40%" : "50% 50%"};
	
	justify-items: center;

	border-bottom: 1px solid ${(props) => props.theme.colors.gray};
	margin-bottom: 0.3rem;
	padding-bottom: 0.3rem;

	@media screen and (max-width: 1300px) {
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: row;
	}
`
