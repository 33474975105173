/** @format */

import api from "./api";
import Cookies from "js-cookie";
import { validatePasswordStrength } from "./handleCredentials";

export async function login({ email, password }) {
	let res = await api.post("/users/login", { email, password });
	if (res.data && res.data.data && res.data.data.id) {
		return res.data.data;
	}
	return {};
}

export async function updateUserInfo({
	name,
	lastName,
	birthDay,
	gender,
	country,
}) {
	try {
		let res = await api.patch("/user", {
			name,
			lastName,
			birthDay,
			gender,
			country,
		});

		return { success: true, newUserData: res.data.data };
	} catch (err) {
		return { success: false };
	}
}

export async function logout() {
	// if (Cookies.get("session")) {
	try {
		await api.post("/users/logout");
	} catch (error) {}
	Cookies.remove("session");
	// }
}

export async function changePassword(
	currentPassword,
	newPassword,
	newPasswordConfirm
) {
	try {
		if (newPassword !== newPasswordConfirm) {
			return { success: false, error: "Different Passwords" };
		}

		if (!validatePasswordStrength(newPassword)) {
			return { success: false, error: "Weak password" };
		}
		let res = await api.post("/user/changePassword", {
			currentPassword,
			newPassword,
		});

		if (res.data.message === "User password updated successfully!") {
			return { success: true };
		}
	} catch (err) {
		return {
			success: false,
			error: err.response?.data?.message,
		};
	}
}

export async function getUser() {
	try {
		// if (Cookies.get("session")) {
		let response = await api.get("/user");
		if (response.data.data.id) {
			return response.data.data;
		}
		// }
	} catch (err) {}
	return {};
}

export async function updateProfileImage(newProfileImage) {
	try {
		const form = new FormData();
		form.append("file", newProfileImage);

		let res = await api.put(`/user/profileImage`, form);

		console.log(res.data);
		if (res.data && res.data.data && res.data.data.pictureURL) {
			return res.data.data.pictureURL;
		}
	} catch (err) {
		console.log(err.response);
		console.log(err);
	}
	return null;
}

export async function getLastSessions(numbreOfSessions = 2) {
	let res = await api.get("/user/lastSessions", {
		params: { take: numbreOfSessions },
	});
	if (res.data && res.data.data && res.data.data && res.data.data.length) {
		return res.data.data;
	}
	return [];
}

export async function getLastAccess(numbreOfAccess = 2) {
	let res = await api.get("/user/lastAccess", {
		params: { take: numbreOfAccess },
	});
	if (res.data && res.data.data && res.data.data && res.data.data.length) {
		return res.data.data;
	}
	return [];
}

export async function startFinalizeRegistration(code) {
	try {
		let res = await api.post("/user/finalizeRegistration/start", {
			code,
		});

		return { success: true, user: res.data.data };
	} catch (err) {
		return {
			success: false,
			message: err.response?.data?.message || "default",
		};
	}
}

export async function finalizeRegistration(
	code,
	name,
	lastName,
	birthDay,
	birthMonth,
	birthYear,
	gender,
	country,
	password
) {
	try {
		let res = await api.post("/user/finalizeRegistration", {
			code,
			name,
			lastName,
			birthDay,
			birthMonth,
			birthYear,
			gender,
			country,
			password,
		});

		return { success: true };
	} catch (err) {
		return {
			success: false,
			message: err.response?.data?.message || "default",
		};
	}
}
