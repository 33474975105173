module.exports = {
    login:"LOGIN",
    placeholderEmail:"Enter your email",
    placeholderPassword:"Enter your password",
    keepMeConnected:"Keep me connected",
    signin:"Sign in",
    forgotYourPassword:[ "Forgot your ", "password?" ],
    registerNow:[ "Register ", "now!" ],

    errors:{
        
        userNotFound:"We couldn't find a user with the email you entered!",
        incorrectPassword:"Incorrect password!",
        unableToLogin:["Unable to login (",")."]
        
    }
}