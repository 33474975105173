/** @format */

import styled from "styled-components";

export const RankingPositionWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	height: 3.5rem;
	padding: 0.5rem;

	align-items: center;
	display: grid;
	grid-template-columns: 10% 20% 50% 10%;

	border-bottom: 1px solid ${(props) => props.theme.colors.gray};

	span {
		justify-self: center;
	}

	span:first-of-type {
		justify-self: left;
	}

	span:last-of-type {
		justify-self: right;
	}

	color: ${(props) => props.mainUser && props.theme.colors.orange} !important;
	`;

export const ProfilePic = styled.img`
	height: 2.5rem;
	width: 2.5rem;

	border-radius: 50%;
`;

export const RankingName = styled.main`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;
