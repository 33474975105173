/** @format */

import { useEffect } from "react";
import { LevelContainer } from "../../../environments/courses-board/BoardSectionContainers";
import DynamicLevelBadge from "../../../environments/courses-board/Level/DynamicLevelBadge";

import language from "../../../../services/defineLanguage";
const texts = require(`../../../../texts/${language}/course`);
const levelsTexts = require(`../../../../texts/${language}/levels`);

const Level = ({ level }) => {
	useEffect(() => {
		try {
		} catch (error) {
			console.error("");
		}
	});

	return (
		<LevelContainer>
			<h3>{texts.levelTitle}</h3>
			<DynamicLevelBadge levelId={level} />
			<h1>{levelsTexts[level].name}</h1>
			<p style={{textAlign:"justify"}}>{levelsTexts[level].description}</p>
		</LevelContainer>
	);
};

export default Level;
