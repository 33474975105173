/** @format */

module.exports = {
	accountVerificationTitle: "Verificação de conta",
	verifyingWaitASecond:
		"Estamos verificando sua conta. Aguarde alguns instantes.",
	accountVerified1: "Conta verificada com sucesso! Faça seu ",
	accountVerified2: ".",
	login: "login",
	accountNotVerified: "Não foi possível verificar sua conta! ",
	errors: {
		"Code timeout exceeded":
			"Token de verificação expirado. Nós mandamos um novo token para seu e-mail.",
		"Invalid Token": "Token de verificação inválido.",
	},

	description: "Foi enviado um email com o código de verificação para ",
	"case_1.1": "- Caso você não tenha recebido, clique ",
	"case_1.2": "aqui",
	"case_1.3": " para que nós enviemos um novo código;",
	case_2:
		"- Caso sua conta esteja vinculado a alguma organização, contate o responsável pela sua organização;",
	case_3: "- Caso você esteja tendo outros problemas de acesso, contate-nos em ",
};
