import { CardContainer, CardTitle, CardText, CardLink } from './Card-styled'

import language from '../../../services/defineLanguage.js'
const texts = require(`../../../texts/${language}/card`)

export default function Card({ children, style, title, linkText, link }) {
    return (
        <CardContainer style={style}>
            <CardTitle title={title}>{title}</CardTitle>
            <CardText title={children}>{children}</CardText>
            <CardLink to={link}>{linkText || texts.access}</CardLink>
        </CardContainer>
    )
}
