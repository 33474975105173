import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 15px;
    padding: 15px 5px;
    overflow: hidden;

    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.white};
`

export const Header = styled.div`
    display: flex;
    
    width: 80%;
    height: 70px;
    padding: 9px;

    @media screen and (max-width: 420px) {
        width: 100%;
    }


    h2 {
        width: 50%;
        margin-right: auto;
        margin-left: auto;
        @media screen and (max-width: 420px) {
            margin-left: 5px;
        }
    }

`

export const StatusWrapper = styled.div`
    display:flex;
    justify-content:space-around;
    width: 50%;
    margin-right:8px;

    img {
        width: 30.00%;
        max-height: 40px;
    }
`
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    max-height: ${(props) => `calc(4.5*${props.theme.height.friendRow})`};
    width: 100%;
    overflow-y: scroll;
`
