/** @format */

import { CourseOverviewContainer } from "../../../environments/courses-board/BoardSectionContainers";
import { CourseTitle } from "../course-styling";
import DynamicFaIcon from "../../../common/DynamicFaIcon";
import { CoursePercentage } from "../course-styling";
import ProgressBar from "../../../common/ProgressBar/ProgressBar";

import { useContext } from "react";
import { EagleContext } from "../../../../contexts/EagleContext";

const CourseOverview = ({ data: CurrentCourse }) => {
	const { getCurrentCourseSetting } = useContext(EagleContext);
	return (
		<CourseOverviewContainer>
			<CourseTitle>
				<DynamicFaIcon name={CurrentCourse.icon} size={40} />
				<p>{CurrentCourse.title}</p>
			</CourseTitle>
			{CurrentCourse.progress !== undefined && getCurrentCourseSetting().progression && (
				<>
					<CoursePercentage>({CurrentCourse.progress}%)</CoursePercentage>
					<ProgressBar completionRate={CurrentCourse.progress} />
				</>
			)}
		</CourseOverviewContainer>
	);
};

export default CourseOverview;
