/** @format */

module.exports = {
	and: "e",
	congratulations: "Parabéns!",
	congratulations_2: "parabéns!",
	achievement: "Você tem uma nova conquista!",
	levels: "Você subiu de nível!",
	points_1: "Você ganhou ",
	points_2: " pontos",
	points_3: "por isso ganhou ",
	youHaveEarned: "você ganhou ",
	soYouEarned: "por isso ganhou",
	"points_origin_1.1":
		"Não foi dessa vez. Você terminou a missão, mas errou uma tarefa, ",
	"points_origin_1.2":
		"Capriche e termine a missão sem errar nada para ganhar mais pontos.",
	points_origin_2:
		"Meu orgulho! Você terminou a missão sem errar nenhuma tarefa, ",
	points_origin_3:
		"Coragem é algo que você tem! Você aceitou o desafio e terminou a missão sem errar nenhuma tarefa, ",
	points_origin_4:
		"Não foi dessa vez. Você terminou a missão, mas errou uma tarefa. Capriche e termine a missão sem errar nada na próxima. ",
	points_origin_5:
		"Você aceitou o desafio e errou alguma coisa, mas não se preocupe, na próxima vez você vai conseguir!",
	coins_1: "Você ganhou ",
	coins_2: " moedas! ",
	coins_3: "por isso ganhou ",
	coins_4: "você ganhou ",
	coins_5: "ganhou ",
	coins_6: " moedas",
	economy_origin_1:
		"Você foi demais! Por ficar em primeiro lugar no ranking semanal, ",
	economy_origin_2:
		"Essa semana você mandou muito bem! Por ficar em segundo lugar no ranking semanal, ",
	economy_origin_3:
		"Aí sim em, essa semana você foi muito bem! Por ficar em terceiro lugar no ranking semanal, ",
	"economy_origin_4.1":
		"Essa semana, você ficou  em quarto lugar no ranking e ",
	"economy_origin_4.2":
		"Capriche mais na próxima semana para ganhar mais moedas.",
	"economy_origin_5.1":
		"Essa semana, você ficou em quinto lugar no ranking semanal e ",
	"economy_origin_5.2": "Tente melhorar para ganhar mais moedas. ",
	"economy_origin_6.1":
		"Você está mandando muito bem e passou para o próximo nível, ",
	"economy_origin_6.2": "Continue assim...",
	"economy_origin_7.1": "Você conseguiu se manter no mesmo nível, ",
	"economy_origin_7.2":
		"Agora é hora de melhorar para conseguir passar para o próximo nível.",
	"economy_origin_8.1": "O seu time do curso ",
	"economy_origin_8.2": " conseguiu completar a missão semanal, ",
	"economy_origin_8.3": "por isso todos ganharam ",
	economy_origin_9:
		"Muito bom! Você terminou a missão sem errar nenhuma tarefa, ",
	economy_origin_10:
		"Mostrou coragem! Você aceitou o desafio e terminou a missão sem errar nenhuma tarefa, ",
	continue: "Continuar",
	search: "Pesquisa",
	userFormsInvitation:
		"Precisamos que você responda a um formulário para entendermos que tipo de usuário você é! Quer nos ajudar?",
	wantToHelp: "Sim, quero ajudar!",
	dontWantToHelp: "Não quero ajudar.",
	socialPressureTitle: "Você está ficando para trás!",
	socialPressure_1: "Seu colega ",
	socialPressure_2:
		" acaba de conseguir terminar 10 atividades. Não fique para trás, pratique agora mesmo!",
	hello: "Olá ",
	titleWelcome: "Seja bem-vindo!",
	welcome:
		" seja bem-vindo ao Eagle-edu! Veja os cursos que você está matriculado ao lado esquerdo e comece agora mesmo a praticar e se divertir conosco.",
	teamWork: "Trabalho em equipe",
	poke_1: "Sua equipe do curso ",
	poke_2: " está te cutucando. Termine as suas missões semanais!",
	goToCourse: "Ir para o Curso",
	newTeam_title: "Novo time!",
	newTeam_desc1: "Você foi adicionado em um novo time no curso ",
	newTeam_desc2: "Vá para o curso conferir sua nova equipe!",
	teamSuccess_desc1: " Seu time do curso ",
	teamSuccess_desc2:
		" completou todas as tarefas da semana. Continue se esforçando assim!!!",
	welcomeBack_title: "Bem-vindo de volta!",
	welcomeBack_desc:
		" Você esteve longe por algum tempo, sentimos sua falta... Agora, não perca mais tempo e volte ao trabalho!",
	upgradeInstructorSubscriptionPlan_title: "Atualize seu plano de assinatura",
	upgradeInstructorSubscriptionPlan_desc: `
	Ação negada! Infelizmente, seu plano atual não oferece acesso a esta
	funcionalidade ou atingiu o limite permitido. Faça o upgrade agora e
	aproveite ao máximo!!`,
	upgradeNow:"Atualizar"
};
