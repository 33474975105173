/** @format */

module.exports = {
	security: "Segurança",
	email: "Email",
	password: "Senha",
	current: "Atual",
	newPassword: "Nova senha",
	changePassword: "Alterar senha",
	confirmNewPassword: "Confirmar nova senha",
	success: "Senha mudada com sucesso!",
	errors: {
		"Password provided may be wrong.": "Senha fornecida pode estar errada.",
		"Different Passwords": "A nova senha e sua confirmação não coincidem.",
		"Weak password":
			"A senha deve ter pelo menos 8 caracteres, incluindo letras maiúsculas e minúsculas, números e caracteres especiais (! @ # ? $ *).",
		"Current password and new password should not be the same.":
			"A senha atual e a nova senha não devem ser iguais.",
		default: "Algo deu errado... tente mais tarde.",
	},
};
