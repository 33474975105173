/** @format */

import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin: 15px;
	padding: 15px 5px;
	overflow: hidden;

	box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	background-color: ${(props) => props.theme.colors.white};

	img {
		flex: none;
		width: 100%;
		max-height: 100%;
		object-fit: cover;
		animation: fadeIn 3s, float ease-in-out 6s infinite;
		animation-delay: 0s, 3s;
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
			transform: translateY(1000px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@keyframes float {
		0% {
			transform: translateY(0);
		}
		50% {
			transform: translateY(10px);
		}
		100% {
			transform: translateY(0);
		}
	}
`;
