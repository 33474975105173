import React from 'react'; // Importe o React para que o componente funcione corretamente
import { Container } from './LinkWithIcon-styled';
import DynamicFaIcon from '../DynamicFaIcon';
import { Link } from 'react-router-dom';

export default function LinkWithIcon({ iconName, iconSize, to, children, hover, instructorStyle }) {
  // Setting default values
  const IconName = iconName || '';
  const IconSize = iconSize || 0;
  const Style = instructorStyle ? { color: '#58595B', fontSize: '1.25rem', maxWidth: '220px' } : {};

  return (
    
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Container hover={hover} style={Style}>
        <DynamicFaIcon name={IconName} size={IconSize} />
        {children}
      </Container>
    </Link>
  );
}
