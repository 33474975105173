/** @format */

module.exports = {
	edit: "Edit",
	clone: "Clone",
	delete: "Delete",
	registrationKey: "Registration key:",
	acceptStudents: "Automatically accept new students:",
	on: " On",
	off: " Off",
	editCourse: "Edit course",
	cloneCourse: "Clone course",
	cloneWarning:
		"Are you sure you want to clone this course? This is a costly process and can take some time.",
	cancel: "Cancel",
	cloning: "Cloning...",
	cloned: "Your course has been cloned!",
	newSubject: "New subject",
	createSubject: "Create new subject",
	confirmDelete: "Do you really want to delete this course?",
	errors: {
		default: "Something went wrong... try again later.",
		clone:
			"Something went wrong with the cloning... try again later. If the problem persists, please contact us at contact@eagle-edu.com.br",
	},
};
