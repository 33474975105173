import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin: 15px;
    padding: 30px;
    border-radius: 10px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`

export const Title = styled.h1`
    margin-bottom: 5px;
    font-size: 2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
`

export const SubTitle = styled.h2`
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 15px;
    color: ${(props) => props.theme.colors.black};
`

export const CardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    justify-content: space-evenly;
    grid-gap: 15px;

    @media screen and (max-width: 400px) {
        grid-template-columns: repeat(auto-fill, 230px);
    }
`

export const Card = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 30px;
    border-radius: 10px;
    border: 2px solid ${(props) => props.theme.colors.gray};

    text-decoration: none;
    color: ${(props) => props.theme.colors.black};

    > h3 {
        font-weight: 500;
        margin-bottom: 15px;
    }

    > img {
        max-width: 50%;
        margin-bottom: 15px;
    }

    > hr {
        min-width: 100%;
        height: 2px;
        background-color: ${(props) => props.theme.colors.gray};
        border: none;
    }

    > p {
        margin-top: 15px;
        
        &:hover {
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`
