/** @format */

import { useState } from "react";
import { useHistory } from "react-router";

import initLang from "../../../../services/defineLanguage";
import defineLanguage from "../../../../services/changeLanguage";
import SettingsLanguageView from "./SettingsLanguageView";


export default function SettingsLanguageContainer() {
	const [language, setLanguage] = useState(initLang);
	const history = useHistory();

	function changeLanguage(event) {
		event.preventDefault();
		defineLanguage(language);
		setTimeout(() =>{
			history.go(0);
		}, 500)
	}

	return (
		<>
            <SettingsLanguageView changeLanguage={changeLanguage} language={language} setLanguage={setLanguage} />
        </>
	);
}
