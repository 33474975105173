module.exports = {
    title:"Termos e Condições de Serviço",
    content:[
    {
        title: "1. Geral",
        texts: [{
            paragraphs:[
                `Eagle-edu (“Sites”) e aplicativos móveis são operados pela Eagle-edu,
                Inc. (“Eagle-edu” ou “nós” ou “Serviço”). O acesso e o uso do Serviço estão
                sujeitos aos seguintes Termos e Condições de Serviço (“Termos e Condições”). 
                Ao acessar ou usar qualquer parte do Serviço, você representa que leu, entendeu 
                e concorda em estar vinculado a estes Termos e Condições, incluindo quaisquer 
                modificações futuras. A Eagle-edu pode alterar, atualizar ou refazer estes Termos 
                e Condições. Se fizermos isso, publicaremos um aviso de que fizemos alterações nestes 
                Termos e Condições nos Sites por pelo menos 7 dias após a publicação das alterações 
                (eventualmente, também podemos enviar indicar que fizemos alterações nestes Termos e 
                Condições por e-mail, por meio do e-mail ativo cadastrado no Serviço) e indicaremos 
                na parte inferior dos Termos e Condições a data em que estes termos foram revisados pela 
                última vez. Quaisquer revisões nestes Termos e Condições entrarão em vigor no início de i) 
                o fim de um período de 7 dias ou ii) a primeira vez que você acessar ou usar o Serviço após 
                tais alterações. Se você não concordar em cumprir estes Termos e Condições, você não
                está autorizado a usar, acessar ou participar do Serviço.`,
                `Observe que estes termos e condições contêm uma arbitragem obrigatória de provisão de litígios
                que requer o uso da arbitragem individualmente para resolver disputas em determinadas circunstâncias,
                em vez de julgamentos de jurados ou ações coletivas.`
            ]
        }]
    },
    {
        title: "2. Descrição do Site/Sistema e Serviço",
        texts: [{
            paragraphs:[
                `O Serviço permite que os usuários acessem e utilizem uma variedade de serviços educacionais, incluindo 
                aprender ou praticar um tema, fazer exames/avaliações/testes no sistema e criar e visualizar novos cursos. 
                A Eagle-edu pode, a seu exclusivo critério e a qualquer momento, atualizar, alterar, suspender, fazer 
                melhorias ou descontinuar qualquer aspecto do Serviço, temporariamente ou permanentemente.`
            ]
        }]
    },
    {
        title: "3. Inscrição; Submissão de Conteúdo",
        texts: [{
            subtitles:"a. Registro",
            paragraphs:[
                `Em relação ao registro e uso do Serviço, você concorda que i) irá fornecer informações precisas, 
                atuais e completas sobre você e/ou sua organização, conforme solicitado pela Eagle-edu; ii) irá 
                manter a confidencialidade de sua senha e outras informações relacionadas à segurança de sua conta; 
                iii) irá manter e atualizar prontamente quaisquer informações cadastrais que você fornecer ao 
                Eagle-edu, para manter tais informações precisas, atuais e completas; e iv) será totalmente 
                responsável por todo o uso de sua conta e por quaisquer ações que ocorram através de sua conta.`
            ]
        },
        {
            subtitles:"b. Submissões de Colaboradores de Curso",
            paragraphs:[
                `Se você é ou se torna um Colaborador ou Professor, você pode se oferecer para traduzir ou ter 
                traduzido certos cursos existentes para outros idiomas e você pode se oferecer para contribuir 
                com a criação de novos cursos ou melhoria de cursos existentes para o Serviço, conforme 
                acordado entre você e Eagle-edu caso a caso. Sujeito a quaisquer diretrizes publicadas no Serviço, 
                você pode realizar tais traduções, criações ou melhorias de cursos de acordo com sua própria atribuição 
                e usando suas próprias instalações e recursos. Você não é obrigado a se tornar um Contribuinte e você 
                pode cessar suas atividades como Colaborador ou Professor a qualquer momento. Você reconhece que não 
                deseja e não receberá compensação por suas atividades como Colaborador/Professor ou pelo uso de quaisquer 
                materiais contribuintes do curso (conforme definido abaixo) que você submeta. Qualquer tradução de um 
                curso da Eagle-edu existente que você submeteu ou se submeter como Colaborador/Professor (coletivamente, 
                “Materiais contribuintes”) pertencem à Eagle-edu. Ao enviar qualquer Material, você nos concede uma licença 
                integralmente paga, livre de royalties, perpétua, sublicenciável para reproduzir, exibir, executar, modificar, 
                criar trabalhos derivados, distribuir e usar esse Material contribuinte do curso de qualquer maneira.`
            ]
        },
        {
            subtitles:"c. Conteúdo Geral",
            paragraphs:[
                `Como condição de submissão de qualquer classificação, revisões, informações, dados, textos, fotografias, 
                áudios, obras audiovisuais, traduções, flashcards ou outros materiais sobre os Serviços (“Conteúdo”), você 
                concede à Eagle-edu uma licença livre de royalties, perpétua, irrevogável, mundial, não exclusiva, 
                transferível e sublicenciável para usar, reproduzir, copiar, adaptar, modificar, fundir, distribuir, exibir publicamente, 
                criar trabalhos derivados, incorporar tais conteúdos em outros trabalhos; sublicenciar através de vários níveis o Conteúdo 
                e reconhecer que essa licença não pode ser encerrada por você uma vez que seu Conteúdo é enviado aos Serviços. Você 
                representa que possui ou garantiu todos os direitos legais necessários para que o Conteúdo enviado por você seja usado 
                por você, Eagle-edu e outros como descrito e contemplado nestes Termos e Condições. Você entende que outros usuários 
                terão acesso ao Conteúdo e que nem eles nem a Eagle-edu têm qualquer obrigação com você ou qualquer outra pessoa para 
                manter a confidencialidade do Conteúdo.
                `
            ]
        },
        ]
    },
    {
        title: "4. Suas Representações e Garantias",
        texts: [{
            paragraphs:[
                `Você representa e garante à Eagle-edu que seu acesso e uso do Serviço será de acordo com estes Termos e Condições e 
                com todas as leis, regras e regulamentos aplicáveis no Brasil e de qualquer outra jurisdição relevante, incluindo aquelas 
                relativas à conduta on-line ou conteúdo aceitável, e aquelas relativas à transmissão de dados ou informações exportadas 
                do Brasil e/ou da jurisdição em que você reside. Você ainda representa e garante que você criou ou possui qualquer material 
                que você enviar através do Serviço (incluindo Materiais de Tradução, Materiais do Contribuinte do Curso, Materiais de Atividade 
                e Conteúdo) e que você tem o direito, conforme aplicável, de nos conceder uma licença para usar esse material conforme 
                estabelecido acima ou o direito de atribuir esse material a nós conforme estabelecido abaixo. Você ainda representa e garante 
                que protegerá cuidadosamente sua senha, nome de usuário e informações da conta, não compartilhará sua senha ou nome de usuário 
                com terceiros e não permitirá que terceiros acessem sua conta da Eagle-edu para qualquer finalidade.`,
                `Você representa e garante que (1) você não está sob as leis de, operando ou de outra forma residente em um país ou 
                território que seja alvo ou abrangente de sanções econômicas ou comerciais do Brasil (ou seja, um embargo) ou (2) 
                identificados em uma lista de pessoas proibidas ou restritas no Brasil, ou (3) de outra forma alvo das sanções do Brasil.`
            ]
        }]
    },
    {
        title: "5. Uso inadequado",
        texts: [{
            paragraphs:[
                `Você não enviará, exibirá ou fornecerá sobre ou através do Serviço qualquer conteúdo que: i) seja calunioso, difamatório, 
                abusivo, ameaçador, assediador, odioso, ofensivo ou de outra forma viola qualquer lei ou infringe o direito de terceiros 
                (incluindo direitos autorais, marcas registradas, privacidade, publicidade ou outros direitos pessoais ou proprietários); ou 
                ii) no julgamento exclusivo da Eagle-edu, é censurável ou que restringe ou inibe qualquer outra pessoa de usar o Serviço ou 
                que possa expor a Eagle-edu ou seus usuários a qualquer dano ou responsabilidade de qualquer tipo. Você não usará o Sistema 
                para coletar informações sobre nossos processos internos ou para desenvolver estratégias, guias ou outros materiais de 
                preparação de testes/avaliações ou serviço de teste semelhante, e não violará nenhuma regra aplicável a Eagle-edu.`
            ]
        }]
    },
    {
        title: "6. Indenização",
        texts: [{
            paragraphs:[
                `Você concorda em defender, indenizar e manter inofensiva a Eagle-edu e seus diretores, funcionários, 
                colaboradores, terceirizados, agentes, fornecedores, licenciadores, sucessores e atribuições, de e 
                contra toda e qualquer perda, reclamações, causas de ação, obrigações, passivos e danos, incluindo 
                honorários advocatícios, decorrentes ou relacionados ao seu acesso ou uso do Serviço, qualquer falsa 
                representação feita a nós (como parte destes Termos e Condições ou de outra forma) , sua violação de 
                qualquer um destes Termos e Condições, ou qualquer alegação de que qualquer serviço que prestamos a 
                você é impreciso, inapropriado ou defeituoso de qualquer forma.`
            ]
        }]
    },
    {
        title: "7. Licença para Aplicativos",
        texts: [{
            paragraphs:[
                `Sujeito aos termos destes Termos e Condições, a Eagle-edu concede-lhe uma licença não-transferível 
                e não exclusiva para baixar, instalar e usar uma cópia de cada App em forma de código de objeto 
                apenas em um dispositivo sem fio interativo que você possui ou controla. Você não pode derivar ou 
                tentar derivar o código fonte de todo ou qualquer parte do Sistema, permitir a qualquer terceiro 
                derivar ou tentar derivar tal código fonte, ou engenharia reversa, descompilar, desmontar ou 
                traduzir qualquer parte dele. A Eagle-edu e seus licenciadores possuem e manterão todos os direitos 
                de propriedade intelectual e outros direitos dentro e para o Sistema, e quaisquer alterações, 
                modificações ou correções.`
            ]
        }]
    },
    {
        title: "8. Compras de e no Serviço",
        texts: [{
            paragraphs:[
                `Se você comprar uma assinatura periódica de renovação automática através do Serviço, sua conta 
                Eagle-edu será cobrada continuamente pela assinatura até que você a encerre conforme estabelecido 
                abaixo. Após seu período inicial de assinatura e novamente após qualquer período de assinatura 
                subsequente, sua assinatura será renovada automaticamente por um período equivalente adicional. 
                Se você não deseja que sua assinatura seja renovada automaticamente, ou se você quiser alterar 
                ou encerrar sua assinatura, você precisará fazer login na sua conta Eagle-edu e seguir as 
                instruções para encerrar ou alterar sua assinatura, mesmo que você tenha excluído sua conta.`,
                
                `No Serviço, você pode comprar, com dinheiro do “mundo real”, uma licença limitada, pessoal, 
                intransferível, não sublicenciável e revogável para usar (a) “moeda virtual”, incluindo, mas 
                não se limitando à moeda virtual, exclusivamente para uso no Serviço, e (b) “itens virtuais 
                no aplicativo” (juntamente com “moeda virtual”, “Itens virtuais”). Você pode comprar Itens 
                Virtuais através do Serviço, e não de nenhuma outra forma.`,

                `A Eagle-edu pode gerenciar, regular, controlar, modificar ou eliminar itens virtuais a qualquer 
                momento, com ou sem aviso prévio. A Eagle-edu pode atualizar os preços dos Itens Virtuais a 
                qualquer momento, a seu exclusivo critério, e pode adicionar novos Itens Virtuais por taxas 
                adicionais. A Eagle-edu não terá nenhuma responsabilidade para com você ou qualquer terceiro 
                no caso da Eagle-edu exercer tais direitos.`,

                `A transferência de Itens Virtuais é proibida, exceto quando expressamente autorizada no Serviço. 
                Diferente do que expressamente autorizado no Serviço, você não venderá, resgatará ou transferirá 
                Itens Virtuais para qualquer pessoa ou entidade, incluindo, mas não se limitando à Empresa, 
                outro usuário ou a qualquer terceiro.`,

                `Você concorda em pagar todas as taxas e impostos aplicáveis incorridos por você ou qualquer pessoa 
                usando uma conta Eagle-edu registrada para você. A Eagle-edu pode revisar os preços dos bens e 
                serviços oferecidos através do Serviço a qualquer momento. Todas as informações fornecidas em conexão 
                com uma compra ou transação ou outra interação de transação monetária com o Serviço, devem ser precisas, 
                completas e atuais. Você concorda em pagar todos os encargos incorridos pelos usuários de seu 
                cartão de crédito, cartão de débito ou outro método de pagamento usado em conexão com uma compra 
                ou transação ou outra interação de transação monetária com o Serviço aos preços vigentes quando tais 
                encargos são incorridos. Você pagará quaisquer impostos aplicáveis, se houver, relacionados a quaisquer 
                tais compras, transações ou outras interações de transações monetárias.`
            ]
        }]
    },
    {
        title: "9. Processadores de Pagamento",
        texts: [{
            paragraphs:[
                `Todas as transações financeiras feitas em conexão com o Serviço serão processadas por terceiros 
                de acordo com seus respectivos termos de uso, política de privacidade e/ou quaisquer termos e 
                condições de pagamento aplicáveis. Nós encorajamos você a aprender sobre as práticas de tais 
                terceiros. Em nenhum caso a Eagle-edu será responsável pelas ações ou inações de qualquer 
                processador de pagamento de terceiros, incluindo, mas não se limitando a, paralisações de tempo 
                de inatividade do sistema ou paralisações de serviços de pagamento.`
            ]
        }]
    },
    {
        title: "10. Política de Reembolso",
        texts: [{
            paragraphs:[
                `No caso da Eagle-edu suspender ou encerrar seu uso do Serviço ou destes Termos e Condições ou 
                fechar sua conta voluntariamente, você entende e concorda que não receberá nenhum reembolso ou 
                troca de qualquer tipo, inclusive para qualquer moeda virtual não utilizada ou outro Item 
                Virtual, qualquer Conteúdo ou dados associados ao seu uso do Serviço, ou para qualquer outra coisa.`
            ]
        }]
    },
    {
        title: "11. Links, sites e serviços de terceiros",
        texts: [{
            paragraphs:[
                `O Serviço pode conter links para sites de terceiros, anunciantes, serviços, ofertas especiais ou outros 
                eventos ou atividades que não sejam de propriedade ou controladas pela Eagle-edu. Não endossamos ou 
                assumimos qualquer responsabilidade por quaisquer sites, informações, materiais, produtos ou serviços 
                de terceiros. Se você acessar qualquer site, serviço ou conteúdo de terceiros da Eagle-edu, você 
                entenderá que estes Termos e Condições e nossa Política de Privacidade não se aplicam ao uso desses 
                sites. Você reconhece e concorda expressamente que a Eagle-edu não será responsável, direta ou indiretamente, 
                por qualquer dano ou perda decorrente do uso de qualquer site, serviço ou conteúdo de terceiros.`,

                `O Serviço pode incluir anúncios, que podem ser direcionados ao Conteúdo ou informações sobre o Serviço, ou 
                outras informações. Os tipos e a extensão da publicidade da Eagle-edu no Serviço estão sujeitos a alterações. 
                Em consideração à Eagle-edu que lhe concede acesso e uso do Serviço, você concorda que a Eagle-edu e seus 
                fornecedores e parceiros de terceiros podem colocar tal publicidade em conexão com a exibição de conteúdo ou 
                informações enviadas por você ou outros.`
            ]
        }]
    },

    {
        title: "12. Sem representações ou garantias da Eagle-edu",
        texts: [{
            paragraphs:[
                `O Serviço, incluindo todas as imagens, arquivos de áudio e outros conteúdos nele, e quaisquer outras 
                informações, propriedades e direitos concedidos ou fornecidos a você pela Eagle-edu são fornecidos a 
                você em uma base “como está”. A Eagle-edu e seus fornecedores não fazem representações ou garantias de 
                qualquer tipo sobre o serviço, expressas ou implícitas e todas essas representações e garantias, 
                incluindo garantias de comercialização, adequação para um propósito específico ou não infração, são 
                expressamente desremissadas. Sem limitar a generalidade do anterior, a Eagle-edu não faz qualquer 
                representação ou garantia de qualquer tipo relacionada à exatidão, disponibilidade de serviço, 
                completude, conteúdo informativo, operação livre de erros, resultados a serem obtidos de uso ou não 
                infração. O acesso e o uso do serviço podem estar indisponíveis durante períodos de pico de demanda, 
                atualizações do sistema, defeitos ou manutenção programada ou não programada ou por outros motivos. 
                Algumas jurisdições não permitem a exclusão de garantias implícitas, portanto, a exclusão acima pode 
                não se aplicar a você.`
            ]
        }]
    },
    {
        title: "13. LIMITAÇÃO DOS TIPOS DE DANOS/LIMITAÇÃO DE RESPONSABILIDADE",
        texts: [{
            paragraphs:[
                `Em nenhum caso a Eagle-edu será responsável por você ou a qualquer terceiro que reivindicar 
                através de você (seja baseado em contrato, delito, responsabilidade estrita ou outra teoria) 
                por danos indiretos, incidentais, especiais, consequentes ou exemplares decorrentes ou 
                relacionados ao acesso ou uso de, ou a incapacidade de acessar ou usar, o Serviço ou qualquer 
                parte dele, incluindo, mas não se limitando à perda de uso do serviço, resultados imprecisos, 
                perda de lucros, interrupção de negócios ou danos decorrentes de perda ou corrupção de 
                dados ou dados sendo imprecisos, o custo de recuperação de quaisquer dados, o custo de 
                serviços substitutos ou reclamações por terceiros por qualquer dano a computadores, software, 
                modems, telefones ou outros bens, mesmo que a Eagle-edu tenha sido avisada da possibilidade 
                de tais danos. A responsabilidade da Eagle-edu para você ou qualquer terceiro que reclame através 
                de você por qualquer causa, e independentemente da forma da ação, está limitada ao valor pago, se 
                houver, a Eagle-edu pelo serviço nos 12 meses anteriores à ação inicial que dá origem à 
                responsabilidade. Este é um limite agregado. A existência de mais de uma reivindicação aqui 
                submetida não aumentará esse limite.`
            ]
        }]
    },
    {
        title: "14. Terminação",
        texts: [{
            paragraphs:[
                `A Eagle-edu pode encerrar seu acesso e uso do Serviço imediatamente a qualquer momento, por 
                qualquer motivo, e nesse momento você não terá mais direito de usar o Serviço. Você pode 
                encerrar sua conta na Eagle-edu a qualquer momento, seguindo as instruções disponíveis através 
                do Serviço. As disposições destes Termos e Condições relativas à proteção e aplicação dos 
                direitos proprietários da Eagle-edu, suas representações e garantias, isenção de responsabilidade 
                de representações e garantias, liberação e indenizações, limitações de responsabilidade e 
                tipos de danos, propriedade de dados e informações, lei e local de governo, e disposições 
                diversas sobreviverão a qualquer rescisão.`
            ]
        }]
    },
    {
        title: "15. Direitos proprietários em conteúdo de serviços e materiais de atividades",
        texts: [{
            paragraphs:[
                `Todo o conteúdo disponível através do Serviço, incluindo designs, textos, gráficos, 
                imagens, informações, softwares, áudios e outros arquivos, e sua seleção e arranjo 
                (o “Conteúdo de Serviço”), são de propriedade da Eagle-edu ou seus licenciadores. 
                Nenhum Conteúdo de Serviço poderá ser modificado, copiado, distribuído, emoldurado, 
                reproduzido, republicado, baixado, minerado, exibido, postado, transmitido ou vendido 
                de qualquer forma ou por qualquer meio, total ou parcialmente, diferente do 
                expressamente permitido nestes Termos e Condições. Você não pode usar qualquer tipo 
                de mineração de dados, robôs, raspagem ou métodos similares de coleta ou extração de 
                dados para obter conteúdo de serviço. Como entre você e a Eagle-edu, todos os dados, 
                informações e materiais gerados a partir do seu acesso e uso das atividades 
                educativas disponibilizadas em ou através do Serviço, incluindo conteúdo traduzido 
                gerado por você (coletivamente, os “Materiais de Atividade”), serão exclusivamente 
                de propriedade da Eagle-edu, e você não terá o direito de usar tais Materiais de 
                Atividade, exceto conforme expressamente autorizado por estes Termos e Condições. Ao 
                usar o Serviço, você atribui à Eagle-edu quaisquer direitos, títulos e juros, 
                incluindo quaisquer direitos de propriedade intelectual ou direitos de propriedade, 
                nos Materiais de Atividade. Todos os direitos da Eagle-edu ou seus licenciadores 
                que não são expressamente concedidos nestes Termos e Condições são reservados à 
                Eagle-edu e seus licenciadores.`
            ]
        }]
    },
    {
        title: "16. Marcas comerciais",
        texts: [{
            paragraphs:[
                `A Eagle-edu e todas as outras marcas comerciais, marcas de serviço, gráficos 
                e logotipos usados em conexão com o Serviço são marcas comerciais ou marcas de 
                serviço da Eagle-edu ou seus respectivos proprietários, e algumas delas estão 
                registradas no Escritório de Patentes e Marcas do Brasil ou dos Estados Unidos. 
                O acesso e o uso do Serviço não concedem ou fornecem o direito ou licença para 
                reproduzir ou usar de outra forma o nome Eagle-edu ou quaisquer marcas comerciais, 
                marcas de serviço, gráficos ou logotipos da Eagle-edu ou de terceiros.`
            ]
        }]
    },
    {
        title: "17. Privacidade",
        texts: [{
            paragraphs:[
                `O uso do Serviço também é regido por nossa Política de Privacidade, cuja cópia 
                está localizada em www.eagle-edu.com.br/privacy. Ao usar o Serviço, você concorda 
                com os termos da Política de Privacidade.`
            ]
        }]
    },
    {
        title: "18. Aviso de reclamações de violações de direitos autorais e agente para aviso",
        texts: [{
            paragraphs:[
                `Se você é um proprietário de direitos autorais e tem uma crença de boa fé de 
                que qualquer material disponível através do Serviço viola seus direitos autorais, 
                você pode enviar uma notificação de violação de direitos autorais à Eagle-edu de 
                acordo com a Lei de Direitos Autorais do Milênio Digital, fornecendo-nos as 
                seguintes informações por escrito:`
            ],
            list:[
                `uma assinatura eletrônica ou física do proprietário dos direitos autorais ou da 
                pessoa autorizada a agir em nome do proprietário dos direitos autorais;`,

                `uma descrição do trabalho protegido por direitos autorais que você alega ter sido infringido;`,

                `uma descrição de onde o material que você alega estar infringindo está localizado no Serviço, 
                com detalhes suficientes para que possamos encontrá-lo no Serviço;`,

                `seu endereço, número de telefone e endereço de e-mail;`,

                `uma declaração sua de que você tem uma crença de boa fé de que o uso contestado não é 
                autorizado pelo proprietário de direitos autorais, seu agente ou a lei; E`,

                `uma declaração sua, feita sob pena de perjúrio, de que as informações acima em seu 
                aviso são precisas e que você é o proprietário dos direitos autorais ou está autorizado 
                a agir em nome do proprietário dos direitos autorais.`
            ]
        }]
    },
    {
        title: "19. Direito e Arbitragem; sem ação de classe",
        texts: [{
            paragraphs:[
                `Estes Termos e Condições, seu assunto e os direitos da Eagle-edu e seus respectivos 
                direitos nos termos destes Termos e Condições, bem como qualquer reclamação, causa 
                de ação ou disputa (“reivindicação”) decorrentes ou relacionadas a estes Termos e 
                Condições, serão regidos e interpretados sob as leis do Brasil, excluindo o conflito 
                de disposições legais dessa ou de qualquer outra jurisdição, independentemente do seu 
                país de origem ou onde você acessar o Serviço. QUALQUER DISPUTA OU RECLAMAÇÃO 
                RELACIONADA DE QUALQUER FORMA A ESTES TERMOS E CONDIÇÕES OU O SERVIÇO SERÁ RESOLVIDO 
                POR ARBITRAGEM VINCULANTE, E NÃO EM TRIBUNAL, exceto pelo direito da Eagle-edu de 
                buscar a medida cautelar conforme estabelecido abaixo. A menos que expressamente exigido 
                pela lei aplicável, cada parte arcará com seus próprios honorários advocatícios sem 
                considerar qual parte é considerada a parte predominante no processo arbitral.`,

                `Se você não quiser arbitrar disputas com a Eagle-edu e você for um indivíduo, você 
                pode optar por sair deste contrato de arbitragem enviando um e-mail para 
                legal@eagle-edu.com .br dentro de 30 dias a partir do dia em que você acessar ou 
                usar o Serviço.`,

                `Se você pretende buscar a arbitragem, você deve primeiro enviar uma notificação 
                por escrito ao Escritório de Administração da Eagle-edu de sua intenção de arbitrar 
                (“Aviso”). O Aviso para Eagle-edu deve ser enviado pelo correio eletrônico para 
                legal@eagle-edu.com.br. O Aviso deve (x) descrever a natureza e a base da reivindicação 
                ou disputa; e (y) estabelecer o alívio específico buscado; e (z) definir seu nome, 
                endereço e informações de contato. Se pretendermos buscar arbitragem contra você, 
                enviaremos qualquer aviso da disputa para você nas informações de contato que temos para você.`,

                `Na medida do permitido pela legislação aplicável, VOCÊ E A Eagle-edu CONCORDAM CADA UM 
                QUE QUALQUER PROCESSO DE RESOLUÇÃO DE DISPUTAS SERÁ CONDUZIDO APENAS INDIVIDUALMENTE E 
                NÃO EM UMA AÇÃO COLETIVA, CONSOLIDADA OU REPRESENTATIVA. Se, por qualquer razão, uma 
                reclamação prossegue em tribunal e não em arbitragem, VOCÊ E A Eagle-edu CADA UM RENUNCIAM 
                A QUALQUER DIREITO A UM JULGAMENTO DO JÚRI. Se um tribunal de jurisdição competente 
                considerar as disposições arbitrais acima inválidas ou inaplicáveis, você e a Eagle-edu 
                concordam que todas as reivindicações decorrentes ou relacionadas a estes Termos e 
                Condições devem ser resolvidas exclusivamente por um tribunal estadual ou federal 
                localizado no Brasil, e você e a Eagle-edu concordam em submeter-se ao exercício de 
                jurisdição pessoal de tais tribunais com o propósito de litígio de todas essas 
                reivindicações. Não obstante o acima, você concorda que a Eagle-edu ainda será 
                permitido solicitar e obter recursos cautelares (ou um tipo equivalente de alívio 
                legal urgente) em qualquer jurisdição.`

            ]
        }]
    },
    {
        title: "20. Idioma",
        texts: [{
            paragraphs:[
                `Este acordo foi originalmente escrito em Português do Brasil (PT-BR). 
                Na medida em que qualquer versão traduzida deste acordo conflita com a 
                versão em Português do Brasil, a versão em Português do Brasil predomina.`
            ]
        }]
    },
    {
        title: "21. Diversos",
        texts: [{
            paragraphs:[
                `Estes Termos e Condições constituem todo o acordo entre Eagle-edu e você 
                sobre o assunto deste presente contrato. Se qualquer um dos Termos e 
                Condições for considerado inexequível por um tribunal ou outro tribunal 
                de jurisdição competente, tais disposições serão limitadas ou eliminadas 
                na medida mínima necessária para que estes Termos e Condições permaneçam 
                em pleno vigor e efeito. Uma renúncia da Eagle-edu ou de qualquer disposição 
                destes Termos e Condições ou qualquer violação deles, em qualquer instância, 
                não renunciará a tal termo ou condição ou qualquer violação subsequente dele. 
                A Eagle-edu pode atribuir seus direitos ou obrigações sob estes Termos e 
                Condições sem condições. Estes Termos e Condições serão vinculantes e serão 
                vinculados em benefício da Eagle-edu e você, e a Eagle-edu e seus respectivos 
                sucessores e assinações permitidas.`
            ]
        }]
    }
],
footer:"Last revised on April 18th, 2020"
}