module.exports = {
    courseName: 'Nome do curso',
    courseDescription: 'Descrição do curso',
    courseKey: 'Chave de cadastro',
    acceptStudents: 'Aceitar novos alunos automaticamente?',
    save: 'Salvar',
    delete: 'Excluir curso',
    confirmDelete: 'Deseja realmente excluir este curso?',
    errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
}