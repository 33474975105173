import styled from 'styled-components'

export const ProgressContainer = styled.div`
	margin-top: 0.5rem;
	position: relative;
	width: ${(props) => props.width || 100}%;
	height: ${(props) => props.height || 15}px;

	background: ${(props) => props.theme.colors.gray};
`
export const BaseBox = styled.div`
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 3px;
	transition: width 2.5s ease-in-out; // speed with which the bar progresses
`

export const Progress = styled(BaseBox)`
	width: ${({ percent }) => percent}%;
	max-width: 100%;

	background-image: -webkit-linear-gradient(
			45deg,
			transparent 33%,
			rgba(0, 0, 0, 0.1) 33%,
			rgba(0, 0, 0, 0.1) 66%,
			transparent 66%
		),
		-webkit-linear-gradient(left, #73d687, #7ade8c);

	border-radius: 3px;
	background-size: 25px 15px, 100% 100%, 100% 100%;
	opacity: 0.85;
`
