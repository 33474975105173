import styled from 'styled-components'
import { StyledBackground } from '../../../globalStyles'

const Background = styled(StyledBackground)`
    position: absolute;
    width: 100%;
    height: auto;
    max-width: 100vw;
    min-height: 100vh;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ${StyledBackground}
`

export default Background

