/** @format */

import { useState, useContext, useEffect } from "react";
import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import { GamificationContainer, Title, SubTitle } from "./Gamification-styled";
import GamificationElement from "./GamificationElement/GamificationElement";
import { InstructorContext } from "../../../contexts/InstructorContext";

import language from "../../../services/defineLanguage";
import {
	getGamificationSettings,
	updateGamificationSettings,
} from "../../../services/Instructor";
import courseGamificationSettings from "../../../constants/courseGamificationSettings";
import { EagleContext } from "../../../contexts/EagleContext";
const texts = require(`../../../texts/${language}/gamificationAdvanced`);

export default function Advanced() {
	const { currentCourseId } = useContext(InstructorContext);
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [settings, setSettings] = useState({});

	const [reload, setReload] = useState({});

	const forceReload = () => {
		setReload({});
	};

	const loadData = async () => {
		try {
			let newSettings = await getGamificationSettings(currentCourseId);
			setSettings(newSettings);
		} catch (err) {
			console.log("Get CourseSettings error!");
		}
	};

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		loadData();
	}, [reload]);

	async function handleChangeSetting(settingName) {
		let settingToUpdate = {};
		settingToUpdate[settingName] = settings[settingName] ? false : true;
		let response = await updateGamificationSettings(
			currentCourseId,
			settingToUpdate
		);

		if (response.success) {
			forceReload();
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
		}
	}
	return (
		<InstructorStructure>
			<GamificationContainer>
				<Title>{texts.advancedSettings}</Title>
				<SubTitle>{texts.settingsDescription}</SubTitle>
				<GamificationElement
					title={texts.acknowledgment.title}
					checked={settings.acknowledgment}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.acknowledgment)
					}
					description={texts.acknowledgment.description}
				/>
				<GamificationElement
					title={texts.level.title}
					checked={settings.level}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.level)
					}
					description={texts.level.description}
				/>
				<GamificationElement
					title={texts.point.title}
					checked={settings.points}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.points)
					}
					description={texts.point.description}
				/>
				<GamificationElement
					title={texts.progression.title}
					checked={settings.progression}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.progression)
					}
					description={texts.progression.description}
				/>
				<GamificationElement
					title={texts.stats.title}
					checked={settings.stats}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.stats)
					}
					description={texts.stats.description}
				/>
				<GamificationElement
					title={texts.chance.title}
					checked={settings.chance}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.chance)
					}
					description={texts.chance.description}
				/>
				<GamificationElement
					title={texts.economy.title}
					checked={settings.economy}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.economy)
					}
					description={texts.economy.description}
				/>
				<GamificationElement
					title={texts.imposedChoice.title}
					checked={settings.imposedChoice}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.imposedChoice)
					}
					description={texts.imposedChoice.description}
				/>
				<GamificationElement
					title={texts.rarity.title}
					checked={settings.rarity}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.rarity)
					}
					description={texts.rarity.description}
				/>
				<GamificationElement
					title={texts.timePressure.title}
					checked={settings.timePressure}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.timePressure)
					}
					description={texts.timePressure.description}
				/>
				<GamificationElement
					title={texts.competition.title}
					checked={settings.competition}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.competition)
					}
					description={texts.competition.description}
				/>
				<GamificationElement
					title={texts.cooperation.title}
					checked={settings.cooperation}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.cooperation)
					}
					description={texts.cooperation.description}
				/>
				<GamificationElement
					title={texts.reputation.title}
					checked={settings.reputation}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.reputation)
					}
					description={texts.reputation.description}
				/>
				<GamificationElement
					title={texts.socialPressure.title}
					checked={settings.socialPressure}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.socialPressure)
					}
					description={texts.socialPressure.description}
				/>
				<GamificationElement
					title={texts.novelty.title}
					checked={settings.novelty}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.novelty)
					}
					description={texts.novelty.description}
				/>
				<GamificationElement
					title={texts.objectives.title}
					checked={settings.objectives}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.objectives)
					}
					description={texts.objectives.description}
				/>
				<GamificationElement
					title={texts.puzzles.title}
					checked={settings.puzzles}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.puzzles)
					}
					description={texts.puzzles.description}
				/>
				<GamificationElement
					title={texts.renovation.title}
					checked={settings.renovation}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.renovation)
					}
					description={texts.renovation.description}
				/>
				<GamificationElement
					title={texts.sensation.title}
					checked={settings.sensation}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.sensation)
					}
					description={texts.sensation.description}
				/>
				<GamificationElement
					title={texts.narrative.title}
					checked={settings.narrative}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.narrative)
					}
					description={texts.narrative.description}
				/>
				<GamificationElement
					title={texts.storytelling.title}
					checked={settings.storytelling}
					setChecked={() =>
						handleChangeSetting(courseGamificationSettings.storytelling)
					}
					description={texts.storytelling.description}
				/>
			</GamificationContainer>
		</InstructorStructure>
	);
}
