import { useState } from 'react'
import {
    CardContainer,
    CardName,
    CardImage,
    BuyButton,
    CardDescription,
    PriceText,
    CoinImage,
} from './StoreCard-styled'
import coin from '../../../../assets/images/coin.svg'

import language from '../../../../services/defineLanguage'
const texts = require(`../../../../texts/${language}/store`)

export default function StoreCard({ name, description, imagePath, price, onBuy, wasBought }) {

    return (
        <CardContainer>
            <CardName>{name}</CardName>
            <CardDescription>{description}</CardDescription>
            <CardImage wasBought={wasBought} src={imagePath} />
            {!wasBought && (
                <BuyButton onClick={onBuy}>
                    {texts.buy}
                    <PriceText>
                        {price}
                        <CoinImage src={coin} />
                    </PriceText>
                </BuyButton>
            )}
        </CardContainer>
    )
}
