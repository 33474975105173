import styled from 'styled-components'
import { BaseContainer } from '../../environments/courses-board/BoardSectionContainers'
import { Button } from '../../GenericComponents'

export const MissionContainer = styled(BaseContainer)`
    h3 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 375px) {
        margin: 1rem 1rem 1.5rem 1rem;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    overflow-x: hidden;

    background-color: ${(props) => props.theme.colors.gray};
`

export const MissionBody = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.mediumGray};
    flex-direction: column;

    > p {
        margin-bottom: 100px;
        font-size: 1.5rem;
        text-align: center;
        color: ${(props) => props.theme.colors.black};
    }
`

export const MissionButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
        margin: 0;

        &:nth-child(2) {
            margin: 0 10px;
        }
    }
`

export const AnswerSection = styled.div`
    width: 100%;
    padding: 1rem;
    border-radius: 5px;

    ${(props) => {
        if (props.isCorrect === true)
            return (
                `
            background-color:` +
                props.theme.colors.lightGreen +
                `;` +
                `color:` +
                props.theme.colors.darkGreen +
                `;`
            )
        else {
            return (
                `
            background-color:` +
                props.theme.colors.lightRed +
                `;` +
                `color:` +
                props.theme.colors.darkRed +
                `;`
            )
        }
    }}
    hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid
            ${(props) =>
                props.isCorrect
                    ? props.theme.colors.darkGreen
                    : props.theme.colors.darkRed};
        margin: 1em 0;
        padding: 0;
    }
`

export const MissionHeader = styled.header`
    display: flex;
    align-items: center;
`

export const TaskIndicator = styled.span`
    margin-left: auto;
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.darkGray};
`

export const PlusButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-bottom: 0;
    border-radius: 50%;
`

export const DeleteButton = styled(PlusButton)`
    background: ${(props) => props.theme.colors.warning};

    &:hover {
        background: ${(props) => props.theme.colors.black};
    }
`

export const IconInputWrapper = styled.div`
    max-width:350px;
`