/** @format */

import { useState } from "react";
import { Link } from "react-router-dom";
import {
	Wrapper,
	ButtonsWrapper,
	EditButton,
	DeleteButton,
} from "./MissionLink-styled";
import Modal2 from "../../../common/Modal2/Modal2";
import EditMission from "../../EditMission/EditMission";

import language from "../../../../services/defineLanguage.js";
import { deleteMission } from "../../../../services/Instructor";
import DynamicFaIcon from "../../../common/DynamicFaIcon";
import { ButtonWithLoading } from "../../../common/ButtonWithLoading/ButtonWithLoading.jsx";
import { FloatingMessageBox } from "../../../common/FlaotingBox/FloatingMessageBox/FloatingMessageBox.jsx";
const texts = require(`../../../../texts/${language}/instructor/subject`);

export default function MissionLink({
	title,
	icon,
	description,
	courseId,
	subjectId,
	missionId,
	style,
	callback,
}) {
	const [showEditModal, setShowEditModal] = useState(false);
	const [deleteMissionResponse, setDeleteMissionResponse] = useState({});

	async function tryDeleteMission() {
		let response = await deleteMission(missionId);

		if (response.success) {
			callback();
		}

		setDeleteMissionResponse({ ...response, order: Date.now() });
	}

	async function handleDeleteMission() {
		if (!window.confirm(texts.confirmDeleteMission)) {
			return;
		}

		tryDeleteMission();
		setDeleteMissionResponse({ isLoading: true });
	}

	async function handleEditMission(forceReloadNeeded) {
		setShowEditModal(false);
		if (forceReloadNeeded) {
			callback();
		}
	}
	return (
		<Wrapper style={style}>
			<Link
				to={
					"/instructor/course/" +
					courseId +
					"/subject/" +
					subjectId +
					"/mission/" +
					missionId
				}
			>
				<h3 style={{ display: "flex", alingItems: "center" }}>
					{" "}
					<DynamicFaIcon name={icon} />
					{title}
				</h3>
			</Link>
			<ButtonsWrapper>
				<EditButton onClick={() => setShowEditModal(true)}>
					{texts.edit}
				</EditButton>
				<ButtonWithLoading
					ButtonTemplate={DeleteButton}
					onClick={handleDeleteMission}
					isloading={deleteMissionResponse.isLoading}
				>
					{texts.delete}
				</ButtonWithLoading>
			</ButtonsWrapper>
			<Modal2
				title={texts.editMission}
				show={showEditModal}
				onClose={() => setShowEditModal(false)}
			>
				<EditMission
					missionId={missionId}
					initialTitle={title}
					initialIcon={icon}
					initialDescription={description}
					callback={handleEditMission}
				/>
			</Modal2>
			{deleteMissionResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={deleteMissionResponse.order - 1707521092301}
				>
					{texts.errors[deleteMissionResponse.error] ?? texts.errors["default"]}
				</FloatingMessageBox>
			)}
		</Wrapper>
	);
}
