/** @format */

module.exports = {
	title: "Account recovery",
	description:
		"Please enter your email to begin the account recovery process.",
	recoveryButton: "Start recovery",
	warningDescription: "An email with a verification link has been sent to your email address.",
	case1_1: "- If you have not received the email with the link, restart the process by clicking ",
	case1_2: "here",
	case1_3: " ;",
	case2: "- If you are experiencing other access issues, please contact us at ",

	email: "Email",
	changePassword:"Change password",

	changingWaitASecond: "We are changing your password. Wait a few moments.",

	accountRecovered1: "Password changed successfully! ",
	accountRecovered2: ".",
	login: "Log in",

	accountNotRecovered: "Unable to change your password!",
	errors: {
		"Code timeout exceeded":
			"Token timeout exceeded. We sent a new token to your email.",
		"Invalid Token": "Invalid Token.",
	},
};
