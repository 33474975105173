import {
	SidebarContainer,
	SidebarContentContainer,
	SidebarFooter,
	SidebarContent,
	LogoContainer,
} from './Sidebar-styled'

// Importing components used to build sidebar items
import SidebarItem from './SidebarItem/SidebarItem'
import { SidebarLink } from './SidebarItem/SidebarItem-styled'

import logoText from '../../../../assets/images/logo-text.svg'
import logo from '../../../../assets/images/logo.svg'

import language from '../../../../services/defineLanguage'
const texts = require(`../../../../texts/${language}/sidebar`)

export default function Sidebar({ isSidebarHidden, openSidebar }) {
	return (
		<SidebarContainer isHidden={isSidebarHidden}>
			<LogoContainer to='/instructor'>
				{isSidebarHidden ? (
					<img src={logo} alt='Eagle-edu logo' />
				) : (
					<img src={logoText} alt='Eagle-edu logo' />
				)}
			</LogoContainer>
			<SidebarContentContainer isHidden={isSidebarHidden}>
				<SidebarContent>
					<SidebarItem
						name={texts.students}
						icon='FaUserFriends'
						openSidebar={openSidebar}
						isSidebarHidden={isSidebarHidden}
					>
						<SidebarLink to='/instructor/students/new'>
							{texts.newRegistrations}
						</SidebarLink>
						<SidebarLink to='/instructor/students/current'>
							{texts.yourStudents}
						</SidebarLink>
					</SidebarItem>
				</SidebarContent>
				<SidebarContent>
					<SidebarItem
						name={texts.performance}
						icon='FaChartLine'
						openSidebar={openSidebar}
						isSidebarHidden={isSidebarHidden}
					>
						<SidebarLink to='/instructor/course/statistics'>
							{texts.courseStats}
						</SidebarLink>
					</SidebarItem>
				</SidebarContent>
				<SidebarContent>
					<SidebarItem
						name={texts.gamification}
						icon='FaGamepad'
						openSidebar={openSidebar}
						isSidebarHidden={isSidebarHidden}
					>
						<SidebarLink to='/instructor/gamification/basics'>
							{texts.basicSettings}
						</SidebarLink>
						<SidebarLink to='/instructor/gamification/advanced'>
							{texts.advancedSettings}
						</SidebarLink>
					</SidebarItem>
				</SidebarContent>
				<SidebarContent>
					<SidebarItem
						name={texts.support}
						icon='FaMapSigns'
						openSidebar={openSidebar}
						isSidebarHidden={isSidebarHidden}
					>
						<SidebarLink to='/instructor/tutorials'>
							{texts.tutorials}
						</SidebarLink>
					</SidebarItem>
				</SidebarContent>
				{!isSidebarHidden && (
					<SidebarFooter>Copyright © Eagle-edu 2021</SidebarFooter>
				)}
			</SidebarContentContainer>
		</SidebarContainer>
	)
}
