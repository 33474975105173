module.exports = {
	points: "Points",
	achievements: "Achievements",
	economy: "Coins",
	home: "Home",
	profile: "Profile",
	friends: "Friends",
	suggestions: "Suggestions",
	settings: "Settings",
	logout: "Log out",
	enrollCourse:"Home"
}