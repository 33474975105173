/** @format */

module.exports = {
	instruction: "Escolha o tipo de tarefa a ser adicionada na missão",
	quiz: " Selecione a opção adequada",
	pairs: "Selecione os pares",
	openQuestion: " Questão aberta",
	completeWithoutOptions: " Complete os espaços em branco",
	completeWithOptions: " Complete With Options",
	addTask: "Adicionar tarefa",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
