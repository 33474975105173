import React from 'react'
import * as Icons from 'react-icons/fa'

const DynamicFaIcon = ({ name, size, color, style }) => {
  const IconComponent = Icons[name]

  if (!IconComponent) {
    // Return a default one
    return <Icons.FaGraduationCap style={style} size={size} />
  }

  return <IconComponent style={style} color={color} size={size} />
}

export default DynamicFaIcon
