/** @format */

import styled from "styled-components";

export const FloatingBoxWrapper = styled.div`
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: ${({ color }) => color};
	border-radius: 6px;
	position: fixed;
	width: 90vw;
	top: 0;
	left: 5vw;
	transition: 0.5s;
	z-index: ${(props) => props.zIndex? props.zIndex : "1000"};
	padding: 20px;
	font-size: 1.4rem;
	box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
	transform: translate(0, -120%);

	display: felx;
	justify-content: center;
	align-items: center;
`;

export const TextWrapper = styled.div`
	flex-grow: 1;
`;

export const CloseButton = styled.button`
	border: none;
	background: transparent;
	cursor: pointer;
	border-radius: 50%;
	height: 30px;
	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}
	transition: all 0.5s ease-in-out;
`;

export const CloseButtonWrapper = styled.div`
	height: 30px;
`;
