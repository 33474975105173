import styled from 'styled-components'

export const AllCoursesWrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    margin: 15px;
    padding: 30px;

    background: ${(props) => props.theme.colors.white};
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`

export const AllCoursesTitle = styled.h1`
    font-size: 2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
    margin: 10px 0;
`

export const AllCoursesSubTitle = styled.h2`
    font-size: 1rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 20px;
`

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 215px);
    justify-content: space-evenly;
    grid-gap: 20px;
`
