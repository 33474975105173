import styled from "styled-components"

export const NavbarContainer = styled.div`
	display: flex;
	
	min-height: ${(props) => props.theme.height.navbar};
	height: ${(props) => props.theme.height.navbar};
	width: 100%;

	background-color: ${(props) => props.theme.colors.white};
`

export const NavbarButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 5px;
	padding: 5px;

	cursor: pointer;

	border: none;
	background-color: ${(props) => props.theme.colors.white};

	svg {
		font-size: 2rem;
		color: ${(props) => props.theme.colors.secondary};
	}

	@media screen and (max-width: 660px) {
		margin-right: auto;
	}
`
