module.exports = {
    yourStudents: 'Seus estudantes',
    newRegistrations: 'Novas matrículas',
    instructionCurrent: 'Veja os estudantes que estão matriculados neste curso.',
    instructionNew: 'Veja os novos estudantes que solicitaram matrícula neste curso.',
    noStudents: 'Você não possui estudantes neste curso! Envie a chave de cadastro para seus alunos para que eles façam parte do curso.',
    noRegistrations: 'Você não possui nenhum pedido de matrícula pendente para este curso.',
    access: 'Acessar',
    accept: 'Aceitar',
    reject: 'Rejeitar',
    acceptAll: 'Aceitar todas',
    rejectAll: 'Rejeitar todas',
    country:"País",
    email:"E-mail",
    name:"Nome",
    picture:"Imagem",
    acceptColumn:"Aceitar?",
    gender:"Gênero",
    lastAccess:"Último acesso",
    studentPage:"Página do estudante"
}