import styled from 'styled-components'

export const Wrapper = styled.form`
    margin: 1rem;
    width: 100%;

    justify-content: center;
    display: flex;
    align-items: center;

    color: black;
`
export const PairsTable = styled.div`
    border-collapse: collapse;

    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
`

export const PairText = styled.p`
    width: 100%;
    padding: 10px;
`

export const RemoveButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    cursor: pointer;
    background: transparent;

    svg {
        font-size: 20px;
    }
`

export const PairContainer = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 0.5rem;
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.mediumGray};

    color: ${(props) => props.theme.colors.black};
    background: ${(props) => props.theme.colors.white};

    &:hover {
        background: ${(props) => props.theme.colors.gray};
    }

    input {
        width: 100%;
        border: none;
    }

    button {
        margin: 0;
        margin-left: 10px;
    }

    span {
        margin: 0 5px;
    }

    @media screen and (max-width: 460px) {
        flex-direction: column;
        justify-content: center;

        button {
            margin-right: auto;
        }
    }
`

export const NewPairContainer = styled(PairContainer)`
    flex-direction: row;
    align-items: center;

    input {
        &:focus {
            border: 1px solid ${(props) => props.theme.colors.primary};
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;

        button {
            margin-top: 10px;
            margin-right: 0;
        }
    }
`
