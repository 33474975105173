import React from 'react'
import { IconContext } from 'react-icons'
import { Navbar, 
    NavbarContainer, 
} from './Navbar'


function Footer() {
    return (
        <>
        <IconContext.Provider value={{color: '#fff'}}> 
            <Navbar>
                <NavbarContainer >
                    <span> 
                        Copyright © Eagle-edu 2021
                    </span>
                </NavbarContainer>
            </Navbar>
        </IconContext.Provider>
        </>
    )
}

export default Footer
