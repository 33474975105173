import styled from "styled-components";

export const EnrollCourseContainerWrapper = styled.div`
	flex-grow: 1;
  	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  	width:100%;
`;


export const EnrollCourseContainer = styled.div`
	background: #fff;
	padding-top: 20px;
	padding-left: 0;
	padding-right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 20vh;
	box-shadow: 0 0 20px #000;
	border-radius: 10px;
	width: 40%;

	@media screen and (max-width: 1080px) {
		width: 65%;
	}

	@media screen and (max-width: 720px) {
		width: 80%;
	}

	@media screen and (max-width: 480px) {
		width: 80%;
	}
`;