/** @format */

module.exports = {
	formTitle: "Sugestões",
	formSubtitle: "Tem alguma sugestão? Conta pra gente!",
	placeholderTitle: "Título",
	placeholderBody: "Insira suas sugestões...",
	inputFilePlaceholder: "Enviar Arquivo...",
	submitLabel: "Enviar sugestão",
	uploadFileLabel: "Enviar arquivo",
	success: "Sugestão enviada com sucesso!",
	tryAgain:"Tentar novamente",
	sendOneMore:"Enviar mais uma",

	errors: {
		FileNotSelected: "Por favor selecione o arquivo",
		FileTooLarge: "O tamanho do arquivo não pode exceder 5MB",
		default:"A sugestão não pode ser enviada... Tente mais tarde!",
	},
};
