/** @format */

import styled from "styled-components";
import { H2 } from "../../GenericComponents";

export const ChestsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const ChanceExplanation = styled(H2)`
	text-align: center;
`;

export const ChestWrapper = styled.a`
	width: 100%;
	cursor: pointer;
`;

export const ChanceFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	overflow: hidden;

	span {
		overflow: hidden;

		font-size: 1.5rem;
		margin-bottom: 10px;
		font-weight: bold;
	}

	line-height: 50px;
	background: ${(props) => props.theme.colors.white};

	transition: all 0.5s ease-in-out;
	transform-origin: left top;
	transform: ${({ active }) => (active ? "scaleY(1.1)" : "scaleY(0)")};
	border-radius: 0 0 10px 10px;
	margin-top: -1px;
	margin-bottom: -1px;
`;
