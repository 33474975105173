/** @format */

import LoadingIcon from "../LoadingIcon/LoadingIcon";
import { FaCheck, FaTimes } from "react-icons/fa";
import { VerificationText, CheckIcon, TimesIcon } from "./LocalLoader-styled";

function LocalLoader({ isLoading, wasSuccessful, message, scale }) {
	return (
		<div style={{ textAlign: "center", width: "100%" }}>
			{isLoading ? (
				<LoadingIcon
					loadingText={message}
					scale={1.5}
				/>
			) : (
				<>
					{wasSuccessful ? (
						<CheckIcon scale={scale}>
							<FaCheck />
						</CheckIcon>
					) : (
						<TimesIcon scale={scale}>
							<FaTimes />
						</TimesIcon>
					)}
					<VerificationText scale={scale}>{message}</VerificationText>
				</>
			)}
		</div>
	);
}

export default LocalLoader;
