/** @format */

import SettingsContent from "../SettingsContent/SettingsContent";
import Select from "../../../common/Select/Select";
import { MainContentInner } from "../SettingsContent/SettingsContent-styled";

import initLang from "../../../../services/defineLanguage";
const texts = require(`../../../../texts/${initLang}/settingsLanguage`);

export default function SettingsLanguageView({changeLanguage, language, setLanguage}) {
	return (
		<SettingsContent
			title={texts.language}
			onSubmit={changeLanguage}
		>
			<MainContentInner margin="0 0 0 10px">
				<Select
					label={texts.language}
					value={language}
					setValue={setLanguage}
					required
					style={{ width: "200px" }}
				>
					<option value={"en"}>English</option>
					<option value={"pt"}>Português</option>
				</Select>
			</MainContentInner>
		</SettingsContent>
	);
}
