module.exports = {
    customization: 'Acessibilidade e Customização',
    theme: 'Tema',
    light: 'Claro',
    dark: 'Escuro',
    colorBlind: 'Daltônico',
    fontSize: 'Tamanho da fonte',
    small: 'Pequeno',
    medium: 'Médio',
    big: 'Grande',
    notifications: 'Notificações',
}
