/** @format */

import { Link } from "react-router-dom";
import styled from "styled-components";
import NavItem from "../navbar/NavItem";
import { useContext } from "react";
import { EagleContext } from "../../../../contexts/EagleContext";
// Importing components used to build sidebar items
import SidebarItem, { SidebarLink } from "./SidebarItem";

import logoText from "../../../../assets/images/logo-text.svg";
import logo from "../../../../assets/images/logo.svg";

import language from "../../../../services/defineLanguage";
import { useState } from "react";
import Modal2 from "../../../common/Modal2/Modal2";
import AddCourse from "../../../common/AddCourse/AddCourse";
const texts = require(`../../../../texts/${language}/sidebar`);

const SidebarContainer = styled.div`
	display: flex;
	flex-direction: column;

	max-width: ${(props) =>
		props.isHidden ? props.theme.width.hideSidebar : props.theme.width.sidebar};

	min-width: ${(props) =>
		props.isHidden ? props.theme.width.hideSidebar : props.theme.width.sidebar};

	@media screen and (max-width: 719px) {
		min-width: 0;
		max-width: 0;
	}
`;

const SidebarFooter = styled.div`
	font-size: 0.9rem;
	font-weight: 600;

	padding: 5px;
	margin-top: auto;

	color: ${(props) => props.theme.colors.white};
`;

const SidebarContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	height: 100%;

	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.primary};

	@media screen and (max-width: 719px) {
		display: ${(props) => (props.isHidden ? "none" : "flex")};

		width: 100vw;

		z-index: 1;

		margin-top: ${(props) => props.theme.height.navbar};
	}
`;

const SidebarContent = styled.ul`
	width: 100%;
`;

const LogoContainer = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;

	min-height: ${(props) => props.theme.height.navbar};
	width: 100%;
	padding: 5px;

	background-color: ${(props) => props.theme.colors.white};

	@media screen and (max-width: 719px) {
		display: none;
	}
`;

const GamificationElements = styled.div`
	display: none;

	@media screen and (max-width: 719px) {
		display: flex;
		width: 100%;

		a {
			width: 100%;
			margin: 5px 5px;
			padding: 1px;
			color: ${(props) => `${props.theme.colors.white}`};
		}
	}
`;

const Divider = styled.div`
	display: none;

	@media screen and (max-width: 719px) {
		display: block;
		width: 100%;

		hr {
			color: ${(props) => props.theme.colors.white};
		}
	}
`;

export default function Sidebar(props) {
	const [showAddCourse, setShowAddCourse] = useState(false);
	const { user, getCurrentCourseSetting, getCurrentCourse } =
		useContext(EagleContext);
	let courses = user ? user.courses || [] : [];
	let currentCourse = getCurrentCourse() || {};
	let settings = getCurrentCourseSetting();
	return (
		<>
			<SidebarContainer isHidden={props.isSidebarHidden}>
				<LogoContainer to="/profile">
					{props.isSidebarHidden ? (
						<img
							src={logo}
							alt="Eagle-edu logo"
						/>
					) : (
						<img
							src={logoText}
							alt="Eagle-edu logo"
						/>
					)}
				</LogoContainer>
				<SidebarContentContainer isHidden={props.isSidebarHidden}>
					<GamificationElements>
						{settings.economy && (
							<NavItem
								to="/store"
								icon="store"
							/>
						)}
						{settings.stats && (
							<>
								{settings.points && (
									<NavItem
										icon="point"
										value={currentCourse.points}
									/>
								)}
								{settings.acknowledgment && (
									<NavItem
										icon="achievements"
										value={
											currentCourse.achievements
												? currentCourse.achievements.length
												: 0
										}
									/>
								)}
								{settings.economy && (
									<NavItem
										icon="coin"
										value={currentCourse.economy}
									/>
								)}
							</>
						)}
					</GamificationElements>
					<Divider>
						<hr />
					</Divider>
					<SidebarContent>
						<SidebarItem
							name={texts.courses}
							icon="courses"
							openSidebar={props.openSidebar}
							isSidebarHidden={props.isSidebarHidden}
						>
							{courses.map((course) => {
								return (
									<SidebarLink
										key={course.courseInfo.id}
										to={`/course/${course.courseInfo.id}`}
									>
										{course.courseInfo.title}
									</SidebarLink>
								);
							})}
						</SidebarItem>
						<SidebarItem
							onClick={() => {
								setShowAddCourse(true);
							}}
							name={texts.addCourse}
							icon="plus"
							openSidebar={props.openSidebar}
							isSidebarHidden={props.isSidebarHidden}
						/>
					</SidebarContent>
					{!props.isSidebarHidden && (
						<SidebarFooter>Copyright © Eagle-edu 2021</SidebarFooter>
					)}
				</SidebarContentContainer>
			</SidebarContainer>
			<Modal2
				show={showAddCourse}
				title={texts.addCourse}
				disableFooter={true}
				onClose={() => {
					setShowAddCourse(false);
				}}
			>
				{" "}
				<AddCourse disableTitle={true} />
			</Modal2>
		</>
	);
}
