/** @format */

import Item1 from "../assets/images/items/item1.svg";
import Item2 from "../assets/images/items/item2.svg";
import Item3 from "../assets/images/items/item3.svg";
import Item4 from "../assets/images/items/item4.svg";
import Item5 from "../assets/images/items/item5.svg";
import Item6 from "../assets/images/items/item6.svg";
import Item7 from "../assets/images/items/item7.svg";
import Item8 from "../assets/images/items/item8.svg";
import Item9 from "../assets/images/items/item9.svg";
import Item10 from "../assets/images/items/item10.svg";
import Item11 from "../assets/images/items/item11.svg";
import Item12 from "../assets/images/items/item12.svg";
import ItemOff from "../assets/images/items/itemoff.svg";

const storeItems = new Map([
	["GoldFeather", { image: Item1, price: 30 }],
	["MetalClaws", { image: Item2, price: 30 }],
	["LuckyNail", { image: Item3, price: 50 }],
	["DiamondEgg", { image: Item4, price: 65 }],
	["ChristmasEagle", { image: Item5, price: 90 }],
	["RomanticEagle", { image: Item6, price: 90 }],
	["NurseEagle", { image: Item7, price: 90 }],
	["TeacherEagle", { image: Item8, price: 90 }],
	["DoubleOrNothing", { image: Item9, price: 260 }],
	["BigDay", { image: Item10, price: 280 }],
	["SuperWeek", { image: Item11, price: 340 }],
	["MyMonth", { image: Item12, price: 490 }],
	// ['', ItemOff],
]);

export default storeItems;
