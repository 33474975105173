/** @format */
import DynamicPatentBadge from "../../../environments/courses-board/Achievements/DynamicPatentBadge/DynamicPatentBadge";
import { Wrapper,SubTitle, BadgeContainer, BadgeName,BadgeImageBox, Description  } from "../Message-styled";


import language from '../../../../services/defineLanguage'

const messagesTexts = require(`../../../../texts/${language}/messages`)
const achievementsMessages = require(`../../../../texts/${language}/achievements`)

function NewAchievementContent({ value: achievementId }) {
	let achievement = achievementsMessages[`${achievementId}`] || {name:"unkonw", description:"unkonw"}
	return (
		<Wrapper>
			<SubTitle>
				<b>{messagesTexts.achievement}</b>
			</SubTitle>
			<BadgeContainer>
				<BadgeName> {achievement.name}</BadgeName>

				<BadgeImageBox>
					<DynamicPatentBadge
						badgeId={achievementId}
						enable={true}
					/>
				</BadgeImageBox>
				<Description>
					{achievement.description}
				</Description> 
			</BadgeContainer>
		</Wrapper>
	);
}

export default {
	content: NewAchievementContent,
	title: messagesTexts.congratulations,
};
