import styled from 'styled-components'

export const Wrapper = styled.form`
	margin: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	color: black;
`

export const OptionLabel = styled.label`
	margin-bottom: 0.3rem;
	text-align: left;
	width: 100%;
	display: inline-block;

	&:hover {
		cursor: pointer;
	}
`

export const OptionImage = styled.img`
	width: 15%;
	height: auto; // CHANGE
	margin-left: auto;
	margin-right: auto;
	display: block;
`
export const OptionsTable = styled.div`
	border-collapse: collapse;
	border-radius: 10px;
	width: 80%;

	@media screen and (max-width: 960px) {
		width: 100%;
	}

	&:hover,
	div:hover,
	label {
		pointer-events: ${(props) =>
			props.isQuestionAnswered ? 'none' : 'auto'} !important;
		cursor: ${(props) =>
			props.isQuestionAnswered ? 'not-allowed' : 'pointer'} !important;
	}
`

export const OptionContainer = styled.div`
	border: 1px solid ${(props) => props.theme.colors.mediumGray};
	border-radius: 10px;
	margin-bottom: 0.5rem;
	width: 100%;
	padding: 0.3rem;
	display: flex;
	align-items: center;

	${(props) => {
		// selected option
		if (props.isSelected === true) {
			if (props.isQuestionAnswered === false)
				return (
					`
            background-color:` +
					props.theme.colors.lightBlue +
					`;` +
					`color:` +
					props.theme.colors.white +
					`;`
				)
			// selected and question answered
			else {
				if (props.isCorrect) {
					return (
						`
            background-color:` +
						props.theme.colors.darkGreen +
						`;` +
						`color:` +
						props.theme.colors.white +
						`;`
					)
				} else {
					return (
						`
            background-color:` +
						props.theme.colors.darkRed +
						`;` +
						`color:` +
						props.theme.colors.white +
						`;`
					)
				}

				// selected and question not answered
			}
		} else {
			if (props.isQuestionAnswered === false) {
				return (
					`
            background-color:` +
					props.theme.colors.white +
					`;` +
					`color:` +
					props.theme.colors.black +
					`;`
				)
			} else {
				return (
					`
            background-color:` +
					props.theme.colors.gray +
					`;` +
					`color:` +
					props.theme.colors.black +
					`;`
				)
			}
		}
	}}

	pointer-events: ${(props) =>
		props.isQuestionAnswered ? 'none' : 'auto'} !important;
`
