/** @format */

module.exports = {
	instruction: "Choose the type of task to be added to the mission",
	quiz: " Select the correct option",
	pairs: " Select the pairs",
	openQuestion: " Open question",
	completeWithoutOptions: " Fill in the blanks",
	completeWithOptions: " Complete With Options",
	addTask: "Add task",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
