/** @format */

import { useState } from "react";

import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

import {
	InstructorContainer,
	InstructorSubContainer,
	ContentContainer,
} from "./InstructorStructure-styled";
import InstructorAccessManager from "../../../common/InstructorAccessManager";

export default function InstructorStructure({ children }) {
	const [isSidebarHidden, setIsSidebarHidden] = useState(true);

	function toggleSidebar() {
		setIsSidebarHidden(!isSidebarHidden);
	}

	function openSidebar() {
		setIsSidebarHidden(false);
	}

	return (
		<InstructorContainer>
			<InstructorAccessManager>
				<Sidebar
					isSidebarHidden={isSidebarHidden}
					openSidebar={openSidebar}
				/>
				<InstructorSubContainer>
					<Navbar toggleSidebar={toggleSidebar} />
					<ContentContainer>{children}</ContentContainer>
				</InstructorSubContainer>
			</InstructorAccessManager>
		</InstructorContainer>
	);
}
