/** @format */

import { useEffect } from "react";
import { TeamHeaderWrapper, PokeIcon } from "./team-header-styling";
import ReactTooltip from "react-tooltip";
import DynamicFaIcon from "../../../../common/DynamicFaIcon";

import language from "../../../../../services/defineLanguage";
import { FaQuestionCircle } from "react-icons/fa";
const texts = require(`../../../../../texts/${language}/course`);

const TeamHeader = ({ socialPressure }) => {
	return (
		<TeamHeaderWrapper socialPressure={socialPressure}>
			<div>{texts.teamColumnName}</div>
			<div>{texts.teamColumnProgress}</div>

			{socialPressure && (
				<PokeIcon>
					<p>{texts.teamColumnPoke}</p>
					<PokeIcon data-tip={texts.teamPokeTooltip}>
						<FaQuestionCircle/>
						<ReactTooltip
							place="top"
							type="dark"
							effect="solid"
						/>
					</PokeIcon>
				</PokeIcon>
			)}
		</TeamHeaderWrapper>
	);
};

export default TeamHeader;
