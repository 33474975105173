/** @format */

import api from "./api";
import * as Icons from "react-icons/fa";

export async function getCourses() {
	try {
		let res = await api.get("/instructor/Course/");
		return res.data.data;
	} catch (err) {
		console.log(err);
		return [];
	}
}

export async function getAllCourseTree(courseId) {
	try {
		let res = await api.get(`/instructor/Course/${courseId}/all`);
		return res.data.data;
	} catch (err) {
		console.log(err);
		return {};
	}
}

export async function getCourse(courseId) {
	try {
		let res = await api.get(`/instructor/Course/${courseId}`);
		return res.data.data;
	} catch (err) {
		console.log(err);
		return {};
	}
}

export async function getSubject(subjectId) {
	try {
		let res = await api.get(`/instructor/subject/${subjectId}`);
		return res.data.data;
	} catch (err) {
		console.log(err);
		return {};
	}
}

export async function getMissionTasks(missionId) {
	try {
		let res = await api.get(`/instructor/missionTasks/${missionId}`);
		return res.data.data;
	} catch (err) {
		console.log(err);
		return {};
	}
}

export async function createCourse(title, description, icon) {
	try {
		let res = await api.post(`/instructor/Course/`, {
			title,
			description,
			icon:
				icon === undefined
					? undefined
					: Object.keys(Icons).includes(icon)
					? icon
					: "",
		});

		if (res.data?.data?.id)
			return { success: true, courseId: res.data.data.id };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function deleteCourse(courseId) {
	try {
		let res = await api.delete(`/instructor/Course/`, {
			data: {
				courseId,
			},
		});

		if (res?.data?.data) return { success: true };
	} catch (err) {
		console.log(err);
	}
	return { success: false };
}

export async function editCourse(
	courseId,
	title,
	description,
	registrationKey,
	autoAcceptStudents,
	icon
) {
	try {
		let res = await api.patch(`/instructor/Course/`, {
			courseId,
			title,
			description,
			registrationKey,
			autoAcceptStudents,
			icon:
				icon === undefined
					? undefined
					: Object.keys(Icons).includes(icon)
					? icon
					: "",
		});

		if (res.data?.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function createSubject(courseId, title, description, icon) {
	try {
		let res = await api.post(`/instructor/course/subject`, {
			courseId,
			title,
			description,
			icon:
				icon === undefined
					? undefined
					: Object.keys(Icons).includes(icon)
					? icon
					: "",
		});
		if (res.data.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function editSubject(subjectId, title, description, icon) {
	try {
		let res = await api.patch(`/instructor/course/subject`, {
			subjectId,
			title,
			description,
			icon:
				icon === undefined
					? undefined
					: Object.keys(Icons).includes(icon)
					? icon
					: "",
		});

		if (res.data.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function deleteSubject(subjectId) {
	try {
		let res = await api.delete(`/instructor/course/subject`, {
			data: {
				subjectId,
			},
		});

		if (res?.data?.data) return { success: true };
	} catch (err) {
		console.log(err);
	}
	return { success: false };
}

export async function createMission(subjectId, title, description, icon) {
	try {
		let res = await api.post(`/instructor/course/mission`, {
			subjectId,
			title,
			description,
			icon:
				icon === undefined
					? undefined
					: Object.keys(Icons).includes(icon)
					? icon
					: "",
		});
		if (res.data.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function editMission(missionId, title, description, icon) {
	try {
		let res = await api.patch(`/instructor/course/mission`, {
			missionId,
			title,
			description,
			icon:
				icon === undefined
					? undefined
					: Object.keys(Icons).includes(icon)
					? icon
					: "",
		});

		if (res.data.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function deleteMission(missionId) {
	try {
		let res = await api.delete(`/instructor/course/mission`, {
			data: {
				missionId,
			},
		});

		if (res?.data?.data) return { success: true };
	} catch (err) {
		console.log(err);
	}
	return { success: false };
}

export async function createTask(missionId, taskType) {
	try {
		let res = await api.post(`/instructor/course/task`, {
			missionId,
			taskType,
		});
		if (res.data.data) return { success: true, data: res.data.data };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function editTask(taskId, text, icon = undefined, isTrue) {
	try {
		let res = await api.patch(`/instructor/course/task`, {
			taskId,
			text,
			icon:
				icon === undefined
					? undefined
					: Object.keys(Icons).includes(icon)
					? icon
					: "",
			isTrue,
		});

		if (res.data.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function updateTaskCorrectAlternative(
	taskId,
	newCorrectAlternativeId
) {
	try {
		let res = await api.patch(`/instructor/course/task/correctAlternative`, {
			taskId,
			newCorrectAlternativeId,
		});

		if (res.data.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function deleteTask(taskId) {
	try {
		let res = await api.delete(`/instructor/course/task`, {
			data: {
				taskId,
			},
		});

		if (res.data?.data) return { success: true };
	} catch (err) {
		console.log(err);
	}
	return { success: false };
}

export async function createAlternative(taskId, text) {
	try {
		let res = await api.post(`/instructor/course/alternative`, {
			taskId,
			text,
		});
		if (res.data.data) return { success: true, data: res.data.data };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function deleteAlternative(alternativeId) {
	try {
		let res = await api.delete(`/instructor/course/alternative`, {
			data: {
				alternativeId,
			},
		});

		if (res.data?.data) return { success: true };
	} catch (err) {
		console.log(err);
	}
	return { success: false };
}

export async function createPair(taskId, pairA, pairB) {
	try {
		let res = await api.post(`/instructor/course/pair`, {
			taskId,
			pairA,
			pairB,
		});
		if (res.data.data) return { success: true, data: res.data.data };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function deletePair(pairId) {
	try {
		let res = await api.delete(`/instructor/course/pair`, {
			data: {
				pairId,
			},
		});

		if (res?.data?.data) return { success: true };
	} catch (err) {
		console.log(err.response);
	}
	return { success: false };
}

export async function getGamificationSettings(courseId) {
	try {
		let res = await api.get(
			`/instructor/course/gamificationSettings/${courseId}`
		);
		return res.data.data;
	} catch (err) {
		console.log(err);
		return {};
	}
}
export async function updateGamificationSettings(courseId, settings) {
	try {
		let res = await api.patch(`/instructor/course/gamificationSettings`, {
			courseId,
			settings,
		});
		if (res.data.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function getCourseRegistrations(courseId) {
	try {
		let res = await api.get(`/instructor/course/${courseId}/registrations`);
		return res.data.data;
	} catch (err) {
		console.log(err);
		return [];
	}
}

export async function acceptRegistration(courseId, userId) {
	try {
		let res = await api.post(`/instructor/course/registrations/accept`, {
			courseId,
			userId,
		});
		if (res.data.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function rejectRegistration(courseId, userId) {
	try {
		let res = await api.post(`/instructor/course/registrations/reject`, {
			courseId,
			userId,
		});
		if (res.data.data) return true;
	} catch (err) {
		console.log(err);
	}
	return false;
}

export async function acceptAllRegistration(courseId) {
	try {
		let res = await api.post(`/instructor/course/registrations/accept/all`, {
			courseId,
		});
		if (res.data.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}

export async function rejectAllRegistration(courseId) {
	try {
		let res = await api.post(`/instructor/course/registrations/reject/all`, {
			courseId,
		});
		if (res.data.data) return true;
	} catch (err) {
		console.log(err);
	}
	return false;
}

export async function getCourseStudents(courseId) {
	try {
		let res = await api.get(`/instructor/course/${courseId}/students/`);
		return res.data.data;
	} catch (err) {
		console.log(err);
	}
	return [];
}

export async function getCourseStudentsStatistics(courseId) {
	try {
		let res = await api.get(
			`/instructor/course/${courseId}/students/statistics`
		);
		return res.data.data;
	} catch (err) {
		console.log(err);
	}
	return { numberOfMissions: 0, statistics: [] };
}

export async function getCourseStudentDeepStatistics(
	courseId,
	studentCourseId
) {
	try {
		let res = await api.get(
			`/instructor/course/${courseId}/students/statistics/${studentCourseId}`
		);
		return res.data.data;
	} catch (err) {
		console.log(err);
	}
	return { numberOfMissions: 0, statistics: [] };
}

export async function duplicateCourse(courseId) {
	try {
		let res = await api.post(`/instructor/course/duplicate`, {
			courseId,
		});

		if (res.data.data && res.data.data.id) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}
