import styled from "styled-components"

export const InputContent = styled.input`
    display: block;
    height: 40px;
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 10px;
    padding: 5px;
    font-size: 1rem;
    outline: none;

    &:focus {
        border-color: ${(props) => props.theme.colors.primary};
    }
`

export const InputLabel = styled.label`
    display: block;
    font-weight: 500;
`
