import { useState } from 'react'
import { QuestionType, QuestionInstruction } from '../Question-styled'
import { FaQuestion } from 'react-icons/fa'

import { Wrapper, StatementPreview } from './CompleteWithoutOptions-styled'
import TextArea from '../../../../common/TextArea/TextArea'

import language from '../../../../../services/defineLanguage'
const texts = require(`../../../../../texts/${language}/instructor/completeWithoutOptions`)

export default function CompleteWithoutOptions({ data }) {
    const [question, setQuestion] = useState('')
    const [statement, setStatement] = useState('')
    // Matches any character between '%' except '%' and ' '.
    const regexp = /%[^%\s]+%/gi

    function handlePreview() {
        let preview = statement.slice()
        preview = preview.replace(regexp, (match) => {
            return '_'.repeat(match.length - 2)
        })
        return preview
    }

    return (
        <>
            <FaQuestion size={50} />
            <QuestionType>{texts.type}</QuestionType>
            <QuestionInstruction>{texts.instruction1}</QuestionInstruction>
            <Wrapper>
                <TextArea
                    placeholder={texts.typeHere}
                    value={question}
                    setValue={setQuestion}
                />
            </Wrapper>
            <QuestionInstruction>
                <span>{texts.instruction2}</span>
            </QuestionInstruction>
            <Wrapper>
                <StatementPreview>{handlePreview()}</StatementPreview>
                <TextArea
                    placeholder={texts.typeHere}
                    value={statement}
                    setValue={setStatement}
                />
            </Wrapper>
        </>
    )
}
