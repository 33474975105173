/** @format */

const itemsNamesList = [
	"GoldFeather",
	"MetalClaws",
	"LuckyNail",
	"DiamondEgg",
	"ChristmasEagle",
	"RomanticEagle",
	"NurseEagle",
	"TeacherEagle",
	"DoubleOrNothing",
	"BigDay",
	"SuperWeek",
	"MyMonth",
];

export default itemsNamesList;
