/** @format */

import styled from "styled-components";
import { Button } from "../../GenericComponents";

export const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

export const TextCount = styled.span`
	font-size: 0.9rem;
	margin-bottom: 10px;
	color: ${(props) => props.theme.colors.darkGray};
`;

export const ButtonsWrapper = styled.div`
	display: flex;
`;

export const CreateButton = styled(Button)``;

export const DeleteButton = styled(Button)`
	margin-left: auto;
	background: ${(props) => props.theme.colors.warning};

	&:hover {
		background: ${(props) => props.theme.colors.black};
	}
`;
