/** @format */

module.exports = {
	rankingTitle: "Weekly Ranking",
	explanation:
		"The first two students in the weekly ranking will advance to the next level. However, be careful, as the two last ones will be demoted one level ",
	remainingTime: "Remaining Time:",
	timeOver: "Time is Over!",
	teamTitle: "Your Team",
	teamColumnName: "Name",
	teamColumnProgress: "Progress",
	teamPokeTooltip:
		"Send a notification by pressing one of your team members...",
	teamColumnPoke: "Poke",
	teamGreetings: "Hello,",
	teamThisWeek: "In this week, you are in team ",
	levelTitle: "Level",
	teamIfAllMembers: "If all team members  accomplish",
	teamMissionUntilWeekend:
		"missions until the weekend, each team member will earn ",
	teamCoins: "coins",
	achievementsTitle: "Achievements",
	achievementsDescription:
		"You have no achievements yet. But calm down, just do the missions that you will soon have your first achievement.",
	you: "You",
	teamsNames: ["Real Eagle", "Harpia", "Steller Eagle"],
	youAreNotOnAnyTeam:"You are not on any team!",

	errors: {},
};
