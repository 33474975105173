module.exports = {
	titlePlaceholder: 'Nome do Curso ',
	supportMaterialTooltipp: 'Material de Suporte',
	youHaveNewMission: 'Você possui uma nova missão',
	youCompletedAllMissions: "Parabéns! Você completou todas as missões desse assunto.",
	buttonLabel: 'Iniciar',
	disabledButtonLabel:
		'Clique no ícone no canto superior direito para continuar...',
	newMissionInstruction: "Missões são uma oportunidade de por em prática o que aprendeu em aula!",
	activities:"Atividades",
	missions:"Missões",	
	supportMaterials:"Materiais de suporte",
	noMaterials:"Sem materiais no momento.",
	errors: {},
}
