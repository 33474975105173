/** @format */

import { useState, useContext } from "react";
import { Form, TextCount, CreateButton } from "./NewCourse-styled";
import Input from "../../common/Input/Input";
import TextArea from "../../common/TextArea/TextArea";
import { useHistory } from "react-router-dom";

import language from "../../../services/defineLanguage.js";
import { createCourse } from "../../../services/Instructor";
import { IconInput } from "../../common/IconInput/IconInput";
import { ButtonWithLoading } from "../../common/ButtonWithLoading/ButtonWithLoading";
import { EagleContext } from "../../../contexts/EagleContext";
import { ErrorMessage } from "../../forms/FormsMessages";
const texts = require(`../../../texts/${language}/instructor/newCourse`);

export default function NewCourse({ onClose }) {
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [title, setTitle] = useState("");
	const [icon, setIcon] = useState("");
	const [description, setDescription] = useState("");
	const [response, setResponse] = useState({});
	const history = useHistory();

	async function tryCreateCourse() {
		let newResponse = await createCourse(title, description, icon);
		if (newResponse.success) {
			history.push(`/instructor/course/${newResponse.courseId}`);
		} else {
			if (newResponse.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
				console.log(newResponse);
				insertFakeInstantMessage(31, 0, 0, {});
				onClose();
			} else {
				setResponse(newResponse);
			}
		}
	}
	async function onSubmit(event) {
		event.preventDefault();

		tryCreateCourse();
		setResponse({ isLoading: true });
	}

	return (
		<>
			<Form onSubmit={onSubmit}>
				<Input
					label={texts.courseName}
					value={title}
					setValue={setTitle}
					placeholder={texts.typeHere}
					maxLength={50}
					required
				/>
				<TextCount>{title.length}/50</TextCount>
				<IconInput
					value={icon}
					setValue={setIcon}
					margin={"0 0 10px 0"}
				/>
				<TextArea
					label={texts.courseDescription}
					value={description}
					setValue={setDescription}
					placeholder={texts.typeHere}
					maxLength={300}
					required
				/>
				<TextCount>{description.length}/300</TextCount>

				{response.success === false && (
					<ErrorMessage style={{ marginBottom: "10px" }}>
						{texts.errors[response.error] ?? texts.errors["default"]}
					</ErrorMessage>
				)}

				<ButtonWithLoading
					width={"110px"}
					isloading={response?.isLoading}
				>
					{texts.create}
				</ButtonWithLoading>
			</Form>
		</>
	);
}
