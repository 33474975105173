import { useState } from 'react'
import { InputContainer, FormTextArea } from './InputContainer'

import iconNameMap from '../../../constants/iconNameUsingMap'

export default function TextArea({
	value,
	icon,
	placeholder,
	name,
	rows,
	onChange,
}) {
	const [isInvalid, setIsInvalid] = useState(false)

	const handleError = () => {
		if (!value) {
			setIsInvalid(true)
		} else {
			setIsInvalid(false)
		}
	}

	return (
		<InputContainer isInvalid={isInvalid}>
			{iconNameMap[icon]}

			<FormTextArea
				placeholder={placeholder}
				name={name}
				rows={rows}
				value={value}
				onChange={onChange}
				onBlur={handleError}
				required
			/>
		</InputContainer>
	)
}
