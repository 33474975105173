/** @format */

module.exports = {
	and: "and",
	congratulations: "Congratulations!",
	congratulations_2: "congratulations!",
	achievement: "You have a new achievement!",
	levels: "You Level Up!",
	//-------Points messages--------
	points_1: "You have earned ",
	points_2: " points",
	points_3: "so you earned ",
	youHaveEarned: "you have earned ",
	soYouEarned: "so you earned",
	"points_origin_1.1":
		"It wasn't this time. You finished the mission, but you missed a task, ",
	"points_origin_1.2":
		" Caprice and finish the mission without making any mistakes to earn more points.",
	points_origin_2:
		"My pride! You completed the mission without missing any tasks, ",
	points_origin_3:
		"Courage is something you have! You accepted the challenge and finished the mission without missing any tasks, ",
	points_origin_4:
		"It wasn't this time. You completed the mission, but missed a task. Go ahead and finish the mission without missing anything in the next one. ",
	points_origin_5:
		"You accepted the challenge and missed something, but don't worry, next time you'll make it!",
	//-------------------------------
	//-------economy messages--------
	coins_1: "You have earned ",
	coins_2: " coins! ",
	coins_3: "so you earned ",
	coins_4: "you have earned ",
	coins_5: "earned ",
	coins_6: " coins",
	economy_origin_1:
		"You were awesome! By taking first place in the weekly ranking, ",
	economy_origin_2:
		"This week you did very well! By being second in the weekly ranking, ",
	economy_origin_3:
		"Nice, this week you did very well! By being third in the weekly ranking, ",
	"economy_origin_4.1":
		"This week, you were in fourth place in the ranking and ",
	"economy_origin_4.2": "Caprice more next week to earn more coins.",
	"economy_origin_5.1":
		"This week, you were in fifth place in the weekly ranking and ",
	"economy_origin_5.2": "Try to improve to earn more coins. ",
	"economy_origin_6.1":
		"You’re doing really well and you’ve moved on to the next level, ",
	"economy_origin_6.2": " Keep it up...",
	"economy_origin_7.1": "You managed to stay on the same level, ",
	"economy_origin_7.2": "Now it's time to improve to get to the next level.",
	"economy_origin_8.1": "Your team from the course ",
	"economy_origin_8.2": " managed to complete the weekly mission, ",
	"economy_origin_8.3": "so everyone earned ",
	economy_origin_9:
		"Very good! You finished the mission without missing any tasks, ",
	economy_origin_10:
		"You showed courage! You accepted the challenge and finished the mission without missing any tasks, ",
	//-------------------------------
	continue: "Continue",
	search: "Search",
	userFormsInvitation:
		"We need you to answer a form to understand what kind of user you are! Want to help us?",
	wantToHelp: "Yes, I want to help!",
	dontWantToHelp: "I don't want to help.",
	socialPressureTitle: "You're falling behind!",
	socialPressure_1: "Your Colleague ",
	socialPressure_2:
		" has just managed to finish 10 activities. Don't be left behind, practice right now!",
	hello: "Hello ",
	titleWelcome: " Welcome!",
	welcome:
		" welcome to Eagle-edu! See the courses you are enrolled on the left and start right now to practice and have fun with us.",
	teamWork: "Team Work",
	poke_1: "Your team of Course ",
	poke_2: " is poking you. Finish your weekly missions!",
	goToCourse: "Go to the Course",
	newTeam_title: "New team!",
	newTeam_desc1: "You have been added to a new team in the course: ",
	newTeam_desc2: "Go to the course to check out your new team!",
	teamSuccess_desc1: " Your ",
	teamSuccess_desc2:
		" course team completed all the tasks for the week. Keep trying like that!!!",
	welcomeBack_title: "Welcome back!",
	welcomeBack_desc:
		" You've been away for a while, we miss you... Now don't waste any more time and get back to work!",
	upgradeInstructorSubscriptionPlan_title: "Upgrade your subscription plan",
	upgradeInstructorSubscriptionPlan_desc: `
	Action denied! Unfortunately, your current plan does not offer access to this
	functionality or has reached the permitted limit. Upgrade now and
	make the most of it!!`,
	upgradeNow: "Upgrade now",
};
