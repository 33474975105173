/** @format */

import { useState, useContext } from "react";
import { CourseOverviewContainer } from "../../../environments/courses-board/BoardSectionContainers";
import {
	CourseTitle,
	CourseData,
	CourseButtonContainer,
	CourseButton,
	CancelButton,
	CloneModalText,
	CloneModalWrapper,
	CloneModalButtonsWrapper,
} from "../Course-styled";
import DynamicFaIcon from "../../../common/DynamicFaIcon";
import { FaPencilRuler, FaClone, FaTrash } from "react-icons/fa";
import Modal2 from "../../../common/Modal2/Modal2";
import EditCourse from "../../EditCourse/EditCourse";
import LocalLoader from "../../../common/LocalLoader/LocalLoader.jsx";

import language from "../../../../services/defineLanguage.js";
import { deleteCourse, duplicateCourse } from "../../../../services/Instructor";
import { useHistory } from "react-router-dom";
import { Button } from "../../../GenericComponents";
import { EagleContext } from "../../../../contexts/EagleContext";
import { ButtonWithLoading } from "../../../common/ButtonWithLoading/ButtonWithLoading.jsx";
import { FloatingMessageBox } from "../../../common/FlaotingBox/FloatingMessageBox/FloatingMessageBox.jsx";
const texts = require(`../../../../texts/${language}/instructor/course`);

export default function CourseOverview({ data: CurrentCourse, forceReload }) {
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showCloneModal, setShowCloneModal] = useState(false);
	const [isDuplicating, setIsDuplicating] = useState(false);
	const [duplicationResponse, setDuplicationResponse] = useState(null);
	const [deleteCourseResponse, setDeleteCourseRespose] = useState({});

	const history = useHistory();

	async function handleRequestDuplication() {
		setIsDuplicating(true);
		requestDuplication();
	}

	async function requestDuplication() {
		try {
			let response = await duplicateCourse(CurrentCourse.id);
			if (response.success) {
				setDuplicationResponse({
					success: true,
					message: texts.cloned,
				});
			} else {
				if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
					insertFakeInstantMessage(31, 0, 0, {});
					setShowCloneModal(false);
					setDuplicationResponse(null);
				} else {
					setDuplicationResponse({
						success: false,
						message: texts.errors.clone,
					});
				}
			}
		} catch (err) {
			// handleError(err.response);
			setDuplicationResponse({
				success: false,
				message: texts.errors.clone,
			});
			console.log(err.response);
		}
		setIsDuplicating(false);
	}

	async function tryDeleteCourse() {
		let response = await deleteCourse(CurrentCourse.id);
		if (response.success) {
			history.push("/instructor");
		}

		setDeleteCourseRespose({ ...response, order: Date.now() });
	}
	async function handleDelete() {
		if (!window.confirm(texts.confirmDelete)) {
			return;
		}

		tryDeleteCourse();
		setDeleteCourseRespose({ isLoading: true });
	}

	function editCallback(forceReloadNeeded) {
		setShowEditModal(false);
		if (forceReloadNeeded) {
			forceReload();
		}
	}

	return (
		<CourseOverviewContainer>
			{CurrentCourse.id && (
				<CourseButtonContainer>
					<CourseButton onClick={() => setShowEditModal(true)}>
						<FaPencilRuler /> {texts.edit}
					</CourseButton>
					<CourseButton onClick={() => setShowCloneModal(true)}>
						<FaClone /> {texts.clone}
					</CourseButton>
					<ButtonWithLoading
						ButtonTemplate={CourseButton}
						isloading={deleteCourseResponse.isLoading}
						onClick={handleDelete}
					>
						<FaTrash /> {texts.delete}
					</ButtonWithLoading>
				</CourseButtonContainer>
			)}
			<CourseTitle>
				<DynamicFaIcon name={CurrentCourse.icon} />
				<p>{CurrentCourse.title}</p>
			</CourseTitle>
			<CourseData>{CurrentCourse.description}</CourseData>
			<CourseData>
				{texts.registrationKey} {CurrentCourse.registrationKey}
			</CourseData>
			<CourseData>
				{texts.acceptStudents}
				{CurrentCourse.autoAcceptStudents ? texts.on : texts.off}
			</CourseData>

			<Modal2
				title={texts.editCourse}
				show={showEditModal}
				onClose={() => setShowEditModal(false)}
				disableFooter={true}
			>
				<EditCourse
					courseId={CurrentCourse.id}
					initialTitle={CurrentCourse.title}
					initialIcon={CurrentCourse.icon}
					initialDescription={CurrentCourse.description}
					initialRegistrationKey={CurrentCourse.registrationKey}
					initialAutoAcceptStudents={CurrentCourse.autoAcceptStudents}
					callback={editCallback}
				/>
			</Modal2>
			<Modal2
				title={texts.cloneCourse}
				show={showCloneModal}
				onClose={() => {
					setShowCloneModal(false);
					if (!isDuplicating) {
						setDuplicationResponse(null);
					}
				}}
				disableFooter={true}
			>
				<CloneModalWrapper>
					{(duplicationResponse === null && isDuplicating) ||
					duplicationResponse !== null ? (
						<LocalLoader
							isLoading={isDuplicating}
							wasSuccessful={duplicationResponse && duplicationResponse.success}
							message={
								duplicationResponse !== null
									? duplicationResponse.message
									: texts.cloning
							}
							scale={1.2}
						/>
					) : (
						<>
							<CloneModalText>{texts.cloneWarning}</CloneModalText>
							<CloneModalButtonsWrapper>
								<CancelButton
									onClick={() => {
										setShowCloneModal(false);
									}}
								>
									{texts.cancel}
								</CancelButton>
								<Button
									onClick={() => {
										handleRequestDuplication();
									}}
								>
									{texts.clone}
								</Button>
							</CloneModalButtonsWrapper>
						</>
					)}
				</CloneModalWrapper>
			</Modal2>
			{deleteCourseResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={deleteCourseResponse.order - 1707521092301}
				>
					{texts.errors[deleteCourseResponse.error] ?? texts.errors.default}
				</FloatingMessageBox>
			)}
		</CourseOverviewContainer>
	);
}
