import { DropDown, Option } from './MissionSelect-styled'
import { useState, useEffect } from 'react'
import shuffleAlternatives from '../../../constants/shuffleAlternatives'

export default function MissionSelect ({
  id,
  options,
  answerWord,
  isInvalid,
  callback: handleSelectChange
}) {
  const [dropdownOptions, setDropDownOptions] = useState([])

  useEffect(() => {
    setDropDownOptions(shuffleAlternatives(options))
  }, [options])

  function dropDownOnChange (value) {
    // TODO: only send TRUE if all N selects have correct options in them!

    // isCorrect, seletedWord, missionSelectId
    handleSelectChange(value === answerWord ? true : false, value, id)
  }

  function renderDropDownOptions () {
    return dropdownOptions.map((currentOption, index) => {
      return (
        <Option key={index} value={currentOption.answerText}>{currentOption.answerText}</Option>
      )
    })
  }

  return (
    <DropDown
      onChange={e => dropDownOnChange(e.target.value)}
      isInvalid={isInvalid}
    >
      <Option value='none' selected disabled hidden>
        Select an Option
      </Option>

      {dropdownOptions && renderDropDownOptions()}
    </DropDown>
  )
}
