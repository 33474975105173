/** @format */

import styled from "styled-components";
import { Link } from "react-router-dom";
import point from "../../../../assets/images/point.svg";
import achievements from "../../../../assets/images/achievements.svg";
import coin from "../../../../assets/images/coin.svg";
import store from "../../../../assets/images/store.svg";

const NavItemContainer = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	margin: 5px;
	padding: 5px;

	color: ${(props) => `${props.theme.colors.black}`};

	font-size: 1rem;
	font-weight: 600;

	svg {
		font-size: 2rem;
	}

	img {
		width: 2.5rem;
		margin-right: 3px;
	}

	&:first-child {
		margin-right: auto;
	}
`;

const NavItemName = styled.span`
	@media screen and (max-width: 860px) {
		display: none;
	}
`;

const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

export default function NavItem({ children, icon, value, to = "" }) {
	function handleIcon() {
		if (icon === "point") {
			return <img src={point} />;
		} else if (icon === "achievements") {
			return <img src={achievements} />;
		} else if (icon === "coin") {
			return <img src={coin} />;
		} else if (icon === "store") {
			return <img src={store} />;
		} else {
			return;
		}
	}

	return (
		<NavItemContainer to={to}>
			{handleIcon()}
			<FlexColumn>
				<span>{value}</span>
				<NavItemName>{children}</NavItemName>
			</FlexColumn>
		</NavItemContainer>
	);
}
