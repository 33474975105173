import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
`;

export const ModalWrapper = styled.div`
  min-width: 90vw; /* Responsivo */
  max-height: 90vh; /* Definindo altura máxima */
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  z-index: 30;
  border-radius: 10px;

  /* Posicionando o modal no centro */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Estilização para telas menores que 768px */
  @media (max-width: 768px) {
    width: 90%;
  }

  /* Estilização para telas menores que 480px */
  @media (max-width: 480px) {
    width: 95%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  max-height: 90vh;
  min-height: 20vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  color: #141414;
  ::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	background: transparent; 
  p {
    margin-bottom: 1rem;
  }

  /* Estilização para telas menores que 480px */
  @media (max-width: 480px) {
    width: 95%;
  }
`;

export const MissionTitle = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0; /* Título fica fixo no topo */
  padding: 1rem 1; /* Espaçamento para o título */
  background: #fff;
  p {
    margin-left: 0.5rem;
  }
`;

export const MissionDescription = styled.span`
  font-size: 22px;
  margin: 1rem;
  max-height: 100%;
  overflow-y: auto; /* Adiciona um scroll no conteúdo se necessário */
  text-align: justify;
  text-justify: inter-word;
  @media (max-width: 480px) {
	font-size: 22px;
	margin: 1rem;
	text-align: justify;
	text-justify: inter-word;
	  }
`;

export const SubjectIconSection = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-bottom: 0.5rem;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 30;
  color: ${(props) => props.theme.colors.primary};
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const CloseModalButtonContainer = styled.div`
  width: 100%;
`;
