/** @format */

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useContext } from "react";
import {
	Notifications,
	NotificationsIconBorder,
	NotificationsIconFilled,
	NotificationContainer,
	NotificationList,
	Header,
	Title,
	Container,
	NotificationsAlert,
	NotificationsAlertBox,
} from "./NotificationPanel-styled";
import { OptionItem } from "../../../../common/OptionsButton/OptionsButton-styled";
import OptionsButton from "../../../../common/OptionsButton/OptionsButton";

import { EagleContext } from "../../../../../contexts/EagleContext"; // Importando o Contexto do Eagle
import NotificationItem from "../NotificationItem/NotificationItem";
import Message from "../../../../common/Message/Message";

export default function NotificationPanel() {
	const [isSelected, setIsSelected] = useState(false);
	const history = useHistory();
	const location = useLocation().pathname;

	const [selectedMessage, setSelectedMessage] = useState({});
	const { messages, markNotificationAsRead, markAllNotificationsAsRead } =
		useContext(EagleContext); // Obtendo o estado de notificações e a função de marcar como lida do contexto

	// Função handleIcon() retorna o ícone de notificações preenchido ou não, dependendo do estado isSelected
	function handleIcon() {
		if (isSelected) {
			return <NotificationsIconFilled />;
		}
		return <NotificationsIconBorder />;
	}

	// Função handleSeeAll() redireciona o usuário para a página de notificações
	function handleSeeAll() {
		history.push("/notifications");
	}

	// Função handleNotificationClick() recebe uma notificação e a armazena no estado selectedMessage
	function handleNotificationClick(notification) {
		setSelectedMessage(notification);
		markNotificationAsRead(notification.id);
	}

	let numberOfUncheckedMessages = messages.filter((message) => {
		return !message.checked;
	}).length;
	return (
		<>
			{location !== "/notifications" && (
				<Container>
					{/* Uso da variável isSelected para exibir o ícone de notificações preenchido ou não */}
					<Notifications onClick={() => setIsSelected(!isSelected)}>
						{numberOfUncheckedMessages > 0 && (
							<NotificationsAlertBox>
								<NotificationsAlert
									size={numberOfUncheckedMessages > 99 ? "26px" : "22px"}
								>
									{numberOfUncheckedMessages > 99
										? "+99"
										: numberOfUncheckedMessages}
								</NotificationsAlert>
							</NotificationsAlertBox>
						)}
						{handleIcon()}
					</Notifications>
					{isSelected && (
						<NotificationContainer>
							<Header>
								<Title>Notificações</Title>
								{/* Uso do componente OptionsButton para exibir as opções de "Ver todas" e "Marcar todas como lido" */}
								<OptionsButton>
									<OptionItem onMouseDown={() => handleSeeAll()}>
										Ver todas
									</OptionItem>
									<OptionItem onMouseDown={() => markAllNotificationsAsRead()}>
										Marcar todas como lido
									</OptionItem>
								</OptionsButton>
							</Header>
							{/* Lista de notificações renderizadas com base nos dados do arquivo testDataBase.js */}
							<NotificationList>
								{messages.map((message) => (
									<NotificationItem
										key={message.id}
										type={message.type}
										date={message.date}
										checked={message.checked}
										onClick={() => handleNotificationClick(message)}
										onCheck={() => {
											if (!message.checked) markNotificationAsRead(message.id);
										}} // Chama a função para marcar como lida
									/>
								))}
							</NotificationList>
						</NotificationContainer>
					)}
				</Container>
			)}
			{/* Renderiza o componente Message com a mensagem selecionada, se existir */}
			<Message
				message={selectedMessage}
				closeModal={() => setSelectedMessage({})}
			/>
		</>
	);
}

// qdo clicar na bolinha, marcar como lida (criar função para isso)
