/** @format */

module.exports = {
	gamification: "Gamification",
	advancedSettings: "Advanced gamification settings",
	settingsDescription:
		"Enable or disable specific gamification elements on your course.",
	acknowledgment: {
		title: "Acknowledgment",
		description:
			"All kind of feedback that praises players' specific actions. The Acknowledgment element is displayed in all pages (in the fixed header) and highlighted on the Home and Profile pages and is represented by the student's badges display. Six different badges can be achieved through special sequences in the system: a) complete a mission without making a mistake (expert patent), b) complete 5 missions without making a mistake (super conquering patent), c) complete 10 missions without making any mistakes (hero rank), d) climb three divisions in a row (great pathfinder rank), e) lead the ranks 2 weeks in a row (grand rank leader), f) unlock all three missions (boss big rank). When Acknowledgment is disabled, badges do not appear on the student interface.",
		icon: "FaGamepad",
	},
	level: {
		title: "Level",
		description:
			"Hierarchical layers present in a game that provides a gradual way for the player to gain new advantages as he progresses. The level element is displayed on the homepage and is represented by phase (Bronze, Silver, Gold, Diamond and Ruby, respectively). All students start in the Bronze phase. The top two places in the weekly ranking pass and the last two return to the bottom (with each division, the level of activities is come more difficult). In addition, each student receive 5 coins for in-store purchases always advance in phase or 2 coins whenever they can stay in-phase (see the implementation of the Economy element). When Level is disabled, the phases do not appear in the student interface, and students don't earn those specific coins.",
		icon: "FaGamepad",
	},
	point: {
		title: "Points",
		description:
			"Units used to measure user performance. The Point element is displayed on all pages (in the fixed header) and is represented by experience points (XP). The student earn seven points for each activity done and two extra points if the mission is done correctly (hit all the answers without mistakes). Points is updated each time the student completes an activity group. The total points are also highlighted on the Profile page. When Points are disabled, points do not appear in the students' interface and the ranking is updated based on the number of tasks performed (each task is equivalent to two points).",
		icon: "FaGamepad",
	},
	progression: {
		title: "Progression",
		description:
			"Allows players to locate (and their progress) within a game. The Progression element is displayed on the Home and Learn pages and is represented as a progress bar. In the homepage is represented by a progress bar in the activity tree (see the implementation of the Objectives element) indicating how much of each activity group has been completed and how much remains to be completed. The Learn page is represented by a progress bar showing how much has been completed and how much is left to complete each activity group. When Progression is disabled, the progress bars do not appear in the students' interface.",
		icon: "FaGamepad",
	},
	stats: {
		title: "Stats",
		description:
			"Visible information used by the player, related to his game results. The Stats element is presented on the Profile page (as well in the Friends sub-page and in the header) and is represented by all user progress information (Points, Achievements, Economy, Following and Followers), which by default is already displayed on the Profile page. When Status is disabled, the information mentioned does not appear in the students' interface.",
		icon: "FaGamepad",
	},
	chance: {
		title: "Chance",
		description:
			"Chance: Characteristics of randomness and probability to increase or decrease the chances of certain actions or outcomes. The Chance element is displayed on the Learn page. Students have different types of choices to do, for instance choosing between chests. In each chest, different prizes can be given, for example, coins, points, or surprise badges. When Chance is disabled, the students will no longer be able to make the mentioned choices, as they will not appear in the students' interface.",
		icon: "FaGamepad",
	},
	economy: {
		title: "Economy",
		description:
			"Transactions within the game, monetizing game values and other elements. The Economy element appear on all pages (in the fixed header) and is represented by coins that can be used to make in-game purchases (see the implementation of the Rarity element). Coins can be won through the Competition and Cooperation elements (see their respective implementations). In addition, for each three missions, students can choose to open a chest that have a random value between 1 and 10 coins (see the implementation of the Imposed Choice element). The total coins are also highlighted on the Profile page. When Economy is disabled, the student stops earning coins and the store page does not appear in the students' interface.",
		icon: "FaGamepad",
	},
	imposedChoice: {
		title: "Imposed choice",
		description:
			"Decisions that the player is required to make to advance the game. The Chance element appear on the Learn page and is represented by the random option offered to the user to increase their prize. At certain (random) times, before starting a mission, the user is informed that if they completes that specific mission without any mistake, they earn 2 extra coins and receive double the points, however, if they make a mistake in any task, they will not earn any points and no coins. When Imposed Choice is disabled, students will not have these choice options in their interface.",
		icon: "FaGamepad",
	},
	rarity: {
		title: "Rarity",
		description:
			"Limited and collectible resources. The Rarity element appear on the Store page and is represented as a series of shields. Rare items is available in the system store with purchase values that can be purchased through coins (see implementation of the Economy element). When Rarity is disabled, rare items do not appear in the store's store.",
		icon: "FaGamepad",
	},
	timePressure: {
		title: "Time pressure",
		description:
			"Pressure in time within the game. The Time Pressure element is present on the homepage and is represented by a weekly countdown (thus associated with the Competition element). The countdown start each week and is displayed in the day/time format. When Time Pressure is disabled, the ranking clock does not appear in the students' interface.",
		icon: "FaGamepad",
	},
	competition: {
		title: "Competition",
		description:
			"Occurs when two or more players compete with each other for a common goal. The Competition element is featured on the homepage and is represented by weekly ranking (leaderboards with up to 10 students). The ranking is based on points earned (see the implementation of Points element), however is weekly reset. First place in each weekly ranking earn 50 coins for in-store purchases (see Economy element implementation). The runner-up win 40 coins, third place 30, fourth place 20 and fifth place 10. When Competition is disabled, the ranking does not appear in the students' interface and stops generating its specific coins.",
		icon: "FaGamepad",
	},
	cooperation: {
		title: "Cooperation",
		description:
			"When two or more players collaborate to achieve a common goal. The Cooperation element is featured on the homepage and is represented by the formation of random teams of up to 5 students. All points earned individually by each student is simultaneously accumulated individually for and for the team to which they are assigned during the week. Also, whenever all students on a team complete the same activities, the team earn five extra points (team only). If the team achieves the weekly goal (set each week), all members earn 10 coins for in-store purchases (see Implementation Economy element). When Cooperation is disabled, the team board does not appear in the students' interface.",
		icon: "FaGamepad",
	},
	reputation: {
		title: "Reputation",
		description:
			"Titles that the player accumulates in the game. It is shown on the home page and is represented by titles that the student earns as they complete the activities. There are 5 titles, starting in the “Beginner” and ending in “Great king”. When Reputation is disabled, the titles do not appear in the students' interface.",
		icon: "FaGamepad",
	},
	socialPressure: {
		title: "Social pressure",
		description:
			"Pressure through social interactions with another player(s). The Social Pressure element is displayed on the Profile page and is represented by an alert message whenever another student finishes a sequence of activities that he/she has not yet finished. When Social Pressure is disabled, the students will no longer receive pressure messages.",
		icon: "FaGamepad",
	},
	novelty: {
		title: "Novelty",
		description:
			"New and updated information presented to the player continuously. The Novelty element appear in the Store page. It is represented by selling special objects according to annual commemorative dates (e.g., Christmas or Easter). When Novelty is disabled, special items do not appear in the store.",
		icon: "FaGamepad",
	},
	objectives: {
		title: "Objectives",
		description:
			"Guide the actions of players. Quantifiable or spatial, short to long term. The Objectives element is displayed on the homepage and is represented by a quest tree. This tree show the entire sequence of missions the student has in the system, highlighting what has already been done and what can still be done. When Objectives is deactivated, the activity tree does not appear in the students' interface and the student only has access to the next activity.",
		icon: "FaGamepad",
	},
	puzzles: {
		title: "Puzzles",
		description:
			"In-game challenges that should make the player think. The Puzzles element is presented on the Learn page and is represented by proposing surprise challenges, related to the subject being studied (randomly selected by the system, from the missions registered by the instructor/teacher). For each challenge accepted, the user earn 10 points and if successfully resolved, the user earn 5 extra coins. When Puzzles is disabled, missions with these special challenges do not appear to students.",
		icon: "FaGamepad",
	},
	renovation: {
		title: "Renovation",
		description:
			"When players can redo/re-initiate an action. The Renovation element is displayed on the Learn page and is represented through the possibility for students to perform activities in which they err. There is no cost for students to redo the activities they have missed. Each mission is only completed when the student has completed all activities. When Renovation is disabled, whenever a user wrongs an activity, they can no longer do the same activity.",
		icon: "FaGamepad",
	},
	sensation: {
		title: "Sensation",
		description:
			"Use of players' senses to create new experiences. The Sensation element is displayed on the Learn page and is represented by immediate feedback (visual and audible) regarding each action/response from users, indicating whether they have hit or missed each question. When Sensation is disabled, the student does not receive visual or audible feedback on activities, only normal textual feedback.",
		icon: "FaGamepad",
	},
	narrative: {
		title: "Narrative",
		description:
			"Order of the events in which they take place in a game. These are choices influenced by the actions of the players. The narrative element is presented on the Learn page and is represented by the user's ability to do extra activities. At times (random), when the user completes a quest, they may choose to go to other system pages or immediately do a new quest with the premise of earning 2 extra coins. When Narrative is disabled, the story does not appear in the students' interface.",
		icon: "FaGamepad",
	},
	storytelling: {
		title: "Storytelling",
		description:
			"How the story of the game is told (as a screenplay). It is counted within the game through text, voice or sensory capabilities. The Storytelling element is present on the Profile page and is represented by the evolution of the avatar (and its story) that represents each student. Every five active days on the system (using the system on 5 different days), a new avatar evolution is made available. In all, 15 avatars evolution are.",
		icon: "FaGamepad",
	},
};
