/** @format */

import { useState, useContext } from "react";
import { EagleContext } from "../../../../contexts/EagleContext";

import SettingsSecurityView from "./SettingsSecurityView";
import { changePassword } from "../../../../services/User";
import { InstructorContext } from "../../../../contexts/InstructorContext";

export default function SettingsSecurityCotainer() {
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
	const [changePasswordResponse, setChangePasswordResponse] = useState({});

	const { user: student, userType } = useContext(EagleContext);
	const { user: instructor } = useContext(InstructorContext);

	let user = userType === "Student" ? student : instructor;

	async function tryChangePassword() {
		let newResponse = await changePassword(
			password,
			newPassword,
			newPasswordConfirm
		);
		setChangePasswordResponse(newResponse);
	}
	const onSubmit = async (event) => {
		event.preventDefault();

		setChangePasswordResponse({ isLoading: true });
		tryChangePassword();
	};
	return (
		<>
			<SettingsSecurityView
				email={user.email}
				onSubmit={onSubmit}
				password={password}
				setPassword={setPassword}
				newPassword={newPassword}
				setNewPassword={setNewPassword}
				newPasswordConfirm={newPasswordConfirm}
				setNewPasswordConfirm={setNewPasswordConfirm}
				changePasswordResponse={changePasswordResponse}
			/>
		</>
	);
}
