/** @format */

module.exports = {
	chooseYourAvatar: "Escolha seu avatar",
    chooseThisAvatar: "Escolher este avatar",
	avatarModalDescription:
		"Esse avatar te representará no sistema (uma vez escolhido, você não poderá mais o alterar).",
	GoldenEagle: {
		name: "Águia Real",
		description: "A Águia Real chama a atenção por suas grandes dimensões, de até dois metros e meio. Quando voa para baixo, mergulhando, atinge uma velocidade de 250 km/h. Geralmente, ele caça roedores, bem como animais de tamanho médio, como filhotes de javali ou cervos.",
		levels: [
			"A Águia Real ainda nem sabe que irá nascer, mas seus pais já estão se preparando para sua chegada e construíram grandioso ninho.",
			"A Águia Real está próxima de nascer. Seu belo e grandioso ovo o guarda protegida dos perigos.",
			"Agora a Águia Real está bem mais próxima de nascer. Já é possível perceber seu ovo começar a rachar.",
			"Finalmente! A Águia Real pôde ver o mundo pela primeira vez.",
			"Pouco tempo após nascer já é possível ver a Águia Real coberta por sua penugem branca.",
		],
	},
	Harpia: {
		name: "Harpia",
		description: "A Harpia vive nas florestas tropicais e é uma das maiores e mais poderosas da espécie. Sua plumagem é acinzentada com faixas e listras mais escuras nas asas e na cabeça.",
		levels: [
			"A Harpia ainda nem sabe que irá nascer, mas seus pais já estão se preparando para sua chegada e construíram lindo ninho.",
			"A Harpia está próxima de nascer. Seu lindo ovo a guarda protegida dos perigos da selva.",
			"Agora a Harpia está muito próxima de conhecer o mundo. Já é possível perceber seu ovo começar a rachar.",
			"Agora sim!!! A Harpia pôde começar a conhecer o mundo.",
			"A Harpia nasceu e já é possível perceber sua característica marcante da penugem branca com um lindo bico preto.",
		],
	},
	StellerEagle: {
		name: "Águia Steller",
		description: "A Águia Steller é nativa do Japão, Coreia e Norte da China e adora se alimentar de salmão. Ele pode caçar em pleno voo ou até mergulhar na água. Com seu grande bico laranja e seu andar curvado, é extraordinário e original.",
		levels: [
			"A Águia Steller nem imagina que irá nascer, mas seus pais já estão prontos para seu nascimento e construíram maravilhoso ninho para sua chegada.",
			"A Águia Steller já está próxima de nascer. Ela esta protegida dentro de um lindo ovo.",
			"Agora a Águia Steller está muito próxima de nascer e seu ovo já começar a rachar.",
			"Agora sim!!! A Águia Steller está começando a conhecer o mundo.",
			"A Águia Steller nasceu! Desde criança já se destaca por seu belo bico laranja.",
		],
	},

	confirmTitle: "Confirme sua escolha de avatar",
	confirmHeader1: "Você tem certeza que quer escolher ",
	confirmHeader2: " como seu avatar?",
	confirmDesc: "Uma vez escolhido, você não poderá mais mudar seu avatar.",
	confirmButton: "Confirmar",
};