import styled from 'styled-components'

export const CenterBox = styled.div`
    background: ${(props) => props.theme.colors.white};
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 600px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 10px;

    @media screen and (max-width: 660px) {
        width: 350px;
    }
`