import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    height: 180px;
    width: 120px;

    box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
`

export const Name = styled.p`
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;

    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
` 

export const FollowButton = styled.button`
	padding: 3px;
    width: 100%;
    margin-bottom: 5px;

	border: none;
	border-radius: 5px;
	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.lightBlue};
	background-image: linear-gradient(
		90deg,
		rgba(0, 34, 34, 0.1) 0%,
		rgba(34, 34, 34, 0.2) 100%
	);

	font-size: 1rem;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => props.theme.colors.primary};
	}
`


export const UnfollowButton = styled(FollowButton)`
	
	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.warning};
` 