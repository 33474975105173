module.exports = {
	points: "Pontos",
	achievements: "Conquistas",
	economy: "Moedas",
	home: "Inicio",
	profile: "Perfil",
	friends: "Amigos",
	suggestions: "Sugestões",
	settings: "Configurações",
	logout: "Sair",
	enrollCourse:"Inicio"

}
