/** @format */

import { useState, useContext } from "react";
import {
	Form,
	TextCount,
	SaveButton,
	Label,
	ButtonsWrapper,
} from "./EditCourse-styled";
import Input from "../../common/Input/Input";
import TextArea from "../../common/TextArea/TextArea";
import ToggleButton from "../../common/ToggleButton/ToggleButton";
import language from "../../../services/defineLanguage.js";
import { editCourse } from "../../../services/Instructor";
import { IconInput } from "../../common/IconInput/IconInput";
import { EagleContext } from "../../../contexts/EagleContext";
import { ErrorMessage } from "../../forms/FormsMessages";
import { ButtonWithLoading } from "../../common/ButtonWithLoading/ButtonWithLoading";
const texts = require(`../../../texts/${language}/instructor/editCourse`);

export default function EditCourse({
	courseId,
	initialTitle,
	initialIcon,
	initialDescription,
	initialRegistrationKey,
	initialAutoAcceptStudents,
	callback,
}) {
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [title, setTitle] = useState(initialTitle);
	const [icon, setIcon] = useState(initialIcon);
	const [description, setDescription] = useState(initialDescription);
	const [registrationKey, setRegistrationKey] = useState(
		initialRegistrationKey
	);
	const [autoAcceptStudents, setAutoAcceptStudents] = useState(
		initialAutoAcceptStudents
	);
	const [response, setResponse] = useState({});

	async function tryEditCourse() {
		let finalTitle = initialTitle === title ? undefined : title;
		let finalDescription =
			initialDescription === description ? undefined : description;
		let finalRegistrationKey =
			initialRegistrationKey === registrationKey ? undefined : registrationKey;
		let finalAcceptNewStudents =
			initialAutoAcceptStudents === autoAcceptStudents
				? undefined
				: autoAcceptStudents;
		let finalIcon = initialIcon === icon ? undefined : icon;

		let newResponse = await editCourse(
			courseId,
			finalTitle,
			finalDescription,
			finalRegistrationKey,
			finalAcceptNewStudents,
			finalIcon
		);

		if (newResponse.success) {
			callback(true);
		} else if (newResponse.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
			callback(false);
		} else {
			setResponse(newResponse);
		}
	}

	async function onSubmit(event) {
		event.preventDefault();

		tryEditCourse();
		setResponse({ isLoading: true });
	}
	return (
		<>
			<Form onSubmit={onSubmit}>
				<Input
					label={texts.courseName}
					value={title}
					setValue={setTitle}
					maxLength={50}
					required
				/>
				<TextCount>{title.length}/50</TextCount>
				<IconInput
					value={icon}
					setValue={setIcon}
					margin={"0 0 10px 0"}

				/>
				<TextArea
					label={texts.courseDescription}
					value={description}
					setValue={setDescription}
					maxLength={300}
					required
				/>
				<TextCount>{description.length}/300</TextCount>
				<Input
					label={texts.courseKey}
					value={registrationKey}
					setValue={setRegistrationKey}
					maxLength={50}
					required
				/>
				<TextCount>{registrationKey.length}/50</TextCount>
				<Label>{texts.acceptStudents}</Label>
				<ToggleButton
					checked={autoAcceptStudents}
					setChecked={setAutoAcceptStudents}
				/>
				{response.success === false && (
					<ErrorMessage margin={"10px 0 15px 0"}>
						{texts.errors[response.error] ?? texts.errors["default"]}
					</ErrorMessage>
				)}
					<ButtonWithLoading
						width={"110px"}
						isloading={response?.isLoading}
					>
						{texts.save}
					</ButtonWithLoading>
			</Form>
		</>
	);
}
