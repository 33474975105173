/** @format */

import DynamicFaIcon from "../../../common/DynamicFaIcon";
import { InputLabel } from "../../../common/Input/Input-styled";
import { SelectContainer, FormSelect } from "./select-input-styling";
import { useMemo } from "react";

export default function SelectInput({
	children,
	onChange,
	icon,
	placeholder,
	name,
	value,
    label,
	margin,
}) {
	const memoIcon = useMemo(() => <DynamicFaIcon name={icon} />, [icon]);

	return (
		<>
			{label && <InputLabel>{label}</InputLabel>}
			<SelectContainer
				icon={!!icon}
				margin={margin}
			>
				{icon && memoIcon}
				<FormSelect
					placeholder={placeholder}
					name={name}
					value={value}
					onChange={onChange}
					required
				>
					{children}
				</FormSelect>
			</SelectContainer>
		</>
	);
}
