/** @format */

module.exports = {
	gamification: "Gamificação",
	basicSettings: "Configurações básicas de gamificação",
	settingsDescription:
		"Habilite ou desabilite dimensões de gamificação no seu curso.Se um ou mais elementos da gamificação avançada pertencentes a uma dessas dimensões estiver habilitado, a categoria constará como habilitada.",
	performance: {
		title: "Performance/Medidas",
		description:
			"Esta dimensão é composta por cinco elementos de gamificação (ou seja, Reconhecimento, Nível, Ponto, Progressão e Estatísticas). Esses são elementos relacionados à resposta do ambiente, que podem ser usados para fornecer feedback ao aluno.",
		icon: "FaGamepad",
	},
	ecological: {
		title: "Ecológico",
		description:
			"Esta dimensão é representada por cinco elementos de gamificação (ou seja, Chance, Economy, ImposedChoice, Rarity e Time Pressure). Este contexto está relacionado ao ambiente em que a gamificação está sendo implementada. Esses elementos podem ser representados como propriedades.",
		icon: "FaGamepad",
	},
	social: {
		title: "Social",
		description:
			"Essa dimensão é composta por quatro elementos de gamificação (ou seja, Competição, Cooperação, Reputação e Pressão Social). Essa dimensão está relacionada às interações entre os alunos apresentadas no ambiente.",
		icon: "FaGamepad",
	},
	personal: {
		title: "Pessoal",
		description:
			"Esta dimensão é composta por cinco elementos de gamificação (ou seja, Novidade, Objetivos, Quebra-cabeças, Renovação e Sensação). Essa dimensão está relacionada ao aluno que está usando o ambiente.",
		icon: "FaGamepad",
	},
	fictional: {
		title: "Ficcional",
		description:
			"Esta dimensão é composta por dois elementos de gamificação (ou seja, Narrativa e Storytelling). É uma dimensão temática que se relaciona com o usuário (por meio da Narrativa) e o ambiente (por meio da Storytelling), vinculando sua experiência ao contexto.",
		icon: "FaGamepad",
	},
};
