/** @format */

module.exports = {
	missionsMenu: "Missions menu",
	newTask: "New task",
	noQuestions:
		'There are no questions in this mission! Create a question by clicking the "+" button above.',
	save: "Save",
	confirmDelete: "Do you really want to delete this question?",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
