
import React from 'react';
import { Container, SettingsOptionsContainer } from './SettingsStructure-styled';
import LinkWithIcon from '../../../common/LinkWithIcon/LinkWithIcon';
import language from '../../../../services/defineLanguage';
const texts = require(`../../../../texts/${language}/settingsStructure`);

export default function SettingsStructure({ pathBase="",children }) {
  return (
      <Container>
        <SettingsOptionsContainer>
          <LinkWithIcon
            iconName="FaUser"
            iconSize={25}
            to={`${pathBase}/settings/profile`}
            hover={true}
          >
            {texts.profile}
          </LinkWithIcon>

          <LinkWithIcon
            iconName="FaLanguage"
            iconSize={25}
            to={`${pathBase}/settings/language`}
            hover={true}
          >
            {texts.language}
          </LinkWithIcon>

          <LinkWithIcon
            iconName="FaShieldAlt"
            iconSize={25}
            to={`${pathBase}/settings/security`}
            hover={true}
          >
            {texts.security}
          </LinkWithIcon>

          {/* <LinkWithIcon
            iconName="FaUserLock"
            iconSize={25}
            to="/settings/privacy"
            hover={true}
          >
            {texts.privacy}
          </LinkWithIcon> */}

          {/* <LinkWithIcon
            iconName="FaPencilRuler"
            iconSize={25}
            to="/settings/customization"
            hover={true}
          >
            {texts.customization}
          </LinkWithIcon>

          <LinkWithIcon
            iconName="FaGamepad"
            iconSize={25}
            to="/settings/gamification"
            hover={true}
          >
            {texts.gamification}
          </LinkWithIcon> */}
        </SettingsOptionsContainer>
        {children}
      </Container>
  );
}
