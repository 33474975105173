/** @format */

import React from "react";
import { Route } from "react-router-dom";
import AccountVerificationRequest from "./Request/AccountVerificationRequest";
import AccountVerificationWarning from "./Warning/AccountVerificationWarning";

function AccountVerification() {
	

	return (
		<>
			<AccountVerificationWarning />
			<Route
				path={`/accountverification/:code`}
				component={AccountVerificationRequest}
			/>
		</>
	);
}

export default AccountVerification;
