import SettingsStructure from './SettingsStructure/SettingsStructure'
import InstructorStructure from '../../environments/instructor/InstructorStructure/InstructorStructure'
import SettingsPrivacy from './SettingsPrivacy/SettingsPrivacy'
import SettingsCustomization from './SettingsCustomization/SettingsCustomization'
import SettingsGamification from './SettingsGamification/SettingsGamification'
import { Route, useLocation, useHistory } from 'react-router-dom'


import { useEffect } from 'react'
import SettingsProfileContainer from './SettingsProfile/SettingsProfileContainer'
import SettingsLanguageContainer from './SettingsLanguage/SettingsLanguageContainer'
import SettingsSecurityCotainer from './SettingsSecurity/SettingsSecurityCotainer'


export default function InstructorSettings() {
    const {pathname} = useLocation();
    const history = useHistory()

    useEffect(() => {
        if(pathname === "/instructor/settings" || pathname === "/instructor/settings/" ){
            history.push("/instructor/settings/profile")
        }
    },[pathname])

    return (
    <InstructorStructure>
        <SettingsStructure pathBase={"/instructor"}>
        <Route
				path={`/instructor/settings/profile`}
				component={SettingsProfileContainer}
        />
        <Route
				path={`/instructor/settings/language`}
				component={SettingsLanguageContainer}
        />
        <Route
				path={`/instructor/settings/security`}
				component={SettingsSecurityCotainer}
        />
        <Route
				path={`/instructor/settings/privacy`}
				component={SettingsPrivacy}
        />
        <Route
				path={`/instructor/settings/customization`}
				component={SettingsCustomization}
        />
        <Route
				path={`/instructor/settings/gamification`}
				component={SettingsGamification}
        />
           
        </SettingsStructure>
    </ InstructorStructure>
    )
}
