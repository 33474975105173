/** @format */

export function createSelectOptions(
	optionsBase,
	unshiftString = "",
	isIndexValue = false,
	indexInitialValue = 0
) {
	const options = optionsBase.map((value, index) => {
		return (
			<option
				key={index}
				value={isIndexValue ? index + indexInitialValue : value}
			>
				{value}
			</option>
		);
	});
	if (!!unshiftString)
		options.unshift(
			<option
				key={-1}
				value=""
			>
				{unshiftString}
			</option>
		);
	return options;
}

export function createSelectOptionsKeyValue(
	optionsBase,
	unshiftString = "",
	isIndexValue = false,
	indexInitialValue = 0
) {
	const options = optionsBase.map((pair, index) => {
		return (
			<option
				key={index}
				value={isIndexValue ? index + indexInitialValue : pair.value}
			>
				{pair.text}
			</option>
		);
	});
	if (!!unshiftString)
		options.unshift(
			<option
				key={-1}
				value=""
			>
				{unshiftString}
			</option>
		);
	return options;
}

export function createSelectOptionsFromJson(
	optionJson
) {
	let options = [];
	for (let value in optionJson) {
		let text = optionJson[value];
		options.push(
			<option
				key={value}
				value={value}
			>
				{text}
			</option>
		);
	}

	return options
}
