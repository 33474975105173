/** @format */

import { useContext } from "react";
import { ColleaguesListWrapper } from "../../../environments/courses-board/YourTeam/your-team-styling";
import TeamMateCard from "../../../environments/courses-board/YourTeam/TeamMateCard/TeamMateCard";
import { TeamContainer } from "../../../environments/courses-board/BoardSectionContainers";
import { TeamWrapper } from "../../../environments/courses-board/YourTeam/your-team-styling";
import TeamHeader from "../../../environments/courses-board/YourTeam/TeamHeader/TeamHeader";

import photo from "../../../../assets/images/defaultProfileImage2.png";

import language from "../../../../services/defineLanguage";
import { EagleContext } from "../../../../contexts/EagleContext";
const texts = require(`../../../../texts/${language}/course`);

const Team = ({ data: team }) => {
	const {getCurrentCourseSetting} = useContext(EagleContext);
	let settings = getCurrentCourseSetting();
	
	function renderTeamMateCards() {
		return team.id !== undefined ? (
			team.students.map((teamMate, index) => {
				return (
					<TeamMateCard
						id={teamMate.studentCourseId}
						name={
							teamMate.mainUser
								? texts.you
								: `${teamMate.name} ${teamMate.lastName}`
						}
						completedTasks={teamMate.finishedMissions}
						numTasks={team.numMissions}
						mainUser={teamMate.mainUser}
						profilePic={
							teamMate.pictureURL ==
								"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
								teamMate.pictureURL?.length === 0
								? photo
								: teamMate.pictureURL
						}
						key={index}
					/>
				);
			})
		) : (
			<></>
		);
	}

	let mainUser = team.students.filter((student) => student.mainUser)[0];
	return (
		<TeamContainer>
			<h3>{texts.teamTitle}</h3>
			{team.id !== undefined ? (
				<>
					<p>
						{texts.teamGreetings} {mainUser.name} {mainUser.lastName}! {texts.teamThisWeek}
						<strong>{texts.teamsNames[team.id % 2]}</strong> !{" "}
						{texts.teamIfAllMembers}
						<strong> {team.numMissions}</strong> {texts.teamMissionUntilWeekend}{" "}
						10 {texts.teamCoins}!
					</p>
					<ColleaguesListWrapper>
						<TeamHeader socialPressure={settings.socialPressure}/>
						<TeamWrapper>{renderTeamMateCards()}</TeamWrapper>
					</ColleaguesListWrapper>
				</>
			) : (
				texts.youAreNotOnAnyTeam
			)}
		</TeamContainer>
	);
};

export default Team;
