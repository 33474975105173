/** @format */
import { Wrapper, BadgeContainer, Description  } from "../Message-styled";

import language from '../../../../services/defineLanguage'

const messagesTexts = require(`../../../../texts/${language}/messages`)

function SocialPressureContent({extraData}) {
	return (
		<Wrapper>
			<BadgeContainer>
				<Description>
					<span>{messagesTexts.socialPressure_1} {extraData} {messagesTexts.socialPressure_2}</span>
				</Description> 
			</BadgeContainer>
		</Wrapper>
	);
}

export default {
	content: SocialPressureContent,
	title: messagesTexts.socialPressureTitle,
};
