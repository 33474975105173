/** @format */

import loaderPic from "../../../assets/images/logo.svg";
import {
	LoaderWrapper,
	LoaderBox,
	LoaderPic,
	Circular,
	Path,
} from "./loader-styled";
export default function Loader() {
	return (
		<LoaderWrapper>
			<LoaderBox>
				<Circular viewBox="25 25 50 50">
					<Path
						cx="50"
						cy="50"
						r="21"
						fill="none"
						stroke-width="3"
						stroke-miterlimit="10"
					/>
				</Circular>
			</LoaderBox>
			<LoaderPic
				src={loaderPic}
				alt=""
			/>
		</LoaderWrapper>
	);
}
