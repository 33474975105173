import styled from "styled-components";
import { FaCheck, FaTimes } from 'react-icons/fa';

export const VerificationText = styled.p`
    width: 100%;
    color: grey;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 10px;
    display: flex; 
    align-items: center; 
    justify-content: center; 
`;

const IconStyles = `
    width: 100%;
    font-size: 2rem;
    margin-top: 10px;
    align-items: center;
    display: flex; 
    justify-content: center; 
    flex-direction: column; 
    `;

export const CheckIcon = styled(FaCheck)`
    ${IconStyles}
    color: green;
    display: flex; 
    justify-content: center; 
    flex-direction: column; 
    align-items: center;
    text-align: center;
`;

export const TimesIcon = styled(FaTimes)`
    ${IconStyles}
    color: red;
`;
