import styled from 'styled-components'

export const ItemContainer = styled.li`
    display: ${(props) => (props.isDeleted ? 'none' : 'flex')};
    align-items: center;
    padding: 10px 2px;
    border: 1px solid #ddd;
    border-color: #ddd transparent transparent transparent;
    cursor: pointer;
    width: 100%;

    &:hover {
        background-color: #eee;
    }
`

export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 50px;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 50%;
    margin-right: 20px;

    background-color: ${props => props.color};
    color: ${props => props.theme.colors.white};
`

export const NotificationInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const NotificationText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: auto;
`

export const DateAndTime = styled.span`
    font-size: 0.9rem;
    color: #555;
`

export const NotificationRead = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary};
`

export const ReadContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 20px;
`
