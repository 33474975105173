/** @format */

import styled from "styled-components";

export const TeamMateWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	
	display: grid;
	grid-template-columns: ${(props) => props.socialPressure ? "40% 20% 40%" : "50% 50%"};

	border-bottom: 1px solid ${(props) => props.theme.colors.gray};
	margin-bottom: 0.3rem;
	padding-bottom: 0.3rem;
`;

export const PokeButton = styled.button`
	align-self: center;
	justify-self: center;

	background-color: ${(props) => props.theme.colors.primary};
	padding: 0.8rem 1.3rem 0.8rem 1.3rem;
	border-radius: 25px;
	border: none;
	outline: none;
	cursor: pointer;


	color: ${(props) => props.theme.colors.white};
	font-weight: bold;
	transition: 0.3s;
	font-size: 15px;
	font-weight: bold;

	&:hover {
		background-color: ${(props) => props.theme.colors.darkBlue};
	}

	&:disabled,
	&[disabled] {
		background-color: ${(props) => props.theme.colors.gray};
	}
`;
export const TeamMateProfile = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	span {
		font-size: 15px;
		color: ${(props) => props.theme.colors.darkGray};
	}
`;
