import { useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import SettingsContent from '../SettingsContent/SettingsContent'
import Input from '../../../common/Input/Input'
import { MainContentInner } from '../SettingsContent/SettingsContent-styled'
import { H2, StyledText } from '../../../GenericComponents'

import language from '../../../../services/defineLanguage'
const texts = require(`../../../../texts/${language}/settingsPrivacy`)

export default function SettingsPrivacy() {
    const themeContext = useContext(ThemeContext)
    const [password, setPassword] = useState('')
    const [deleteAccount, setDeleteAccount] = useState(false)

    return (
            <SettingsContent title={texts.privacy}>
                <MainContentInner margin="0 0 0 10px">
                    <H2>{texts.account}</H2>
                    <StyledText
                        color={themeContext.colors.warning}
                        cursor="pointer"
                        onClick={() => setDeleteAccount(!deleteAccount)}
                    >
                        {texts.deleteAccount}
                    </StyledText>
                    <br />
                    {deleteAccount && (
                        <>
                            <StyledText color={themeContext.colors.warning}>
                                {texts.deleteMessage}
                            </StyledText>
                            <Input
                                label={texts.password}
                                type="password"
                                value={password}
                                setValue={setPassword}
                                required
                                style={{ width: '200px' }}
                            />
                        </>
                    )}
                </MainContentInner>
            </SettingsContent>
    )
}
