/** @format */

import React from "react";
import { ThemeProvider } from "styled-components";

export const theme = {
	colors: {
		primary: "#393f97",
		secondary: "#366696",
		white: "#fefefe",
		black: "#222222",
		gray: "#edeef0",
		mediumGray: "#a0a2a7d7",
		darkGray: " #58595B",
		link: "#0045fb",
		font: "#152d52",
		warning: "#e50d12",
		warningBorder: "#c00072",
		lightBlue: "#3f87f3",
		darkBlue: "#322f64",
		green: "#b9d22c",
		lightGreen: "#E2F7E2",
		darkGreen: "#3a713a",
		lightRed: "#EFD9DF",
		darkRed: "#853131",
		orange: "#e24f0e",
		lightOrange: "#F7D8C5", 
		darkOrange: "#995E3D",
	},
	gradients: {
		countdown: " linear-gradient(230deg, #0e4cfd, #6a8eff)",
		divider:
			"linear-gradient(90deg, rgba(57, 63, 161, 0.0) 0%, rgba(54, 102, 150, 1) 50%, rgba(2, 144, 251, 0.0) 100%)",
		whiteDivider:
			"linear-gradient(90deg, rgba(220, 220, 220, 0.0) 0%, rgba(220, 220, 220, 1) 50%, rgba(220, 220, 220, 0.0) 100%)",
		button:
			"linear-gradient(to right, rgba(57, 63, 161, 1) 0%, rgba(54, 102, 150, 1) 50%, rgba(57, 63, 161, 1) 100%)",
	},
	hover: {
		primary: "rgba(57, 63, 151, 0.5)",
	},
	width: {
		sidebar: "220px",
		hideSidebar: "65px",
	},
	height: {
		navbar: "70px",
		friendRow: "70px",
	},
};

const Theme = ({ children }) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
