/** @format */

import React, { useState, useContext, useEffect } from "react";
import {
	SubjectContainer,
	SubjectTitle,
	Wrapper,
	SubjectBody,
	SubjectHeader,
	InfoButton,
	UnreadCircle,
	MissionsList,
	MissionsListItem,
	MissionsListTitle,
	MissionsListContainer,
	MissionListItemContent,
	SubjectButton,
	MissionIconWrapper,
	SubjectBodyMessage,
} from "./subject-styling.js";
import DynamicFaIcon from "../../common/DynamicFaIcon.jsx";
import AppStructure from "../../environments/app/AppStructure";
import newMission from "../../../assets/images/missions/newMission.svg";
import ReactTooltip from "react-tooltip";
import Modal2 from "../../common/Modal2/Modal2.jsx";
import { EagleContext } from "../../../contexts/EagleContext.jsx";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader/Loader.jsx";
import language from "../../../services/defineLanguage.js";
import { Link, useHistory } from "react-router-dom";
import ImposedChoice from "../../common/ImposedChoice/ImposedChoice.jsx";
import LocalLoader from "../../common/LocalLoader/LocalLoader.jsx";
import { getSupportMaterials } from "../../../services/Subject.js";

const texts = require(`../../../texts/${language}/subjects`);

function verifyIfMissionSessionEnded(mission) {
	for (let i = 0; i < mission.history.length; i++) {
		const missionSession = mission.history[i];
		if (missionSession.end !== null) {
			return true;
		}
	}
	return false;
}

export default function Subject() {
	const [showModal, setShowModal] = useState(false);
	const [checkedInfoButton, setCheckedInfoButton] = useState(false);
	const [areAllMissionsCompleted, setAreAllMissionsCompleted] = useState(false);
	const {
		getSubject,
		currentCourse,
		isLoadingCourse,
		getCurrentCourseSetting,
		tryImposedChoice,
	} = useContext(EagleContext);
	const [imposedChoice, setImposedChoice] = useState(false);
	const { subjectId } = useParams();
	const history = useHistory();
	const [supportMaterials, setSupportMaterials] = useState({
		load: false,
		materials: [],
	});

	let subject = null;
	let settings = getCurrentCourseSetting();

	const [selectedMission, setSelectedMission] = useState();

	if (!isLoadingCourse) {
		subject = getSubject(subjectId);
		if (subject == null) {
			history.push("/profile");
		}
	}

	useEffect(() => {
		setImposedChoice(tryImposedChoice());
		if (subject && subject.Missions) {
			for (let i = 0; i < subject.Missions.length; i++) {
				if (
					subject.Missions[i].history.length === 0 ||
					!verifyIfMissionSessionEnded(subject.Missions[i])
				) {
					setSelectedMission(subject.Missions[i]);
					setAreAllMissionsCompleted(false);
					return true;
				}
			}
			setAreAllMissionsCompleted(true);
			setSelectedMission(
				subject.Missions.length > 0 ? subject.Missions[0] : null
			);
			return true;
		}
		setSelectedMission(null);
		setAreAllMissionsCompleted(true);
		return false;
	}, [isLoadingCourse]);

	if (isLoadingCourse) {
		return <Loader />;
	}

	async function loadSupportMaterials() {
		let res = await getSupportMaterials(subjectId);
		setSupportMaterials({ load: true, materials: res.newSupportMaterials });
	}
	function openModal() {
		setShowModal(true);
		setCheckedInfoButton(true);
		if (!supportMaterials.load) {
			loadSupportMaterials();
		}
	}

	if (subject) {
		return (
			<AppStructure>
				<Modal2
					show={showModal}
					onClose={() => setShowModal(false)}
					title={texts.supportMaterials}
					disableFooter={true}
				>
					{supportMaterials.load ? (
						<p
							dangerouslySetInnerHTML={{
								__html: supportMaterials?.materials[0]?.text || texts.noMaterials,
							}}
						></p>
					) : (
						<div style={{ marginTop: "5vh", marginBottom: "5vh" }}>
							<LocalLoader
								isLoading={!supportMaterials.load}
								scale={1.5}
							/>
						</div>
					)}
				</Modal2>
				{imposedChoice && (
					<ImposedChoice
						show={imposedChoice}
						subjectId={subjectId}
						onClose={() => setImposedChoice(false)}
					/>
				)}

				<Wrapper>
					<SubjectContainer>
						<SubjectHeader>
							<SubjectTitle>
								<DynamicFaIcon name={subject.icon} />
								<p>{subject.title}</p>
							</SubjectTitle>
							<InfoButton
								data-tip={texts.supportMaterialTooltip}
								onClick={() => openModal()}
							>
								{!checkedInfoButton ? <UnreadCircle /> : null}
								<ReactTooltip
									place="top"
									type="dark"
									effect="solid"
								/>
								<DynamicFaIcon
									name={"FaInfoCircle"}
									size={"40px"}
								/>
							</InfoButton>
						</SubjectHeader>

						<SubjectBody>
							{!areAllMissionsCompleted ? (
								<>
									<img
										src={newMission}
										alt="new mission"
									/>
									<SubjectBodyMessage>
										{texts.youHaveNewMission}
									</SubjectBodyMessage>
									<p>{subject.description}</p>
								</>
							) : (
								<>
									<p>{subject.description}</p>
									<SubjectBodyMessage>
										{texts.youCompletedAllMissions}
									</SubjectBodyMessage>
								</>
							)}
							{selectedMission != null ? (
								<>
									{settings.objectives && (
										<MissionsListContainer>
											<MissionsListTitle>{texts.missions}</MissionsListTitle>
											<MissionsList>
												{subject.Missions.map((mission) => {
													let isSelected = mission.id == selectedMission.id;
													return (
														<MissionsListItem
															onClick={() => {
																setSelectedMission(mission);
															}}
															isSelected={isSelected}
															key={mission.id}
														>
															<MissionListItemContent>
																<MissionIconWrapper>
																	<DynamicFaIcon
																		name={mission.icon}
																		size={"20px"}
																	/>
																</MissionIconWrapper>
																{mission.title}
															</MissionListItemContent>
															{mission.history.length > 0 &&
																verifyIfMissionSessionEnded(mission) && (
																	<DynamicFaIcon
																		name="FaCheckCircle"
																		size="20px"
																		color={isSelected ? "white" : "green"}
																		style={{ transitionDuration: "0.3s" }}
																	/>
																)}
														</MissionsListItem>
													);
												})}
											</MissionsList>
										</MissionsListContainer>
									)}
									{!areAllMissionsCompleted || settings.objectives ? (
										<SubjectButton
											as={Link}
											to={`/mission/${selectedMission.id}`}
										>
											{texts.buttonLabel}
										</SubjectButton>
									) : (
										<SubjectButton
											as={Link}
											to={`/course/${currentCourse}`}
										>
											No missions, back to the course
										</SubjectButton>
									)}
								</>
							) : (
								<SubjectButton
									as={Link}
									to={`/course/${currentCourse}`}
								>
									No missions, back to the course
								</SubjectButton>
							)}
						</SubjectBody>
					</SubjectContainer>
				</Wrapper>
			</AppStructure>
		);
	} else {
		return <>Fida</>;
	}
}
