import styled from 'styled-components'

export const StoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin: 15px;
    padding: 20px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`

export const StoreTitle = styled.h1`
    font-size: 2rem;
    font-weight: 500;
`

export const StoreSubtitle = styled.h2`
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 15px;
`

export const SectionTitle = styled.h3`
    font-size: 1.3rem;
    font-weight: 500;
`

export const StoreContent = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    justify-content: space-evenly;
    grid-gap: 20px;

    width: 100%;
    margin: 20px 0;
`

export const BuyModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width: 100%;
`
