/** @format */

import React from "react";
import AddCourse from "../../common/AddCourse/AddCourse";
import NonCourseEnv from "../../environments/nonCourse/NonCourseEnv";
import {
	EnrollCourseContainerWrapper,
	EnrollCourseContainer,
} from "./EnrollCoursePage-styled";

const EnrollCoursePage = () => {
	return (
		<NonCourseEnv>
			<EnrollCourseContainerWrapper>
				<EnrollCourseContainer>
					<AddCourse />
				</EnrollCourseContainer>
			</EnrollCourseContainerWrapper>
		</NonCourseEnv>
	);
};

export default EnrollCoursePage;
