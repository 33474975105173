import styled from 'styled-components'

export const ContentBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	// height: 575px;
	height: 450px;

	background-color: ${(props) => props.theme.colors.lightBlue};
	background-image: linear-gradient(
		rgba(0, 34, 34, 0) 0%,
		rgba(34, 34, 34, 0.25) 100%
	);
	
	color: ${(props) => props.theme.colors.white};

	padding: 0 10px 10px 10px;
	margin: 15px 5px 15px 15px;
	box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
	border-radius: 5px;

	@media screen and (max-width: 1080px) {
		min-height: 200px;
		width: auto;
		margin: 15px 15px 15px 15px;
	}
`

export const PictureBox = styled.div`
	position: relative;

	background-color: white;
	border-radius: 50%;
	width: 8.5rem;
	height: 8.5rem;
	margin-top: -4rem;
	margin-bottom: 10px;

	img {
		width: 90%;
		height: 90%;
		object-fit: cover;
		border-radius: 50%;

		position: relative;
		left: 5%;
		top: 5%;
	}
`

export const CameraBox = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 2.5rem;
	height: 2.5rem;
	border: none;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.7);

	position: relative;
	left: 100%;
	transform: translateX(-75%);
	bottom: 25%;

	cursor: pointer;
`

export const SocialDataContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	padding: 5px 10px;
`

export const SocialData = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.theme.colors.white};

	svg {
		font-size: 1.5rem;
		margin-left: 10px;
	}

	div {
		display: flex;
		flex-direction: column;
		margin-left: 5px;
		font-size: 0.9rem;

		span {
			margin: 0;
			padding: 0;
		}
	}
`

export const Friends = styled.a`
	border: none;
	border-radius: 5px;
	margin: 10px 0;
	padding: 5px;

	font-size: 0.9rem;
	font-weight: 600;
	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.darkBlue};

	cursor: pointer;
	text-decoration: none;

	span {
		margin-left: 5px;
	}
`

export const Text = styled.span`
	color: ${(props) => props.theme.colors.white};
	font-weight: 700;
	text-align: center;
`

export const Title = styled(Text)`
	font-size: 1.1rem;
`

export const SlimText = styled(Text)`
	font-weight: 600;
`
