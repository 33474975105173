/** @format */

import { useState, useContext } from "react";
import { EagleContext } from "../../../../contexts/EagleContext";
import { InstructorContext } from "../../../../contexts/InstructorContext";
import { updateUserInfo } from "../../../../services/User";
import photo from "../../../../assets/images/defaultProfileImage2.png"
import SettingsProfileView from "./SettingsProfileView";
const gendersMap = require(`../../../../texts/en/genders`);

export default function SettingsProfileContainer() {
	const {
		userType,
		user: student,
		updateUserInfo: updateStudent,
		updateUserProfilePic,
	} = useContext(EagleContext);
	const {
		user: instructor,
		updateInstructor,
		updateInstructorProfilePic,
	} = useContext(InstructorContext);

	let user = userType === "Student" ? student : instructor;

	const [name, setName] = useState(user.name);
	const [surname, setSurname] = useState(user.lastName);
	const [country, setCountry] = useState(user.country);
	const [gender, setGender] = useState(
		gendersMap.findIndex((value) => {
			return value == user.gender;
		}) + 1
	);
	const [birthday, setBirthDay] = useState(
		user.dateOfBirth !== undefined ? user.dateOfBirth.split("T")[0] : null
	);
	const [response, setResponse] = useState({});

	async function handleUserUpdate() {
		let newResponse = await updateUserInfo({
			name,
			country,
			lastName: surname,
			birthDay: birthday,
			gender: gendersMap[gender - 1],
		});

		if (newResponse.success) {
			if (userType === "Student") {
				await updateStudent(newResponse.newUserData);
			} else {
				await updateInstructor(newResponse.newUserData);
			}
		}

		setResponse(newResponse);
	}

	function updateProfilePic(newProfilePicURL) {
		if (userType === "Student") {
			updateUserProfilePic(newProfilePicURL);
		} else {
			updateInstructorProfilePic(newProfilePicURL);
		}
	}

	function onSubmit(event) {
		event.preventDefault();
		handleUserUpdate();
		setResponse({ isLoading: true });
	}

	return (
		<>
			<SettingsProfileView
				setBirthDay={setBirthDay}
				birthday={birthday}
				setCountry={setCountry}
				country={country}
				setGender={setGender}
				gender={gender}
				setName={setName}
				name={name}
				setSurname={setSurname}
				surname={surname}
				onSubmit={onSubmit}
				pictureURL={
					user.pictureURL ==
						"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
					user.pictureURL?.length === 0
						? photo
						: user.pictureURL
				}
				updateProfilePic={updateProfilePic}
				response={response}
			/>
		</>
	);
}
