import { useEffect } from 'react'
import levelBadgeImgMap from '../../../../constants/levelBadgeNameUsingMap'
import { Wrapper, BadgeImg } from './level-section-styling'

const DynamicLevelBadge = ({ levelId }) => {
    useEffect(() => {
        try {
        } catch (error) {
            console.error('')
        }
    })

    return (
        <Wrapper>
            <BadgeImg src={levelBadgeImgMap[levelId - 1]} />
        </Wrapper>
    )
}

export default DynamicLevelBadge
