module.exports = [
    {type: 1, message: 'You have a new achievement!'},
    {type: 2, message: 'You\'ve earned points!'},
    {type: 3, message: 'You\'ve earned coins!'},
    {type: 4, message: 'You have leveled up!'},
    {type: 5, message: 'Your team has completed all missions for the week!'},
    {type: 11, message: 'Your colleague just finished 10 activities. Don\'t get left behind...'},
    {type: 12, message: 'Your team is poking you. Finish your weekly missions!'},
    {type: 21, message: 'Welcome to Eagle-edu! See the courses you are registered on the left side and start practicing and having fun with us right now.'},
    {type: 22, message: 'You have been added to a new team!'},
    {type: 23, message: 'Welcome back!'},
]