/** @format */

module.exports = {
	earned: "ganhou",
	achievement: "conquista",
	achievements: "conquistas",
	economy: "moeda",
	economys: "moedas",
	point: "ponto",
	points: "pontos",
};
