import empty from '../../../assets/images/chests/empty.svg'
import locked from '../../../assets/images/chests/locked.svg'
import small from '../../../assets/images/chests/small.svg'
import medium from '../../../assets/images/chests/medium.svg'
import large from '../../../assets/images/chests/large.svg'

import { ChestWrapper } from './Chest-styled'

let chestImageMap = {
	empty,
	locked,
	small,
	medium,
	large
}

export default function Chest({state, maxWidth, maxHeight}) {
	return (
		
		<ChestWrapper maxWidth={maxWidth} maxHeight={maxHeight}>
			<img src={chestImageMap[state]} alt="" />
		</ChestWrapper>
	)
}
