/** @format */

import React, { useState, useEffect, useContext } from "react";
import {
	createSupportMaterial,
	getSupportMaterials,
} from "../../../services/Subject";
import LocalLoader from "../../common/LocalLoader/LocalLoader";
import { Button, SecondaryButton } from "../../GenericComponents";
import ToggleButton from "../../common/ToggleButton/ToggleButton";
import TextArea from "../../common/TextArea/TextArea";
import { updateSupportMaterial } from "../../../services/SupportMaterials";
import { EagleContext } from "../../../contexts/EagleContext";
import { ErrorMessage, SuccessMessage } from "../../forms/FormsMessages";
import language from "../../../services/defineLanguage.js";
import { ButtonWithLoading } from "../ButtonWithLoading/ButtonWithLoading";
const texts = require(`../../../texts/${language}/instructor/supportMaterialsEditor`);

export default function SupportMaterialsEditor({ subjectId, onClose }) {
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [getSupportMatirialsResponse, setGetSupportMatirialsResponse] =
		useState({ isLoading: false });
	const [preview, setPreview] = useState(false);
	const [supportMaterials, setSupportMaterials] = useState([{ text: "" }]);
	const [changeSupportMaterialsResponse, setChangeSupportMaterialsResponse] =
		useState({});

	useEffect(() => {
		loadSupportMaterials();
		setGetSupportMatirialsResponse({ isLoading: true });
	}, []);

	async function loadSupportMaterials() {
		let response = await getSupportMaterials(subjectId);

		if (response.success) {
			setSupportMaterials(
				response.newSupportMaterials.map((element) => {
					return { ...element };
				})
			);
		}

		setGetSupportMatirialsResponse(response);
	}

	async function resetSupportMaterial() {
		setSupportMaterials(
			getSupportMatirialsResponse.success
				? getSupportMatirialsResponse.newSupportMaterials.map((element) => {
						return { ...element };
				  })
				: [{ text: "" }]
		);
		setPreview(false);
	}

	async function trySaveSupportMaterial() {
		let response = null;
		if (supportMaterials[0]?.id) {
			response = await updateSupportMaterial(
				supportMaterials[0].id,
				supportMaterials[0].text
			);
		} else if (supportMaterials.length > 0) {
			response = await createSupportMaterial(
				subjectId,
				supportMaterials[0].text
			);
		}

		if (response.success) {
			loadSupportMaterials();
		}

		if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			onClose();
			insertFakeInstantMessage(31, 0, 0, {});
			resetSupportMaterial();
		}

		setChangeSupportMaterialsResponse(response);
		setPreview(false);
	}
	async function saveSupportMaterial() {
		trySaveSupportMaterial();
		setChangeSupportMaterialsResponse({ isLoading: true });
	}

	return getSupportMatirialsResponse.isLoading === true ||
		getSupportMatirialsResponse.success === false ? (
		<div style={{ marginTop: "5vh", marginBottom: "5vh" }}>
			<LocalLoader
				isLoading={getSupportMatirialsResponse.isLoading}
				scale={1.5}
				wasSuccessful={false}
				message={
					getSupportMatirialsResponse.isLoading
						? ""
						: texts.errors[getSupportMatirialsResponse?.error] ??
						  texts.errors.default
				}
			/>
		</div>
	) : (
		<>
			<p
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<span
					style={{
						marginRight: "5px",
						fontSize: "1.3rem",
					}}
				>
					Preview{" "}
				</span>
				<ToggleButton
					checked={preview}
					setChecked={() => {
						setPreview((lastState) => {
							return !lastState;
						});
					}}
				/>
			</p>
			<div
				style={{
					margin: "10px 0 20px 0",
					width: " 100%",
					border: "1px solid grey",
				}}
			></div>
			{preview ? (
				<p
					dangerouslySetInnerHTML={{
						__html: supportMaterials[0]?.text || texts.noMaterials,
					}}
				></p>
			) : (
				<TextArea
					value={supportMaterials[0]?.text}
					placeholder={texts.writeHTML}
					setValue={(value) => {
						setSupportMaterials((oldSupportMaterials) => {
							let newSupportMaterials = [...oldSupportMaterials];
							if (newSupportMaterials[0] !== undefined) {
								newSupportMaterials[0].text = value;
							} else {
								newSupportMaterials.push({ text: value });
							}
							return newSupportMaterials;
						});
					}}
				></TextArea>
			)}

			<div
				style={{
					margin: "10px 0 10px 0",
					width: " 100%",
					border: "1px solid grey",
				}}
			></div>
			{changeSupportMaterialsResponse.success === false && (
				<ErrorMessage margin="0 0 10px 0">
					{texts.errors[changeSupportMaterialsResponse.error] ??
						texts.errors.default}
				</ErrorMessage>
			)}
			{changeSupportMaterialsResponse.success === true && (
				<SuccessMessage margin="0 0 10px 0">
					{texts.savedSuccessfully}
				</SuccessMessage>
			)}
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<ButtonWithLoading
					margin={"0px 5px 5px 0"}
					onClick={saveSupportMaterial}
					isloading={changeSupportMaterialsResponse.isLoading === true}
					width={"100px"}
				>
					{texts.save}
				</ButtonWithLoading>
				<SecondaryButton
					onClick={resetSupportMaterial}
					style={{ width: "100px" }}
				>
					{texts.reset}
				</SecondaryButton>
			</div>
		</>
	);
}
