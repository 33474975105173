/** @format */

import { useEffect } from "react";
import SubjectCard from "../../../environments/courses-board/SubjectCard/SubjectCard";
import { SubjectCardsContainer } from "../../../environments/courses-board/BoardSectionContainers";
import { useContext } from "react";
import { EagleContext } from "../../../../contexts/EagleContext";

import language from "../../../../services/defineLanguage.js";
const texts = require(`../../../../texts/${language}/subjects`);

function calculateCompletitionRate(missions) {
	let numberOfCompletedMissions = 0;
	for (let i = 0; i < missions.length; i++) {
		if (missions[i].history.length > 0) numberOfCompletedMissions++;
	}

	return missions.length > 0
		? (100 * numberOfCompletedMissions) / missions.length
		: 100;
}

function getnextSubjectId(subjects) {
	for (let i = 0; i < subjects.length; i++) {
		let subject = subjects[i];
		if (calculateCompletitionRate(subject.Missions) < 100) {
			return subject.id;
		}
	}
	return subjects[subjects.length - 1].id;
}

function getUniqueSubject(subjects) {
	if (!subjects || subjects.length === 0) return null;

	let uniqueSubject = {
		Missions: [...subjects[0].Missions],
		id: getnextSubjectId(subjects),
		title: texts.activities,
	};

	for (let i = 1; i < subjects.length; i++) {
		uniqueSubject.Missions = [
			...uniqueSubject.Missions,
			...subjects[i].Missions,
		];
	}
	return uniqueSubject;
}

const Subjects = ({ data: SubjectCards }) => {
	const { getCurrentCourseSetting } = useContext(EagleContext);
	let settings = getCurrentCourseSetting();

	function renderSubjects() {
		if (settings.objectives) {
			return SubjectCards
				? SubjectCards.map((subject, index) => {
						return (
							<SubjectCard
								subject={subject}
								progressBar={settings.progression}
								key={subject.id}
								isOdd={SubjectCards.length % 2}
							/>
						);
				  })
				: [];
		}
		let uniqueSubject = getUniqueSubject(SubjectCards);
		return uniqueSubject !== null? (
			<SubjectCard
				subject={uniqueSubject}
				progressBar={settings.progression}
				key={uniqueSubject.id}
				isOdd={true}
			/>
		) : [];
	}

	return <SubjectCardsContainer>{renderSubjects()}</SubjectCardsContainer>;
};

export default Subjects;
