/** @format */

import React, { useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";
import AppStructure from "../../environments/app/AppStructure";
import useInfiniteScroll from "../../../services/useInfiniteScroll";
import useInfiniteCards from "../../../services/useInfiniteCards";

import { Container } from "./Friends-styled";
import { Title, Divider } from "../../GenericComponents";

import ProfileBox from "../../common/ProfileBox/ProfileBox";
import CoverBox from "../../common/CoverBox/CoverBox";
import CarouselCards from "../../common/Carousel/CarouselCards";
import FriendCard from "../../common/FriendCard/FriendCard";
import FriendsList from "../../common/FriendsList/FriendsList";
import FriendRow from "../../common/FriendRow/FriendRow";

import photo from "../../../assets/images/defaultProfileImage2.png";

import language from "../../../services/defineLanguage";
const texts = require(`../../../texts/${language}/friends`);


const TAKE = 100000;

export default function Friends({}) {
	const {
		getCurrentCourseFriends,
		getCurrentCourseFollowers,
		getCurrentCourseFollowing,
	} = useContext(EagleContext);

	// const [following, followingIndex, isFollowingFull, loadingComponent] =
	// 	useInfiniteScroll(getCurrentCourseFollowing().length, loadFollowing);

	// const [followers, followersIndex, findMoreFollowers] = useInfiniteCards(
	// 	getCurrentCourseFollowers().length,
	// 	loadFollowers
	// );

	// const [courseFriends, courseFriendsIndex, findMoreCourseFriends] =
	// 	useInfiniteCards(getCurrentCourseFriends().length, loadCourseFriends);

	// function loadFollowers() {
	// 	let followers = getCurrentCourseFollowers();
	// 	let items = [];
	// 	let i;
	// 	let index = followersIndex ? followersIndex : 0;
	// 	for (i = index; i < index + TAKE && i < followers.length; i++) {
	// 		items.push(
	// 			<FriendCard
	// 				key={`followers ${followers[i].Users.name} ${followers[i].Users.lastName} ${i}`}
	// 				name={`${followers[i].Users.name} ${followers[i].Users.lastName}`}
	// 				imagePath={followers[i].Users.pictureURL}
	// 				id={followers[i].id}
	// 				isFollowed={followers[i].followed}
	// 			/>
	// 		);
	// 	}

	// 	return [items, i];
	// }

	function renderFollowers() {
		let followers = getCurrentCourseFollowers();
		return followers.map((follower, i) => (
			<FriendCard
				key={`followers ${follower.Users.name} ${follower.Users.lastName} ${i}`}
				name={`${follower.Users.name} ${follower.Users.lastName}`}
				imagePath={follower.Users.pictureURL ==
					"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
					follower.Users.pictureURL?.length === 0
					? photo
					: follower.Users.pictureURL}
				id={follower.id}
				isFollowed={follower.followed}
			/>
		));
	}

    

	// function loadCourseFriends() {
	// 	let courseFriends = getCurrentCourseFriends();
	// 	let items = [];
	// 	let i;
	// 	let index = courseFriendsIndex ? courseFriendsIndex : 0;
	// 	for (i = index; i < index + TAKE && i < courseFriends.length; i++) {
	// 		items.push(
	// 			<FriendCard
	// 				key={`course friends ${courseFriends[i].Users.name} ${courseFriends[i].Users.lastName} ${i}`}
	// 				name={`${courseFriends[i].Users.name} ${courseFriends[i].Users.lastName}`}
	// 				id={courseFriends[i].id}
	// 				imagePath={courseFriends[i].Users.pictureURL}
	// 				isFollowed={courseFriends[i].followed}
	// 			/>
	// 		);
	// 	}

	// 	return [items, i];
	// }

    function renderCourseFriends() {
		let courseFriends = getCurrentCourseFriends();
		return courseFriends.map((follower, i) => (
			<FriendCard
				key={`followers ${follower.Users.name} ${follower.Users.lastName} ${i}`}
				name={`${follower.Users.name} ${follower.Users.lastName}`}
				imagePath={follower.Users.pictureURL ==
					"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
					follower.Users.pictureURL?.length === 0
					? photo
					: follower.Users.pictureURL}
				id={follower.id}
				isFollowed={follower.followed}
			/>
		));
	}
	

	// function loadFollowing() {
	// 	let following = getCurrentCourseFollowing();
	// 	let items = [];
	// 	let i;
	// 	let index = followingIndex;
	// 	for (i = index; i < index + TAKE && i < following.length; i++) {
	// 		items.push(
	// 			<React.Fragment
	// 				key={`following ${following[i].Users.name} ${following[i].Users.lastName} ${i}`}
	// 			>
	// 				<FriendRow
	// 					name={`${following[i].Users.name} ${following[i].Users.lastName}`}
	// 					points={following[i].points}
	// 					achievements={following[i].achievements}
	// 					economy={following[i].economy}
	// 				/>
	// 				<Divider />
	// 			</React.Fragment>
	// 		);
	// 	}

	// 	return [items, i];
	// }

	function renderFollowing() {
		let following = getCurrentCourseFollowing();
		return following.map((followed, i) => (
			<React.Fragment
				key={`followers ${followed.Users.name} ${followed.Users.lastName} ${i}`}
			>
				<FriendRow
					name={`${followed.Users.name} ${followed.Users.lastName}`}
					profilePic={followed.Users.pictureURL}
					id={followed.id}
					points={followed.points}
					achievements={followed.achievements}
					economy={followed.economy}
				/>
				<Divider />
			</React.Fragment>
		));
	}

	return (
		<AppStructure>
			<Container>
				<CoverBox />
				<ProfileBox />
				<FriendsList id="following friends">
					{/* {following} */}
					{/* {!isFollowingFull && loadingComponent} */}
					{renderFollowing()}
				</FriendsList>
				<br />
				<CarouselCards
					header={<Title>{texts.followers}</Title>}
					elementsWidth={120}
					spaceBetween={20}
					// handleEndOfSlides={() => findMoreFollowers()}
				>
					{/* {followers} */}
					{renderFollowers()}
				</CarouselCards>
				<br />
				<CarouselCards
					header={<Title>{texts.courseColleagues}</Title>}
					elementsWidth={120}
					spaceBetween={20}
					// handleEndOfSlides={() => findMoreCourseFriends()}
				>
					{renderCourseFriends()}
				</CarouselCards>
			</Container>
		</AppStructure>
	);
}
