import React from 'react'
import { IconContext } from 'react-icons'
import { Navbar, 
    NavbarContainer, 
    NavLogo, 
} from './Navbar'

function Header({linkPath}) {
    return (
        <>
        <IconContext.Provider value={{color: '#fff'}}> 
            <Navbar>
                <NavbarContainer >
                    <NavLogo to={linkPath || "/"}> 
                        Eagle-edu
                    </NavLogo>
                </NavbarContainer>
            </Navbar>
        </IconContext.Provider>
        </>
    )
}

export default Header
