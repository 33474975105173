import styled from 'styled-components';

// export const CenterBox = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width: 50%;
//   height: 60vh;
// `;

export const DivTitle = styled.div`
  width: 100%;
  `;

export const Title = styled.h2`
  color: black;
  margin: 20px 0 40px 0;
  padding: 0;
  text-align: center;
`;

export const SubTitle = styled.p`
    color: grey;
    font-size: 1.1rem;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
`;

export const Paragraph = styled.p`
  color: grey;
  margin: 20px 0;
  font-size: 1.1rem;
  text-align: center;
  width: 100%;
`;
