/** @format */

import React, { useState, useEffect } from "react";
import DynamicFaIcon from "../../../common/DynamicFaIcon";
import ProgressBar from "../../../common/ProgressBar/ProgressBar";
import { useHistory } from "react-router-dom";

import {
	CardWrapper,
	CardButton,
	SubjectIconSection,
	ClickableArea,
	SubjectTitle,
} from "./subject-card-styling";

function verifyIfMissionSessionEnded(mission) {
	for (let i = 0; i < mission.history.length; i++) {
		const missionSession = mission.history[i];
		if (missionSession.end !== null) {
			return true;
		}
	}
	return false;
}

const SubjectCard = ({
	subject,
	progressBar = true,
	onClick,
	isOdd,
	pathBase,
}) => {
	const history = useHistory();
	function handleRouter() {
		history.push(
			pathBase !== undefined
				? pathBase + `/subject/${subject.id}`
				: `/subject/${subject.id}`
		);
	}

	/**
	 * Loading next available mission of this Subject
	 */
	function calculateCompletitionRate() {
		let numberOfCompletedMissions = 0;
		for (let i = 0; i < subject.Missions.length; i++) {
			if (
				subject.Missions[i].history.length > 0 &&
				verifyIfMissionSessionEnded(subject.Missions[i])
			)
				numberOfCompletedMissions++;
		}

		return subject.Missions.length > 0
			? (100 * numberOfCompletedMissions) / subject.Missions.length
			: 100;
	}


	return (
		<CardWrapper isOdd={isOdd}>
			<ClickableArea
				onClick={() => {
					if (onClick) {
						onClick();
					} else {
						handleRouter();
					}
				}}
			>
				<SubjectIconSection>
					<DynamicFaIcon
						name={subject.icon}
						size={90}
						color={"gray"}
					/>
				</SubjectIconSection>
				<CardButton
					onClick={() => {
						if (onClick) {
							onClick();
						} else {
							handleRouter();
						}
					}}
				>
					<SubjectTitle>{subject.title}</SubjectTitle>
				</CardButton>
			</ClickableArea>
			{progressBar && (
				<ProgressBar completionRate={calculateCompletitionRate()} />
			)}
		</CardWrapper>
	);
};

export default SubjectCard;
