import styled from 'styled-components';


export const DivTitle = styled.div`

  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  color: black;
  padding: 0;
  text-align: center;
  width: 100%;
`;

export const SubTitle = styled.p`
    color: grey;
    font-size: 1.1rem;
    margin-bottom: 20px;
    text-align: center;
`;

export const Paragraph = styled.p`
  color: grey;
  margin: 40px 3px 30px;
  font-size: 1.1rem;
`;
