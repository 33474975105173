/** @format */

import { useEffect, useState } from "react";
import { EagleContext } from "../../contexts/EagleContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import Loader from "./Loader/Loader";
import { InstructorContext } from "../../contexts/InstructorContext";

export default function PlataformAccessManager({ children }) {
	const history = useHistory();
	const { isLoadingSystem, isLoadingCourses, user } = useContext(EagleContext);
	const { user: instructor } = useContext(InstructorContext);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (!user.id) {
			if (instructor && instructor.id) {
				history.push("/instructor");
			} else {
				history.push("/signin");
			}
		} else if (!user.verified) {
			history.push("/accountverification");
		} else if (user.userType === "Instructor") {
			history.push("/instructor");
		} else {
			setLoading(isLoadingSystem);
			if (!isLoadingSystem && !isLoadingCourses) {
				if (user.courses && user.courses.length === 0) {
					history.push("/enrollcourse");
				}
			}
		}
	}, [isLoadingSystem, isLoadingCourses]);

	// useEffect(() => {
	// 	if (isLoadingSystem) {
	// 		if (!user.id) {
	// 			history.push("/signin");
	// 		} else if (!user.verified) {
	// 			// console.log(user.verified)
	// 			history.push("/accountverification");
	// 		} else {
	// 			let isLoadingTemp = isLoadingSystem || true;
	// 			setLoading(isLoadingTemp);
	// 			if (!isLoadingTemp) {
	// 				if (user.courses.length === 0) {
	// 					history.push("/enrollcourse");
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [isLoadingSystem]);

	return loading ? <Loader /> : <>{children}</>;
}
