import styled from 'styled-components'
import {
    FormLabel,
    FormLink
 } from '../FormsElements';



 /* ------styled components------ */
const CheckboxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0 0 5px;
`

const FormInputCheckbox = styled.input`
    transform: scale(1.2);
    box-shadow: 0 0 2px #ccc;
`

const CheckBoxLabel = styled(FormLabel)`
    margin: 0;
    padding-left: 10px;
`
 /* ---------------------------- */


function CheckboxInput(props) {
    return (
        <CheckboxContainer borderless={props.borderless}>
            <FormInputCheckbox type="checkbox" id="terms" adjus={props.adjust} onChange={props.onChange}/>
            <CheckBoxLabel htmlFor="terms">
                {props.label}
                {props.to ? 
                    <FormLink as="a" target="_blank" href={props.to}>
                        <strong> {props.text} </strong>
                    </FormLink> : ''}
                </CheckBoxLabel>
        </CheckboxContainer>
    )
}

export default CheckboxInput
