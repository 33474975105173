import styled from 'styled-components'

export const Paragraph = styled.p`
	padding: 10px 20px;
	font-size: 0.9rem;
	color: grey;
`

export const Ulist = styled.ul`
	padding: 10px 40px;
	font-size: 0.9rem;
	color: grey;
`

export const TermsFooter = styled(Paragraph)`
	padding: 10px 0;
`
