import { InputContent, InputLabel } from './Input-styled'

export default function Input({
    type,
    name,
    label,
    value,
    setValue,
    placeholder,
    style,
    required,
    disabled,
    maxLength,
    onKeyPress,
}) {
    return (
        <>
            <InputLabel>{label}</InputLabel>
            <InputContent
                id={label}
                name={name}
                type={type ? type : 'text'}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                maxLength={maxLength}
                style={style}
                onKeyPress={onKeyPress}
            />
        </>
    )
}
