import styled from 'styled-components';
import { CenterBox } from "../../GenericComponents";

export const VerificationContentBox = styled(CenterBox)`
  width: 50%;
  align-items: center;
 `;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h2`
  color: black;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 0;
  text-align: center;
`;

export const SubTitle = styled.p`
    color: grey;
    font-size: 1.1rem;
    margin-bottom: 20px;
    text-align: center;
`;

export const Paragraph = styled.p`
  color: grey;
  margin: 10px 0;
  font-size: 1.1rem;
`;

// CRIAR OUTRA PÁGINA E OUTRO COMPONENTE (TIPO Loader)
// Inserir um título numa center box (Verificação de conta)
// Inserir frase: Estamos verificando sua conta. Aguarde alguns instantes.
// Isso enquanto envia pra API. Quando chega a resposta o ícone para de carregar.
// Procurar check react icons (FaCheck) (FaTimes) caso dê certo
// https://www.w3schools.com/howto/howto_css_loader.asp