/** @format */

import { useState, useContext } from "react";
import AppStructure from "../../environments/app/AppStructure";
import ProfileBox from "../../common/ProfileBox/ProfileBox";
import CoverBox from "../../common/CoverBox/CoverBox";
import CarouselAvatar from "../../common/CarouselAvatar/CarouselAvatar";
import { Container } from "./Profile-styled";
import { EagleContext } from "../../../contexts/EagleContext";
import Avatar from "../../common/Avatar/Avatar";

export default function Profile(props) {

	const { getCurrentCourseAvatar, getCurrentCourseSetting } = useContext(EagleContext);
	let currentAvatar = getCurrentCourseAvatar();

	let settings = getCurrentCourseSetting();
	return (
		<AppStructure>
			<Container isStorytellingAvailable={settings.storytelling}>
				<CoverBox />
				<ProfileBox />
				{settings.storytelling &&
					(currentAvatar === null ? (
						<CarouselAvatar />
					) : (
						<Avatar {...currentAvatar}></Avatar>
					))}
			</Container>
		</AppStructure>
	);
}
