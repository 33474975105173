/** @format */

module.exports = {
	title: "Recuperação de conta",
	description:
		"Por favor, insira o seu e-mail para começar o processo de recuperação de conta.",
	recoveryButton: "Iniciar recuperação",
	warningDescription: "Foi enviado um e-mail com o link de verificação para seu endereço de e-mail.",
	case1_1: "- Caso você não tenha recebido o e-mail com o link, reinicie o processo clicando ",
	case1_2: "aqui",
	case1_3: " ;",
	case2: "- Caso você esteja tendo outros problemas de acesso, contate-nos em",

	email: "E-mail",
	changePassword:"Alterar senha",

	changingWaitASecond: "Estamos mudando sua senha. Aguarde alguns instantes.",

	accountRecovered1: "Senha trocada com sucesso! Faça seu ",
	accountRecovered2: ".",
	login: "login",

	accountNotRecovered: "Não foi possível trocar a senha! ",
	errors: {
		"Code timeout exceeded":
			"Token de verificação expirado. Nós mandamos um novo token para seu e-mail.",
		"Invalid Token": "Token de verificação inválido.",
	},
};
