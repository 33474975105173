/** @format */
import DynamicLevelBadge from "../../../environments/courses-board/Level/DynamicLevelBadge";
import { Wrapper,SubTitle, BadgeContainer, BadgeName,BadgeImageBox, Description  } from "../Message-styled";

import language from '../../../../services/defineLanguage'

const messagesTexts = require(`../../../../texts/${language}/messages`)
const levelsTexts = require(`../../../../texts/${language}/levels`);

function LevelUpContent({ value: levelId }) {
	let level = levelsTexts[`${levelId}`] || {name:"Unknow", description:"Unknow"}
	return (
		<Wrapper>
			<SubTitle>
				<b>{messagesTexts.levels}</b>
			</SubTitle>
			<BadgeContainer>
				<BadgeName> {level.name}</BadgeName>

				<BadgeImageBox>
					<DynamicLevelBadge
						levelId={levelId}
					/>
				</BadgeImageBox>
				<Description>
					{level.description}
				</Description> 
			</BadgeContainer>
		</Wrapper>
	);
}

export default {
	content: LevelUpContent,
	title: messagesTexts.congratulations,
};
