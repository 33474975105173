/** @format */

import { useState } from "react";
import {
	SuggestionsContainer,
	Wrapper,
	SuggestionForm,
} from "./suggestions-styling.js";
import TextInput from "../../forms/input/TextInput.jsx";
import TextArea from "../../forms/input/TextArea.jsx";
import FileInput from "../../forms/input/FileInput.jsx";

import {
	FormTitle,
	FormSubtitle,
	FormDivider,
	FormRow,
	FormButton,
} from "../../forms/FormsElements.jsx";

import language from "../../../services/defineLanguage.js";
import { giveSuggestions } from "../../../services/Suggestions.js";
import LocalLoader from "../../common/LocalLoader/LocalLoader.jsx";
import { Button } from "../../GenericComponents.jsx";
const texts = require(`../../../texts/${language}/suggestions`);

export default function Suggestions({}) {
	const [data, setData] = useState(initialState);
	const [response, setResponse] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	function initialState() {
		return {
			title: "",
			body: "",
			file: "",
		};
	}

	function onChange(event) {
		const { value, name } = event.target;
		console.log(name);
		if (name === "file") {
			console.log(event.target.files[0]);
			if (event.target.files.length > 0) {
				setData({
					...data,
					[name]: event.target.files[0],
				});
			}
		} else {
			setData({
				...data,
				[name]: value,
			});
		}
	}

	async function handleSuggestionSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		sendSuggestion(data.title, data.description, data.file);
	}

	async function sendSuggestion(title, description, file) {
		try {
			if (file !== "" && file.size > 5 * 1024 * 1024) {
				setResponse({
					success: false,
					message: texts.errors.FileTooLarge,
				});
			} else {
				if (await giveSuggestions(title, description, file)) {
					setResponse({
						success: true,
						message: texts.success,
					});
				} else {
					setResponse({
						success: false,
						message: texts.errors.default,
					});
				}
			}
		} catch (err) {
			// handleError(err.response);
			setResponse({
				success: false,
				message: texts.errors.default,
			});
			console.log(err.response);
		}
		setIsLoading(false);
	}

	function undoSuggestion() {
		setResponse(null);
		setIsLoading(false);
	}

	function resetSuggestions() {
		setData(initialState);
		setResponse(null);
		setIsLoading(false);
	}

	return (
			<Wrapper>
				<SuggestionsContainer>
					<FormRow
						center
						column
					>
						<FormTitle>{texts.formTitle}</FormTitle>
						{!((response === null && isLoading) || response !== null) && (
							<>
								<FormSubtitle>{texts.formSubtitle}</FormSubtitle>
								<FormDivider />
							</>
						)}
					</FormRow>

					{(response === null && isLoading) || response !== null ? (
						<div style={{ textAlign: "center" }}>
							<div style={{ marginTop: "25vh", marginBottom: "25vh" }}>
								<LocalLoader
									isLoading={isLoading}
									wasSuccessful={response && response.success}
									message={response !== null ? response.message : ""}
									scale={1.5}
								/>
							</div>
							<FormButton
								disabled={response === null}
								onClick={
									response && response.success
										? resetSuggestions
										: undoSuggestion
								}
							>
								{response && response.success
									? texts.sendOneMore
									: texts.tryAgain}
							</FormButton>
						</div>
					) : (
						<>
							<SuggestionForm onSubmit={handleSuggestionSubmit}>
								<FormRow center>
									<TextInput
										placeholder={texts.placeholderTitle}
										icon="title"
										name="title"
										value={data.title}
										onChange={onChange}
									/>
								</FormRow>

								<FormRow center>
									<TextArea
										placeholder={texts.placeholderBody}
										icon="body"
										name="description"
										rows={10}
										value={data.description}
										onChange={onChange}
									/>
								</FormRow>

								<FormRow center>
									<FileInput
										icon="file"
										name="file"
										accept="image/jpeg, image/pjpeg, image/png, image/gif, application/pdf"
										onChange={onChange}
									/>
								</FormRow>

								<FormRow>
									<FormButton type="submit">{texts.submitLabel}</FormButton>
								</FormRow>
							</SuggestionForm>
						</>
					)}
				</SuggestionsContainer>
			</Wrapper>
	);
}
