/** @format */

import { useState } from "react";
import CarouselImages from "../Carousel/CarouselImages";
import { Title, Button } from "../../GenericComponents";
import Modal2 from "../Modal2/Modal2";
import { ModalContentText, ModalContentWrapper } from "./CarouselAvatar-styled";

import goldenEagle from "../../../assets/images/avatars/GoldenEagle/7.svg";
import harpia from "../../../assets/images/avatars/Harpia/7.svg";
import stellerEagle from "../../../assets/images/avatars/StellerEagle/7.svg";

import avatarsNamesList from "../../../constants/avatarsNamesList";

import language from "../../../services/defineLanguage";
import { useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";
const texts = require(`../../../texts/${language}/avatar`);

const avatarDescriptions = avatarsNamesList.map((avatarName) => {
	return texts[avatarName];
});

export default function CarouselAvatar({}) {
    const { selectAvatar } = useContext(EagleContext)
	const [selectedAvatar, setSelectedAvatar] = useState(0);
	const [showModal, setShowModal] = useState(false);
	let header = <Title>{texts.chooseYourAvatar}</Title>;
	let footer = (
		<Button
			onClick={() => {
				setShowModal(true);
			}}
		>
			{texts.chooseThisAvatar}
		</Button>
	);
	return (
		<>
			<CarouselImages
				handleNext={setSelectedAvatar}
				handlePrev={setSelectedAvatar}
				header={header}
				footer={footer}
				elementsDescription={avatarDescriptions}
			>
				<img
					src={goldenEagle}
					alt="Real eagle avatar"
				/>
				<img
					src={harpia}
					alt="Harpia avatar"
				/>
				<img
					src={stellerEagle}
					alt="Steller eagle avatar"
				/>
			</CarouselImages>
			<Modal2
				title={texts.confirmTitle}
				show={showModal}
				onClose={() => {
					setShowModal(false);
				}}
			>
				<ModalContentWrapper>
					<ModalContentText>
						{texts.confirmHeader1}
						<b>{avatarDescriptions[selectedAvatar].name}</b>
						{texts.confirmHeader2}
					</ModalContentText>
					<ModalContentText>{texts.confirmDesc}</ModalContentText>
					<Button
						onClick={() => {
                            selectAvatar(avatarsNamesList[selectedAvatar])
							setShowModal(false);
						}}
					>
						{texts.confirmButton}
					</Button>
				</ModalContentWrapper>
			</Modal2>
		</>
	);
}
