import styled from 'styled-components'
import { FaChevronRight, FaChevronDown } from 'react-icons/fa'
import { Link } from 'react-router-dom'
export const SidebarItemContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 2.5rem;
    padding: 0 5px;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};

    background: none;
    border: none;

    cursor: pointer;

    &:hover,
    &:focus {
        background-color: rgba(204, 204, 204, 0.15);
    }
`

export const ToggleIconRight = styled(FaChevronRight)`
    font-size: 0.9rem;
    margin-left: auto;
`

export const ToggleIconDown = styled(FaChevronDown)`
    font-size: 0.9rem;
    margin-left: auto;
`

export const LinkContainer = styled.div`
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const SidebarLink = styled(Link)`
    text-decoration: none;
    display: flex;
    align-items: center;

    width: 100%;
    padding: 5px;
    height: 2.5rem;

    color: ${(props) => props.theme.colors.white};

    &:hover,
    &:focus {
        background-color: rgba(204, 204, 204, 0.15);
    }
`
