import styled from 'styled-components';
import { BaseContainer } from '../../environments/courses-board/BoardSectionContainers';

const SuggestionsContainer = styled(BaseContainer)`
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  h3 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 660px) {
    width: 90%;
  }
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1000px;
  overflow-x: hidden;
  display:flex;
  flex-direction:column;

  background-color: transparent;

  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 360px) {
    /* Defina os estilos específicos para telas menores que 360x640 aqui */
	width: 100%;
	  

}
`;

const SuggestionForm = styled.form`
  /* Defina os estilos do formulário aqui */
  display:flex;
  justify-content:space-around;
  flex-direction:column;
  align-items:center;
  flex-grow:1;

  @media screen and (max-width: 360px) {
  width: 100%;
	/* Defina os estilos específicos para telas menores que 360x640 aqui */
  }
`;

export { SuggestionsContainer, Wrapper, SuggestionForm };
