/** @format */

import { useContext } from "react";
import styled from "styled-components";
import Profile from "./Profile/Profile";
import { EagleContext } from "../../../../contexts/EagleContext";
import Header from "../../entrance/Header.jsx";

const NavbarContainer = styled.div`
	display: flex;

	min-height: 60px;
	height: 60px;
	width: 100%;
	padding-right: 50px;
	padding-left: 50px;

	background-color: ${(props) => "rgba(0,0,0,0.15)"};
	justify-content: flex-end;

	@media screen and (max-width: 991px) {
		padding-right: 30px;
		padding-left: 30px;
	}
`;

export default function Navbar(props) {
	return (
		<NavbarContainer>
			<Header linkPath={"/enrollcourse"} />
			<Profile />
		</NavbarContainer>
	);
}
