/** @format */

import {
	Wrapper,
	TableContainer,
	HeaderContainer,
	HeaderCell,
	Row,
	BodyContainer,
	DataCell,
} from "./Table-styled";

export default function Table({
	data,
	color,
	repeatHeader,
	columnNameHash,
	headerCallback = () => {},
}) {
	function createHeaderCells() {
		return data.columns.map((column) => {
			return (
				<HeaderCell
					onClick={() => {
						headerCallback(column);
					}}
					key={column}
				>
					{columnNameHash && columnNameHash[column]
						? columnNameHash[column]
						: column }
				</HeaderCell>
			);
		});
	}

	function createBodyRows() {
		return data.rows.map((row, index) => {
			return <Row key={index}>{createRowDataCells(row)}</Row>;
		});
	}

	function createRowDataCells(row, wrapperComponent) {
		return data.columns.map((column, index) => {
			return <DataCell key={index}>{row[column]}</DataCell>;
		});
	}

	return (
		<Wrapper>
			<TableContainer
				withBottomBorder={!repeatHeader}
				cellPadding={0}
				cellSpacing={0}
			>
				<HeaderContainer color={color}>
					{data && data.columns && <Row>{createHeaderCells()}</Row>}
				</HeaderContainer>
				{data && data.columns && data.rows && (
					<BodyContainer
						color={color}
						withBottomBorder={!repeatHeader}
					>
						{createBodyRows()}
					</BodyContainer>
				)}
				{repeatHeader && (
					<HeaderContainer color={color}>
						{data && data.columns && <Row>{createHeaderCells()}</Row>}
					</HeaderContainer>
				)}
			</TableContainer>
		</Wrapper>
	);
}
