/** @format */

import styled from "styled-components";
import { BaseContainer } from "../../environments/courses-board/BoardSectionContainers";

export const MissionContainer = styled(BaseContainer)`
	h3 {
		font-size: 24px;
		font-weight: 400;
		margin-bottom: 10px;
	}

	@media screen and (max-width: 375px) {
		margin: 1rem 1rem 1.5rem 1rem;
	}
`;

export const Wrapper = styled.div`
	width: 100%;
	overflow-x: hidden;

	background-color: ${(props) => props.theme.colors.gray};
`;

export const MissionBody = styled.div`
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.theme.colors.mediumGray};
	flex-direction: column;

	cursor: ${(props) =>
		props.isQuestionAnswered ? "not-allowed" : "zoom-in"} !important;

	input,
	textarea,
	select {
		pointer-events: ${(props) =>
			props.isQuestionAnswered ? "none" : "auto"}!important;

		color: ${(props) =>
			props.isQuestionAnswered
				? props.theme.colors.mediumGray
				: props.theme.colors.darkGray};
	}
`;
export const MissionInstruction = styled.h2`
	margin-top: 1rem;
	margin-bottom: 0.3rem;
	font-weight: 500;
	color: black;
`;

export const MissionStatement = styled.h4`
	margin-top: 0.5rem;
	color: ${(props) => props.theme.colors.primary};
`;

export const MissionButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	> * {
		&:first-child {
			margin-right: 0.5rem;
		}

		&:nth-child(2) {
			margin-left: 0.5rem;
		}
	}

	@media screen and (max-width: 375px) {
		> * {
			&:first-child {
				margin-right: 0.1rem;
				margin-bottom: 0.2rem;
			}

			&:nth-child(2) {
				margin-left: 0.1rem;
			}
		}

		display: flex;
		flex-direction: column;
	}
`;

export const AnswerSection = styled.div`
	width: 100%;
	padding: 1rem;
	border-radius: 5px;

	${(props) => {
		if (props.isCorrect === true)
			return (
				`
            background-color:` +
				props.theme.colors.lightGreen +
				`;` +
				`color:` +
				props.theme.colors.darkGreen +
				`;`
			);
		else {
			return (
				`
            background-color:` +
				props.theme.colors.lightRed +
				`;` +
				`color:` +
				props.theme.colors.darkRed +
				`;`
			);
		}
	}}
	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid
			${(props) =>
				props.isCorrect
					? props.theme.colors.darkGreen
					: props.theme.colors.darkRed};
		margin: 1em 0;
		padding: 0;
	}
`;

export const MissionEndContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 1rem;
`;

