/** @format */

import { useState, useEffect, useContext } from "react";

import { EagleContext } from "../../../contexts/EagleContext.jsx";
import {
	MissionContainer,
	MissionBody,
	Wrapper,
	MissionStatement,
	MissionInstruction,
	MissionButtons,
	AnswerSection,
	MissionEndContainer,
} from "./mission-styling.js";
import AppStructure from "../../environments/app/AppStructure";
import DynamicFaIcon from "../../common/DynamicFaIcon.jsx";
import ProgressBar from "../../common/ProgressBar/ProgressBar";
import { Button } from "../../../globalStyles.js";
import { useParams, Link } from "react-router-dom";

// Mission TYPES
import Quiz from "./Types/Quiz/Quiz.jsx";
import Pairs from "./Types/Pairs/Pairs.jsx";
import CompleteWithOptions from "./Types/CompleteWithOptions/CompleteWithOptions.jsx";
import OpenQuestion from "./Types/OpenQuestion/OpenQuestion.jsx";
import CompleteWithoutOptions from "./Types/CompleteWithoutOptions/CompleteWithoutOptions.jsx";

import correctAudioUrl from "../../../assets/audios/correct-answer.mp3";
import wrongAudioUrl from "../../../assets/audios/wrong-answer.mp3";
import skipAudioUrl from "../../../assets/audios/skip.mp3";

import language from "../../../services/defineLanguage.js";
import { useHistory } from "react-router-dom";
const texts = require(`../../../texts/${language}/mission`);

const audios = {
	correct: new Audio(correctAudioUrl),
	wrong: new Audio(wrongAudioUrl),
	skip: new Audio(skipAudioUrl),
};

export default function Mission() {
	const [showAnswerSection, setShowAnswerSection] = useState(false); // after check answer button is clicked, this shows the bottom section
	const [selectedAnswer, setSelectedAnswer] = useState(0);
	const [isRightAnswer, setIsRightAnswer] = useState(false);

	const [countErrors, setCountErrors] = useState(0);
	const [currentTask, setCurrentTask] = useState({});
	const [initialQueueLength, setinitialQueueLength] = useState(1);
	const [taskQueue, setTaskQueue] = useState([{}]);
	const [missionSessionId, setMissionSessionId] = useState(null);
	const {
		checkAnswer,
		checkPairAnswer,
		loadTasks,
		currentCourse,
		getCurrentCourseSetting,
		finishMission,
		startTask,
		skipTask,
		finishPairTask,
	} = useContext(EagleContext);
	const { missionId } = useParams();
	const history = useHistory();
	let settings = getCurrentCourseSetting();

	useEffect(async () => {
		if (!currentCourse) {
			history.push("/profile");
			return;
		}
		let { tasks, missionSessionId } = await loadTasks(missionId);
		if (tasks.length === 0) {
			history.push("/profile");
			return;
		}
		setMissionSessionId(missionSessionId);
		setTaskQueue(tasks);
		setCurrentTask(tasks[0]);
		setinitialQueueLength(tasks.length);
	}, []);

	useEffect(async () => {
		if (currentTask !== null && currentTask.id && !currentTask.repeated) {
			startTask(missionSessionId, currentTask.id);
		}
	}, [currentTask]);

	function handleWithRightAnswer() {
		if (settings.sensation) {
			audios.correct.play();
		}
		setTaskQueue((oldTaskQueue) => {
			let newTaskQueue = [...oldTaskQueue];
			newTaskQueue.shift();
			return newTaskQueue;
		});
	}

	function handleWithWrongAnswer() {
		if (settings.sensation) {
			audios.wrong.play();
		}
		setCountErrors((prevValue) => prevValue + 1);
		setTaskQueue((oldTaskQueue) => {
			let newTaskQueue = [...oldTaskQueue];
			let firstTask = newTaskQueue.shift();
			if (settings.renovation) {
				firstTask.repeated = true;
				newTaskQueue.push(firstTask);
			}
			return newTaskQueue;
		});
	}

	const handleCheckButtonClick = async () => {
		let answerResult = await checkAnswer(
			missionSessionId,
			currentTask.id,
			selectedAnswer
		);
		if (answerResult) {
			handleWithRightAnswer();
		} else {
			handleWithWrongAnswer();
		}

		setIsRightAnswer(answerResult);
		setShowAnswerSection(true);
		setSelectedAnswer(0);
	};

	const handleCompletePairs = async () => {
		finishPairTask(missionSessionId, currentTask.id);
		handleWithRightAnswer();

		setIsRightAnswer(true);
		setShowAnswerSection(true);
		setSelectedAnswer(0);
	};

	const handlePairVerify = async (pairA, pairB) => {
		let answerResult = await checkPairAnswer(
			missionSessionId,
			currentTask.id,
			pairA,
			pairB
		);
		return answerResult;
	};

	const handleNextButtonClick = async () => {
		if (taskQueue.length > 0) {
			setCurrentTask(taskQueue[0]);
			setShowAnswerSection(false);
		} else {
			await handleMissionEnded();
		}
	};

	async function handleMissionEnded() {
		if (currentTask != null) {
			await finishMission(missionSessionId, countErrors);
			setCurrentTask(null);
		}
	}

	const handleSkipButtonCLick = () => {
		if (settings.sensation) {
			audios.skip.play();
		}
		skipTask(missionSessionId, currentTask.id);
		if (taskQueue.length > 1) {
			setCurrentTask(taskQueue[1]);

			setTaskQueue((oldTaskQueue) => {
				let newTaskQueue = [...oldTaskQueue];
				let firstTask = newTaskQueue.shift();
				newTaskQueue.push(firstTask);
				return newTaskQueue;
			});
		}
	};

	const handleQuestionType = () => {
		if (currentTask.taskType === "Quiz")
			return (
				<Quiz
					data={currentTask.Alternatives}
					isQuestionAnswered={showAnswerSection}
					setSelectedAnswer={setSelectedAnswer}
					selectedAnswer={selectedAnswer}
				/>
			);
		else if (currentTask.taskType === "Pair") {
			return (
				<Pairs
					data={currentTask.Pairs}
					isQuestionAnswered={showAnswerSection}
					verifyPairs={handlePairVerify}
					completePairs={handleCompletePairs}
				/>
			);
		} else if (currentTask.taskType === "CompleteWithOptions") {
			return (
				<CompleteWithOptions
					callback={setSelectedAnswer}
					data={currentTask}
				/>
			);
		} else if (currentTask.taskType === "CompleteWithoutOptions") {
			return (
				<CompleteWithoutOptions
					callback={setSelectedAnswer}
					data={currentTask}
				/>
			);
		} else if (currentTask.taskType === "OpenQuestion") {
			return (
				<OpenQuestion
					isQuestionAnswered={showAnswerSection}
					callback={setSelectedAnswer}
				/>
			);
		}
	};

	return (
		<AppStructure>
			<Wrapper>
				<MissionContainer>
					{settings.progression && (
						<ProgressBar
							completionRate={
								((initialQueueLength - taskQueue.length) / initialQueueLength) *
								100
							}
						/>
					)}
					{currentTask === null && taskQueue.length === 0 ? (
						<MissionEndContainer>
							<h1>{texts.finishedMissionTitle}</h1>
							<Button
								style={{ marginTop: "1rem" }}
								as={Link}
								to={`/course/${currentCourse}`}
							>
								{texts.finishedMissionButton }
							</Button>
						</MissionEndContainer>
					) : (
						<>
							<MissionBody isQuestionAnswered={showAnswerSection}>
								<DynamicFaIcon
									name={currentTask.icon}
									size={50}
								/>
								<MissionInstruction>
									{currentTask.taskType === "Pair" && texts.pairInstruction}
									{currentTask.taskType === "Quiz" && texts.quizInstruction}
								</MissionInstruction>
								<MissionStatement>{currentTask.text}</MissionStatement>

								{handleQuestionType()}
							</MissionBody>

							{!showAnswerSection && (
								<MissionButtons>
									<Button onClick={handleSkipButtonCLick}>
										{texts.skipTaskButtonLabel}
									</Button>

									{currentTask.taskType !== "OpenQuestion" &&
										currentTask.taskType !== "Pair" && (
											<Button
												onClick={handleCheckButtonClick}
												disabled={selectedAnswer === 0}
											>
												{texts.checkAnswerButtonLabel}
											</Button>
										)}

									{currentTask.taskType === "OpenQuestion" && (
										<Button
											disabled={selectedAnswer === 0}
											onClick={handleCheckButtonClick}
										>
											{texts.sendAnswerButtonLabel}
											<DynamicFaIcon
												name={"FaRegPaperPlane"}
												size={10}
											/>
										</Button>
									)}
								</MissionButtons>
							)}

							{showAnswerSection && (
								<AnswerSection isCorrect={isRightAnswer}>
									<h4>
										{isRightAnswer
											? texts.questionIsRightTitleText
											: texts.questionIsWrongTitleText}
									</h4>
									<p>
										{isRightAnswer
											? texts.questionIsRightBodyText
											: texts.questionIsWrongBodyText}
									</p>
									<hr />
									<Button onClick={handleNextButtonClick}>
										{texts.nextButtonLabel}
									</Button>
								</AnswerSection>
							)}
						</>
					)}
				</MissionContainer>
			</Wrapper>
		</AppStructure>
	);
}
