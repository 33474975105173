import styled from 'styled-components'

export const TextAreaWrapper = styled.div`
    // height: 100%;
    width: auto;
    
    border: 1px solid ${(props) => props.isOnFocus? props.theme.colors.primary : props.theme.colors.gray};
    border-radius: 10px;
    padding: 5px;
`

export const TextAreaContent = styled.textarea`
    height: 100px;
    mix-width: 100%;
    width: 100%;
    
    font-size: 1rem;
    
    border: none;
    outline: none;

`

export const TextAreaLabel = styled.label`
    display: block;
    font-weight: 500;
`
