import styled from 'styled-components'

export const Container = styled.div`
    display: flex;

    width: 80%;
    height: 70px;
    padding: 10px;

    @media screen and (max-width: 420px) {
        width: 100%;
    }

    div {
        margin-right: auto;
        margin-left: auto;
    }
`

export const Profile = styled.div`
    display: flex;
    align-items: center;
    width: 50%;

    a {
        width: 50%;
        font-size: 1.1rem;
        font-weight: 500;
        text-decoration: none;
        color: ${(props) => props.theme.colors.black};
        margin-left: 10px;

        @media screen and (max-width: 420px) {
            margin-left: 5px;
        }
    }

    img {
        @media screen and (max-width: 420px) {
            display: none;
        }
    }
`
export const StatusWrapper = styled.div`
    display:flex;
    justify-content:space-around;
    width: 50%;

    img {
        width: 16.67%;
        max-height: 40px;
    }
`

export const Name = styled.a`
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
` 

export const Number = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 14.1%;

    color: ${(props) => props.theme.colors.black};

    font-size: 1.2rem;
    font-weight: 500;
`
