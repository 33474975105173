/** @format */

import { useState, useEffect, createContext, useContext } from "react";
import { getUser, logout } from "../services/User";
import { EagleContext } from "./EagleContext";
import { useHistory } from "react-router-dom";

export const InstructorContext = createContext({});

export function InstructorProvider({ children }) {
	const [user, setUser] = useState({});
	const [courses, setCourses] = useState({});
	const [isInstructorLoading, setIsInstructorLoading] = useState(true);
	const [currentCourseId, setCurrentCourseId] = useState(null);
	const { instructor } = useContext(EagleContext);
	const history = useHistory();

	function updateInstructor(newData) {
		setUser((lastState) => {
			return { ...lastState, ...newData };
		});
	}

	useEffect(async () => {
		if (instructor) {
			loadSessionUser();
			setIsInstructorLoading(false);
		}
	}, [instructor]);

	function updateInstructorProfilePic(newProfilePic) {
		setUser((lastState) => {
			return { ...lastState, pictureURL: newProfilePic };
		});
	}

	function loadSessionUser() {
		let sessionUser = instructor;
		if (sessionUser) {
			if (sessionUser.userType == "Instructor") {
				setUser(sessionUser);
			}
		}
	}

	async function instructorLogout(){
		await logout();
		setUser({});
		history.push("/")
	}

	return (
		<InstructorContext.Provider
			value={{
				user,
				setUser,
				updateInstructor,
				updateInstructorProfilePic,
				isInstructorLoading,
				currentCourseId,
				setCurrentCourseId,
				instructorLogout
			}}
		>
			{children}
		</InstructorContext.Provider>
	);
}
