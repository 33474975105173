/** @format */

import styled from "styled-components";

export const ChestWrapper = styled.div`
	width:100%;
	text-align:center;
	img {
		width: 100%;
		height: auto;
		// object-fit: scale-down;
		max-width:${({ maxWidth }) => (maxWidth ? maxWidth : '50vw')};
		max-height:${({ maxHeight }) => (maxHeight ? maxHeight : '25vh')};

		@media (max-width: 660px) {
			max-width:${({ maxWidth }) => (maxWidth ? maxWidth : '75vw')};
			max-height:${({ maxHeight }) => (maxHeight ? maxHeight : '18vh')};
		}
		
	}
`;
