import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin: 15px;
    padding: 30px;
    border-radius: 10px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`

export const StudentDataWrapper = styled.div`
    display: flex;

    img {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        object-fit: cover;
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-left: 10px;
    }

    div > h1 {
        font-size: 1.8rem;
        color: ${(props) => props.theme.colors.black};
    }

    div > h2 {
        font-size: 1.2rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors.darkGray};
    }
`

export const StudentProgressWrapper = styled.div`
    > h3 {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors.black};

        margin-top: 15px;
    }
`

export const Subtitle = styled.h2`
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
    margin: 15px 0;
`
