/** @format */

import { useState, useEffect, useContext } from "react";
import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import {
	StudentsContainer,
	Title,
	SubTitle,
	AcceptButton,
	RejectButton,
	ButtonContainer,
} from "./Students-styled";
import Table from "../../common/Table/Table";

import { InstructorContext } from "../../../contexts/InstructorContext";
import photo from "../../../assets/images/defaultProfileImage2.png";

import {
	acceptAllRegistration,
	acceptRegistration,
	getCourseRegistrations,
	rejectAllRegistration,
	rejectRegistration,
} from "../../../services/Instructor";

import language from "../../../services/defineLanguage.js";
import { Photo } from "../../GenericComponents";
import { EagleContext } from "../../../contexts/EagleContext";
const countries = require(`../../../texts/${language}/countries`);
const texts = require(`../../../texts/${language}/instructor/students`);

export default function New() {
	const { currentCourseId } = useContext(InstructorContext);
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [newStudents, setNewStudents] = useState([]);
	const [reload, setReload] = useState({});

	const forceReload = () => {
		setReload({});
	};

	const loadData = async () => {
		if (currentCourseId) {
			let registrations = await getCourseRegistrations(currentCourseId);
			registrations = registrations.map((registration) => {
				let newRegistration = {};
				newRegistration[texts.picture] = (
					<Photo
						src={
							registration.pictureURL ==
								"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
							registration.pictureURL?.length === 0
								? photo
								: registration.pictureURL
						}
					/>
				);
				newRegistration[
					texts.name
				] = `${registration.name} ${registration.lastName}`;
				newRegistration[texts.email] = registration.email;
				newRegistration[texts.country] =
					countries[registration.country] || registration.country;
				newRegistration[texts.acceptColumn] = createAcceptButton(
					registration.id
				);
				return newRegistration;
			});

			setNewStudents(registrations);
		}
	};

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		loadData();
	}, [reload]);

	async function handleAcceptRegistration(userId) {
		let response = await acceptRegistration(currentCourseId, userId);

		if (response.success) {
			forceReload();
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
		}
	}

	async function handleRejectRegistration(userId) {
		await rejectRegistration(currentCourseId, userId);
		forceReload();
	}

	async function handleAcceptAllRegistration() {
		let response = await acceptAllRegistration(currentCourseId);

		if (response.success) {
			forceReload();
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
		}
	}

	async function handleRejectAllRegistration() {
		await rejectAllRegistration(currentCourseId);
		forceReload();
	}

	function createAcceptButton(userId) {
		return (
			<ButtonContainer style={{ justifyContent: "start" }}>
				<AcceptButton
					onClick={() => {
						handleAcceptRegistration(userId);
					}}
				>
					{texts.accept}
				</AcceptButton>
				<RejectButton
					onClick={() => {
						handleRejectRegistration(userId);
					}}
				>
					{texts.reject}
				</RejectButton>
			</ButtonContainer>
		);
	}

	return (
		<InstructorStructure>
			<StudentsContainer>
				<Title>{texts.newRegistrations}</Title>
				{newStudents.length > 0 ? (
					<>
						<SubTitle>{texts.instructionNew}</SubTitle>
						<Table
							repeatHeader={true}
							data={{
								columns: [
									texts.picture,
									texts.name,
									texts.email,
									texts.country,
									texts.acceptColumn,
								],
								rows: newStudents,
							}}
						></Table>
						<ButtonContainer>
							<AcceptButton onClick={handleAcceptAllRegistration}>
								{texts.acceptAll}
							</AcceptButton>
							<RejectButton onClick={handleRejectAllRegistration}>
								{texts.rejectAll}
							</RejectButton>
						</ButtonContainer>
					</>
				) : (
					<SubTitle>{texts.noRegistrations}</SubTitle>
				)}
			</StudentsContainer>
		</InstructorStructure>
	);
}
