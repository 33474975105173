import styled from 'styled-components'

export const BaseContainer = styled.div`
	background-color: white;
	border-radius: 10px;
	height: auto;
	margin: 2rem;
	padding: 2rem;

	h3 {
		font-size: 24px;
		font-weight: 400;
		margin-bottom: 10px;
	}

	box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.2);
`

export const ContainerOnRight = styled(BaseContainer)`
	margin-left: 0;

	@media screen and (max-width: 1080px) {
		margin: 1rem 1rem 1.5rem 1rem;
	}

	p {
		color: ${(props) => props.theme.colors.darkGray};
		text-align: center;
	}
`

export const SubjectCardsContainer = styled(BaseContainer)`
	padding: 2.8rem 2.8rem 2.8rem 2.8rem;
	display: grid;
	grid-template-columns: 50% 50%;

	@media screen and (max-width: 1080px) {
		margin: 1rem 1rem 1.5rem 1rem;
	}
	
	@media screen and (max-width: 600px) {
		grid-template-columns: 100%;
		padding: 1rem;
		margin: 1rem 1rem 1.5rem 1rem;
	}
`

export const WeeklyRankingContainer = styled(ContainerOnRight)`
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 1080px) {
		margin-top: 0rem;
	}
`

export const TeamContainer = styled(ContainerOnRight)`
	display: flex;
	flex-direction: column;
`

export const LevelContainer = styled(ContainerOnRight)`
	display: flex;
	flex-direction: column;
`
export const AchievementsContainer = styled(ContainerOnRight)`
	display: grid;
`

export const CourseOverviewContainer = styled(BaseContainer)`
	display: grid;
	align-items: flex-start;

	@media screen and (max-width: 1080px) {
		margin: 1rem 1rem 1.5rem 1rem;
	}
`
