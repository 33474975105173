/** @format */

import { useState, useContext } from "react";
import { QuestionType, QuestionInstruction } from "../Question-styled";
import { FaTimesCircle } from "react-icons/fa";

import {
	OptionsTable,
	Wrapper,
	OptionContainer,
	NewOptionContainer,
	OptionText,
	EditContainer,
	RemoveButton,
} from "./Quiz-styled";

import TextArea from "../../../../common/TextArea/TextArea";
import Input from "../../../../common/Input/Input";
import ToggleButton from "../../../../common/ToggleButton/ToggleButton";
import { Button, SimpleLoader } from "../../../../GenericComponents";

import language from "../../../../../services/defineLanguage";
import {
	createAlternative,
	deleteAlternative,
	editTask,
	updateTaskCorrectAlternative,
} from "../../../../../services/Instructor";
import DynamicFaIcon from "../../../../common/DynamicFaIcon";
import { IconInput } from "../../../../common/IconInput/IconInput";
import { IconInputWrapper } from "../../Mission-styled";
import { EagleContext } from "../../../../../contexts/EagleContext";
import { FloatingMessageBox } from "../../../../common/FlaotingBox/FloatingMessageBox/FloatingMessageBox";
import { ButtonWithLoading } from "../../../../common/ButtonWithLoading/ButtonWithLoading";
const texts = require(`../../../../../texts/${language}/instructor/quiz`);

export default function Quiz({
	taskId,
	initialText,
	alternatives,
	correctAlternativeId,
	initialIcon,
	callback,
}) {
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [text, setText] = useState(initialText || "");
	const [icon, setIcon] = useState(initialIcon || "");
	const [newOption, setNewOption] = useState("");
	const [addNewAlternativeResponse, setAddNewAlternativeResponse] = useState(
		{}
	);
	const [
		changeCorrectAlternativeResponse,
		setChangeCorrectAlternativeResponse,
	] = useState({});
	const [deleteOptionResponse, setDeleteOptionResponse] = useState({});
	const [saveIconResponse, setSaveIconResponse] = useState({});
	const [saveTextResponse, setSaveTextResponse] = useState({});

	function createVirtualId(compareFunction) {
		let newId = "n0";
		while (!compareFunction(newId)) {
			newId = `n${parseInt(Math.random() * 10000000)}`;
		}
		return newId;
	}

	async function trySaveNewText() {
		let response = await editTask(taskId, text);

		if (response.success) {
			callback();
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
			setText(initialText || "");
		}

		setSaveTextResponse({ ...response, order: Date.now() });
	}

	async function saveNewText() {
		trySaveNewText();
		setSaveTextResponse({ isLoading: true });
	}

	async function trySaveNewIcon() {
		let response = await editTask(taskId, undefined, icon);

		if (response.success) {
			callback();
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
			setIcon(initialIcon || "");
		}

		setSaveIconResponse({ ...response, order: Date.now() });
	}

	async function saveNewIcon() {
		trySaveNewIcon();
		setSaveIconResponse({ isLoading: true });
	}

	async function tryAddAlternative() {
		let response = await createAlternative(taskId, newOption);

		if (response.success) {
			setNewOption("");
			callback();
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
		}

		setAddNewAlternativeResponse({ ...response, order: Date.now() });
	}
	async function addAlternative(event) {
		if (event) {
			event.preventDefault();
		}
		if (newOption.length < 1) {
			return;
		}

		tryAddAlternative();
		setAddNewAlternativeResponse({ isLoading: true });
	}

	async function tryDeleteOption(removedAlternativeId) {
		let response = await deleteAlternative(removedAlternativeId);
		if (response.success === true) {
			callback();
		}

		setDeleteOptionResponse({ ...response, order: Date.now() });
	}

	async function deleteOption(removedAlternativeId) {
		tryDeleteOption(removedAlternativeId);
		setDeleteOptionResponse({
			isLoading: true,
			alternativeId: removedAlternativeId,
		});
	}

	async function tryChangeCorrectAlternative(alternativeId) {
		let response = await updateTaskCorrectAlternative(taskId, alternativeId);
		if (response.success) {
			callback();
			setChangeCorrectAlternativeResponse(response);
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
			setChangeCorrectAlternativeResponse({});
		} else {
			setChangeCorrectAlternativeResponse({ ...response, order: Date.now() });
		}
	}

	async function changeCorrectAlternative(alternativeId) {
		tryChangeCorrectAlternative(alternativeId);
		setChangeCorrectAlternativeResponse({ isLoading: true, alternativeId });
	}

	function renderOptions() {
		return alternatives.map((alternative, index) => (
			<OptionContainer key={alternative.id}>
				<EditContainer>
					<ButtonWithLoading
						type="button"
						onClick={(e) => {
							if (e) {
								e.preventDefault();
							}
							deleteOption(alternative.id);
						}}
						ButtonTemplate={RemoveButton}
						disabled={
							deleteOptionResponse.isLoading &&
							deleteOptionResponse.alternativeId !== alternative.id
						}
						isloading={
							deleteOptionResponse.isLoading &&
							deleteOptionResponse.alternativeId === alternative.id
						}
					>
						<FaTimesCircle />
					</ButtonWithLoading>
					<div style={{ display: "flex", alignItems: "center" }}>
						{changeCorrectAlternativeResponse.isLoading &&
							changeCorrectAlternativeResponse.alternativeId ===
								alternative.id && (
								<SimpleLoader
									style={{ margin: " 0 5px 0 0" }}
									size={30}
								/>
							)}
						<ToggleButton
							checked={correctAlternativeId === alternative.id && true}
							setChecked={() => {
								changeCorrectAlternative(alternative.id);
							}}
							disabled={changeCorrectAlternativeResponse.isLoading}
						/>
					</div>
				</EditContainer>
				<OptionText>{alternative.text}</OptionText>
			</OptionContainer>
		));
	}

	return (
		<>
			<DynamicFaIcon
				name={initialIcon}
				size={50}
			/>
			<IconInputWrapper>
				<IconInput
					value={icon}
					setValue={setIcon}
				/>
			</IconInputWrapper>
			<ButtonWithLoading
				margin={"10px 0 0 0"}
				width={"80px"}
				type="button"
				onClick={saveNewIcon}
				disabled={initialIcon === icon}
				isloading={saveIconResponse.isLoading}
			>
				{texts.save}
			</ButtonWithLoading>
			<QuestionType>{texts.type}</QuestionType>
			<QuestionInstruction>{texts.instruction}</QuestionInstruction>
			<Wrapper>
				<NewOptionContainer>
					<TextArea
						placeholder={texts.typeHere}
						value={text}
						setValue={setText}
						style={{ border: "none", flexGrow: 1 }}
					/>
					<ButtonWithLoading
						type="button"
						width={"80px"}
						onClick={saveNewText}
						disabled={initialText === text}
						isloading={saveTextResponse.isLoading}
					>
						{texts.save}
					</ButtonWithLoading>
				</NewOptionContainer>
				<OptionsTable>
					{renderOptions()}
					<NewOptionContainer>
						<Input
							value={newOption}
							setValue={setNewOption}
							placeholder={texts.newOption}
							onKeyPress={(e) => {
								if (e.key == "Enter") {
									addAlternative(e);
								}
							}}
						/>
						<ButtonWithLoading
							width={"80px"}
							onClick={addAlternative}
							disabled={newOption === ""}
							isloading={addNewAlternativeResponse.isLoading}
						>
							{texts.add}
						</ButtonWithLoading>
					</NewOptionContainer>
				</OptionsTable>
			</Wrapper>
			{changeCorrectAlternativeResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={changeCorrectAlternativeResponse.order - 1707521092301}
				>
					{texts.errors[changeCorrectAlternativeResponse.error] ??
						texts.errors.default}
				</FloatingMessageBox>
			)}
			{deleteOptionResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={deleteOptionResponse.order - 1707521092301}
				>
					{texts.errors[deleteOptionResponse.error] ?? texts.errors.default}
				</FloatingMessageBox>
			)}
			{saveIconResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={saveIconResponse.order - 1707521092301}
				>
					{texts.errors[saveIconResponse.error] ?? texts.errors.default}
				</FloatingMessageBox>
			)}
			{saveTextResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={saveTextResponse.order - 1707521092301}
				>
					{texts.errors[saveTextResponse.error] ?? texts.errors.default}
				</FloatingMessageBox>
			)}
			{addNewAlternativeResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={addNewAlternativeResponse.order - 1707521092301}
				>
					{texts.errors[addNewAlternativeResponse.error] ??
						texts.errors.default}
				</FloatingMessageBox>
			)}
		</>
	);
}
