/** @format */

import { useState, useContext } from "react";
import { useHistory } from "react-router";
import {
	ProfileDropdown,
	DropdownTitle,
	DropIcon,
	DropdownContent,
	DropdownLink,
} from "./Profile-styled.js";
import { EagleContext } from "../../../../../contexts/EagleContext.jsx";

import photo from "../../../../../assets/images/defaultProfileImage2.png";
import language from "../../../../../services/defineLanguage";
import { Photo } from "../../../../GenericComponents.jsx";
const texts = require(`../../../../../texts/${language}/navbar`);

export default function Profile({}) {
	const [isShown, setIsShown] = useState(false);
	const history = useHistory();
	const { logout, user } = useContext(EagleContext);

	function toggleDropdown() {
		setIsShown(!isShown);
	}

	function handleLink(path) {
		history.push(path);
	}

	return (
		<ProfileDropdown
			onClick={toggleDropdown}
			onBlur={() => setIsShown(false)}
		>
			<DropdownTitle>
				<Photo
					size={40}
					src={
						user.pictureURL ==
							"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
						user.pictureURL?.length === 0
							? photo
							: user.pictureURL
					}
				/>
				<DropIcon />
			</DropdownTitle>
			<DropdownContent isShown={isShown}>
				<DropdownLink onMouseDown={() => handleLink("/profile")}>
					{texts.profile}
				</DropdownLink>
				<DropdownLink onMouseDown={() => handleLink("/friends")}>
					{texts.friends}
				</DropdownLink>
				<DropdownLink onMouseDown={() => handleLink("/suggestions")}>
					{texts.suggestions}
				</DropdownLink>
				<DropdownLink onMouseDown={() => handleLink("/settings")}>
					{texts.settings}
				</DropdownLink>
				<DropdownLink
					onMouseDown={async () => {
						await logout();
						handleLink("/");
					}}
				>
					{texts.logout}
				</DropdownLink>
			</DropdownContent>
		</ProfileDropdown>
	);
}
