/** @format */

module.exports = {
	1: {
		name: "Expert Patent",
		description: "Complete a mission without making a mistake",
	},
	2: {
		name: "Super Conquering Patent",
		description: "Complete 5 missions without making a mistake",
	},
	3: {
		name: "Hero Rank",
		description: "Complete 10 missions without making any mistakes",
	},
	4: {
		name: "Great Pathfinder Rank",
		description: "Climb three divisions in a row",
	},
	5: {
		name: "Grand Rank Leader",
		description: "Lead the ranks 2 weeks in a row",
	},
	6: {
		name: "Big Boss Rank",
		description: "Finish all missions from a subject",
	},
};
