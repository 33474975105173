/** @format */

module.exports = {
	profile: "Perfil",
	name: "Nome",
	surname: "Sobrenome",
	gender: "Gênero",
	country: "País",
	birthday: "Data de nascimento",
	uploadNewPhoto: "Carregar nova foto",
	picture: "Imagem",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
