import styled from 'styled-components'

export const Container = styled.div`
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 180px 490px auto auto;

    width: 100%;
    overflow-x: hidden;

    background-color: ${(props) => props.theme.colors.gray};

    @media screen and (max-width: 1080px) {
        grid-template-columns: 100%;
        grid-template-rows: 180px auto auto auto;
    }
`