import styled from 'styled-components'

export const Wrapper = styled.form`
	margin: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	color: black;
`
