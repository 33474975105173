/** @format */

module.exports = {
	1: {
		name: "Bronze",
		description:
			"Nice, you are on the Bronze level! Now, you need to be one of the best in the weekly ranking to reach the Silver.",
	},
	2: {
		name: "Silver",
		description:
			"Nice, you are on the Silver level! Now, you need to be one of the best in the weekly ranking to reach the Gold.",
	},
	3: {
		name: "Gold",
		description:
			"Nice, you are on the Gold level! Now, you need to be one of the best in the weekly ranking to reach the Ruby.",
	},
	4: {
		name: "Ruby",
		description:
			"Nice, you are on the Ruby level! Now, you need to be one of the best in the weekly ranking to reach the Diamond.",
	},
	5: {
		name: "Diamond",
		description:
			"Congratulations, you are on the Diamond level! This is the last level, keep striving not to drop a level",
	},
};
