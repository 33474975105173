/** @format */

import api from "./api";

export async function getSupportMaterials(subjectIs) {
	try {
		let res = await api.get(`/subject/${subjectIs}/support-materials`);
		if (res.data?.data) {
			return { success: true, newSupportMaterials: res.data.data };
		}
	} catch (err) {
		console.log(err);
	}
	return { success: false };
}

export async function createSupportMaterial(subjectId, text) {
	try {
		let res = await api.post(`/instructor/course/subject/support-material`, {
			subjectId,
			text,
		});
		if (res.data?.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}
