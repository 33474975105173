/** @format */

import LoadingIcon from "../LoadingIcon/LoadingIcon";
import { FaCheck, FaTimes } from "react-icons/fa";
import {
	VerificationText,
	CheckIcon,
	TimesIcon,
} from "./RecoveryStatus-styled";
import { Link } from "react-router-dom";

import language from "../../../services/defineLanguage";
const texts = require(`../../../texts/${language}/accountRecovery.js`)

function RecoveryStatus({ isLoading, wasRecovery, errorMessage }) {
	return (
		<>
			{isLoading ? (
				<LoadingIcon
					loadingText={texts.changingWaitASecond}
				/>
			) : (
				<>
					{wasRecovery ? (
						<CheckIcon>
							<FaCheck />
						</CheckIcon>
					) : (
						<TimesIcon>
							<FaTimes />
						</TimesIcon>
					)}
					<VerificationText>
						{wasRecovery ? (
							<>
								<p>{texts.accountRecovered1}<Link to="/signin">{texts.login}</Link>{texts.accountRecovered2}</p>
							</>
						) : (
							<p>{texts.accountNotRecovered} {texts.errors[errorMessage]}</p>
						)}
					</VerificationText>
				</>
			)}
		</>
	);
}

export default RecoveryStatus;
