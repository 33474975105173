/** @format */

import SettingsContent from "../SettingsContent/SettingsContent";
import Input from "../../../common/Input/Input";
import { MainContentInner } from "../SettingsContent/SettingsContent-styled";
import { H2 } from "../../../GenericComponents";

import language from "../../../../services/defineLanguage";
const texts = require(`../../../../texts/${language}/settingsSecurity`);

export default function SettingsSecurityView({
	onSubmit,
	email,
	password,
	setPassword,
	newPassword,
	setNewPassword,
	newPasswordConfirm,
	setNewPasswordConfirm,
	changePasswordResponse,
}) {
	console.log(changePasswordResponse)
	return (
		<SettingsContent
			title={texts.security}
			buttonText={texts.changePassword}
			onSubmit={onSubmit}
			isLoading={changePasswordResponse.isLoading}
			errorMessage={
				changePasswordResponse.success === false &&
				(texts.errors[changePasswordResponse.error] ?? texts.errors.default)
			}
			successMessage={changePasswordResponse.success === true && texts.success}
		>
			<MainContentInner margin="0 0 0 10px">
				<Input
					label={texts.email}
					value={email}
					disabled
					style={{ width: "200px" }}
				/>
				<H2 padding="10px 0">{texts.password}</H2>
				<Input
					label={texts.current}
					type="password"
					value={password}
					setValue={setPassword}
					required
					style={{ width: "200px" }}
				/>
				<Input
					label={texts.newPassword}
					type="password"
					value={newPassword}
					setValue={setNewPassword}
					required
					style={{ width: "200px" }}
				/>
				<Input
					label={texts.confirmNewPassword}
					type="password"
					value={newPasswordConfirm}
					setValue={setNewPasswordConfirm}
					required
					style={{ width: "200px" }}
				/>
			</MainContentInner>
		</SettingsContent>
	);
}
