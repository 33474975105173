/** @format */

import { useEffect } from "react";
import DynamicPatentBadge from "../DynamicPatentBadge/DynamicPatentBadge";
import { PatentsContainer, Wrapper } from "./achievements-grid-styling";

import language from "../../../../../services/defineLanguage";
const texts = require(`../../../../../texts/${language}/course`);

const AchievementsGrid = ({ achievementsAchieved }) => {

	return (
		<Wrapper>
			<PatentsContainer>
				{[1, 2, 3, 4, 5, 6].map((achievementId) => {
					return <DynamicPatentBadge
						key={achievementId}
						badgeId={achievementId}
						enable={(achievementsAchieved && achievementsAchieved.findIndex((achievementAchieved) => {
							return achievementAchieved.achievementId === achievementId;
						})) !== -1}
					/>;
				})}

			</PatentsContainer>
			{/* <p>{texts.achievementsDescription}</p> */}
		</Wrapper>
	);
};

export default AchievementsGrid;
