import styled from 'styled-components'
import { InputContainer } from '../InputContainer'

export const SelectContainer = styled(InputContainer)`
    padding-left: ${(props) => (props.icon ? '10px' : '0')};
`
export const FormSelect = styled.select`
    width: 100%;
    border: none;

    height: 50px;
    font-size: 1.2rem;

    font-weight: 500;
    border-radius: 10px;

    padding: 0px 10px;
    background-size: 200% auto;
    background-color: white;

    outline: none;
`
