/** @format */

module.exports = {
	security: "Security",
	email: "Email",
	password: "Password",
	current: "Current",
	newPassword: "New password",
	changePassword: "Change Password",
	confirmNewPassword: "Confirm new password",
	success: "Password changed successfuly!",
	errors: {
		"Password provided may be wrong.": "Password provided may be wrong.",
		"Different Passwords":
			"The new password and its confirmation do not match..",
		"Weak password":
			"The password must be at least 8 characters long, including uppercase and lowercase letters, numbers and special characters ( ! @ # ? $ *).",
		"Current password and new password should not be the same.":
			"Current password and new password should not be the same.",
		default: "Something went wrong... try again later.",
	},
};
