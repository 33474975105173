/** @format */

import styled from "styled-components";
import { Button } from "../../../GenericComponents";

export const Wrapper = styled.div`
	display: flex;
	align-items: center;

	list-style: none;
	margin: 10px 0;

	> a {
		padding: 10px 5px;
		width: 100%;
		cursor: pointer;
		text-decoration: none;

		border: 1px solid ${(props) => props.theme.colors.black};
		border-radius: 5px;

		color: ${(props) => props.theme.colors.black};

		&:hover {
			color: ${(props) => props.theme.colors.white};
			background: ${(props) => props.theme.colors.primary};
		}
	}

	h3 {
		font-size: 1.2rem;
		font-weight: 400;
	}

	@media screen and (max-width: 660px) {
		flex-direction: column-reverse;
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;

	@media screen and (max-width: 660px) {
		margin-bottom: 5px;
		margin-right: auto;
	}
`;

export const EditButton = styled(Button)`
	margin: 0 10px;

	@media screen and (max-width: 660px) {
		margin-left: 0;
	}
`;

export const DeleteButton = styled(Button)`
	margin: 0;
	width: 80px;
	background: ${(props) => props.theme.colors.warning};

	&:hover {
		background: ${(props) => props.theme.colors.black};
	}
`;
