/** @format */

import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import {
	AllCoursesWrapper,
	AllCoursesTitle,
	AllCoursesSubTitle,
	CardsContainer,
} from "./AllCourses-styled";
import LinkWithIcon from "../../common/LinkWithIcon/LinkWithIcon";
import Card from "../../common/Card/Card";

import language from "../../../services/defineLanguage.js";
const texts = require(`../../../texts/${language}/instructor/allCourses`);

export default function AllCoursesView({ courses }) {
	function createCards(courses) {
		let cards = courses.map((course) => {
			return (
				<Card
					key={course.courseInfo.title}
					title={course.courseInfo.title}
					link={"/instructor/course/" + course.courseInfo.id}
				>
					{course.courseInfo.description}
				</Card>
			);
		});

		return cards;
	}

	return (
		<InstructorStructure>
			<AllCoursesWrapper>
				<LinkWithIcon
					iconName="FaArrowLeft"
					to="/instructor"
					instructorStyle
				>
					{texts.back}
				</LinkWithIcon>
				<AllCoursesTitle>{texts.allCourses}</AllCoursesTitle>
				{courses.length !== 0 ? (
					<>
						<AllCoursesSubTitle>{texts.instruction}</AllCoursesSubTitle>
						<CardsContainer>{createCards(courses)}</CardsContainer>
					</>
				) : (
					<AllCoursesSubTitle>{texts.noCoures}</AllCoursesSubTitle>
				)}
			</AllCoursesWrapper>
		</InstructorStructure>
	);
}
