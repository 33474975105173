/** @format */

import { useContext } from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import NavItem from "./NavItem";
import Profile from "./Profile/Profile";
// import Notifications from "./Notifications"
import NotificationPanel from "./NotificationPanel/NotificationPanel";
import { EagleContext } from "../../../../contexts/EagleContext";

import language from "../../../../services/defineLanguage";
const texts = require(`../../../../texts/${language}/navbar`);

const NavbarContainer = styled.div`
	display: flex;

	min-height: ${(props) => props.theme.height.navbar};
	height: ${(props) => props.theme.height.navbar};
	width: 100%;

	background-color: ${(props) => props.theme.colors.white};
`;

const NavbarButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 5px;
	padding: 5px;

	cursor: pointer;

	border: none;
	background-color: ${(props) => props.theme.colors.white};

	svg {
		font-size: 2rem;
		color: ${(props) => props.theme.colors.secondary};
	}

	@media screen and (max-width: 719px) {
		margin-right: auto;
	}
`;

const NavItemsContainer = styled.div`
	display: flex;

	width: 100%;

	@media screen and (max-width: 719px) {
		display: none;
	}
`;

export default function Navbar(props) {
	const { getCurrentCourse, getCurrentCourseSetting } =
		useContext(EagleContext);

	let currentCourse = getCurrentCourse() || {};
	let settings = getCurrentCourseSetting();

	return (
		<>
			{currentCourse ? (
				<NavbarContainer>
					<NavbarButton onClick={props.toggleSidebar}>
						<FaBars />
					</NavbarButton>
					<NavItemsContainer>
						{settings.economy? (
							<NavItem
								icon="store"
								to="/store"
							/>
						) : <NavItem />}
						{settings.stats && (
							<>
								{settings.points && (
									<NavItem
										icon="point"
										value={currentCourse.points}
									>
										{texts.points}
									</NavItem>
								)}
								{settings.acknowledgment && (
								<NavItem
									icon="achievements"
									value={currentCourse.achievements ? currentCourse.achievements.length : 0}
								>
									{texts.achievements}
								</NavItem>
								)}
								{settings.economy && (
									<NavItem
										icon="coin"
										value={currentCourse.economy}
									>
										{texts.economy}
									</NavItem>
								)}
							</>
						)}
					</NavItemsContainer>
					<NotificationPanel />
					<Profile />
				</NavbarContainer>
			) : (
				<></>
			)}
		</>
	);
}
