/** @format */

import styled from "styled-components";
import { Button } from "../../GenericComponents";

export const StudentsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	margin: 15px;
	padding: 30px;
	border-radius: 10px;
	background: ${(props) => props.theme.colors.white};
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.h1`
	margin-bottom: 5px;
	font-size: 2rem;
	font-weight: 500;
	color: ${(props) => props.theme.colors.black};
`;

export const SubTitle = styled.h2`
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 15px;
	color: ${(props) => props.theme.colors.black};
`;

export const AccessButton = styled(Button)`
	> a {
		padding: 3px;
		text-decoration: none;
		color: ${(props) => props.theme.colors.white};
	}
`;

export const AcceptButton = styled(Button)`
	margin: 0 15px 0 0;
	background: ${(props) => props.theme.colors.green};

	&:hover {
		background: ${(props) => props.theme.colors.darkGreen};
	}
`;

export const RejectButton = styled(Button)`
	margin: 0 0 0 0;
	background: ${(props) => props.theme.colors.warning};

	&:hover {
		background: ${(props) => props.theme.colors.black};
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: ${(props) => (props.margin ? props.margin : 0)};
`;
