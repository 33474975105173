/** @format */

import api from "./api";

export async function giveSuggestions(title, description, file, uploadCallback) {

	try {
        const form = new FormData();
        form.append('title', title);
        form.append("description", description);
        form.append('file', file);

        let res = await api.post(`/suggestions`, form);

		// let res = await api.post(`/suggestions`, form, {onUploadProgress: (event) => {
        //     uploadCallback(event.loaded*100/event.total)
        // }});

        if(res.data.message === "Suggestion sended succeessfully!"){
            return true;
        }

		
	} catch (err) {
		console.log(err);
		return false;
	}
	return false;
}