import Carousel from './Carousel'

export default function CarouselCards({
    children,
    elementsWidth,
    header,
    handleEndOfSlides,
    spaceBetween
}) {
    return (
        <Carousel
            header={header}
            elementsWidth={elementsWidth}
            handleEndOfSlides={handleEndOfSlides}
            spaceBetween={spaceBetween}
        >
            {children}
        </Carousel>
    )
}
