import { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import { Container, Button, OptionsDropDown } from './OptionsButton-styled'

export default function OptionsButton({ children }) {
    const [toggleOptions, setToggleOptions] = useState(false)

    return (
        <Container>
            <Button
                onClick={() => setToggleOptions(!toggleOptions)}
                onBlur={() => setToggleOptions(false)}
            >
                <FaEllipsisH />
            </Button>
            <OptionsDropDown toggleOptions={toggleOptions}>
                {children}
            </OptionsDropDown>
        </Container>
    )
}
