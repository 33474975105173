/** @format */

module.exports = {
	edit: "Editar",
	clone: "Clonar",
	delete: "Deletar",
	registrationKey: "Chave de cadastro:",
	acceptStudents: "Aceitar novos estudantes automaticamente:",
	on: " Ativado",
	off: " Desativado",
	editCourse: "Editar curso",
	cloneCourse: "Clonar curso",
	cloneWarning:
		"Você tem certeza que quer clonar este curso? Esse é um processo custoso e pode demorar um tempo.",
	cancel: "Cancelar",
	cloning: "Clonando...",
	cloned: "Seu curso foi clonado!",
	newSubject: "Novo assunto",
	createSubject: "Criar novo assunto",
	confirmDelete: "Deseja realmente excluir este curso?",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
		clone:
			"Algo deu errado na clonagem... tente mais tarde. Se o problema persistir, entre em contato com contact@eagle-edu.com.br",
	},
};
