/** @format */

module.exports = {
	addCourse: "Adicionar Curso",
	description: "Insira a chave de registro do curso:",
	registrationKey: "Chave de registro",
	courseFound: "Curso encontrado!",
	courseName: "Nome do curso: ",
	enrolledSuccessfuly:
		"Sua inscrição foi registrada com sucesso. Estamos esperando a confirmação do instrutor do curso para liberar o acesso.",
	confirmRegistration: "Confirmar",
	cancel: "Cancelar",
	searchCourse: "Procurar curso",
	errors: {
		"No Courses found": "Nenhum curso foi encontrado.",
		"The studant already requested entry in this course.":
			"Você já solicitou entrada neste curso. Aguarde a permissão do instrutor.",
		"You are already enrolled in the course.":
			"Você já está matriculado nesse curso.",
	},
};
