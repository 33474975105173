/** @format */

module.exports = {
	title: "Finalizar cadastro da conta",
	finish: "Finalizar",
	registrationFinished1: `Parabéns! Seu cadastro no Eagle-Edu 
	foi completado com sucesso. Agora, você está pronto para embarcar na sua jornada
	educacional com nós. Vá para a `,
	registrationFinished2: ` e comece a explorar todo o potêncial educacional
	que o Eagle-Edu tem a oferecer.`,
	registrationFinished3:" Bom aprendizado!",
	login: "página de login ",
	errors: {
		"Code timeout exceeded":
			"Token timeout exceeded. We sent a new token to your email.",
		"Invalid Token": "Invalid Token.",
	},
	errors: {
		"Code timeout exceeded":
			"Token expirado. Nós mandamos um novo token para seu e-mail.",
		"Invalid Token": "Token inválido.",
		"default": "Erro desconhecido... tente mais tarde."
	},
};
