import styled from 'styled-components'

export const DropDown = styled.select`
	color: ${(props) => props.theme.colors.black};
	margin-right: 0.2rem;
	margin-left: 0.2rem;
	font-size: 15px;

	background: white;
	color: ${(props) => props.theme.colors.darkGray};
	border-radius: 0.5rem;

	border: 1px solid
		${(props) => (props.isInvalid ? props.theme.colors.warning : '#cccccc')};
	box-shadow: ${(props) =>
		props.isInvalid && '0 0 1px 1px ' + props.theme.colors.warning};

	display: inline-block;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	outline: none;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		transition: 0.2s;
		border: 1px solid ${(props) => props.theme.colors.primary};
		box-shadow: ${(props) => '0 0 1px 1px ' + props.theme.colors.primary};
	}
`

export const Option = styled.option``
