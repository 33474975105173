import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 215px;
    height: 190px;
    padding: 10px 15px;

    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.darkGray};
    border-radius: 10px;
`
export const CardTitle = styled.h1`
    margin-bottom: 5px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    font-size: 1.5rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
`

export const CardText = styled.p`
    display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	white-space: normal;
	overflow: hidden;
    text-overflow: ellipsis;

    margin-bottom: auto;
    font-size: 1rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.black};
`

export const CardLink = styled(Link)`
    max-width: 50%;
    padding: 5px;
    margin-bottom: 5px;

    border: none;
    border-radius: 5px;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.lightBlue};
    background-image: linear-gradient(
        90deg,
        rgba(0, 34, 34, 0.1) 0%,
        rgba(34, 34, 34, 0.2) 100%
    );

    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
    }
`
