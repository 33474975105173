/** @format */

import { useState, useContext, useEffect } from "react";

import { getCourses } from "../../../services/Instructor";
import { HomeView } from "./HomeView";
import { InstructorContext } from "../../../contexts/InstructorContext";


export function HomeContainer() {
	const {setCurrentCourseId, currentCourseId} = useContext(InstructorContext)
	const [showModal, setShowModal] = useState(false);
	const [courses, setCourses] = useState([]);

	useEffect(async () => {
		let courses = await getCourses();
		if(courses && courses.length > 0 && currentCourseId === null){
			setCurrentCourseId(courses[0].id)
		}
		setCourses(courses);
	}, []);

	return (
		<>
			<HomeView
				courses={courses}
				closeModal={() => {
					setShowModal(false);
				}}
				isModalOpen={showModal}
				openModal={() => {
					setShowModal(true);
				}}
			/>
		</>
	);
}
