/** @format */

module.exports = {
	type: "Select the correct option",
	instruction:
		"Type the statement of the question below. Also add answer items and indicate the correct alternative.",
	typeHere: "Type here...",
	newOption: "New option...",
	add: "Add",
	save: "Save",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
