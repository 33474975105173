/** @format */

import Modal2 from "../Modal2/Modal2";
import Chest from "../Chest/Chest";
import {
	Wrapper,
	Description,
	SubTitle,
	EmojiImage,
	ChanceFooter,
} from "./ImposedChoice-styled.js";
import { Button, SecondaryButton } from "../../GenericComponents";
import { useState, useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";

import language from "../../../services/defineLanguage";

const texts = require(`../../../texts/${language}/imposedChoice`);
const newChallengeImg = require(`../../../assets/images/emojis/newChallenge${language}.svg`);

export default function ImposedChoice({ show, onClose, subjectId }) {
	const { acceptImposedChoice } = useContext(EagleContext);

	return (
		<Modal2
			title={texts.newChallenge}
			show={show}
			onClose={onClose}
			footer={
				<ChanceFooter active={true}>
					<>
						<SecondaryButton onClick={onClose}> {texts.deny} </SecondaryButton>
						<Button
							onClick={() => {
								acceptImposedChoice(subjectId);
								onClose();
							}}
						>
							{" "}
							{texts.accept}{" "}
						</Button>
					</>
				</ChanceFooter>
			}
			disableFooter={false}
		>
			<Wrapper>
				<EmojiImage src={newChallengeImg} />
				<Description>{texts.instructions}</Description>
			</Wrapper>
		</Modal2>
	);
}
