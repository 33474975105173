/** @format */

module.exports = {
	addCourse: "Add Course",
	description: "Enter the course registration key:",
	registrationKey: "Registration key",
	courseFound: "Course found!",
	courseName: "Course Name: ",
	enrolledSuccessfuly:
		"Your registration has been registered successfully. We are waiting for confirmation from the course instructor to grant access.",
	confirmRegistration: "Confirm",
	cancel: "Cancel",
	searchCourse: "Search course",
	errors: {
		"No Courses found": "No courses were found.",
		"The studant already requested entry in this course.":
			"You already requested entry in this course. Wait Instructor permission.",
		"You are already enrolled in the course.":
			"You are already enrolled in this course.",
	},
};
