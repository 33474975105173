/** @format */

import { useState, useContext } from "react";
import { ThemeContext } from "styled-components";

import Switch from "react-switch";
import {
	ToggleButtonContainer,
	ToggleButtonLabel,
} from "./ToggleButton-styled";
import { Modal } from "../Modal/Modal";

export default function ToggleButton({
	checked,
	setChecked,
	width,
	height,
	label,
	useModal,
	modalContent,
  disabled
}) {
	const [showModal, setShowModal] = useState(false);
	const themeContext = useContext(ThemeContext);

	function openModal() {
		setShowModal((prev) => !prev);
	}

	/* modalContent example:
    const modalContent = {
        title: modalTitle,
        description: modalDescription,
        icon: modalIcon,
    }
    */

	return (
		<ToggleButtonContainer>
			{useModal && (
				<Modal
					showModal={showModal}
					setShowModal={setShowModal}
					content={modalContent}
				/>
			)}
			<Switch
				onChange={setChecked}
				checked={checked}
				onColor={themeContext.colors.green}
				offColor={themeContext.colors.warning}
				onHandleColor={themeContext.colors.gray}
				offHandleColor={themeContext.colors.gray}
				width={width}
				height={height}
				disabled={disabled}
			/>
			<ToggleButtonLabel
				onClick={() => openModal()}
				useModal={useModal}
			>
				{label}
			</ToggleButtonLabel>
		</ToggleButtonContainer>
	);
}
