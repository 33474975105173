/** @format */

//Import components
import React, { useState, useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";
import { InstructorContext } from "../../../contexts/InstructorContext";
import Message from "../../common/Message/Message";

import EntranceEnv from "../../environments/entrance/EntranceEnv";
import { useHistory } from "react-router-dom";

import { IconContext } from "react-icons";
import { theme } from "../../../contexts/theme/Theme";

import {
	FormBox,
	FormRow,
	FormImg,
	FormTitle,
	FormDivider,
	FormButton,
	FormLink,
} from "../../forms/FormsElements";

import { ErrorMessage } from "../../forms/FormsMessages";
import { login } from "../../../services/User";

import TextInput from "../../forms/input/TextInput";
import CheckboxInput from "../../forms/input/CheckboxInput";
import PasswordInput from "../../forms/input/PasswordInput";

//Import language, data and services
import language from "../../../services/defineLanguage";

import logo from "../../../assets/images/Eagle_edu.png";
import { ButtonWithLoading } from "../../common/ButtonWithLoading/ButtonWithLoading";

const texts = require(`../../../texts/${language}/signin`);

export default function Signin(props) {
	const history = useHistory();
	const primary = theme.colors.primary;
	const [data, setData] = useState(initialState);

	const { login: userLogin, setUserType } = useContext(EagleContext);
	const { setUser: setInstructor } = useContext(InstructorContext);

	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	function initialState() {
		return {
			email: "",
			password: "",
		};
	}

	function onChange(event) {
		const { value, name } = event.target;
		setData({
			...data,
			[name]: value,
		});
	}

	function handleError(data) {
		switch (data.id) {
			case 1:
				setErrorMessage(texts.errors.userNotFound);
				break;
			case 3:
				setErrorMessage(texts.errors.incorrectPassword);
				break;
			default:
				setErrorMessage(
					texts.errors.unableToLogin[0] +
						data.message +
						texts.errors.unableToLogin[1]
				);
		}
	}

	async function trySignIn() {
		try {
			let newUser = await login(data);

			if (newUser && newUser.userType === "Student") {
				userLogin(newUser);
				history.push("/profile");
			} else {
				setInstructor(newUser);
				setUserType(newUser.userType);
				history.push("/instructor");
			}
		} catch (err) {
			try {
				let errorData = JSON.parse(err.request.response);
				handleError(errorData);
			} catch {
				setErrorMessage("Unknow Error... Try later");
			}
		}

		setIsLoading(false);
	}
	async function handleSignIn(event) {
		event.preventDefault();

		trySignIn();
		setIsLoading(true);
	}

	return (
		<EntranceEnv>
			<IconContext.Provider
				value={{
					color: primary,
					size: "30",
				}}
			>
				<FormBox onSubmit={handleSignIn}>
					<FormRow
						center
						noMargin
					>
						<FormImg
							src={logo}
							alt="Eagle-edu logo"
						/>
					</FormRow>

					<FormRow
						center
						column
					>
						<FormTitle>{texts.login}</FormTitle>
						<FormDivider />
					</FormRow>

					<FormRow center>
						<TextInput
							placeholder={texts.placeholderEmail}
							icon="FaEnvelope"
							name="email"
							value={data.email}
							onChange={onChange}
						/>
					</FormRow>

					<FormRow center>
						<PasswordInput
							placeholder={texts.placeholderPassword}
							icon="password"
							name="password"
							value={data.password}
							onChange={onChange}
						/>
					</FormRow>

					{/* <FormRow>
						<CheckboxInput label={texts.keepMeConnected} />
					</FormRow> */}

					<FormRow>
						{errorMessage && (
							<ErrorMessage margin={"15px 0 0 0"}>{errorMessage}</ErrorMessage>
						)}
					</FormRow>

					<FormRow center>
						<ButtonWithLoading
							width={"110px"}
							isloading={isLoading}
							ButtonTemplate={FormButton}
							size={40}
							// loaderPrimaryColor={}
							// loaderSecondaryColor={}
						>
							{texts.signin}
						</ButtonWithLoading>
					</FormRow>

					<FormRow>
						<FormLink to="/accountrecovery">
							{texts.forgotYourPassword[0]}
							<strong>{texts.forgotYourPassword[1]}</strong>
						</FormLink>
						<FormLink to="/signup">
							{texts.registerNow[0]}
							<strong>{texts.registerNow[1]}</strong>
						</FormLink>
					</FormRow>
				</FormBox>
			</IconContext.Provider>
		</EntranceEnv>
	);
}
