/** @format */

import AppStructure from "../../environments/app/AppStructure";
import { useContext, useState, useEffect } from "react";
import NotificationItem from "../../environments/app/navbar/NotificationItem/NotificationItem";
import {
	Header,
	Title,
} from "../../environments/app/navbar/NotificationPanel/NotificationPanel-styled";
import { CenterBox } from "./Notifications-styled";
import { OptionItem } from "../../common/OptionsButton/OptionsButton-styled";
import OptionsButton from "../../common/OptionsButton/OptionsButton";
import { EagleContext } from "../../../contexts/EagleContext"; // Importando o Contexto do Eagle
import Message from "../../common/Message/Message";

export default function Notifications({}) {
	const [selectedMessage, setSelectedMessage] = useState({});
	const {
		messages,
		markNotificationAsRead,
		markAllNotificationsAsRead,
		requestAllMessages,
		isLoadingCourse,
	} = useContext(EagleContext); // Obtendo o estado de notificações e a função de marcar como lida do contexto

	function handleNotificationClick(notification) {
		setSelectedMessage(notification);
		markNotificationAsRead(notification.id);
	}

	function loadNotifications() {
		let items = [];
		let i;
		for (i = 0; i < messages.length; i++) {
			let message = messages[i];
			items.push(
				<NotificationItem
					key={message.id}
					type={message.type}
					date={message.date}
					checked={message.checked}
					onClick={() => handleNotificationClick(message)}
					onCheck={() => {
						if (!message.checked) markNotificationAsRead(message.id);
					}} // Chama a função para marcar como lida
				/>
			);
		}
		return items;
	}

	useEffect(async () => {
		if (!isLoadingCourse) {
			await requestAllMessages();
		}
	}, [isLoadingCourse]);

	return (
		<>
			<AppStructure>
				<CenterBox>
					<Header style={{ width: "100%", marginBottom: "15px" }}>
						<Title>Notificações</Title>
						<OptionsButton>
							<OptionItem onMouseDown={() => markAllNotificationsAsRead()}>
								Marcar todas como lido
							</OptionItem>
							{/* <OptionItem onMouseDown={() => setMarkAllRead(false)}>
                            Marcar todas como não lido
                        </OptionItem>
                        <OptionItem onMouseDown={() => setAreDeleted(true)}>Excluir todas</OptionItem> */}
						</OptionsButton>
					</Header>
					{loadNotifications()}
				</CenterBox>
			</AppStructure>
			<Message
				message={selectedMessage}
				closeModal={() => setSelectedMessage({})}
			/>
		</>
	);
}
