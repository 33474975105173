/** @format */

import { useState, useMemo } from "react";
import {
	InputContainer,
	FormInputText,
	FileInput,
} from "../input/InputContainer";
import DynamicFaIcon from "../../common/DynamicFaIcon";
import { InputLabel } from "../../common/Input/Input-styled";

export default function TextInput({
	value,
	icon,
	placeholder,
	name,
	type,
	onChange,
	required,
	label,
	margin,
	inputLabelMargin
}) {
	const [isInvalid, setIsInvalid] = useState(false);

	// const handleError = () => {
	//   if (!value) {
	//     setIsInvalid(true)
	//   } else {
	//     setIsInvalid(false)
	//   }
	// }

	const memoIcon = useMemo(() => <DynamicFaIcon name={icon} />, [icon]);

	return (
		<div style={{ display: "inline", flexGrow:1, margin }}>
			{label && <InputLabel>{label}</InputLabel>}
			<InputContainer
				isInvalid={isInvalid}
				margin={inputLabelMargin}
			>
				{icon && memoIcon}

				<FormInputText
					placeholder={placeholder}
					name={name}
					type={type}
					value={value}
					onChange={onChange}
					// onBlur={handleError}
					required={required}
				/>
			</InputContainer>
		</div>
	);
}
