//Import components
import React from 'react'
import EntranceEnv from '../../environments/entrance/EntranceEnv'
import { CenterBox } from '../../GenericComponents'
import { Paragraph, Ulist, TermsFooter } from './TermsAndConditions-styled'
// import { theme } from '../../../contexts/theme/Theme'

//Import language, data and services
import language from '../../../services/defineLanguage'

const {
	title,
	content,
	footer,
} = require(`../../../texts/${language}/termsAndConditions`)

function createListElements(element, index) {
	return <li key={index}>{element}</li>
}

function createParagraphs(element, index) {
	return <Paragraph key={index}>{element}</Paragraph>
}

function createTermListElement(element, index) {
	return (
		<React.Fragment key={index}>
			<li style={{ listStyle: 'none' }}>
				<h4>
					<u>{element.subtitles}</u>
				</h4>
			</li>
			<div>
				{element.paragraphs.map(createParagraphs)}
				{element.list && (
					<Ulist>{element.list.map(createListElements)}</Ulist>
				)}
			</div>
		</React.Fragment>
	)
}

let termsContent = content.map((element, index) => {
	return (
		<div key={index}>
			<h3>{element.title}</h3>
			<ol>{element.texts.map(createTermListElement)}</ol>
		</div>
	)
})

export default function TermsAndConditions(props) {
	return (
		<EntranceEnv>
			<CenterBox>
				<h2>{title}</h2>

				{termsContent}

				<TermsFooter>{footer}</TermsFooter>
			</CenterBox>
		</EntranceEnv>
	)
}
