/** @format */
import {
	Wrapper,
	EmojiImage,
	Description,
} from "../Message-styled";
import StatusMessage from "../StatusMessage/StatusMessage";
import neutralEagleEmoji from "../../../../assets/images/emojis/neutralCourseMPC.svg";
import happyEagleEmoji from "../../../../assets/images/emojis/happyCourseMPC.svg";
import sadEagleEmoji from "../../../../assets/images/emojis/sadCourseMPC.svg";

import language from '../../../../services/defineLanguage'

const messagesTexts = require(`../../../../texts/${language}/messages`)

let subTypeMap = {
	1: {
		image: neutralEagleEmoji,
		points: false,
		economy: false,
		originBefore: messagesTexts.points_origin_4,
	},
	2: {
		image: neutralEagleEmoji,
		points: true,
		economy: false,
		originBefore: <>{messagesTexts["points_origin_1.1"]} <b>{messagesTexts.soYouEarned} </b></>,
		originAfter: (
			<>
				<b>! </b> {messagesTexts["points_origin_1.2"]}
			</>
		),
	},
	3: {
		image: happyEagleEmoji,
		points: false,
		economy: false,
		originBefore:
			messagesTexts.points_origin_2 + messagesTexts.congratulations_2,
	},
	4: {
		image: happyEagleEmoji,
		points: true,
		economy: false,
		originBefore: <>{messagesTexts.points_origin_2} <b>{messagesTexts.soYouEarned} </b></>,
		originAfter: <b>!</b>,
	},
	5: {
		image: happyEagleEmoji,
		points: false,
		economy: true,
		originBefore: <>{messagesTexts.points_origin_2} <b>{messagesTexts.soYouEarned} </b></>,
		originAfter: <b>!</b>,
	},
	6: {
		image: happyEagleEmoji,
		points: true,
		economy: true,
		originBefore: <>{messagesTexts.points_origin_2} <b>{messagesTexts.soYouEarned} </b></>,
		originAfter: <b>!</b>,
	},
	7: {
		image: sadEagleEmoji,
		points: false,
		economy: false,
		originBefore: messagesTexts.points_origin_5,
	},
	8: {
		image: happyEagleEmoji,
		points: false,
		economy: false,
		originBefore:
			messagesTexts.points_origin_3 + messagesTexts.congratulations_2,
	},
	9: {
		image: happyEagleEmoji,
		points: true,
		economy: false,
		originBefore: <>{messagesTexts.points_origin_3} <b>{messagesTexts.soYouEarned} </b></>,
		originAfter: <b>!</b>,
	},
	10: {
		image: happyEagleEmoji,
		points: false,
		economy: true,
        originBefore: <>{messagesTexts.points_origin_3} <b>{messagesTexts.soYouEarned} </b></>,
		originAfter: <b>!</b>,
	},
	11: {
		image: happyEagleEmoji,
		points: true,
		economy: true,
        originBefore: <>{messagesTexts.points_origin_3} <b>{messagesTexts.soYouEarned} </b></>,
		originAfter: <b>!</b>,
	},
};

function FinishedMission({ subType, extraData }) {
	let currentMessage = subTypeMap[`${subType}`];
	return (
		<Wrapper>
			<EmojiImage src={currentMessage.image} />
			<Description>
				{currentMessage.originBefore}{" "}
				{ currentMessage.points && extraData.points > 0 && (
					<>
						<StatusMessage
							value={extraData.points}
							type={"point"}
						/>
					</>
				)}
				{	currentMessage.points &&
					extraData.points > 0 &&
					currentMessage.economy &&
					extraData.economy > 0 &&
					<b> {messagesTexts.and}</b>}
				{ currentMessage.economy && extraData.economy > 0 && (
					<>
						{" "}
						<StatusMessage
							value={extraData.economy}
							type={"economy"}
						/>
					</>
				)}
				{currentMessage.originAfter}
			</Description>
		</Wrapper>
	);
}

export default {
	content: FinishedMission,
	title: messagesTexts.congratulations,
};
