/** @format */

import axios from "axios";

const api = axios.create({
	// baseURL: "http://localhost",
	baseURL: "https://test.api.eagle-edu.com.br",
});

api.defaults.withCredentials = true;

export default api;
