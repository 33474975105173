import styled from 'styled-components'

export const ToggleButtonContainer = styled.div`
    display: flex;
    margin: 5px 0;
`

export const ToggleButtonLabel = styled.label`
    margin: auto 0;
    margin-left: 10px;
    font-weight: 500;
    cursor: ${(props) => (props.useModal ? 'pointer' : 'context-menu')};
`
