/** @format */

import styled from "styled-components";

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 2;
	transition: all 0.5s ease-in-out;
`;

export const ModalContainer = styled.div`
	margin-top: ${({ hasFooter }) => (hasFooter ? "-4.5px" : "0")};
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: ${({ hasFooter }) => (hasFooter ? "85vh" : "90vh")};
	max-width: 90vw;
	min-width: 35vw;
	z-index: 3;
	// width:100%;

	@media screen and (max-width: 1440px) {
		min-width: 70vw;
	}

	@media screen and (max-width: 768px) {
		width: 90vw;
		// max-height: ${({ hasFooter }) => "80vh"};
	}
	// transition: all 0.5s ease-in-out;
`;

export const ModalHeader = styled.header`
	border-radius: 10px 10px 0 0;
	display: flex;
	align-items: center;
	justify-content: ${(props) =>
		props.alignCetner ? "center" : "space-between"};
	padding: 15px;
	transition: all 0.5s ease-in-out;
	background: ${(props) => props.theme.colors.white};
	margin-bottom: -1px;
	transition: all 0.5s ease-in-out;
`;

export const CloseModal = styled.button`
	border: none;
	border-radius: 50%;
	background: transparent;
	cursor: pointer;
	color: ${(props) => props.theme.colors.primary};

	&:hover {
		background: rgba(0, 0, 0, 0.05);
	}
	transition: all 0.5s ease-in-out;
`;

export const ModalTitle = styled.h1`
	text-align: center;
	color: ${(props) => props.theme.colors.primary};
	transition: all 0.5s ease-in-out;
`;

export const ModalContent = styled.div`
	max-height: 75vh;
	overflow-y: scroll;
	padding: 0px 15px 15px 15px;
	text-align: justify;
	background: ${(props) => props.theme.colors.white};

	border-radius: ${({ hasFooter }) => (hasFooter ? "0" : "0 0 10px 10px")};
	transition: border-radius 0.5s ease-in-out;
`;

export const ModalFooter = styled.div`
	border-radius: ${({ hasFooter }) => (!hasFooter ? "0" : "0 0 10px 10px")};
	background: ${(props) => props.theme.colors.white};
`;
