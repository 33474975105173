import { useState, useEffect } from 'react'
import language from '../../../../../services/defineLanguage.js'
// const texts = require(`../../../../texts/${language}/mission`)

import {
  Wrapper,
  Statement2Container,
  StatementWord,
  InputWord
} from './complete-without-options'
import { removePunctuation } from '../../../../../constants/removePunctuation.js'

export default function CompleteWithoutOptions ({
  data,
  callback: handleTaskFeedback
}) {
  const [statementWords, setStatementWords] = useState([])
  const [answerOptions, setAnswerOptions] = useState([])
  const [numQuestions, setNumQuestions] = useState(0)

  const [currentAnswers] = useState([])

  useEffect(() => {
    setStatementWords(data.statement2.split(' '))
    setAnswerOptions(data.answerOptions)
    setNumQuestions(data.answerOptions.length)
  }, [data])

  function testAllEntries () {
    let answersIndex = 0
    let numCorrectMatches = 0

    for (const [key, value] of Object.entries(currentAnswers)) {
      if (
        answerOptions[answersIndex] &&
        value.toLowerCase() ===
          answerOptions[answersIndex].answerText.toLowerCase()
      )
        numCorrectMatches += 1

      answersIndex += 1
    }

    if (
      numCorrectMatches === Object.keys(currentAnswers).length &&
      numCorrectMatches === numQuestions
    ) {
      return true
    }

    return false
  }

  function inputOnChange (inputIndex, value) {
    currentAnswers[inputIndex] = value

    if (testAllEntries()) handleTaskFeedback(true)
    else handleTaskFeedback(false)
  }

  function renderStatementWords () {
    return statementWords.map((word, index) => {
      return (
        <StatementWord key={index}>
          {/* Every option that appears as a word of Statement 2 will be replaced as a select */}
          {answerOptions.find(
            option =>
              option.answerText.toUpperCase() ===
              removePunctuation(word.toUpperCase())
          ) ? (
            <InputWord
              isInvalid={false}
              placeholder={'...'}
              onChange={e => inputOnChange(index, e.target.value)}
            />
          ) : (
            word
          )}
        </StatementWord>
      )
    })
  }

  return (
    <Wrapper>
      <Statement2Container>
        {answerOptions && renderStatementWords()}
      </Statement2Container>
    </Wrapper>
  )
}
