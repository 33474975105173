import { useState, useEffect } from 'react'
import SubjectCard from '../../../environments/courses-board/SubjectCard/SubjectCard'
import { SubjectCardsContainer } from '../../../environments/courses-board/BoardSectionContainers'
import Modal2 from '../../../common/Modal2/Modal2'
import NewSubject from '../../NewSubject/NewSubject'

import language from '../../../../services/defineLanguage.js'
const texts = require(`../../../../texts/${language}/instructor/course`)

const Subjects = ({ data: SubjectCards, courseId, forceReloadCallback }) => {
    const [showModal, setShowModal] = useState(false)

    function renderSubjects() {
        return SubjectCards.map((subject, index) => {
            return (
                <SubjectCard
                    subject={subject}
                    key={index}
                    pathBase={
                        '/instructor/course/' +
                        courseId
                    }
                    progressBar={false}
                    isOdd={(SubjectCards.length+1) % 2  }
                />
            )
        })
    }

    function handleNewSubject(forceReloadNeeded){
        if(forceReloadNeeded){
            forceReloadCallback()
        }
        setShowModal(false)
    }

    return (
        <SubjectCardsContainer>
            {renderSubjects()}
            <SubjectCard
                subject={{ title: texts.newSubject, icon: 'FaPlus' }}
                key='New subject'
                onClick={() => setShowModal(true)}
                progressBar={false}
                isOdd={(SubjectCards.length+1) % 2}
            />
            <Modal2
                title={texts.createSubject}
                show={showModal}
                disableFooter={true}
                onClose={() => setShowModal(false)}
            >
                <NewSubject courseId={courseId} callback={handleNewSubject}/>
            </Modal2>
        </SubjectCardsContainer>
    )
}

export default Subjects
