import { SelectContent, SelectLabel } from './Select-styled'

export default function Select({
	label,
	name,
	style,
	required,
	value,
	setValue,
	children,
}) {
	return (
		<div>
			<SelectLabel>{label}</SelectLabel>
			<SelectContent
				id={label}
				name={name}
				value={value}
				onChange={(event) => setValue(event.target.value)}
				required={required}
				style={style}
			>
				{children}
			</SelectContent>
		</div>
	)
}
