export function createAGrowingArray(length, initialValue = 1) {
    return Array(length).fill(0).map((value, index) => index + initialValue)
}

export function createVirtualId(compareFunction) {
    let newId = "n0";
    while (!compareFunction(newId)) {
        newId = `n${parseInt(Math.random() * 10000000)}`;
    }
    return newId;
}