import styled from 'styled-components'

export const ElementContainer = styled.div`
    width: 100%;
    padding: 10px 0;

    color: ${(props) => props.theme.colors.black};
`

export const ElementTitle = styled.h3`
    margin-bottom: 5px;
    font-size: 1.3rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
`

export const ElementDescription = styled.p`
    margin-bottom: 10px;
    font-size: 1rem;
    color: ${(props) => props.theme.colors.black};
    text-align: justify;
`
