import { useEffect } from 'react'
import { TeamProgressWrapper } from './team-mate-progress-styling'
import ProgressBar from '../../../../common/ProgressBar/ProgressBar'

const TeamMateProgress = ({ completedTasks, numTasks }) => {
    const findCurrentProgress = () => {
        return completedTasks / numTasks <= 1? (completedTasks / numTasks) * 100 : 100
    }

    return (
        <TeamProgressWrapper>
            <span>
                {completedTasks}/{numTasks}
            </span>{' '}
            <ProgressBar
                height={6}
                width={100}
                completionRate={findCurrentProgress}
            />
        </TeamProgressWrapper>
    )
}

export default TeamMateProgress
