/** @format */
import React from "react";
import { Button, SimpleLoader } from "../../GenericComponents";

export function ButtonWithLoading({
	children,
	isloading,
	disabled,
	width,
	margin,
	ButtonTemplate,
	size,
	loaderPrimaryColor,
	loaderSecondaryColor,
	onClick,
}) {
	let NewButton = ButtonTemplate || Button;
	return (
		<NewButton
			width={width}
			margin={margin}
			disabled={disabled || isloading}
			onClick={onClick || (() => {})}
		>
			{isloading ? (
				<SimpleLoader
					size={size}
					primaryColor={loaderPrimaryColor}
					secondaryColor={loaderSecondaryColor}
				/>
			) : (
				children
			)}
		</NewButton>
	);
}
