/** @format */

import Background from "./Background";
import Footer from "./Footer";
import Header from "./Header";
import EntraceAccessManager from "../../common/EntraceAccessManager";

function EntranceEnv({ children }) {
	return (
		<Background>
			<EntraceAccessManager>
				<Header />
				{children}
				<Footer />
			</EntraceAccessManager>
		</Background>
	);
}

export default EntranceEnv;
