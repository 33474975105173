/** @format */

import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../GenericComponents";

export const ChangePictureWrapper = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;
export const ChangePictureText = styled.p`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;

	margin-bottom: auto;
	font-size: 1rem;
	font-weight: 400;
	color: ${(props) => props.theme.colors.black};
`;

export const PictureBox = styled.div`
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 5%;
	width: 20rem;
	height: 20rem;

	@media screen and (max-width: 400px) {
		width: 16rem;
		height: 16rem;
	}

	img {
		width: 90%;
		height: 90%;
		object-fit: cover;
		border-radius: 50%;

		position: relative;
		left: 5%;
		top: 5%;
	}
`;

export const SubmitButtom = styled(Button)`
	width: 20rem;
	height: auto;
	margin-top: 1rem;
	font-size: 1.4rem;

	@media screen and (max-width: 400px) {
		width: 16rem;
	}

	background-color: ${(props) => props.theme.colors.primary};
`;
