/** @format */

module.exports = {
	1: {
		name: "Patente Especialista",
		description: "Completar uma missão sem cometer erros",
	},
	2: {
		name: "Patente Super Conquistador",
		description: "Completar 5 missões sem cometer erros",
	},
	3: {
		name: "Rank Herói",
		description: "Completar 10 missões sem cometer erros",
	},
	4: {
		name: "Rank Grande Desbravador",
		description: "Subir três divisões de uma vez",
	},
	5: {
		name: "Grande Líder do Ranking",
		description: "Liderar o ranking por duas semanas seguidas",
	},
	6: {
		name: "Rank Chefão",
		description: "Finalizar todas as missões de um assunto",
	},
};
