import React, { useState, useEffect } from 'react'
import { CountdownWrapper } from './countdown-styling'
import { calculateTimeLeft } from '../../../../../services/handleTimeFunctions'

import language from '../../../../../services/defineLanguage'
const texts = require(`../../../../../texts/${language}/course`)

const RankingCountdown = ({ deadline }) => {
    const [timeLeft, setTimeLeft] = useState(0)
    const timerComponents = []

    Object.keys(timeLeft).forEach((interval, index) => {
        if (!timeLeft[interval]) {
            return
        }

        timerComponents.push(
            <span key={index}>
                {timeLeft[interval]} {interval}{' '}
            </span>
        )
    })

    useEffect(() => {
        try {
            const timer = setTimeout(() => {
                setTimeLeft(calculateTimeLeft(deadline))
            }, 1000)

            return () => clearTimeout(timer)
        } catch (error) {
            console.error('Error when fetching deadline for weekly ranking')
        }
    })

    return (
        <CountdownWrapper>
            <h3>{texts.remainingTime}</h3>
            <h4>
                {timerComponents.length ? (
                    timerComponents
                ) : (
                    <span>{texts.TimeOver}</span>
                )}
            </h4>
        </CountdownWrapper>
    )
}

export default RankingCountdown
