export const calculateTimeLeft = (deadline) => {
    let difference = +new Date(deadline) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        }
    }

    return timeLeft
}

// USING GMT!
export function getNextMonday() {
    const today = new Date()
    today.setMilliseconds(0)
    today.setSeconds(0)
    today.setMinutes(0)
    today.setHours(0)

    const nextMonday = new Date(today)

    do {
        nextMonday.setUTCDate(nextMonday.getUTCDate() + 1) // Adding 1 day
    } while (nextMonday.getUTCDay() !== 1)

    return nextMonday
}
