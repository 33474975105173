import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	overflow: hidden;

	margin: 15px;
	padding: 20px;
	border-radius: 10px;
	background-color: ${(props) => props.theme.colors.white};
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`

export const Title = styled.h1`
	font-size: 2rem;
	font-weight: 500;
`

export const Subtitle = styled.h2`
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 15px;
`

export const SectionTitle = styled.h3`
	font-size: 1.3rem;
	font-weight: 500;
`

export const ContentContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, 250px);
	justify-content: space-evenly;
	grid-gap: 60px;

	width: 100%;
	margin: 20px 0;
`
