/** @format */

module.exports = {
	courseName: "Course name",
	courseDescription: "Course description",
	courseKey: "Registration key",
	acceptStudents: "Automatically accept new students?",
	save: "Save",
	delete: "Delete course",
	confirmDelete: "Do you really want to delete this course?",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
