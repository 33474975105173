import {
    ElementContainer,
    ElementTitle,
    ElementDescription,
} from './GamificationElement-styled'
import ToggleButton from '../../../common/ToggleButton/ToggleButton'

export default function GamificationElement({ title, description, checked, setChecked }) {
    return (
        <ElementContainer>
            <ElementTitle>{title}</ElementTitle>
            <ElementDescription>{description}</ElementDescription>
            <ToggleButton checked={checked} setChecked={setChecked}/>
        </ElementContainer>
    )
}
