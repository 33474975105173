/** @format */

module.exports = {
	store: "Store",
	description: "Buy new itens for your collection!",
	rarity: "Rarity",
	novelty: "Festive items",
	boost: "Boost your learning",

	items: {
		GoldFeather: {
			name: "Gold feather",
			description: "A beautiful golden feather for your collection",
		},

		MetalClaws: {
			name: "Metal claws",
			description: "An imposing metal claw to flaunt",
		},

		LuckyNail: {
			name: "Lucky nail",
			description: "Beautiful eagle nail to bring you luck",
		},

		DiamondEgg: {
			name: "Diamond egg",
			description: "Wonderful diamond egg to keep",
		},

		ChristmasEagle: {
			name: "Christmas eagle",
			description: "Your eagle ready for Christmas",
		},

		RomanticEagle: {
			name: "Romantic eagle",
			description: "Beautiful eagle in love <3",
		},

		NurseEagle: {
			name: "Nurse Eagle",
			description: "Nurse Eagle to take care of you",
		},

		TeacherEagle: {
			name: "Teacher eagle",
			description: "Teacher eagle to inspire you",
		},

		DoubleOrNothing: {
			name: "Double or nothing",
			description: "Get double points for each mission performed",
		},

		BigDay: {
			name: "Big day",
			description: "Spend the day earning triple points",
		},

		SuperWeek: {
			name: "Super week",
			description: "Spend the week earning double points",
		},

		MyMonth: {
			name: "My month",
			description: "Spend the month earning twice as many points and coins",
		},
	},

	buy: "Buy",
	ok: "Ok",
	confirmMessage1: "Are you sure you want to buy ",
	confirmMessage2: "If you buy that item you will be left ",
	cantBuyTitle: "You don't have enough",
	cantBuy: "You do not have enough to buy ",
	youOnlyHave: "You only have ",
};
