module.exports = {
	titlePlaceholder: 'PlaceHolder for Course Title ',
	supportMaterialTooltipp: 'Support Material',
	youHaveNewMission: 'You have a new mission',
	youCompletedAllMissions: "Congrats! You have completed all missions for that subject.",
    buttonLabel: 'Start',
    disabledButtonLabel: 'Check the top-right button to continue...',
	newMissionInstruction: "Missions are an opportunity to put in practice what you've learned during classes!",
	activities:"Activities",
	missions:"Missions",
	supportMaterials:"Support Materials",
	noMaterials:"No materials.",
	errors: {},
}
