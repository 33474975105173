/** @format */

//Import components
import React from "react";
import GlobalStyle from "../globalStyles";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

import Signin from "../components/pages/Signin/Signin";
import Signup from "../components/pages/Signup/Signup";
import TermsAndConditions from "../components/pages/TermsAndConditions/TermsAndConditions";

import Profile from "../components/pages/Profile/Profile";

import Course from "../components/pages/Course/Course";

import Subject from "../components/pages/Subject/Subject";

import Friends from "../components/pages/Friends/Friends";

import Notifications from "../components/pages/Notifications/Notifications";

import { HomeContainer } from "../components/instructor/Home/HomeContainer";
import AllCoursesContainer from "../components/instructor/AllCourses/AllCoursesContainer";
import CourseInstructor from "../components/instructor/Course/Course";
import SubjectInstructor from "../components/instructor/Subject/Subject";
import MissionInstructor from "../components/instructor/Mission/Mission";

import CourseStatistics from "../components/instructor/CourseStatistics/CourseStatistics";
import GamificationBasics from "../components/instructor/Gamification/Basics";
import GamificationAdvanced from "../components/instructor/Gamification/Advanced";
import StudentsCurrent from "../components/instructor/Students/Current";
import StudentsNew from "../components/instructor/Students/New";
import StudentPage from "../components/instructor/StudentPage/StudentPage";
import Tutorials from "../components/instructor/Tutorials/Tutorials";

import Store from "../components/pages/Store/Store";
import Mission from "../components/pages/Mission/Mission";
import Suggestions from "../components/pages/Suggestions/Suggestions";
import { EagleProvider } from "../contexts/EagleContext";
import AccountRecovery from "../components/pages/AccountRecovery/AccountRecovery";
import AccountVerification from "../components/pages/AccountVerification/AccountVerification";
import Unsubscribe from "../components/pages/Unsubscribe/Unsubscribe";
import EnrollCoursePage from "../components/pages/EnrollCourse/EnrollCoursePage";
import StudentSettings from "../components/pages/Settings/StudentSettings";
import InstructorSettings from "../components/pages/Settings/InstructorSettings";
import { InstructorProvider } from "../contexts/InstructorContext";
import AccountVerificationWarning from "../components/pages/AccountVerification/Warning/AccountVerificationWarning";
import AccountVerificationRequest from "../components/pages/AccountVerification/Request/AccountVerificationRequest";
import InstructorStructure from "../components/environments/instructor/InstructorStructure/InstructorStructure";
import DynamicStructur from "../components/environments/DynamicStructur";
import FinalizeRegistrationEditor from "../components/pages/FinalizeRegistration/FinalizeRegistrationEditor/FinalizeRegistrationEditor";

export default function Routes() {
	return (
		<Router>
			<GlobalStyle />
			<EagleProvider>
				<InstructorProvider>
					<Switch>
						<Route path="/signin">
							<Signin />
						</Route>
						<Route path="/signup">
							<Signup />
						</Route>
						<Route path="/terms">
							<TermsAndConditions />
						</Route>
						<Route path="/profile">
							<Profile />
						</Route>
						<Route path="/course/:courseId">
							<Course />
						</Route>
						<Route path="/subject/:subjectId">
							<Subject />
						</Route>
						<Route path="/friends">
							<Friends />
						</Route>
						<Route path="/notifications">
							<Notifications />
						</Route>
						<Route path="/instructor/allCourses">
							<AllCoursesContainer />
						</Route>
						<Route path="/instructor/course/:courseId/subject/:subjectId/mission/:missionId">
							<MissionInstructor />
						</Route>
						<Route path="/instructor/course/:courseId/subject/:subjectId">
							<SubjectInstructor />
						</Route>
						<Route path="/instructor/course/statistics">
							<CourseStatistics />
						</Route>
						<Route path="/instructor/course/:courseId">
							<CourseInstructor />
						</Route>
						<Route path="/instructor/gamification/basics">
							<GamificationBasics />
						</Route>
						<Route path="/instructor/gamification/advanced">
							<GamificationAdvanced />
						</Route>
						<Route path="/instructor/students/current/:studentId">
							<StudentPage />
						</Route>
						<Route path="/instructor/students/current">
							<StudentsCurrent />
						</Route>
						<Route path="/instructor/students/new">
							<StudentsNew />
						</Route>
						<Route path="/instructor/settings">
							<InstructorSettings />
						</Route>
						<Route path="/instructor/tutorials">
							<Tutorials />
						</Route>
						<Route path="/instructor/suggestions">
							<InstructorStructure>
								<Suggestions />
							</InstructorStructure>
						</Route>
						<Route path="/instructor">
							<HomeContainer />
						</Route>
						<Route path="/store">
							<Store />
						</Route>
						<Route path="/settings">
							<StudentSettings />
						</Route>
						<Route path="/notifications">
							<Notifications />
						</Route>
						<Route path="/mission/:missionId">
							<Mission />
						</Route>
						<Route path="/suggestions">
							<DynamicStructur>
								<Suggestions />
							</DynamicStructur>
						</Route>

						<Route path="/accountrecovery">
							<AccountRecovery />
						</Route>
						<Route path="/finalizeRegistration/:code">
							<FinalizeRegistrationEditor />
						</Route>
						<Route path="/accountverification/:code">
							<AccountVerificationRequest />
						</Route>
						<Route path="/accountverification">
							<AccountVerificationWarning />
						</Route>

						<Route path="/unsubscribe/:code">
							<Unsubscribe />
						</Route>
						<Route path="/enrollcourse">
							<EnrollCoursePage />
						</Route>

						<Route path="/">
							<Redirect to="/signin" />
						</Route>
					</Switch>
				</InstructorProvider>
			</EagleProvider>
		</Router>
	);
}
