module.exports = {
    customization: 'Accessibility and Customization',
    theme: 'Theme',
    light: 'Light',
    dark: 'Dark',
    colorBlind: 'Color blind',
    fontSize: 'Font size',
    small: 'Small',
    medium: 'Medium',
    big: 'Big',
    notifications: 'Notifications',
}
