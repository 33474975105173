/** @format */

import Background from "../../../environments/entrance/Background";
import Footer from "../../../environments/entrance/Footer";
import Header from "../../../environments/entrance/Header";
import EntraceAccessManager from "../../../common/EntraceAccessManager";
import { CenterBox } from "../../../GenericComponents";
import {
	Title,
	Paragraph,
	SubTitle,
	DivTitle,
} from "./AccountRecoveryWarning-styled";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { EagleContext } from "../../../../contexts/EagleContext";

import language from "../../../../services/defineLanguage";
const texts = require(`../../../../texts/${language}/accountRecovery.js`);

function AccountRecoveryWarning({ children }) {
	const { user } = useContext(EagleContext);
	return (
		<Background>
			<EntraceAccessManager>
				<Header />
				{children}
				<CenterBox>
					<DivTitle>
						<Title>{texts.title}</Title>
					</DivTitle>
					<SubTitle>
						{texts.warningDescription}
					</SubTitle>
					<Paragraph>
						{texts.case1_1}
						<Link to="/accountrecovery">{texts.case1_2}</Link>
						{texts.case1_3}
					</Paragraph>
					<Paragraph>
						{texts.case2} <strong>contact@eagle-edu.com.br</strong>.
					</Paragraph>
					{/* <FormButton>Confirmar</FormButton> */}
				</CenterBox>
				<Footer />
			</EntraceAccessManager>
		</Background>
	);
}

export default AccountRecoveryWarning;
