import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	/* &:hover {
		cursor: pointer;
	} */
`

export const PatentImg = styled.img`

	max-width:100%;
	height:auto;

	@media screen and (max-width: 1080px) {
		max-width: 75%;
	}
`
