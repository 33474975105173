/** @format */

module.exports = {
	title: "Registration completion",
	finish: "Conclude",
	registrationFinished1: `Congratulations! Your registration on the Eagle-Edu 
	has been successfully completed. Now, you're ready to embark on your educational 
	journey with us. Head to the `,
	registrationFinished2: ` and start exploring the full educational
	potential that Eagle-Edu has to offer.`,
	registrationFinished3:" Happy learning!",
	login: "login page",
	errors: {
		"Code timeout exceeded":
			"Token timeout exceeded. We sent a new token to your email.",
		"Invalid Token": "Invalid Token.",
		"default": "Unknown error... try later"
	},
};
