/** @format */

module.exports = {
	title: "Estatisticas do curso",
	description: "Veja as estatisticas do seu curso",
	studentsTitle: "Estudantes",
	name: "Nome",
	missions: "Missões",
	tasks: "Atividades",
	courseProgress: "Progresso no curso",
	points: "Pontos",
	achievements: "Conquistas",
	economy: "Moedas",
	studentsCompleted: "Estutandes que completaram",
	numberStudents: "Número de estudantes",
	studentsStarted: "Estutandes que começaram",
};
