/** @format */

import styled from "styled-components";
import { Button } from "../../../GenericComponents";

export const CardWrapper = styled.div`
	margin: 1rem 3rem 1rem 3rem;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.theme.colors.darkGray};

	overflow: hidden;
	text-overflow: ellipsis;

	@media screen and (min-width: 600px) {
		&:last-child {
			grid-column: ${({ isOdd }) => (isOdd ? "span 2" : "span 1")};
		}
	}
`;

export const SubjectIconSection = styled.div`
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;

	margin-bottom: 0.5rem;
`;

export const CardButton = styled(Button)`
	background: ${(props) => props.theme.colors.darkGray};
	color: ${(props) => props.theme.colors.white};
	cursor: pointer;
	border: none;

	border-radius: 5px;
	padding: 0.3rem 0.3rem 0.3rem 0.3rem;
	font-size: 15px;
	font-weight: 600;
	cursor: pointer;

	display:flex;
	justify-content:center;
	transition: 0.5s;
	&:hover {
		background: ${(props) => props.theme.colors.mediumGray};
	}
`;

export const SubjectTitle = styled.span`
	display: block;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const ClickableArea = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;
