/** @format */

import styled from "styled-components";

export const Container = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;

	border-radius: 0 10px 10px 0;

	@media screen and (max-width: 600px) {
		width: 100%;
		border-radius: 0 0 10px 10px;
	}
`;

export const Title = styled.h1`
	margin: 10px;
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: 5px;
`;

export const MainContent = styled.div`
	display: flex;
	width: 100%;
	height: 100%;

	@media screen and (max-width: 880px) {
		flex-direction: column;
	}
`;

export const MainContentInner = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: ${(props) => (props.centralized ? "center" : "defautl")};
	margin: ${(props) => (props.margin ? props.margin : "0")};
`;
export const PictureBox = styled.div`
	position: relative;

	background-color: white;
	border-radius: 50%;
	width: 8.5rem;
	height: 8.5rem;
	margin-bottom: 10px;

	img {
		width: 90%;
		height: 90%;
		object-fit: cover;
		border-radius: 50%;

		position: relative;
		left: 5%;
		top: 5%;
	}
`;

export const InputLabel = styled.label`
	display: block;
	font-weight: 500;
	font-size: 1rem;
`;

export const CameraBox = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 2.5rem;
	height: 2.5rem;
	border: none;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.7);

	position: relative;
	left: 72%;
	bottom: 3rem;

	cursor: pointer;
`;
