/** @format */

// Import components
import React, { useState, useContext } from "react";

import EntranceEnv from "../../environments/entrance/EntranceEnv";

import { IconContext } from "react-icons";
import { theme } from "../../../contexts/theme/Theme";
import { useHistory } from "react-router-dom";

import {
	FormBox,
	FormRow,
	FormImg,
	FormTitle,
	FormDivider,
	FormButton,
	FormLabel,
} from "../../forms/FormsElements";

import { ErrorMessage } from "../../forms/FormsMessages";

import TextInput from "../../forms/input/TextInput";
import CheckboxInput from "../../forms/input/CheckboxInput";
import PasswordInput from "../../forms/input/PasswordInput";
import SelectInput from "../../forms/input/SelectInput/SelectInput";

import { EagleContext } from "../../../contexts/EagleContext";

//Import language, data and services
import language from "../../../services/defineLanguage";

import {
	createSelectOptions,
	createSelectOptionsFromJson,
} from "../../../services/createSelectOptions";
import { createAGrowingArray } from "../../../services/utils";

import {
	validateName,
	validateEmail,
	validatePasswordStrength,
	removeSpaceBeginEnd,
} from "../../../services/handleCredentials";

import logo from "../../../assets/images/Eagle_edu.png";
import { ButtonWithLoading } from "../../common/ButtonWithLoading/ButtonWithLoading";

const genders = require(`../../../texts/${language}/genders`);
const mapGenders = require(`../../../texts/en/genders`);
const countries = require(`../../../texts/${language}/countries`);
const months = require(`../../../texts/${language}/months`);
const numberOfDaysInMonths = require("../../../constants/numberOfDaysInMonths");

const texts = require(`../../../texts/${language}/signup`);

//Init Values

const initialMonthOption = 0;
const initialDayOption = 1;

let gendersOptions = createSelectOptions(genders, texts.gender, true, 0);
let countriesOptions = createSelectOptionsFromJson(countries);
let daysOptions = createSelectOptions(
	createAGrowingArray(numberOfDaysInMonths[initialMonthOption])
);
let monthsOptions = createSelectOptions(months, "", true);
let yearsOptions = createSelectOptions(
	createAGrowingArray(new Date().getFullYear() - 1919, 1920).reverse()
);

export default function Signup(props) {
	const history = useHistory();

	// Estado para armazenar os dados do formulário
	const [data, setData] = useState(initialState);

	// Estado para armazenar o valor do segundo campo de senha
	const [password2, setPassword2] = useState("");

	// Estado para acompanhar se os termos foram aceitos
	const [agreeTerms, setAgreeTerms] = useState(false);

	// Estados para gerenciar mensagens de erro e sucesso
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	const [isLoading, setIsloading] = useState(false);

	// Contexto para interagir com o estado da aplicação
	const { register } = useContext(EagleContext);

	// Função para inicializar os dados do formulário
	function initialState() {
		return {
			name: "",
			lastName: "",
			email: "",
			password: "",
			gender: "",
			country: "",
			userType: "Student",
			birthDay: initialDayOption,
			birthMonth: initialMonthOption,
			birthYear: new Date().getFullYear(),
		};
	}

	// Função para lidar com a alteração dos campos de entrada
	function onChange(event) {
		const { value, name } = event.target;
		setData({
			...data,
			[name]: value,
		});
	}

	// Função para lidar com a alteração do segundo campo de senha
	function onPass2Change(event) {
		setPassword2(event.target.value);
	}

	// Função para lidar com diferentes casos de erro
	function handleError(data) {
		switch (data.id) {
			case 4:
				setErrorMessage(texts.errors.emailAlreadyRegistered);
				break;
			default:
				setErrorMessage(
					texts.errors.unableToCreateAccount[0] +
						data.message +
						texts.errors.unableToCreateAccount[1]
				);
		}
	}

	// Função para redefinir o formulário e mensagens de erro/sucesso
	function handleReset() {
		// Clear text inputs and checkbox
		Array.from(document.querySelectorAll("input[type='checkbox']")).forEach(
			(input) => {
				input.checked = false;
			}
		);

		// Restarting states
		setData(initialState);
		setPassword2("");
		setAgreeTerms(false);
	}

	async function trySignUp() {
		if (!validateName(data.name)) {
			setErrorMessage(texts.errors.nameInvalid);
			setIsloading(false);
			return;
		}

		if (!validateEmail(data.email)) {
			setErrorMessage(texts.errors.emailInvalid);
			setIsloading(false);
			return;
		}

		if (password2 !== data.password) {
			setErrorMessage(texts.errors.differentPasswords);
			setIsloading(false);
			return;
		}

		if (!validatePasswordStrength(data.password)) {
			setErrorMessage(texts.errors.weakPassword);
			setIsloading(false);
			return;
		}

		if (!agreeTerms) {
			setErrorMessage(texts.errors.termsNotChecked);
			setIsloading(false);
			return;
		}

		let sendData = { ...data, gender: mapGenders[data.gender] };
		sendData.name = removeSpaceBeginEnd(sendData.name);
		sendData.lastName = removeSpaceBeginEnd(sendData.lastName);

		try {
			await register(sendData);

			setErrorMessage("");
			history.push("/accountverification");

			handleReset();
		} catch (err) {
			try {
				// Analisar os dados de erro e lidar com erros específicos
				let errorData = JSON.parse(err.request.response);
				handleError(errorData);
			} catch {
				setErrorMessage("Unknow Error... Try later");
			}
		}

		setIsloading(false);
	}

	// Função assíncrona para lidar com o cadastro do usuário
	async function handleSignUp(event) {
		event.preventDefault();

		setIsloading(true);
		trySignUp();
	}

	return (
		<EntranceEnv>
			<IconContext.Provider
				value={{
					color: theme.colors.primary,
					size: "30",
				}}
			>
				<FormBox onSubmit={handleSignUp}>
					<FormRow
						center
						noMargin
					>
						<FormImg
							src={logo}
							alt="Eagle-edu logo"
							small
						/>
					</FormRow>

					<FormRow
						center
						column
						noMargin
					>
						<FormTitle>{texts.createYourAccount}</FormTitle>
						<FormDivider />
					</FormRow>

					<FormRow>
						<TextInput
							placeholder={texts.placeholderName}
							icon="FaUser"
							name="name"
							adjust
							value={data.name}
							onChange={onChange}
							margin={"0 5px 0 0"}
						/>
						<TextInput
							placeholder={texts.placeholderLastName}
							icon="FaUser"
							name="lastName"
							adjust
							value={data.lastName}
							onChange={onChange}
						/>
					</FormRow>

					<FormRow center>
						<TextInput
							placeholder={texts.placeholderEmail}
							icon="FaEnvelope"
							name="email"
							value={data.email}
							onChange={onChange}
						/>
					</FormRow>

					<FormRow center>
						<PasswordInput
							placeholder={texts.placeholderPassword}
							icon="password"
							name="password"
							value={data.password}
							onChange={onChange}
						/>
					</FormRow>

					<FormRow center>
						<PasswordInput
							placeholder={texts.placeholderPasswordConfirmation}
							icon="password"
							name="password2"
							value={password2}
							onChange={onPass2Change}
						/>
					</FormRow>

					<FormRow>
						<FormLabel>{texts.birthday}</FormLabel>
					</FormRow>

					<FormRow>
						<SelectInput
							id="day"
							name="birthDay"
							value={data.birthDay}
							onChange={onChange}
							required
							margin={"15px 5px 0px 0px"}
						>
							{daysOptions}
						</SelectInput>
						<SelectInput
							id="month"
							name="birthMonth"
							value={data.birthMonth}
							onChange={(event) => {
								daysOptions = createSelectOptions(
									createAGrowingArray(numberOfDaysInMonths[event.target.value])
								);
								onChange(event);
							}}
							required
							margin={"15px 5px 0px 0px"}
						>
							{monthsOptions}
						</SelectInput>
						<SelectInput
							id="year"
							name="birthYear"
							value={data.birthYear}
							onChange={onChange}
							required
						>
							{yearsOptions}
						</SelectInput>
					</FormRow>

					<FormRow>
						<SelectInput
							id="Gênero"
							name="gender"
							icon="FaGenderless"
							value={data.gender}
							onChange={onChange}
							required
							margin={"15px 5px 0px 0px"}
						>
							{gendersOptions}
						</SelectInput>
						<SelectInput
							id="País"
							name="country"
							icon="FaMapMarkerAlt"
							value={data.country}
							onChange={onChange}
							required
						>
							{countriesOptions}
						</SelectInput>
					</FormRow>

					<FormRow>
						<CheckboxInput
							label={texts.acceptTerms[0]}
							adjust
							to="/terms"
							text={texts.acceptTerms[1]}
							borderless
							onChange={() => setAgreeTerms(!agreeTerms)}
						/>
					</FormRow>

					<FormRow>
						{errorMessage && (
							<ErrorMessage margin={"15px 5px 0px 0px"}>
								{errorMessage}
							</ErrorMessage>
						)}
					</FormRow>

					<FormRow center>
						<ButtonWithLoading
							width={"110px"}
							isloading={isLoading}
							ButtonTemplate={FormButton}
							size={40}
							// loaderPrimaryColor={}
							// loaderSecondaryColor={}
						>
							{texts.signup}
						</ButtonWithLoading>
					</FormRow>
				</FormBox>
			</IconContext.Provider>
		</EntranceEnv>
	);
}
