/** @format */

import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import {
	Wrapper,
	StudentDataWrapper,
	StudentProgressWrapper,
	Subtitle,
} from "./StudentPage-styled";
import photo from "../../../assets/images/defaultProfileImage2.png";
import ProgressBar from "../../common/ProgressBar/ProgressBar";
import ToggleList from "../../common/ToggleList/ToggleList";
import ItemList from "../../common/ToggleList/ItemList";

import language from "../../../services/defineLanguage.js";
import {
	getAllCourseTree,
	getCourseStudentDeepStatistics,
} from "../../../services/Instructor";
import countries from "../../../texts/en/countries";
import { InstructorContext } from "../../../contexts/InstructorContext";
const texts = require(`../../../texts/${language}/instructor/studentPage`);
const genders = require(`../../../texts/${language}/genders2`);

export default function StudentPage() {
	let { studentId } = useParams();
	const { currentCourseId } = useContext(InstructorContext);

	const [deepStatistics, setDeepStatistics] = useState({});
	const [course, setCourse] = useState({});

	useEffect(async () => {
		// setDeepStatistics()
		let deepStatisticsPromise = getCourseStudentDeepStatistics(
			currentCourseId,
			studentId
		);
		let coursePromise = getAllCourseTree(currentCourseId);
		setDeepStatistics(await deepStatisticsPromise);
		setCourse(await coursePromise);
	}, []);
	function handleStatus(status) {
		switch (status) {
			case "correct":
				return texts.correct;
			case "done":
				return texts.done;
			case "pending":
				return texts.pending;
			case "not finished":
				return texts.notFinished;
			case "not done":
				return texts.notDone;
			case "wrong":
				return texts.wrong;
			case "in progress":
				return texts.inProgress;
		}
	}

	let numberOfCompletedSubjects = null;
	let numberOfSubjects = null;
	let numberOfCompletedMissions = null;
	let numberOfMissions = null;
	let numberOfCompletedTasks = 0;
	let numberOfTasks = null;
	if (course.Subjects && deepStatistics.Users) {
		numberOfCompletedSubjects = course.Subjects.reduce(
			(numberOfCompletedSubjects, subject) => {
				let numberOfCompletedMissions = subject.Missions.reduce(
					(numberOfCompletedMissions, mission) => {
						return deepStatistics.StudentCompletedMissions.filter(
							(missionSession) => mission.id === missionSession.MissionId
						).some((missionSession) => {
							return missionSession.end != null;
						})
							? numberOfCompletedMissions + 1
							: numberOfCompletedMissions;
					},
					0
				);
				return numberOfCompletedMissions === subject.Missions.length
					? numberOfCompletedSubjects + 1
					: numberOfCompletedSubjects;
			},
			0
		);

		numberOfSubjects = course.Subjects.length;

		numberOfCompletedMissions = course.Subjects.reduce(
			(numberOfCompletedMissions, subject) => {
				return (
					numberOfCompletedMissions +
					subject.Missions.reduce((numberOfCompletedMissions, mission) => {
						let missionIsCompleted =
							deepStatistics.StudentCompletedMissions.filter(
								(missionSession) => mission.id === missionSession.MissionId
							).some((missionSession) => {
								return missionSession.end != null;
							});

						if (missionIsCompleted) {
							numberOfCompletedTasks += mission.Tasks
								? mission.Tasks.length
								: 0;
							return numberOfCompletedMissions + 1;
						} else {
							return numberOfCompletedMissions;
						}
					}, 0)
				);
			},
			0
		);

		numberOfMissions = course.Subjects.reduce((numberOfMissions, subject) => {
			return numberOfMissions + subject.Missions.length;
		}, 0);

		numberOfTasks = course.Subjects.reduce((numberOfTasks, subject) => {
			return (
				numberOfTasks +
				subject.Missions.reduce((numberOfTasks, mission) => {
					return numberOfTasks + mission.Tasks.length;
				}, 0)
			);
		}, 0);
	}

	function handlePerformance() {
		return (
			<>
				<Subtitle>{texts.subjects}</Subtitle>
				{course.Subjects && course.Subjects.length === 0 && (
					<span>{texts.noSubjects}</span>
				)}
				<ToggleList>
					{course.Subjects &&
						course.Subjects.map((subject, subjectIndex) => {
							let numberOfCompletedMissions = subject.Missions.reduce(
								(numberOfCompletedMissions, mission) => {
									return deepStatistics.StudentCompletedMissions.filter(
										(missionSession) => mission.id === missionSession.MissionId
									).some((missionSession) => {
										return missionSession.end != null;
									})
										? numberOfCompletedMissions + 1
										: numberOfCompletedMissions;
								},
								0
							);
							let status =
								numberOfCompletedMissions === 0
									? "pending"
									: numberOfCompletedMissions === subject.Missions.length
									? "done"
									: "in progress";
							return (
								<ItemList
									title={subject.title}
									key={subjectIndex}
									status={{
										code: status,
										text: handleStatus(status),
									}}
									attempts={`${numberOfCompletedMissions}/${subject.Missions.length}`}
								>
									{subject.Missions && (
										<>
											<Subtitle>{texts.missions}</Subtitle>
											{subject.Missions.length === 0 && (
												<span>{texts.noMissions}</span>
											)}
											<ToggleList>
												{subject.Missions.map((mission, missionIndex) => {
													let currentMissionSessions =
														deepStatistics.StudentCompletedMissions.filter(
															(missionSession) =>
																mission.id === missionSession.MissionId
														);
													let status = currentMissionSessions.some(
														(currentMissionSession) =>
															currentMissionSession.end != null
													)
														? "done"
														: "pending";
													return (
														<ItemList
															title={mission.title}
															level={1}
															status={{
																code: status,
																text: handleStatus(status),
															}}
															attempts={
																texts.attempts +
																": " +
																currentMissionSessions.length
															}
															key={mission.id}
														>
															{currentMissionSessions.length > 1 ? (
																<>
																	<Subtitle>{texts.attempts}</Subtitle>
																	<ToggleList>
																		{currentMissionSessions.map(
																			(missionSession, missionSessionIndex) => {
																				let status =
																					missionSession.end != null
																						? "done"
																						: "not finished";
																				return (
																					<ItemList
																						title={
																							texts.attempt +
																							" " +
																							missionSessionIndex
																						}
																						status={{
																							code: status,
																							text: handleStatus(status),
																						}}
																						level={2}
																						key={missionSessionIndex}
																					>
																						<Subtitle>{texts.tasks}</Subtitle>
																						<ToggleList>
																							{course.Subjects[subjectIndex]
																								.Missions[missionIndex]
																								.Tasks ? (
																								course.Subjects[
																									subjectIndex
																								].Missions[
																									missionIndex
																								].Tasks.map(
																									(task, taskIndex) => {
																										let currentTaskSession =
																											missionSession.StudentCompletedTasks
																												? missionSession.StudentCompletedTasks.filter(
																														(taskSession) =>
																															task.id ===
																															taskSession.TaskId
																												  )
																												: [
																														{
																															mistakes: 0,
																															end: null,
																															skips: 0,
																														},
																												  ];
																										if (
																											currentTaskSession.length >
																											0
																										) {
																											currentTaskSession =
																												currentTaskSession[0];
																										} else {
																											currentTaskSession = {
																												mistakes: 0,
																												end: null,
																												skips: 0,
																											};
																										}
																										let status =
																											currentTaskSession.pending
																												? "pending"
																												: currentTaskSession.end !==
																												  null
																												? "correct"
																												: currentTaskSession.mistakes >
																												  0
																												? "wrong"
																												: "not done";
																										return (
																											<ItemList
																												title={task.text}
																												status={{
																													code: status,
																													text: handleStatus(
																														status
																													),
																												}}
																												attempts={
																													<div
																														style={{
																															textAlign:
																																"center",
																														}}
																													>
																														{texts.attempts +
																															": " +
																															(currentTaskSession.mistakes +
																																parseInt(
																																	currentTaskSession.end !==
																																		null
																																		? 1
																																		: 0
																																))}
																														<br />
																														{texts.skips +
																															": " +
																															currentTaskSession.skips}
																													</div>
																												}
																												noToggle
																												level={3}
																												key={taskIndex}
																											/>
																										);
																									}
																								)
																							) : (
																								<></>
																							)}
																						</ToggleList>
																					</ItemList>
																				);
																			}
																		)}
																	</ToggleList>
																</>
															) : course.Subjects[subjectIndex].Missions[
																	missionIndex
															  ].Tasks ? (
																<>
																	<Subtitle>{texts.tasks}</Subtitle>
																	{course.Subjects[subjectIndex].Missions[
																		missionIndex
																	].Tasks &&
																		course.Subjects[subjectIndex].Missions[
																			missionIndex
																		].Tasks.length === 0 && (
																			<span>{texts.noTasks}</span>
																		)}
																	<ToggleList>
																		{course.Subjects[subjectIndex].Missions[
																			missionIndex
																		].Tasks ? (
																			course.Subjects[subjectIndex].Missions[
																				missionIndex
																			].Tasks.map((task, taskIndex) => {
																				let missionSession =
																					currentMissionSessions[0] || {};
																				let currentTaskSession =
																					missionSession.StudentCompletedTasks
																						? missionSession.StudentCompletedTasks.filter(
																								(taskSession) =>
																									task.id === taskSession.TaskId
																						  )
																						: [
																								{
																									mistakes: 0,
																									end: null,
																									skips: 0,
																									pending: true,
																								},
																						  ];
																				if (currentTaskSession.length > 0) {
																					currentTaskSession =
																						currentTaskSession[0];
																				} else {
																					currentTaskSession = {
																						mistakes: 0,
																						end: null,
																						skips: 0,
																						pending: true,
																					};
																				}
																				let status = currentTaskSession.pending
																					? "pending"
																					: currentTaskSession.end !== null
																					? "correct"
																					: currentTaskSession.mistakes > 0
																					? "wrong"
																					: "not done";
																				return (
																					<ItemList
																						title={task.text}
																						status={{
																							code: status,
																							text: handleStatus(status),
																						}}
																						attempts={
																							<div
																								style={{
																									textAlign: "center",
																								}}
																							>
																								{texts.attempts +
																									": " +
																									(currentTaskSession.mistakes +
																										parseInt(
																											currentTaskSession.end !==
																												null
																												? 1
																												: 0
																										))}
																								<br />
																								{texts.skips +
																									": " +
																									currentTaskSession.skips}
																							</div>
																						}
																						noToggle
																						level={3}
																						key={taskIndex}
																					/>
																				);
																			})
																		) : (
																			<></>
																		)}
																	</ToggleList>
																</>
															) : (
																<></>
															)}
														</ItemList>
													);
												})}
											</ToggleList>
										</>
									)}
								</ItemList>
							);
						})}
				</ToggleList>
			</>
		);
	}

	return (
		<InstructorStructure>
			<Wrapper>
				{deepStatistics.Users && (
					<>
						<StudentDataWrapper>
							<img
								src={
									deepStatistics.Users.pictureURL ==
										"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
									deepStatistics.Users.pictureURL?.length === 0
										? photo
										: deepStatistics.Users.pictureURL
								}
								alt="Student"
							/>
							<div>
								<h1>{`${deepStatistics.Users.name || ""} ${
									deepStatistics.Users.lastName || ""
								}`}</h1>
								<h2>{deepStatistics.Users.email || ""}</h2>
								<h2>
									{genders[deepStatistics.Users.gender] ||
										deepStatistics.Users.gender}
								</h2>
								<h2>
									{countries[deepStatistics.Users.country] ||
										deepStatistics.Users.country}
								</h2>
							</div>
						</StudentDataWrapper>
						{numberOfCompletedSubjects != null &&
							numberOfSubjects != null &&
							numberOfCompletedMissions != null &&
							numberOfMissions != null &&
							numberOfCompletedTasks != null &&
							numberOfTasks != null && (
								<StudentProgressWrapper>
									<h3>
										{texts.completedSubjects} {numberOfCompletedSubjects}/
										{numberOfSubjects}
									</h3>
									<ProgressBar
										completionRate={
											(numberOfCompletedSubjects / numberOfSubjects) * 100
										}
									/>
									<h3>
										{texts.completedMissions} {numberOfCompletedMissions}/
										{numberOfMissions}
									</h3>
									<ProgressBar
										completionRate={
											(numberOfCompletedMissions / numberOfMissions) * 100
										}
									/>
									<h3>
										{texts.completedTasks} {numberOfCompletedTasks}/
										{numberOfTasks}
									</h3>
									<ProgressBar
										completionRate={
											(numberOfCompletedTasks / numberOfTasks) * 100
										}
									/>
								</StudentProgressWrapper>
							)}
						{handlePerformance()}
					</>
				)}
			</Wrapper>
		</InstructorStructure>
	);
}
