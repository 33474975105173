/** @format */

import { useEffect, useState } from "react";
import { EagleContext } from "../../contexts/EagleContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import Loader from "./Loader/Loader";
import { InstructorContext } from "../../contexts/InstructorContext";

export default function EntraceAccessManager({ children }) {
	const history = useHistory();
	const { isLoadingSystem, user, userType } = useContext(EagleContext);
	const { user: instructor } = useContext(InstructorContext);

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (user.id && user.verified) {
			history.push("/profile");
		} else if (instructor.id && instructor.verified) {
			history.push("/instructor");
		} else {
			setLoading(isLoadingSystem || false);
		}
	}, [isLoadingSystem]);

	return loading ? <Loader /> : <>{children}</>;
}
