/** @format */

import React, { useState, useEffect } from "react";
import loaderPic from "../../../assets/images/logo.svg";
//import { LoaderWrapper, LoaderBox, LoaderPic, Circular, Path } from '../Loader/loader-styled';
import {
	LoaderWrapper,
	LoaderBox,
	LoaderPic,
	Circular,
	Path,
	LoaderPicBox,
	LoaderText,
	LoaderContainer2,
} from "../LoadingIcon/LoadingIcon-styled";

function LoadingIcon({ loadingText,scale, wrapperStyle }) {
	return (
		<LoaderWrapper style={wrapperStyle}>
			<LoaderBox scale={scale}>
				<Circular viewBox="25 25 50 50">
					<Path
						className="path"
						cx="50"
						cy="50"
						r="21"
						fill="none"
						strokeWidth="3"
						strokeMiterlimit="10"
					/>
				</Circular>
				<LoaderPicBox>
					<LoaderPic
						src={loaderPic}
						alt=""
					/>
				</LoaderPicBox>
			</LoaderBox>
			<LoaderText scale={scale}>
				{loadingText || "Loading..."}
			</LoaderText>
		</LoaderWrapper>
	);
}

export default LoadingIcon;
