/** @format */
import { Wrapper, EmojiImage, Description } from "../Message-styled";
import StatusMessage from "../StatusMessage/StatusMessage";

import welcomeEmojiEn from "../../../../assets/images/emojis/welcomeen.svg";
import welcomeEmojiPtMale from "../../../../assets/images/emojis/welcomeptm.svg";
import welcomeEmojiPtFemale from "../../../../assets/images/emojis/welcomeptf.svg";

import language from "../../../../services/defineLanguage";

const messagesTexts = require(`../../../../texts/${language}/messages`);

const emojis = {
	en: {
		Male: welcomeEmojiEn,
		Female: welcomeEmojiEn,
		Other: welcomeEmojiEn,
		Uninformed: welcomeEmojiEn,
	},
	pt: {
		Male: welcomeEmojiPtMale,
		Female: welcomeEmojiPtFemale,
		Other: welcomeEmojiPtMale,
		Uninformed: welcomeEmojiPtMale,
	},
};

function WelcomeContent({ extraData }) {
	console.log(emojis[language][extraData.user.gender]);
	return (
		<Wrapper>
			<EmojiImage src={emojis[language][extraData.user.gender]} />
			<Description>
				{messagesTexts.hello} {extraData.user.name}, {messagesTexts.welcome}
			</Description>
		</Wrapper>
	);
}

export default {
	content: WelcomeContent,
	title: messagesTexts.titleWelcome,
};
