/** @format */

import { Photo } from "../../GenericComponents";

import {
	Container,
	Profile,
	Name,
	Number,
	StatusWrapper,
} from "./FriendRow-styled";

import photo from "../../../assets/images/defaultProfileImage2.png";

import { useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";

export default function FriendRow({ name, points, achievements, economy, profilePic }) {
	const { getCurrentCourseSetting } = useContext(EagleContext);
	return (
		<Container>
			<Profile>
				<Photo src={profilePic || photo} />
				<Name
					href="/"
					title={name}
				>
					{name}
				</Name>
			</Profile>
			<StatusWrapper>
				{getCurrentCourseSetting().points && <Number>{points}</Number>}
				{getCurrentCourseSetting().acknowledgment && (
					<Number>{achievements}</Number>
				)}
				{getCurrentCourseSetting().economy && <Number>{economy}</Number>}
			</StatusWrapper>
		</Container>
	);
}
