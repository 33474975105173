/** @format */

module.exports = {
	back: "Back",
	editSubject: "Edit subject",
	missions: "Missions",
	newMission: "New mission",
	createNewMission: "Create new mission",
	edit: "Edit",
	sm: "SM",
	delete: "Delete",
	editMission: "Edit mission",
	confirmDelete: "Do you really want to delete this subject?",
	confirmDeleteMission: "Do you really want to delete this mission?",
	supportMaterials: "Support materials",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
