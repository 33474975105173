/** @format */

import { useEffect, useContext } from "react";
import {
	RankingPositionWrapper,
	ProfilePic,
	RankingName,
} from "./ranking-position-styling";
import defaultProfilePic from "../../../../../assets/images/defaultProfileImage2.png";
import { EagleContext } from "../../../../../contexts/EagleContext";
import { Photo } from "../../../../GenericComponents";

const RankingPositionCard = ({
	position,
	profilePic,
	name,
	score,
	mainUser,
}) => {
	const { getCurrentCourseSetting } = useContext(EagleContext);

	useEffect(() => {
		try {
		} catch (error) {
			console.error("");
		}
	});

	return (
		<RankingPositionWrapper mainUser={mainUser}>
			<span>{position}</span>
			<Photo
				size={40}
				src={profilePic || defaultProfilePic}
				alt={"Profile Pic"}
			/>
			<RankingName>{name}</RankingName>
			<span>{getCurrentCourseSetting().points ? score : ""}</span>
		</RankingPositionWrapper>
	);
};

export default RankingPositionCard;
