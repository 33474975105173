import styled from "styled-components"
import { Link } from "react-router-dom"
import { Container } from "../../../globalStyles"

export const Navbar = styled.nav`
	position: sticky;

	width: 100%;
	font-weight: 700;
	font-size: 1.2rem;
	color: white;
	background: transparent;
	z-index: 999;
	background: transparent;
`

export const NavbarContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;

	${Container}
`

export const NavLogo = styled(Link)`
	color: #fff;
	justify-self: flex-start;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	display: flex;
	align-items: center;
`