/** @format */

import { Title, H2, Divider } from "../../GenericComponents";
import {
	Container,
	Header,
	Content,
	StatusWrapper,
} from "../FriendsList/FriendsList-styled";

import achievements from "../../../assets/images/achievements.svg";
import coin from "../../../assets/images/coin.svg";
import points from "../../../assets/images/point.svg";
import { useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";

import language from "../../../services/defineLanguage";
const texts = require(`../../../texts/${language}/friendsList`);

export default function FriendsList({ children, id }) {
	const { getCurrentCourseSetting } = useContext(EagleContext);
	return (
		<Container>
			<Title>{texts.following}</Title>
			<Header>
				<H2>{texts.name}</H2>
				<StatusWrapper>
					{getCurrentCourseSetting().points && (
						<img
							src={points}
							alt="Following points"
						/>
					)}
					{getCurrentCourseSetting().acknowledgment && (
						<img
							src={achievements}
							alt="Following achievements"
						/>
					)}
					{getCurrentCourseSetting().economy && (
						<img
							src={coin}
							alt="Following coins"
						/>
					)}
				</StatusWrapper>
			</Header>
			<Divider />
			<Content id={id}>{children}</Content>
		</Container>
	);
}
