/** @format */

module.exports = {
	accountVerificationTitle: "Account verification",
	verifyingWaitASecond: "We are verifying your account. Wait a few moments.",
	accountVerified1: "Account verified successfully! ",
	accountVerified2: ".",
	login: "Log in",
	accountNotVerified: "Unable to verify your account!",
	errors: {
		"Code timeout exceeded":
			"Token timeout exceeded. We sent a new token to your email.",
		"Invalid Token": "Invalid Token.",
	},

	description: "An email with the verification code has been sent to ",
	"case_1.1": "- If you haven't received it, click ",
	"case_1.2": "here",
	"case_1.3": " for us to send you a new code;",
	case_2:
		"- If your account is linked to any organization, contact the person responsible for your organization;",
	case_3: "- If you are having other access issues, please contact us at ",
};
