import coverImageTeste from '../../../assets/images/cover_image_teste.jpg'
import { CoverImage, CameraBox, Camera } from './CoverBox-styled'

export default function CoverBox(props) {
	return (
		<CoverImage>
			<img src={coverImageTeste} alt="" />
			{/* <CameraBox>
				<Camera />
			</CameraBox> */}
		</CoverImage>
	)
}
