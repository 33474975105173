module.exports = [
    {type: 1, message: 'Você tem uma nova conquista!'},
    {type: 2, message: 'Você ganhou pontos!'},
    {type: 3, message: 'Você ganhou moedas!'},
    {type: 4, message: 'Você subiu de nível!'},
    {type: 5, message: 'Seu time completou todas as missões da semana!'},
    {type: 11, message: 'Seu colega acabou de terminar 10 atividades. Não fique para trás ...'},
    {type: 12, message: 'Seu time está te cutucando. Acabe suas missões semanais!'},
    {type: 21, message: 'Seja bem-vindo ao Eagle-edu! Veja os cursos que você está matriculado ao lado esquerdo e comece agora mesmo a praticar e se divertir conosco.'},
    {type: 22, message: 'Você foi adicionada a um novo time!'},
    {type: 23, message: 'Seja bem-vindo de volta!'},
]