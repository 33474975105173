/** @format */

module.exports = {
	courseName: "Course name",
	courseDescription: "Course description",
	typeHere: "Type here...",
	create: "Create",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
