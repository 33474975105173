import {
	FaEnvelope,
	FaUser,
	FaGenderless,
	FaMapMarkerAlt,
	FaFile,
} from 'react-icons/fa'

const iconNameMap = new Map([
	['email', <FaEnvelope />],
	['name', <FaUser />],
	['gender', <FaGenderless />],
	['country', <FaMapMarkerAlt />],
	['file', <FaFile />],
])

export default iconNameMap
