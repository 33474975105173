/** @format */
import styled from "styled-components";

// Definição dos estilos usando a biblioteca styled-components

// Define o estilo do contêiner que cobre a página inteira e serve de fundo para o ícone de carregamento
export const LoaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%; /* Altura máxima da viewport */
	width: 100%;
	background: transparent;
	margin: 50px 0;
`;

// Define o estilo do contêiner que envolve o ícone de carregamento e o texto
export const LoaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

// Define o estilo do contêiner que envolve o ícone de carregamento
export const LoaderBox = styled.div`
	width: 5rem;
	height: 5rem;
	display: flex;
    transform:scale(${props => (props.scale ? props.scale  : 1)});
`;

// Define o estilo da imagem do ícone de carregamento
export const LoaderPicBox = styled.div`
	width: 0;
	height: 0;
	position: relative;
	top: 14px;
	right: 64px;
`;
export const LoaderPic = styled.img``;

// Define o estilo do SVG circular que forma o ícone de carregamento
export const Circular = styled.svg`
	height: 100%;
	transform-origin: center center;
	width: 100%;
	transform: rotate(-90deg);
`;

// Define o estilo do círculo que é animado para criar o efeito de carregamento
export const Path = styled.circle`

    stroke-dasharray: 0, 200;
    stroke-width: 1.5;
    stroke-dashoffset: 0;
    animation: dash 1.5s linear infinite, color 6s ease infinite;
    stroke-linecap: round;

    @keyframes dash {
        0% {
            stroke-dasharray: 0, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 126, 200;
            stroke-dashoffset: 0;
        }
        100% {
            stroke-dasharray 126, 200;
            stroke-dashoffset: -7.9rem;
        }
    }
    @keyframes color {
        100%,
        0% {
            stroke: #d62d20;
        }
        40% {
            stroke: #0057e7;
        }
        66% {
            stroke: #008744;
        }
        80%,
        90% {
            stroke: #ffa700;
        }
    }
`;

export const LoaderText = styled.p`
	color: grey;
    margin-top: ${props => (props.scale ? 0.2*props.scale*3  : 0.1)}rem;
	font-size: ${props => (props.scale ? 1.1*props.scale  : 1.1)}rem;
	text-align: center;
`;
