/** @format */

import { useState, useEffect } from "react";
import { ItemWrapper, FaChevron } from "./ToggleList-styled";

export default function ItemList({
	title,
	children,
	style,
	noToggle,
	level,
	setSelectedItem,
	selectedItem,
	itemId,
	status,
	attempts,
	callback = () => {},
}) {
	const [open, setOpen] = useState(false);

	function handleOnClick() {
		if (!noToggle) {
			if (setSelectedItem) {
				setSelectedItem(() => itemId);
			}
			if (open === false) {
				callback();
			}
			setOpen((prev) => !prev);
		}
	}

	useEffect(() => {
		if (selectedItem === itemId) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [selectedItem]);

	return (
		<li>
			<ItemWrapper
				level={level}
				style={style}
				status={status && status.code}
				onClick={handleOnClick}
			>
				<h3>{title}</h3>
				<div>
					{status && <p>{status.text}</p>}
					<p>{attempts}</p>
				</div>
				{!noToggle && <FaChevron open={open} />}
			</ItemWrapper>
			{open && children}
		</li>
	);
}
