import Carousel from './Carousel'

export default function CarouselImages({
    children,
    header,
    footer,
    elementsDescription,
    handleNext,
    handlePrev
}) {
    return (
        <Carousel
            img={true}
            header={header}
            footer={footer}
            elementsDescription={elementsDescription}
            handleNext={handleNext}
            handlePrev={handlePrev}
        >
            {children}
        </Carousel>
    )
}
