import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 1000px;
    height: 550px;
    max-height: 80%;
    margin: auto auto auto auto;

    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.white};

    @media screen and (max-width: 1440px) {
        width: 800px;
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        margin: 50px 10px auto 10px;
    }

    @media screen and (max-width: 880px) {
        flex-direction: column;
        height: auto;
        max-height: 100%;
        margin: 30px 5px auto 5px;
    }
`

export const SettingsOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 200px;
    min-width: 200px;
    height: 100%;

    border-radius: 10px 0 0 10px;
    border: 1px solid transparent;
    border-right-color: ${(props) => props.theme.colors.gray};
    background-color: ${(props) => props.theme.colors.white};

    @media screen and (max-width: 880px) {
        max-width: 100%;
        min-width: 100%;
        height: auto;
        border-radius: 10px 10px 0 0;
        border-right-color: transparent;
        border-bottom-color: ${(props) => props.theme.colors.gray};
    }
`
