/** @format */

import Background from "../../../environments/entrance/Background";
import Footer from "../../../environments/entrance/Footer";
import Header from "../../../environments/entrance/Header";
import EntraceAccessManager from "../../../common/EntraceAccessManager";
import {
	ButtonPasswordRecovery,
	Title,
	TitleContainer,
	VerificationContentBox,
} from "./FinalizeRegistration-styled";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	removeSpaceBeginEnd,
	validateName,
	validatePasswordStrength,
} from "../../../../services/handleCredentials";
import { ErrorMessage } from "../../../forms/FormsMessages";

import language from "../../../../services/defineLanguage";
import { FormLabel, FormRow } from "../../../forms/FormsElements";
import TextInput from "../../../forms/input/TextInput";
import PasswordInput from "../../../forms/input/PasswordInput";
import SelectInput from "../../../forms/input/SelectInput/SelectInput";
import CheckboxInput from "../../../forms/input/CheckboxInput";

import { createSelectOptions, createSelectOptionsFromJson } from "../../../../services/createSelectOptions";
import { createAGrowingArray } from "../../../../services/utils";
import {
	finalizeRegistration,
	startFinalizeRegistration,
} from "../../../../services/User";
import LocalLoader from "../../../common/LocalLoader/LocalLoader";
import { Link } from "react-router-dom";

const textsSignup = require(`../../../../texts/${language}/signup`);
const texts = require(`../../../../texts/${language}/finalizeRegistration.js`);

const genders = require(`../../../../texts/${language}/genders`);
const mapGenders = require(`../../../../texts/en/genders`);
const countries = require(`../../../../texts/${language}/countries`);
const months = require(`../../../../texts/${language}/months`);
const numberOfDaysInMonths = require("../../../../constants/numberOfDaysInMonths");

//Init Values

const initialMonthOption = 0;
const initialDayOption = 1;

let gendersOptions = createSelectOptions(genders, textsSignup.gender, true, 0);
let countriesOptions = createSelectOptionsFromJson(countries);
let daysOptions = createSelectOptions(
	createAGrowingArray(numberOfDaysInMonths[initialMonthOption])
);
let monthsOptions = createSelectOptions(months, "", true);
let yearsOptions = createSelectOptions(
	createAGrowingArray(new Date().getFullYear() - 1919, 1920).reverse()
);

function FinalizeRegistrationEditor({ children }) {
	const [data, setData] = useState(initialState);
	const [agreeTerms, setAgreeTerms] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [isLoading, setIsLoading] = useState(true);
	const [tryFinalizeRegistrationResponse, setTryFinalizeRegistrationResponse] =
		useState({});

	const { code } = useParams();

	function initialState() {
		return {
			name: "",
			lastName: "",
			password: "",
			confirmPassword: "",
			gender: "",
			country: "",
			userType: "Student",
			birthDay: initialDayOption,
			birthMonth: initialMonthOption,
			birthYear: new Date().getFullYear(),
		};
	}

	function onChange(event) {
		const { value, name } = event.target;
		setData({
			...data,
			[name]: value,
		});
	}

	async function handleSubmit(event) {
		event.preventDefault();

		if (!validateName(data.name)) {
			setErrorMessage(textsSignup.errors.nameInvalid);
			return;
		}

		if (data.confirmPassword !== data.password) {
			setErrorMessage(textsSignup.errors.differentPasswords);
			return;
		}

		if (!validatePasswordStrength(data.password)) {
			setErrorMessage(textsSignup.errors.weakPassword);
			return;
		}

		if (!agreeTerms) {
			setErrorMessage(textsSignup.errors.termsNotChecked);
			return;
		}

		let sendData = { ...data, gender: mapGenders[data.gender] };
		sendData.name = removeSpaceBeginEnd(sendData.name);
		sendData.lastName = removeSpaceBeginEnd(sendData.lastName);

		requestFinalizeRegistration(sendData);
		setIsLoading(true);
	}

	async function requestFinalizeRegistration({
		name,
		lastName,
		birthDay,
		birthMonth,
		birthYear,
		gender,
		country,
		password,
	}) {
		let response = await finalizeRegistration(
			code,
			name,
			lastName,
			birthDay,
			birthMonth,
			birthYear,
			gender,
			country,
			password
		);

		setTryFinalizeRegistrationResponse({
			...response,
			allowSuccessMessage: true,
		});
		setIsLoading(false);
	}

	useEffect(async () => {
		let response = await startFinalizeRegistration(code);
		setTryFinalizeRegistrationResponse(response);
		if (response.success) {
			setData((prev) => {
				return {
					...prev,
					name: response.user.name,
					lastName: response.user.lastName,
				};
			});
		}
		setIsLoading(false);
	}, []);
	console.log(tryFinalizeRegistrationResponse);
	return (
		<Background>
			<EntraceAccessManager>
				<Header />
				{children}

				<VerificationContentBox
					as={"form"}
					onSubmit={handleSubmit}
				>
					<TitleContainer>
						<Title>{texts.title}</Title>
					</TitleContainer>
					{isLoading ||
					tryFinalizeRegistrationResponse?.success === false ||
					tryFinalizeRegistrationResponse?.allowSuccessMessage ? (
						<LocalLoader
							isLoading={isLoading}
							wasSuccessful={tryFinalizeRegistrationResponse?.success}
							message={
								isLoading ? undefined : tryFinalizeRegistrationResponse?.success ===
								  false ? (
									texts.errors[tryFinalizeRegistrationResponse?.message]
								) : (
									<p>
										{texts.registrationFinished1}
										<Link to="/signin">{texts.login}</Link>
										{texts.registrationFinished2}
										<b>{texts.registrationFinished3}</b>
									</p>
								)
							}
							scale={1.5}
						/>
					) : (
						<>
							<FormRow>
								<TextInput
									placeholder={textsSignup.placeholderName}
									icon="FaUser"
									name="name"
									value={data.name}
									onChange={onChange}
									margin={"0 5px 0 0"}
								/>
								<TextInput
									placeholder={textsSignup.placeholderLastName}
									icon="FaUser"
									name="lastName"
									value={data.lastName}
									onChange={onChange}
								/>
							</FormRow>

							<FormRow center>
								<PasswordInput
									placeholder={textsSignup.placeholderPassword}
									icon="password"
									name="password"
									value={data.password}
									onChange={onChange}
								/>
							</FormRow>

							<FormRow center>
								<PasswordInput
									placeholder={textsSignup.placeholderPasswordConfirmation}
									icon="password"
									name="confirmPassword"
									value={data.confirmPassword}
									onChange={onChange}
								/>
							</FormRow>

							<FormRow>
								<FormLabel>{textsSignup.birthday}</FormLabel>
							</FormRow>

							<FormRow>
								<SelectInput
									id="day"
									name="birthDay"
									value={data.birthDay}
									onChange={onChange}
									required
								>
									{daysOptions}
								</SelectInput>
								<SelectInput
									id="month"
									name="birthMonth"
									value={data.birthMonth}
									onChange={(event) => {
										daysOptions = createSelectOptions(
											createAGrowingArray(
												numberOfDaysInMonths[event.target.value]
											)
										);
										onChange(event);
									}}
									required
								>
									{monthsOptions}
								</SelectInput>
								<SelectInput
									id="year"
									name="birthYear"
									value={data.birthYear}
									onChange={onChange}
									required
								>
									{yearsOptions}
								</SelectInput>
							</FormRow>

							<FormRow>
								<SelectInput
									id="Gênero"
									name="gender"
									icon="FaGenderless"
									value={data.gender}
									onChange={onChange}
									required
								>
									{gendersOptions}
								</SelectInput>
								<SelectInput
									id="País"
									name="country"
									icon="FaMapMarkerAlt"
									value={data.country}
									onChange={onChange}
									required
								>
									{countriesOptions}
								</SelectInput>
							</FormRow>

							<FormRow>
								<CheckboxInput
									label={textsSignup.acceptTerms[0]}
									adjust
									to="/terms"
									text={textsSignup.acceptTerms[1]}
									borderless
									onChange={() => setAgreeTerms(!agreeTerms)}
								/>
							</FormRow>

							{ errorMessage && <ErrorMessage margin={"15px 0 0 0"}>{errorMessage}</ErrorMessage>}
							<ButtonPasswordRecovery>{texts.finish}</ButtonPasswordRecovery>
						</>
					)}
				</VerificationContentBox>
				<Footer />
			</EntraceAccessManager>
		</Background>
	);
}

export default FinalizeRegistrationEditor;
