/** @format */

module.exports = {
	typeHere: "Digite aqui...",
	save: "Salvar",
	delete: "Excluir assunto",
	confirmDelete: "Deseja realmente excluir este assunto?",
	title: "Titulo",
	description: "Descrição",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
