/** @format */

import {
	Container,
	Title,
	ContentContainer,
	MainContent,
} from "./SettingsContent-styled";
import { Button } from "../../../GenericComponents";

import language from "../../../../services/defineLanguage";
import { ButtonWithLoading } from "../../../common/ButtonWithLoading/ButtonWithLoading";
import { ErrorMessage, SuccessMessage } from "../../../forms/FormsMessages";
const texts = require(`../../../../texts/${language}/settingsContent`);

export default function SettingsContent({
	title,
	children,
	onSubmit,
	buttonText,
	isLoading,
	errorMessage,
    successMessage
}) {
	return (
		<Container onSubmit={onSubmit}>
			<Title>{title}</Title>
			<ContentContainer>
				<MainContent>{children}</MainContent>
				{errorMessage && (
					<ErrorMessage margin="5px 0 0 0">{errorMessage}</ErrorMessage>
				)}
                {successMessage && (
					<SuccessMessage margin="5px 0 0 0">{successMessage}</SuccessMessage>
				)}
				<ButtonWithLoading
					isloading={isLoading}
					margin="5px 0 0 0"
				>
					{buttonText || texts.save}
				</ButtonWithLoading>
			</ContentContainer>
		</Container>
	);
}
