module.exports = {
    createYourAccount:"Crie sua conta",
    placeholderName:"Nome",
    placeholderLastName:"Sobrenome",
    placeholderEmail:"E-mail",
    placeholderPassword:"Digite a sua senha",
    placeholderPasswordConfirmation:"Confirme a sua senha",
    birthday:"Data de nascimento",
    gender:"Gênero",
    country:"Pais",
    acceptTerms:[ "Eu concordo com os ", "Termos & Condições"],
    signup:"Cadastrar",
    successMessage:"Conta criada com sucesso! Enviamos um e-mail para verificar sua conta.",

    errors:{
        
        nameInvalid:"Nome e sobrenome não podem conter números ou caracteres especiais e nem ultrapassar 100 caracteres.",
        emailInvalid:"O e-mail inserido não é válido!",
        differentPasswords:"As senhas inseridas não coincidem!",
        weakPassword:"A senha deve ter pelo menos 8 caracteres, incluindo letras maiúsculas e minúsculas, números e caracteres especiais (! @ # ? $ *).",
        termsNotChecked:"Você deve concordar com os Termos & Condições para se cadastrar",

        
        emailAlreadyRegistered:"O email inserido já está cadastrado.",
        unableToCreateAccount:[ "Não foi possível criar a conta (", ")."]
        
    }
}