/** @format */
import { Wrapper, Description } from "../Message-styled";

import language from "../../../../services/defineLanguage";
import { FormButton } from "../../../forms/FormsElements";

const messagesTexts = require(`../../../../texts/${language}/messages`);



function UpgradeInstructorSubscriptionPlan({}) {
	return (
		<Wrapper>
			<Description style={{ margin: "20px 10px" }}>
				{messagesTexts.upgradeInstructorSubscriptionPlan_desc}
			</Description>
			<FormButton
				as={"a"}
				target={"_blank"}
				href={"https://eagle-edu.com.br/#plans"}
			>
				{messagesTexts.upgradeNow}
			</FormButton>
		</Wrapper>
	);
}

export default {
	content: UpgradeInstructorSubscriptionPlan,
	title: messagesTexts.upgradeInstructorSubscriptionPlan_title,
};
