/** @format */

import styled from "styled-components";
import { Button } from "../../GenericComponents";

export const DashboardContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	background-color: ${(props) => props.theme.colors.gray};
`;

export const CourseTitle = styled.h1`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	color: ${(props) => props.theme.colors.primary};
	display: flex;
	align-items: center;
	justify-content: center;
	${"" /* width: 100%; */}

	margin: 1rem;

	p {
		margin-left: 0.5rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	@media screen and (max-width: 300px) {
		p {
			margin-left: 0;
		}

		svg {
			display: none;
		}
	}
`;

export const CourseData = styled.h2`
	display: flex;
	align-items: center;
	justify-content: center;

	color: ${(props) => props.theme.colors.primary};
	font-size: 1.1rem;
	font-weight: 600;
	margin-bottom: 5px;

	@media screen and (max-width: 768px) {
		text-align: center;
	}
`;

export const CourseButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const CourseButton = styled.button`
	// width: 110px;
	margin-left: 9px;
	border: none;

	font-size: 1.25rem;
	font-weight: 500;

	cursor: pointer;
	color: ${(props) => props.theme.colors.darkGray};
	background: transparent;
`;

export const CloneModalText = styled.p`
	font-size: 1.2rem;
`;

export const CloneModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;
	margin: 10px 0;
`;

export const CloneModalButtonsWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const CancelButton = styled(Button)`
	margin: 0 10px 5px 0;

	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.darkGray};
`;
