/** @format */

import { useState, useEffect, createContext, useRef } from "react";
import api from "../services/api";
import Cookies from "js-cookie";
import Message from "../components/common/Message/Message";
import Chance from "../components/common/Chance/Chance";
// import { validatePasswordStrength } from "../services/handleCredentials";
import { useLocation, useHistory } from "react-router-dom";
import { getLastAccess, getUser } from "../services/User";

export const EagleContext = createContext({});

export function EagleProvider({ children }) {
	const [userType, setUserType] = useState(null);
	const [isLoadingSystem, setIsLoadingSystem] = useState(true);
	const [isLoadingCourses, setIsLoadingCourses] = useState(true);
	const [user, setUser] = useState({});
	const [currentCourse, setCurrentCourse] = useState(null);
	const [messages, setMessages] = useState([]); // Estado de notificações
	const [instantMessages, setInstantMessages] = useState([]); // Estado de notificações
	const [lastInstantMessageData, setLastInstantMessageData] = useState(null);
	const [allMessagesRequested, setAllMessagesRequested] = useState(false);
	const [updateCounter, setUpdateCounter] = useState(0);
	const [isLoadingCourse, setIsLoadingCourse] = useState(true);
	const [currentSession, setCurrentSession] = useState(null);
	const [chanceWasRequested, setChanceWasRequested] = useState(false);
	const [wasImposedChoiceAccepted, setWasImposedChoiceAccepted] =
		useState(false);
	const [requestRewardAchievements, setRequestRewardAchievements] =
		useState(null);
	const [currentMissionSession, setCurrentMissionSession] = useState(null);
	const [sessionUpdates, setSessionUpdates] = useState({
		profile: {
			access: 0,
			click: 0,
		},
		store: {
			access: 0,
			click: 0,
		},
		friends: {
			access: 0,
			click: 0,
		},
		course: {
			access: 0,
			click: 0,
		},
		subject: {
			access: 0,
			click: 0,
		},
		mission: {
			access: 0,
			click: 0,
		},
	});
	const [instructor, setInstructor] = useState(null);

	const interval = useRef();
	let history = useHistory();
	let { pathname } = useLocation();

	function resetSessionUpdates() {
		sessionUpdates.profile.access = 0;
		sessionUpdates.profile.click = 0;
		sessionUpdates.store.access = 0;
		sessionUpdates.store.click = 0;
		sessionUpdates.friends.access = 0;
		sessionUpdates.friends.click = 0;
		sessionUpdates.course.access = 0;
		sessionUpdates.course.click = 0;
		sessionUpdates.subject.access = 0;
		sessionUpdates.subject.click = 0;
		sessionUpdates.mission.access = 0;
		sessionUpdates.mission.click = 0;
	}

	function verifySessionUpdates() {
		for (var pageName in sessionUpdates) {
			var pageUpdates = sessionUpdates[pageName];
			for (var updateType in pageUpdates) {
				if (pageUpdates[updateType] > 0) {
					return true;
				}
			}
		}
		return false;
	}

	useEffect(async () => {
		await loadSessionUser();
		interval.current = setInterval(() => {
			setUpdateCounter((last) => last + 1);
		}, 10000);
		setIsLoadingSystem(false);
		window.addEventListener("click", function (event) {
			let page = window.location.pathname.substring(1);
			let pageEndIndex = page.indexOf("/");
			if (pageEndIndex > 0) {
				page = page.substring(0, pageEndIndex);
			}
			incrementSessionUpdates(page, "click");
		});
	}, []);

	useEffect(async () => {
		let page = pathname.substring(1);
		let pageEndIndex = page.indexOf("/");
		if (pageEndIndex > 0) {
			page = page.substring(0, page.indexOf("/", 1));
		}

		if (page !== "course") incrementSessionUpdates(page, "access");
	}, [pathname]);

	function incrementSessionUpdates(page, type) {
		if (sessionUpdates[page]) {
			sessionUpdates[page][type] += 1;
		}
	}

	useEffect(async () => {
		// console.log("New user:");
		// console.log(user);
		if (user.id != undefined) {
			try {
				if (user.courses == undefined) await getUserCourses();
			} catch (err) {}
		}
	}, [user]);

	useEffect(async () => {
		// console.log(`New Current Course:`);
		// console.log(currentCourse);
		if (user.id != undefined && currentCourse != null) {
			try {
				await finishingCurrentStudentCourseAccess();
				await startingCurrentStudentCourseAccess(getCurrentCourse().id);
				requestInstantMessage();
				requestXNormalMessages(10);
				loadCourse(currentCourse);
			} catch (err) {
				console.log(err);
			}
		}
	}, [currentCourse]);

	useEffect(async () => {
		// console.log(`updateCounter: ${updateCounter}`);
		if (updateCounter != 0) {
			if (
				user.id != undefined &&
				instantMessages.length == 0 &&
				currentCourse != null
			) {
				await requestNewMessages();
			}
			updatingCurrentStudentCourseAccess();
		}
	}, [updateCounter]);

	useEffect(async () => {
		// console.log(`updateRequestRewardAchievementsr: ${updateCounter}`);
		if (requestRewardAchievements !== null) {
			rewardAchievements();
			setRequestRewardAchievements(null);
		}
	}, [requestRewardAchievements]);

	async function verifyWelcomesOnLogin() {
		let lastAccess = await getLastAccess();

		if (lastAccess.length === 1) {
			insertFakeInstantMessage(21);
		} else if (
			new Date(lastAccess[0]?.createdAt).getTime() -
				new Date(lastAccess[1]?.createdAt).getTime() >=
			2592000000
		) {
			insertFakeInstantMessage(23);
		}
	}

	async function verifyWelcomeBack() {
		let lastAccess = await getLastAccess();
		console.log(lastAccess);
		if (
			lastAccess?.length > 1 &&
			new Date(lastAccess[0]?.createdAt).getTime() -
				new Date(lastAccess[1]?.createdAt).getTime() >=
				2592000000
		) {
			insertFakeInstantMessage(23);
		}
	}

	async function loadSessionUser() {
		let sessionUser = await getUser();
		setUserType(sessionUser.userType);
		if (sessionUser) {
			if (sessionUser.userType == "Student") {
				setUser(sessionUser);
				verifyWelcomeBack();
			} else {
				setInstructor(sessionUser);
			}
		}
	}

	async function getUserCourses() {
		setIsLoadingCourses(true);
		let response = await api.get(`/student/course/`);
		setUser((oldUser) => {
			let newUser = { ...oldUser };
			newUser.courses = response.data.data;
			if (newUser.courses.length) {
				setCurrentCourse(newUser.courses[0].courseInfo.id);
			}
			return newUser;
		});
		setIsLoadingCourses(false);
	}

	async function login(user) {
		setUser(user);
		setUserType(user.userType);
		verifyWelcomesOnLogin();

		return true;
	}

	async function logout() {
		// if (Cookies.get("session")) {
		try {
			await api.post("/users/logout");
			Cookies.remove("session");
		} catch (error) {}
		setUser({});
		finishingCurrentStudentCourseAccess();
		setCurrentCourse(null);
		// }
	}

	async function finishingCurrentStudentCourseAccess() {
		updatingCurrentStudentCourseAccess();
		if (currentSession !== null) {
			try {
				const res = await api.post(
					"/student/course/finish-session/",
					currentSession
				);
			} catch (err) {
				console.log("Finishing Current Student Course Access error!");
			}
			setCurrentSession(null);
		}
	}

	async function startingCurrentStudentCourseAccess(studentCourseId) {
		updatingCurrentStudentCourseAccess();
		try {
			const res = await api.post("/student/course/start-session/", {
				studentCourseId,
			});
			if (res.data && res.data.data && res.data.data.id) {
				setCurrentSession(res.data.data);
				let page = pathname.substring(1);
				let pageEndIndex = page.indexOf("/");
				if (pageEndIndex > 0) {
					page = page.substring(0, page.indexOf("/", 1));
				}

				if (page === "course") incrementSessionUpdates("course", "access");
			}
		} catch (err) {
			console.log("Starting Current Student Course Access error!");
		}
	}

	async function updatingCurrentStudentCourseAccess() {
		if (currentSession === null || !verifySessionUpdates()) return;

		try {
			const res = await api.patch("/student/course/session/", {
				studentSessionId: currentSession.id,
				updates: sessionUpdates,
			});

			resetSessionUpdates();
		} catch (err) {}
	}

	async function register(registerData) {
		let res = await api.post("/users", registerData);
		setUser(res.data.data);
	}

	async function requestInstantMessage() {
		let course =
			user.courses[
				user.courses.findIndex((course) => {
					return course.courseInfo.id == currentCourse;
				})
			];
		if (course) {
			let studentCourseId = course.id;
			let res = await api.get(`/student/course/${studentCourseId}/message/`, {
				params: { instant: true, checked: false },
			});
			if (res.data && res.data.data) {
				let instantMessages = res.data.data;
				if (instantMessages.length) {
					setLastInstantMessageData(instantMessages[0].createdAt);
					setInstantMessages(instantMessages);
					return true;
				}
			}
		}
	}

	async function requestXNormalMessages(numberOfMessages) {
		let course =
			user.courses[
				user.courses.findIndex((course) => {
					return course.courseInfo.id == currentCourse;
				})
			];
		if (course) {
			let studentCourseId = course.id;

			let res = await api.get(`/student/course/${studentCourseId}/message/`, {
				params: { instant: false, number: numberOfMessages },
			});
			if (res.data && res.data.data && res.data.data) {
				let messages = res.data.data;
				if (messages.length) {
					setMessages(messages);
					return true;
				}
			}
		}
	}

	async function requestAllMessages() {
		if (allMessagesRequested) return true;

		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == currentCourse;
		});

		if (courseIndex != -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;
			let res = await api.get(`/student/course/${studentCourseId}/message/`);
			if (res.data && res.data.data && res.data.data) {
				let newMessages = res.data.data;
				let newNormalMessages = newMessages.filter((message) => {
					return !message.instant;
				});
				let newInstantMessages = newMessages.filter((message) => {
					return message.instant && !message.checked;
				});
				if (newInstantMessages.length)
					setLastInstantMessageData(newInstantMessages[0].createdAt);
				if (newMessages.length) {
					setMessages(newNormalMessages);
					setInstantMessages(newInstantMessages);
					setAllMessagesRequested(true);
					return true;
				}
			}
		}
	}

	async function requestNewMessages() {
		let after = undefined;
		if (messages.length) {
			let lastNormalMessageData = messages[0].createdAt;
			if (lastInstantMessageData === null) {
				after = lastNormalMessageData;
			} else {
				after =
					new Date(lastNormalMessageData) > new Date(lastInstantMessageData)
						? lastNormalMessageData
						: lastInstantMessageData;
			}
		} else {
			if (lastInstantMessageData !== null) {
				after = lastInstantMessageData;
			}
		}
		let params = after !== undefined ? { after } : undefined;

		let course =
			user.courses[
				user.courses.findIndex((course) => {
					return course.courseInfo.id == currentCourse;
				})
			];
		if (course) {
			let studentCourseId = course.id;
			let res = await api.get(`/student/course/${studentCourseId}/message/`, {
				params,
			});
			if (res.data && res.data.data && res.data.data) {
				let newMessages = res.data.data;
				let newNormalMessages = newMessages.filter((message) => {
					return !message.instant;
				});
				let newInstantMessages = newMessages.filter((message) => {
					return message.instant && !message.checked;
				});
				if (newInstantMessages.length)
					setLastInstantMessageData(newInstantMessages[0].createdAt);
				if (newMessages.length) {
					setMessages((oldMessages) => {
						return [...newNormalMessages, ...oldMessages];
					});
					setInstantMessages((oldMessages) => {
						return [...oldMessages, ...newInstantMessages];
					});
					return true;
				}
			}
		}
	}

	async function requestCheckMessage(messageId) {
		if (typeof messageId === "string") return true;
		let studentCourseId = getCurrentCourse().id;
		try {
			let res = await api.post("/message/set-visualized", {
				studentCourseId,
				messageId: messageId,
			});
		} catch (err) {
			return false;
		}
		return true;
	}

	async function requestCheckAllMessages() {
		try {
			let studentCourseId = getCurrentCourse().id;
			let res = await api.post("/message/all/set-visualized", {
				studentCourseId: studentCourseId,
			});
		} catch (err) {
			return false;
		}
		return true;
	}

	// Função para marcar uma notificação como lida
	async function markNotificationAsRead(notificationId) {
		requestCheckMessage(notificationId);
		setMessages((lastState) => {
			return lastState.map((notification) => {
				if (notification.id === notificationId) {
					return { ...notification, checked: true };
				}
				return notification;
			});
		});
	}

	// Função para marcar uma notificação como lida
	async function markInstantMessageAsRead(notificationId) {
		requestCheckMessage(notificationId);
		setInstantMessages((lastState) => {
			return lastState.map((notification) => {
				if (notification.id === notificationId) {
					return { ...notification, checked: true };
				}
				return notification;
			});
		});
	}

	async function markAllNotificationsAsRead() {
		await requestCheckAllMessages();
		setMessages((lastState) => {
			return lastState.map((notification) => {
				if (notification.checked == false) {
					return { ...notification, checked: true };
				}
				return notification;
			});
		});
	}

	async function loadCourse(courseId) {
		setIsLoadingCourse(true);
		await loadSubjects(courseId);
		await loadStudentCourseMissionsHistory(courseId);
		await updateAvatar(courseId);
		await loadPurchasedItems(courseId);
		await loadAchievements(courseId);
		await loadFriends(courseId);
		setIsLoadingCourse(false);
	}

	async function loadSubjects(courseId) {
		let res = await api.get(`/course/${courseId}`);
		for (let i = 0; i < res.data.data.Subjects.length; i++) {
			for (let j = 0; j < res.data.data.Subjects[i].Missions.length; j++) {
				res.data.data.Subjects[i].Missions[j].history = [];
				res.data.data.Subjects[i].Missions[j].tasks = [];
			}
		}
		setUser((oldUser) => {
			let newUser = { ...oldUser };
			let courseIndex = newUser.courses.findIndex((course) => {
				return course.courseInfo.id == courseId;
			});
			if (courseIndex != -1) {
				newUser.courses[courseIndex].subjects = res.data.data.Subjects;
				return newUser;
			}
		});
	}

	async function loadStudentCourseMissionsHistory(courseId) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == courseId;
		});
		let course = user.courses[courseIndex];
		if (course) {
			let studentCourseId = course.id;
			let res = await api.get(`/student/course/${studentCourseId}/missions`);

			if (res.data && res.data.data) {
				let missionHistory = res.data.data;
				setUser((oldUser) => {
					let newUser = { ...oldUser };
					for (
						let i = 0;
						i < newUser.courses[courseIndex].subjects.length;
						i++
					) {
						for (
							let j = 0;
							j < newUser.courses[courseIndex].subjects[i].Missions.length;
							j++
						) {
							let missionId =
								newUser.courses[courseIndex].subjects[i].Missions[j].id;
							for (let k = 0; k < missionHistory.length; k++) {
								if (missionId == missionHistory[k].MissionId) {
									newUser.courses[courseIndex].subjects[i].Missions[
										j
									].history.push(missionHistory[k]);
								}
							}
						}
					}
					return newUser;
				});
			}
		}
	}

	async function setNewCurrentCourse(newCurrentCourseId) {
		if (user.id && user.courses) {
			let courseIndex = user.courses.findIndex((course) => {
				return course.courseInfo.id == newCurrentCourseId;
			});
			if (courseIndex >= 0) {
				setCurrentCourse(parseInt(newCurrentCourseId));
			}
		}
	}

	function getCurrentCourse() {
		try {
			return user.courses[
				user.courses.findIndex((course) => {
					return course.courseInfo.id == currentCourse;
				})
			];
		} catch {
			return null;
		}
	}

	function getSubject(subjectId) {
		if (user.courses != undefined) {
			for (let i = 0; i < user.courses.length; i++) {
				let course = user.courses[i];
				if (course.subjects) {
					for (let j = 0; j < course.subjects.length; j++) {
						let subject = course.subjects[j];
						if (subject.id == subjectId) {
							return subject;
						}
					}
				}
			}
		}
		return null;
	}

	async function loadTasks(missionId) {
		let course = getCurrentCourse();
		if (course) {
			const studentCourseId = getCurrentCourse().id;
			let res = await api.get(
				`/student/course/${studentCourseId}/mission/${missionId}/tasks/`
			);
			let courseIndex = 0;
			let subjectIndex = 0;
			let missionIndex = 0;
			if (res.data && res.data.data) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };
					let tasks = res.data.data.tasks;
					for (let i = 0; i < newUser.courses.length; i++) {
						if (newUser.courses[i].subjects) {
							for (let j = 0; j < newUser.courses[i].subjects.length; j++) {
								for (
									let k = 0;
									k < newUser.courses[i].subjects[j].Missions.length;
									k++
								) {
									if (
										missionId == newUser.courses[i].subjects[j].Missions[k].id
									) {
										newUser.courses[i].subjects[j].Missions[k].tasks = tasks;
										courseIndex = i;
										subjectIndex = j;
										missionIndex = k;
										return newUser;
									}
								}
							}
						}
					}
				});
				// setCurrentMissionSession(res.data.data.missionSessionId)
				return {
					tasks:
						user.courses[courseIndex].subjects[subjectIndex].Missions[
							missionIndex
						].tasks,
					missionSessionId: res.data.data.missionSessionId,
				};
			} else {
				return { tasks: [], missionSessionId: null };
			}
		}
	}

	async function checkAnswer(missionSessionId, taskId, alternativeId) {
		let res = await api.post(
			"/student/course/missions/task/alternative/verify",
			{
				missionSessionId,
				taskId,
				alternativeId,
			}
		);
		if (res.data && res.data.data) {
			return res.data.data.rightAnswer;
		}

		return false;
	}

	async function checkPairAnswer(missionSessionId, taskId, pairA, pairB) {
		let res = await api.post("/student/course/missions/task/pair/verify", {
			missionSessionId,
			taskId,
			pairA,
			pairB,
		});
		if (res.data && res.data.data) {
			return res.data.data.rightAnswer;
		}

		return false;
	}

	async function finishPairTask(missionSessionId, taskId) {
		try {
			let res = await api.post("/student/course/missions/task/pair/finish", {
				missionSessionId,
				taskId,
			});
		} catch (err) {
			console.log(err.response);
		}
	}

	async function finishMission(missionSessionId, mistakesNumber) {
		const studentCourseId = getCurrentCourse().id;
		let res = await api.post("/mission/finish", {
			studentCourseId,
			missionSessionId,
			mistakesNumber,
		});
		if (res.data && res.data.data) {
			let multiplier = 1;
			if (wasImposedChoiceAccepted) {
				if (mistakesNumber > 0) {
					multiplier = 0;
				} else {
					multiplier = 2;
				}
				setWasImposedChoiceAccepted(false);
			}
			generateEndOfMissionMessage(mistakesNumber, wasImposedChoiceAccepted);

			let courseIndex = user.courses.findIndex((course) => {
				return course.courseInfo.id == currentCourse;
			});

			let subjectIndex = -1;
			let missionIndex = -1;
			for (let i = 0; i < user.courses[courseIndex].subjects.length; i++) {
				for (
					let j = 0;
					j < user.courses[courseIndex].subjects[i].Missions.length;
					j++
				) {
					if (
						user.courses[courseIndex].subjects[i].Missions[j].id ==
						res.data.data.newHistory.Missions.id
					) {
						subjectIndex = i;
						missionIndex = j;
					}
				}
			}

			if (subjectIndex !== -1 && missionIndex !== -1) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };

					newUser.courses = [...newUser.courses];
					newUser.courses[courseIndex] = { ...newUser.courses[courseIndex] };

					if (mistakesNumber == 0) {
						newUser.courses[courseIndex].economy =
							newUser.courses[courseIndex].economy + 2 * multiplier;
						newUser.courses[courseIndex].points =
							newUser.courses[courseIndex].points + 9 * multiplier;
					} else {
						newUser.courses[courseIndex].economy =
							newUser.courses[courseIndex].economy + 0 * multiplier;
						newUser.courses[courseIndex].points =
							newUser.courses[courseIndex].points + 7 * multiplier;
					}

					newUser.courses[courseIndex].subjects[subjectIndex].Missions[
						missionIndex
					].history.push(res.data.data.newHistory);

					return newUser;
				});
			}

			setRequestRewardAchievements({
				subjectId: user.courses[courseIndex].subjects[subjectIndex].id,
				mistakesNumber,
			});

			if (res.data.data.bonusChestAttempt && getCurrentCourseSetting().chance) {
				setChanceWasRequested(true);
			}
			return true;
		}

		return false;
	}

	function insertFakeInstantMessage(type, subType, value, extraData) {
		const newMessage = {
			id: `i${Math.floor(Math.random() * 1000000)}`,
			type,
			subType,
			checked: false,
			instant: true,
			value,
			extraData,
			createdAt: new Date(Date.now()).toISOString(),
		};
		setInstantMessages((oldMessages) => {
			return [...oldMessages, newMessage];
		});
	}

	function generateEndOfMissionMessage(mistakesNumber, imposedChoice) {
		let type = 2;
		let subType = 0;
		let points = 0;
		let economy = 0;
		let value = 0;
		let settings = getCurrentCourseSetting();
		if (mistakesNumber == 0) {
			points = 9;
			economy = 2;
			if (imposedChoice) {
				points = 18;
				economy = 4;
				if (settings.points && settings.economy) {
					subType = 11;
				} else if (settings.points) {
					subType = 9;
				} else if (settings.economy) {
					subType = 10;
				} else {
					subType = 8;
				}
			} else {
				if (settings.points && settings.economy) {
					subType = 6;
				} else if (settings.points) {
					subType = 4;
				} else if (settings.economy) {
					subType = 5;
				} else {
					subType = 3;
				}
			}
		} else {
			points = 7;
			economy = 0;
			if (imposedChoice) {
				points = 0;
				economy = 0;
				subType = 7;
			} else if (settings.points) {
				subType = 2;
			} else {
				subType = 1;
			}
		}

		insertFakeInstantMessage(type, subType, value, { points, economy });
	}

	function getCurrentCourseSetting() {
		let course = getCurrentCourse();
		if (course === null || course === undefined) {
			return {};
		}
		if (course.courseInfo.CourseGamificationSettings.length > 0) {
			return course.courseInfo.CourseGamificationSettings[0];
		}

		return {};
	}

	async function loadRanking(courseId) {
		try {
			if (user.id && user.courses) {
				let courseIndex = user.courses.findIndex((course) => {
					return course.courseInfo.id == courseId;
				});

				let course = user.courses[courseIndex];
				if (course) {
					let studentCourseId = course.id;
					let res = await api.get(`/student/course/${studentCourseId}/ranking`);
					if (res.data && res.data.data && res.data.data.length) {
						let ranking = res.data.data.sort((a, b) => {
							return a.competitionPoints > b.competitionPoints ? -1 : 1;
						});
						ranking = ranking.map((rankingUser) => {
							if (rankingUser.studentCourseId === course.id) {
								return { ...rankingUser, mainUser: true };
							}
							return rankingUser;
						});
						return ranking;
					}
				}
			}
		} catch (err) {
			console.log("Get ranking Error");
			console.log(err);
		}
		return [];
	}

	async function loadTeams(courseId) {
		try {
			if (user.id && user.courses) {
				let courseIndex = user.courses.findIndex((course) => {
					return course.courseInfo.id == courseId;
				});

				let course = user.courses[courseIndex];
				if (course) {
					let studentCourseId = course.id;
					let res = await api.get(`/student/course/${studentCourseId}/team`);
					if (res.data && res.data.data && res.data.data.id) {
						let team = res.data.data;
						team.students = team.students.map((student) => {
							if (student.studentCourseId === course.id) {
								return { ...student, mainUser: true };
							}
							return student;
						});
						return team;
					}
				}
			}
		} catch (err) {
			console.log("Get teams Error");
			console.log(err);
		}
		return { students: [] };
	}

	async function selectAvatar(avatar) {
		if (user.id && user.courses) {
			let courseIndex = user.courses.findIndex((course) => {
				return course.courseInfo.id == currentCourse;
			});

			let course = user.courses[courseIndex];
			if (course) {
				let studentCourseId = course.id;
				let res = await api.post(`/student/course/selectAvatar`, {
					studentCourseId,
					avatar,
				});
				if (res.data && res.data.data) {
					setUser((oldUser) => {
						let newUser = { ...oldUser };
						newUser.courses[courseIndex].avatar = res.data.data.avatar;
						newUser.courses[courseIndex].avatarPrimaryLevel =
							res.data.data.avatarPrimaryLevel;
						newUser.courses[courseIndex].avatarSecondaryLevel =
							res.data.data.avatarSecondaryLevel;

						return newUser;
					});
				}
			}
		}
		return [];
	}

	async function updateAvatar(courseId) {
		if (user.id && user.courses) {
			let courseIndex = user.courses.findIndex((course) => {
				return course.courseInfo.id == courseId;
			});

			let course = user.courses[courseIndex];
			if (course) {
				let studentCourseId = course.id;
				let res = await api.post(`/student/course/updateAvatar`, {
					studentCourseId,
				});
				if (res.data && res.data.data && res.data.data.avatarPrimaryLevel) {
					setUser((oldUser) => {
						let newUser = { ...oldUser };
						newUser.courses[courseIndex].avatarPrimaryLevel =
							res.data.data.avatarPrimaryLevel;
						newUser.courses[courseIndex].avatarSecondaryLevel =
							res.data.data.avatarSecondaryLevel;

						return newUser;
					});
				}
			}
		}
		return [];
	}

	async function loadPurchasedItems(courseId) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == courseId;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;

			let response = await api.get(
				`/student/course/${studentCourseId}/purchased`
			);
			if (response && response.data && response.data.data) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };
					newUser.courses[courseIndex].purchasedItems = response.data.data;
					return newUser;
				});
			}
		}
	}

	async function buyItem(itemName, price) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == currentCourse;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;

			let response = await api.post(`/student/course/item`, {
				studentCourseId,
				itemName,
			});
			if (
				response &&
				response.data &&
				response.data.message &&
				response.data.message === "Purchased!"
			) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };
					newUser.courses = [...newUser.courses];
					newUser.courses[courseIndex].purchasedItems.push({ item: itemName });
					newUser.courses[courseIndex].economy =
						newUser.courses[courseIndex].economy - price;
					return newUser;
				});
			}
		}
	}

	function getCurrentCourseAvatar() {
		let course = getCurrentCourse();
		if (
			course === null ||
			course === undefined ||
			course.avatar === "NotDefined" ||
			course.avatar === undefined
		)
			return null;
		return {
			avatar: course.avatar,
			avatarPrimaryLevel: course.avatarPrimaryLevel,
			avatarSecondaryLevel: course.avatarPrimaryLevel,
		};
	}

	async function openBonusChest(bounusChestNumber) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == currentCourse;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;

			let response = await api.post(`/student/course/openBonusChest`, {
				studentCourseId,
				bounusChestNumber,
			});
			if (
				response &&
				response.data &&
				response.data.data &&
				response.data.data.coinsEarned
			) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };
					newUser.courses = [...newUser.courses];
					newUser.courses[courseIndex] = { ...newUser.courses[courseIndex] };
					newUser.courses[courseIndex].economy =
						newUser.courses[courseIndex].economy +
						response.data.data.coinsEarned;
					return newUser;
				});

				return response.data.data.coinsEarned;
			} else {
				setChanceWasRequested(false);
			}
		}
	}

	async function poke(colleagueStudentCourseId) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == currentCourse;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;

			let response = await api.post(`/student/course/poke`, {
				studentCourseId,
				colleagueStudentCourseId,
			});
		}
	}

	function tryImposedChoice() {
		let settings = getCurrentCourseSetting();
		let imposedChoice = Math.floor(Math.random() * 5) + 1 === 5;
		return settings.imposedChoice ? imposedChoice : false;
	}

	async function acceptImposedChoice(subjectId) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == currentCourse;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;

			let response = await api.post(
				`/student/course/subject/acceptImposedChoice`,
				{
					studentCourseId,
					subjectId,
				}
			);

			setWasImposedChoiceAccepted(true);
		}
	}

	async function loadAchievements(courseId) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == courseId;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;

			let response = await api.get(
				`/student/course/${studentCourseId}/achievements`
			);
			if (response && response.data && response.data.data) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };
					newUser.courses[courseIndex].achievements = response.data.data;
					return newUser;
				});
			}
		}
	}

	async function rewardAchievements() {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == currentCourse;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let subjectIndex = course.subjects.findIndex((subject) => {
				return subject.id == requestRewardAchievements.subjectId;
			});
			let mistakesNumber = requestRewardAchievements.mistakesNumber;

			if (course.courseInfo.CourseGamificationSettings[0].acknowledgment) {
				let achievements = course.achievements || [];
				if (mistakesNumber === 0) {
					if (!getAchievementById(achievements, 1)) {
						await requestAchievement(course.id, courseIndex, 1);
					}

					if (
						!getAchievementById(achievements, 2) ||
						!getAchievementById(achievements, 3)
					) {
						let perfectMissionsNumber = 0;

						course.subjects.forEach((subject) => {
							subject.Missions.forEach((mission) => {
								let perfetcMission = false;
								mission.history.forEach((pieceOfHistory) => {
									if (pieceOfHistory.mistakes === 0 && !perfetcMission) {
										perfectMissionsNumber++;
										perfetcMission = true;
									}
								});
							});
						});

						if (
							perfectMissionsNumber >= 5 &&
							!getAchievementById(achievements, 2)
						) {
							await requestAchievement(course.id, courseIndex, 2);
						}

						if (
							perfectMissionsNumber >= 10 &&
							!getAchievementById(achievements, 3)
						) {
							await requestAchievement(course.id, courseIndex, 3);
						}
					}
				}
				if (!getAchievementById(achievements, 6)) {
					let subject = course.subjects[subjectIndex];
					let completedSubject = true;
					for (let i = 0; i < subject.Missions.length; i++) {
						let mission = subject.Missions[i];
						if (mission.history.length === 0) completedSubject = false;
						break;
					}

					if (completedSubject) {
						await requestAchievement(course.id, courseIndex, 6);
					}
				}
			}
		}
	}

	function getAchievementById(achievements, id) {
		let achievementIndex = achievements.findIndex((achievement) => {
			return achievement && achievement.achievementId == id;
		});

		return achievementIndex !== -1 ? achievements[achievementIndex] : null;
	}

	async function requestAchievement(
		studentCourseId,
		courseIndex,
		achievementId
	) {
		let response = await api.post(`/student/course/requestAchievement`, {
			studentCourseId,
			achievementId,
		});
		if (
			response &&
			response.data &&
			response.data.data &&
			response.data.data.achievementId
		) {
			insertFakeInstantMessage(1, 0, achievementId, {});
			setUser((oldUser) => {
				let newUser = { ...oldUser };
				newUser.courses = [...newUser.courses];
				newUser.courses[courseIndex].achievements.push({
					achievementId: response.data.data.achievementId,
				});
				return newUser;
			});
		}
	}

	async function verifyAccount(code) {
		try {
			let response = await api.post(`/user/accountVerification`, {
				code,
			});
			console.log(response);
			if (
				response &&
				response.data &&
				response.data.message &&
				response.data.message === "Account verified successfully."
			) {
				return { verified: true, message: response.data.message };
			}
			return { verified: false };
		} catch (err) {
			return { verified: false, error: err.response.data.message };
		}
	}

	async function resendCode() {
		if (user && user.id) {
			let response = await api.post(`/user/accountVerification/resendToken`, {
				userId: user.id,
			});
		}
	}

	async function stopAccountVerification(code) {
		let response = await api.post(`/user/accountVerification/stop`, {
			code,
		});
	}

	async function requestAccountRecovery(email) {
		let response = await api.post(`/user/accountRecovery/request`, {
			email,
		});
	}

	async function accountRecovery(code, newPassword) {
		try {
			let response = await api.post(`/user/accountRecovery`, {
				newPassword,
				code,
			});
			if (
				response &&
				response.data &&
				response.data.message &&
				response.data.message === "Account recovery successfully."
			) {
				return { recovered: true, message: response.data.message };
			}
			return { recovered: false };
		} catch (err) {
			console.log(err.response);
			return { recovered: false, error: err.response.data.message };
		}
	}

	async function loadFriends(courseId) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == courseId;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;

			let response = await api.get(
				`/student/course/${studentCourseId}/all-colleagues`
			);
			if (response && response.data && response.data.data) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };
					newUser.courses[courseIndex].friends = response.data.data;
					return newUser;
				});
			}
		}
	}

	async function follow(newFollowedId) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == currentCourse;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;

			let response = await api.post(`/student/course/set-following`, {
				newFollowedId,
				studentCourseId,
			});
			if (response && response.data && response.data.data) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };

					let friendIndex = newUser.courses[courseIndex].friends.findIndex(
						(friend) => {
							return friend.id == response.data.data.id;
						}
					);

					newUser.courses[courseIndex].friends[friendIndex].points =
						response.data.data.points;
					newUser.courses[courseIndex].friends[friendIndex].economy =
						response.data.data.economy;
					newUser.courses[courseIndex].friends[friendIndex].achievements =
						response.data.data.achievements;
					newUser.courses[courseIndex].friends[friendIndex].followed = true;

					return newUser;
				});
			}
		}
	}

	async function unfollow(followedId) {
		let courseIndex = user.courses.findIndex((course) => {
			return course.courseInfo.id == currentCourse;
		});

		if (courseIndex !== -1) {
			let course = user.courses[courseIndex];
			let studentCourseId = course.id;

			let response = await api.post(`/student/course/unset-following`, {
				followedId,
				studentCourseId,
			});
			if (response && response.data && response.data.data) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };

					let friendIndex = newUser.courses[courseIndex].friends.findIndex(
						(friend) => {
							return friend.id == followedId;
						}
					);

					newUser.courses[courseIndex].friends[friendIndex].followed = false;

					return newUser;
				});
			}
		}
	}

	function getCurrentCourseFollowers() {
		let course = getCurrentCourse();
		if (course === null || course.friends === undefined) return [];
		return course.friends.filter((friend) => friend.follower);
	}

	function getCurrentCourseFollowing() {
		let course = getCurrentCourse();
		if (course === null || course.friends === undefined) return [];
		return course.friends.filter((friend) => friend.followed);
	}

	function getCurrentCourseFriends() {
		let course = getCurrentCourse();
		if (course === null || course.friends === undefined) return [];
		return course.friends;
	}

	async function startTask(missionSessionId, taskId) {
		try {
			let res = await api.post("/mission/session/task/start", {
				missionSessionId,
				taskId,
			});
		} catch (err) {}
	}
	async function skipTask(missionSessionId, taskId) {
		let res = await api.post("/mission/session/task/skip", {
			missionSessionId,
			taskId,
		});
	}

	async function updateUserInfo(newData) {
		try {
			setUser((lastUser) => {
				return { ...lastUser, ...newData };
			});
		} catch (err) {
			console.log(err);
		}
	}

	async function updateUserProfilePic(newProfilePic) {
		try {
			setUser((lastUser) => {
				return { ...lastUser, pictureURL: newProfilePic };
			});
		} catch (err) {
			console.log(err);
		}
	}

	async function searchCourse(registrationKey) {
		try {
			let res = await api.get(`/course/registrationKey/${registrationKey}`);

			return res.data.data;
		} catch (err) {
			return { error: err.response.data.message };
		}
	}

	async function enrollCourse(registrationKey) {
		try {
			let res = await api.post(`/student/enroll-course`, {
				registrationKey,
			});
			if (res.data.data.autoAccepted) {
				setUser((oldUser) => {
					let newUser = { ...oldUser };
					newUser.courses = [...newUser.courses, res.data.data];
					if (newUser.courses.length) {
						setCurrentCourse(
							newUser.courses[newUser.courses.length - 1].courseInfo.id
						);
					}
					return newUser;
				});

				history.push("/profile");
			} else {
				return { success: true };
			}
		} catch (err) {
			return { error: err.response.data.message };
		}
	}

	return (
		<EagleContext.Provider
			value={{
				isLoadingSystem,
				isLoadingCourse,
				user,
				login,
				register,
				logout,
				messages, // Passando o estado de notificações para o contexto
				markNotificationAsRead, // Passando a função de marcar notificação como lida
				markAllNotificationsAsRead,
				instantMessages,
				requestAllMessages,
				setNewCurrentCourse,
				currentCourse,
				getSubject,
				loadTasks,
				checkAnswer,
				finishMission,
				insertFakeInstantMessage,
				getCurrentCourse,
				getCurrentCourseSetting,
				loadRanking,
				loadTeams,
				getCurrentCourseAvatar,
				selectAvatar,
				buyItem,
				openBonusChest,
				poke,
				tryImposedChoice,
				acceptImposedChoice,
				verifyAccount,
				resendCode,
				stopAccountVerification,
				requestAccountRecovery,
				accountRecovery,
				getCurrentCourseFriends,
				getCurrentCourseFollowers,
				getCurrentCourseFollowing,
				follow,
				unfollow,
				startTask,
				skipTask,
				updateUserInfo,
				updateUserProfilePic,
				isLoadingCourses,
				searchCourse,
				enrollCourse,
				userType,
				setUserType,
				incrementSessionUpdates,
				checkPairAnswer,
				finishPairTask,
				instructor,
			}}
		>
			{children}
			<Message
				message={
					instantMessages.length && !chanceWasRequested
						? (() => {
								if (!instantMessages[0].checked) {
									markInstantMessageAsRead(instantMessages[0].id);
								}
								return instantMessages[0];
						  })()
						: {}
				}
				closeModal={() =>
					setInstantMessages((lastState) => {
						let newState = [...lastState];
						newState.shift();
						return newState;
					})
				}
			/>
			{chanceWasRequested && (
				<Chance onClose={() => setChanceWasRequested(false)} />
			)}
		</EagleContext.Provider>
	);
}
