/** @format */

import { useEffect, useState } from "react";
import { EagleContext } from "../../contexts/EagleContext";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loader from "./Loader/Loader";
import { InstructorContext } from "../../contexts/InstructorContext";

export default function InstructorAccessManager({ children }) {
	const {
		user: instructor,
		isInstructorLoading,
		setCurrentCourseId,
	} = useContext(InstructorContext);
	const history = useHistory();
	const { courseId } = useParams();
	const { user: student } = useContext(EagleContext);

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (!isInstructorLoading) {
			if (courseId >= 0) {
				console.log(courseId)
				setCurrentCourseId(courseId);
			}
			if (!instructor.id) {
				if (student.id) {
					history.push("/profile");
				} else {
					history.push("/signin");
					console.log(courseId);
				}
			} else if (!instructor.verified) {
				history.push("/accountverification");
			} else {
				setLoading(isInstructorLoading);
			}
		}
	}, [isInstructorLoading]);

	// useEffect(() => {
	// 	if (isLoadingSystem) {
	// 		if (!user.id) {
	// 			history.push("/signin");
	// 		} else if (!user.verified) {
	// 			// console.log(user.verified)
	// 			history.push("/accountverification");
	// 		} else {
	// 			let isLoadingTemp = isLoadingSystem || true;
	// 			setLoading(isLoadingTemp);
	// 			if (!isLoadingTemp) {
	// 				if (user.courses.length === 0) {
	// 					history.push("/enrollcourse");
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [isLoadingSystem]);

	return loading ? <Loader /> : <>{children}</>;
}
