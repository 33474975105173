/** @format */
import styled from "styled-components";

export const LoaderWrapper = styled.div`
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999999999;
	background: #fff;
`;

export const LoaderBox = styled.div`
	position: absolute;
	width: 5rem;
	height: 10rem;
	top: 50%;
	margin: 0 auto;
	left: 0;
	right: 0;
	transform: translateY(-50%);
`;

export const LoaderPic = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
`;

export const Circular = styled.svg`
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	transform: rotate(-90deg);
`;

export const Path = styled.circle`
	stroke-dasharray: 0, 200;
	stroke-width: 1.5;
	stroke-dashoffset: 0;
	animation: dash 1.5s linear infinite, color 6s ease infinite;
	stroke-linecap: round;

	@ dash {
		0% {
			stroke-dasharray: 0, 200;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 126, 200;
			stroke-dashoffset: 0;
		}
		100% {
			stroke-dasharray 126, 200;
			stroke-dashoffset: -7.9rem;
		}
	}
	@keyframes color {
		100%,
		0% {
			stroke: #d62d20;
		}
		40% {
			stroke: #0057e7;
		}
		66% {
			stroke: #008744;
		}
		80%,
		90% {
			stroke: #ffa700;
		}
	}
`;
