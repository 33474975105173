/** @format */

import styled from "styled-components";
import { Button } from "../../GenericComponents";
import { Link } from "react-router-dom";

export const HomeWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	margin: 15px;
	padding: 30px;

	background: ${(props) => props.theme.colors.white};
	border-radius: 10px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

export const HomeTitle = styled.h1`
	font-size: 2rem;
	font-weight: 500;
	color: ${(props) => props.theme.colors.black};
`;

export const HomeSubTitle = styled.h2`
	font-size: 1rem;
	font-weight: 400;
	color: ${(props) => props.theme.colors.black};
	margin-bottom: 20px;
`;

export const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, 215px);
	justify-content: space-evenly;
	grid-gap: 20px;
`;

export const SeeAll = styled(Link)`
	text-decoration: none;
	cursor: pointer;
	margin-top:20px;

	font-size: 0.9rem;
	font-weight: 500;
	color: ${(props) => props.theme.colors.lightBlue};

	&:hover {
		text-decoration: underline;
	}
`;

export const NewCourseButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;

	width: 250px;
	padding: 8px;
	border-radius: 5px;

	text-decoration: none;
	font-size: 1rem;
	font-weight: 600;

	@media screen and (max-width: 530px) {
		width: 100%;
	}
`;
