import styled from 'styled-components'

export const QuestionType = styled.h2`
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    font-weight: 500;
    color: black;
`

export const QuestionInstruction = styled.h4`
    margin-top: 0.5rem;
    color: ${(props) => props.theme.colors.primary};
`
