import { FaBars } from 'react-icons/fa'

import { NavbarContainer, NavbarButton } from './Navbar-styled'
import Profile from './Profile/Profile'

export default function Navbar({ toggleSidebar }) {
    return (
        <NavbarContainer>
            <NavbarButton onClick={() => toggleSidebar()}>
                <FaBars />
            </NavbarButton>
            <Profile />
        </NavbarContainer>
    )
}
