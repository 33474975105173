/** @format */

import { useContext } from "react";
import AppStructure from "./app/AppStructure";
import NonCourseEnv from "./nonCourse/NonCourseEnv";
import { EagleContext } from "../../contexts/EagleContext";

export default function DynamicStructur({ children }) {
	const { user, isLoadingSystem, isLoadingCourses } = useContext(EagleContext);

	return !isLoadingSystem && !isLoadingCourses && user.courses && user.courses.length === 0 ? (
		<NonCourseEnv>{children}</NonCourseEnv>
	) : (
		<AppStructure>{children}</AppStructure>
	);
}
