/** @format */

import { useState } from "react";
import { CreateButton, Text } from "./NewQuestion-styled";

import language from "../../../services/defineLanguage.js";
import { ButtonWithLoading } from "../../common/ButtonWithLoading/ButtonWithLoading";
import { ErrorMessage } from "../../forms/FormsMessages";
const texts = require(`../../../texts/${language}/instructor/newQuestion`);

export default function NewQuestion({ handleNewQuestion }) {
	const [chosenType, setChosenType] = useState();
	const [response, setResponse] = useState({});

	function handleOnChange(event) {
		setChosenType(event.target.value);
	}

	async function tryCreateNewTask() {
		let newResponse = await handleNewQuestion(chosenType);
		setResponse(newResponse);
	}
	function createNewTask() {
		tryCreateNewTask();
		setResponse({ isLoading: true });
	}

	return (
		<>
			<div onChange={handleOnChange}>
				<Text>{texts.instruction}</Text>
				<input
					type="radio"
					id="Quiz"
					name="type"
					value="Quiz"
				/>
				<label htmlFor="Quiz">{texts.quiz}</label>
				<br />
				<input
					type="radio"
					id="Pair"
					name="type"
					value="Pair"
				/>
				<label htmlFor="Pair">{texts.pairs}</label>
				<br />
				{/*<input
                    type='radio'
                    id='OpenQuestion'
                    name='type'
                    value='OpenQuestion'
                />
                <label htmlFor='OpenQuestion'>{texts.openQuestion}</label>
                <br />
                <input
                    type='radio'
                    id='CompleteWithoutOptions'
                    name='type'
                    value='CompleteWithoutOptions'
                />
                <label htmlFor='CompleteWithoutOptions'>
                    {texts.completeWithoutOptions}
                </label>
                <br /> */}
				{/* <input
                    type='radio'
                    id='CompleteWithOptions'
                    name='type'
                    value='CompleteWithOptions'
                />
                <label htmlFor='CompleteWithOptions'>{texts.completeWithOptions}</label> */}
			</div>
			{response.success === false && (
				<ErrorMessage margin={"10px 0 0 0"}>
					{texts.errors[response.error] ?? texts.errors["default"]}
				</ErrorMessage>
			)}

			<ButtonWithLoading
				ButtonTemplate={CreateButton}
				width={"140px"}
				isloading={response?.isLoading}
				onClick={createNewTask}
				disabled={!chosenType}
			>
				{texts.addTask}
			</ButtonWithLoading>
		</>
	);
}
