import InstructorStructure from '../../environments/instructor/InstructorStructure/InstructorStructure'
import {
    Wrapper,
    Title,
    SubTitle,
    CardsWrapper,
    Card,
} from './Tutorials-styled'
import pdfImage from '../../../assets/images/pdf.png'
import editCourse from '../../../assets/pdf/editCourse.pdf'
import insertContent from '../../../assets/pdf/insertContent.pdf'
import createTask from '../../../assets/pdf/createTask.pdf'

import language from '../../../services/defineLanguage.js'
const texts = require(`../../../texts/${language}/instructor/tutorials`)

export default function Tutorials() {
    return (
        <InstructorStructure>
            <Wrapper>
                <Title>{texts.tutorials}</Title>
                <SubTitle>{texts.instruction}</SubTitle>
                <CardsWrapper>
                    <Card href={editCourse} download={texts.editCourse}>
                        <h3>{texts.howEditCourse}</h3>
                        <img src={pdfImage} alt='pdf' />
                        <hr />
                        <p>{texts.editCouseInstruction}</p>
                    </Card>
                    <Card href={insertContent} download={texts.insertContent}>
                        <h3>{texts.howInsertContent}</h3>
                        <img src={pdfImage} alt='pdf' />
                        <hr />
                        <p>{texts.insertContentInstruction}</p>
                    </Card>
                    <Card href={createTask} download={texts.createTask}>
                        <h3>{texts.howCreateTask}</h3>
                        <img src={pdfImage} alt='pdf' />
                        <hr />
                        <p>{texts.createTaskInstruction}</p>
                    </Card>
                </CardsWrapper>
            </Wrapper>
        </InstructorStructure>
    )
}
