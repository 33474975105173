/** @format */

import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import {
	MissionContainer,
	MissionBody,
	Wrapper,
	MissionButtons,
	MissionHeader,
	TaskIndicator,
	PlusButton,
	DeleteButton,
} from "./Mission-styled.js";
import { Button } from "../../GenericComponents";
import LinkWithIcon from "../../common/LinkWithIcon/LinkWithIcon";
import { FaAngleLeft, FaAngleRight, FaPlus, FaTimes } from "react-icons/fa";
import Modal2 from "../../common/Modal2/Modal2";
import NewQuestion from "../NewQuestion/NewQuestion";

// Mission TYPES
import Quiz from "./Types/Quiz/Quiz.jsx";
import Pairs from "./Types/Pairs/Pairs.jsx";
// import CompleteWithOptions from './Types/CompleteWithOptions/CompleteWithOptions.jsx'
import OpenQuestion from "./Types/OpenQuestion/OpenQuestion.jsx";
import CompleteWithoutOptions from "./Types/CompleteWithoutOptions/CompleteWithoutOptions.jsx";

import language from "../../../services/defineLanguage.js";
import {
	createTask,
	deleteTask,
	getMissionTasks,
} from "../../../services/Instructor";
import { EagleContext } from "../../../contexts/EagleContext";
import { ButtonWithLoading } from "../../common/ButtonWithLoading/ButtonWithLoading";
import { FloatingMessageBox } from "../../common/FlaotingBox/FloatingMessageBox/FloatingMessageBox";
const texts = require(`../../../texts/${language}/instructor/mission`);

export default function Mission() {
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const { courseId, subjectId, missionId } = useParams();
	const [currentQuestion, setCurrentQuestion] = useState([]);
	const [taskQueue, setTaskQueue] = useState([]);
	const [curTaskIndex, setCurTaskIndex] = useState(0);
	const [deleteTaskResponse, setDeleteTaskResponse] = useState({});

	const [showNewQuestionModal, setShowNewQuestionModal] = useState(false);
	const [reload, setReload] = useState();

	const forceReload = () => {
		setReload({});
	};

	useEffect(async () => {
		setTaskQueue(await getMissionTasks(missionId));
	}, []);

	useEffect(async () => {
		setTaskQueue(await getMissionTasks(missionId));
	}, [reload]);

	useEffect(async () => {
		setCurrentQuestion(taskQueue[curTaskIndex]);
	}, [taskQueue, curTaskIndex]);

	function handleNextButtonCLick() {
		let nextQuestion = curTaskIndex + 1;

		// displaying questions on a loop
		if (nextQuestion >= taskQueue.length) {
			setCurTaskIndex(0);
			return;
		}

		setCurTaskIndex(nextQuestion);
	}

	function handlePreviousButtonCLick() {
		let nextQuestion = curTaskIndex - 1;

		// displaying questions on a loop
		if (nextQuestion < 0) {
			setCurTaskIndex(taskQueue.length - 1);
			return;
		}

		setCurTaskIndex(nextQuestion);
	}

	async function handleNewQuestion(questionType) {
		let response = await createTask(missionId, questionType);
		if (response.success) {
			let newTask = { ...response.data, Alternatives: [], Pairs: [] };

			setTaskQueue((prev) => {
				return [...prev, newTask];
			});

			setCurTaskIndex(taskQueue.length);
			setShowNewQuestionModal(false);
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
			setShowNewQuestionModal(false);
		}

		return response;
	}

	async function tryDeleteTask() {
		let newResponse = await deleteTask(taskQueue[curTaskIndex]?.id);

		if (newResponse.success) {
			setTaskQueue((prev) => {
				// Make a copy of the task queue
				let temp = prev.slice();
				// Remove current task
				temp.splice(curTaskIndex, 1);
				// Update task queue
				return [...temp];
			});

			// Update current task index
			setCurTaskIndex((prev) => (prev > 0 ? prev - 1 : 0));
		}

		setDeleteTaskResponse({ ...newResponse, order: Date.now() });
	}

	async function handleDeleteQuestion() {
		if (!window.confirm(texts.confirmDelete)) {
			return;
		}

		setDeleteTaskResponse({ isLoading: true });
		tryDeleteTask();
	}

	function handleQuestionType() {
		if (!currentQuestion) {
			return;
		}

		switch (currentQuestion.taskType) {
			case "Quiz":
				return (
					<Quiz
						taskId={currentQuestion.id}
						initialText={currentQuestion.text}
						alternatives={currentQuestion.Alternatives}
						correctAlternativeId={currentQuestion.correctAlternativeId}
						initialIcon={currentQuestion.icon}
						callback={forceReload}
						key={currentQuestion.id}
					/>
				);
			case "Pair":
				return (
					<Pairs
						taskId={currentQuestion.id}
						pairs={currentQuestion.Pairs}
						initialIcon={currentQuestion.icon}
						callback={forceReload}
						key={currentQuestion.id}
					/>
				);
			case "CompleteWithoutOptions":
				return <CompleteWithoutOptions key={currentQuestion.id} />;
			case "OpenQuestion":
				return <OpenQuestion key={currentQuestion.id} />;
			default:
				return;
		}
	}

	return (
		<InstructorStructure>
			<Wrapper>
				<MissionContainer>
					<MissionHeader>
						<LinkWithIcon
							iconName="FaArrowLeft"
							to={"/instructor/course/" + courseId + "/subject/" + subjectId}
							instructorStyle
						>
							{texts.missionsMenu}
						</LinkWithIcon>
						<TaskIndicator>
							{taskQueue.length > 0 ? curTaskIndex + 1 : 0}/{taskQueue.length}
						</TaskIndicator>
						<PlusButton onClick={() => setShowNewQuestionModal(true)}>
							<FaPlus />
						</PlusButton>
						<ButtonWithLoading
							ButtonTemplate={DeleteButton}
							isloading={deleteTaskResponse.isLoading}
							onClick={handleDeleteQuestion}
							size={15}
						>
							<FaTimes />
						</ButtonWithLoading>

						<Modal2
							title={texts.newTask}
							show={showNewQuestionModal}
							onClose={() => setShowNewQuestionModal(false)}
							disableFooter={true}
						>
							<NewQuestion handleNewQuestion={handleNewQuestion} />
						</Modal2>
					</MissionHeader>

					<MissionBody>
						{taskQueue.length > 0 ? (
							handleQuestionType()
						) : (
							<p>{texts.noQuestions}</p>
						)}
					</MissionBody>

					{taskQueue.length > 1 && (
						<MissionButtons>
							<Button onClick={handlePreviousButtonCLick}>
								<FaAngleLeft />
							</Button>
							{/* <Button>{texts.save}</Button> */}
							<Button onClick={handleNextButtonCLick}>
								<FaAngleRight />
							</Button>
						</MissionButtons>
					)}
				</MissionContainer>
				{deleteTaskResponse.success === false && (
					<FloatingMessageBox
						type="error"
						zIndex={deleteTaskResponse.order - 1707521092301}
					>
						{texts.errors[deleteTaskResponse.error] ?? texts.errors["default"]}
					</FloatingMessageBox>
				)}
			</Wrapper>
		</InstructorStructure>
	);
}
