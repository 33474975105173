/** @format */

import styled from "styled-components";
import { FaCamera } from "react-icons/fa";

export const CoverImage = styled.div`
	grid-column: 1 / span 2;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media screen and (max-width: 1080px) {
		grid-column: 1;
	}
`;

export const CameraBox = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 2.5rem;
	height: 2.5rem;
	border: none;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.7);

	position: relative;
	left: 100%;
	transform: translateX(-110%);
	bottom: 3rem;

	cursor: pointer;
`;

export const Camera = styled(FaCamera)`
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	font-size: 1.5rem;
	color: ${(props) => props.theme.colors.white};
`;
