import { useState } from 'react'
import { FileInputContainer, FileInputButton } from './InputContainer'

import iconNameMap from '../../../constants/iconNameUsingMap'

export default function FileInput({ value, accept, name, icon, onChange, style }) {
	const [isInvalid, setIsInvalid] = useState(false)

	const handleError = () => {
		if (!value) {
			setIsInvalid(true)
		} else {
			setIsInvalid(false)
		}
	}

	return (
		<FileInputContainer isInvalid={isInvalid} style={style}>
			{iconNameMap[icon]}
			<FileInputButton  accept={accept} name={name} type='file' onChange={onChange}/>
		</FileInputContainer>
	)
}
