/** @format */

import { Title } from "../../GenericComponents";
import { Container } from "./Avatar-styled";

import avatarsImages from "../../../constants/avatarsImages";

import language from "../../../services/defineLanguage";

const texts = require(`../../../texts/${language}/avatar`);

export default function Avatar({
	avatar,
	avatarPrimaryLevel,
	avatarSecondaryLevel,
}) {
	return (
		<Container>
			<Title>{texts[avatar].name}</Title>
			<p>{texts[avatar].levels[avatarPrimaryLevel-1]}</p>
			<img
				src={avatarsImages[avatar][avatarPrimaryLevel]}
				alt={texts[avatar].name}
			/>
		</Container>
	);
}
