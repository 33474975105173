/** @format */

import {
	ChangePictureWrapper,
	PictureBox,
	SubmitButtom,
} from "./ChangePicture-styled";

import Modal2 from "../Modal2/Modal2";
import photo from "../../../assets/images/defaultProfileImage2.png";

import language from "../../../services/defineLanguage.js";
import FileInput from "../../forms/input/FileInput";
import { useState } from "react";
import LocalLoader from "../LocalLoader/LocalLoader";
const texts = require(`../../../texts/${language}/changePicture`);

export default function ChangePicture({
	onClose,
	show,
	title,
	initialImage,
	changePicture,
	callback,
}) {
	const [picture, setPicture] = useState(null);
	const [response, setResponse] = useState(null);
	const [isLoading, setIsloading] = useState(false);

	function resetStates() {
		setPicture(null);
		setResponse(null);
		setIsloading(false);
	}

	async function submitProfilePic() {
		if (picture.size > 5 * 1024 * 1024) {
			setResponse({
				success: false,
				message: texts.errors.FileTooLarge,
			});
		} else {
			let newPictureURL = await changePicture(picture);
			if (newPictureURL) {
				setResponse({
					success: true,
					message: texts.success,
				});
				if (callback) {
					callback(newPictureURL);
				}
			} else {
				setResponse({
					success: false,
					message: texts.errors.default,
				});
			}
		}
		setIsloading(false);
	}
	return (
		<Modal2
			disableFooter={true}
			onClose={() => {
				resetStates();
				onClose();
			}}
			show={show}
			title={title}
		>
			{(response === null && isLoading) || response !== null ? (
				<div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column" }}>
					<div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
						<LocalLoader
							isLoading={isLoading}
							wasSuccessful={response && response.success}
							message={response !== null ? response.message : ""}
							scale={1.5}
						/>
					</div>
					<SubmitButtom
						disabled={response === null}
						onClick={
							response && response.success
								? () => {
										resetStates();
										onClose();
								  }
								: resetStates
						}
					>
						{response && response.success ? texts.finish : texts.tryAgain}
					</SubmitButtom>
				</div>
			) : (
				<ChangePictureWrapper
					onSubmit={async (event) => {
						event.preventDefault();
						setIsloading(true);
						submitProfilePic();
					}}
				>
					<PictureBox>
						<img
							src={
								picture !== null
									? URL.createObjectURL(picture)
									: initialImage || photo
							}
							alt=""
						/>
					</PictureBox>
					<FileInput
						onChange={(event) => {
							if (event.target.files.length > 0) {
								setPicture(event.target.files[0]);
							}
						}}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						accept={"image/jpeg, image/pjpeg, image/png, image/gif"}
					/>
					<SubmitButtom
						type="submit"
						disabled={picture === null}
					>
						Upload
					</SubmitButtom>
				</ChangePictureWrapper>
			)}
		</Modal2>
	);
}
