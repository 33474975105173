/** @format */

import { useEffect } from "react";
import RankingPositionCard from "../../../environments/courses-board/WeeklyRanking/RankingPositionCard/RankingPositionCard";
import { WeeklyRankingContainer } from "../../../environments/courses-board/BoardSectionContainers";
import RankingCountdown from "../../../environments/courses-board/WeeklyRanking/RankingCountdown/RankingCountdown";
import { RankingWrapper } from "../../../environments/courses-board/WeeklyRanking/Container";
import { getNextMonday } from "../../../../services/handleTimeFunctions";

import photo from "../../../../assets/images/defaultProfileImage2.png";

import language from "../../../../services/defineLanguage";
const texts = require(`../../../../texts/${language}/course`);

const Ranking = ({
	data: RankingPositionCards,
	timePressureOn,
	competitionOn,
}) => {
	function renderRanking() {
		return RankingPositionCards.map((rankingCard, index) => {
			return (
				<RankingPositionCard
					position={index + 1}
					name={
						rankingCard.mainUser
							? texts.you
							: `${rankingCard.name} ${rankingCard.lastName}`
					}
					score={rankingCard.competitionPoints}
					profilePic={
						rankingCard.pictureURL ==
							"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
						rankingCard.pictureURL?.length === 0
							? photo
							: rankingCard.pictureURL
					}
					mainUser={rankingCard.mainUser}
					key={index}
				/>
			);
		});
	}

	useEffect(() => {
		try {
		} catch (error) {
			console.error("");
		}
	});

	return (
		<WeeklyRankingContainer>
			{competitionOn ? <h3>{texts.rankingTitle}</h3> : <h3>Weekly Timer</h3>}
			{timePressureOn && <RankingCountdown deadline={getNextMonday()} />}
			{competitionOn && (
				<>
					<p>{texts.explanation}</p>
					<RankingWrapper>{renderRanking()}</RankingWrapper>
				</>
			)}
		</WeeklyRankingContainer>
	);
};

export default Ranking;
