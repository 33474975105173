import styled from 'styled-components'

export const Wrapper = styled.form`
    margin: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    color: black;
`

export const StatementPreview = styled.div`
    max-width: 90vw;
    width: 100%;
    min-height: 40px;

    padding: 8px;
    margin-bottom: 10px;
    word-break: break-word;

    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.darkGray};
`
