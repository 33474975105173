/** @format */

module.exports = {
	achievement: "achievement",
	achievements: "achievements",
	economy: "coin",
	economys: "coins",
	point: "point",
	points: "points",
};
