/** @format */

import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: cetner;
	padding: 20px;
`;

export const SubTitle = styled.h2`
	font-size: 1.8rem;
	text-align: center;
`;

export const BadgeContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px 0px;
`;

export const BadgeName = styled.h2`
	font-size: 1.5rem;
	text-align: center;
`;

export const BadgeImageBox = styled.div`
	max-width: 200px;
`;

export const Description = styled.div`
	font-weight: normal;
	font-size: 1.3rem;
	text-align: center;
`;

export const EmojiImage = styled.img`
	max-width: 350px;
	height: auto;

	@media screen and (max-width: 768px) {
		max-width: 300px;
	}
	
	@media screen and (max-width: 460px) {
		max-width: 250px;
	}
`;
