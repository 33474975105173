module.exports = {
    gamification: 'Gamification',
    basicSettings: 'Basic gamification settings',
    settingsDescription:
        'Enable or disable gamification dimensions on your course. If one or more elements from a dimension is enabled, the dimension will be displayed as enabled.',
    performance: {
        title: 'Performance/Measurement',
        description:
            'This dimension is composed by five gamification elements (i.e., Acknowledgment, Level, Point, Progression and Stats). These are elements related to the environment response, which can be used to provide feedback to the learner.',
        icon: 'FaGamepad',
    },
    ecological: {
        title: 'Ecological',
        description:
            'This dimension is represented by five gamification elements (i.e., Chance, Economy, ImposedChoice, Rarity and Time Pressure). This context is related to the environment that the gamification is being implemented. These elements can be represented as properties.',
        icon: 'FaGamepad',
    },
    social: {
        title: 'Social',
        description:
            'This dimension is composed by four gamification elements (i.e., Competition, Cooperation, Reputation and Social Pressure). This dimension is related to the interactions between the learners presented in the environment.',
        icon: 'FaGamepad',
    },
    personal: {
        title: 'Personal',
        description:
            'This dimension is composed by five gamification elements (i.e., Novelty, Objectives, Puzzles, Renovation and Sensation). This dimension is related to the learner that is using the environment.',
        icon: 'FaGamepad',
    },
    fictional: {
        title: 'Fictional',
        description:
            'This dimension is composed by two gamification elements (i.e., Narrative and Storytelling). It is themixed dimension that is related to the user (through Narrative) and the environment (through Storytelling), tying their experience with the context.',
        icon: 'FaGamepad',
    },
}
