/** @format */

import Background from "../../../environments/entrance/Background";
import Footer from "../../../environments/entrance/Footer";
import Header from "../../../environments/entrance/Header";
import EntraceAccessManager from "../../../common/EntraceAccessManager";
import {
	DivPasswordRecovery,
	ButtonPasswordRecovery,
	PasswordRecovery,
	Title,
	TitleContainer,
	VerificationContentBox,
} from "./AccountRecoveryChangePassword-styled";
import { useContext, useState } from "react";
import { EagleContext } from "../../../../contexts/EagleContext";
import { useParams } from "react-router-dom";
import { validatePasswordStrength } from "../../../../services/handleCredentials";
import { ErrorMessage } from "../../../forms/FormsMessages";

import language from "../../../../services/defineLanguage";
import RecoveryStatus from "../../../common/RecoveryStatus/RecoveryStatus";
const textsSignup = require(`../../../../texts/${language}/signup`);
const texts = require(`../../../../texts/${language}/accountRecovery.js`)

function AccountRecoveryChangePassword({ children }) {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const { accountRecovery } = useContext(EagleContext);
	const [recovertyTry, setRecoveryTry] = useState({});
    const [requestRecovery, setRequestRecovery] = useState(false)
	const [errorMessage, setErrorMessage] = useState("");
	const { code } = useParams();

	async function handdleAccountRecovery(event) {
		event.preventDefault();

		if (confirmPassword !== password) {
			setErrorMessage(textsSignup.errors.differentPasswords);
			return;
		}

		if (!validatePasswordStrength(password)) {
			setErrorMessage(textsSignup.errors.weakPassword);
			return;
		}

        setRequestRecovery(true)
		setRecoveryTry(await accountRecovery(code, password));
	}

	return (
		<Background>
			<EntraceAccessManager>
				<Header />
				{children}

				<VerificationContentBox
					as={"form"}
					onSubmit={handdleAccountRecovery}
				>
					<TitleContainer>
						<Title>{texts.title}</Title>
					</TitleContainer>
					{requestRecovery ? (
						<RecoveryStatus
							isLoading={recovertyTry.recovered === undefined}
							wasRecovery={recovertyTry.recovered}
							errorMessage={recovertyTry.error}
						/>
					) : (
						<>
							<DivPasswordRecovery>
								<PasswordRecovery
									placeholder={textsSignup.placeholderPassword}
									icon="password"
									name="password"
									value={password}
									onChange={(event) => {
										setPassword(event.target.value);
									}}
								/>
							</DivPasswordRecovery>
							<DivPasswordRecovery>
								<PasswordRecovery
									placeholder={textsSignup.placeholderPasswordConfirmation}
									icon="password"
									name="confirmPassword"
									value={confirmPassword}
									onChange={(event) => {
										setConfirmPassword(event.target.value);
									}}
								/>
							</DivPasswordRecovery>
							{errorMessage && <ErrorMessage margin={"15px 0 0 0"}>{errorMessage}</ErrorMessage>}
							<ButtonPasswordRecovery>{texts.changePassword}</ButtonPasswordRecovery>
						</>
					)}
				</VerificationContentBox>
				<Footer />
			</EntraceAccessManager>
		</Background>
	);
}

export default AccountRecoveryChangePassword;
