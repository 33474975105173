import { useState, useEffect } from 'react'
import language from '../../../../../services/defineLanguage.js'
// const texts = require(`../../../../texts/${language}/mission`)

import {
	Wrapper,
	Statement2Container,
	StatementWord,
} from './complete-with-options'

import MissionSelect from '../../../../common/MissionSelect/MissionSelect'
import { removePunctuation } from '../../../../../constants/removePunctuation.js'

export default function CompleteWithOptions({
	data,
	callback: handleTaskFeedback,
}) {
	const [statementWords, setStatementWords] = useState([])
	const [answerOptions, setAnswerOptions] = useState([])

	const [numSelectComponents, setNumSelectComponents] = useState(0)
	const [numCorrectAnswers, setNumCorrectAnswers] = useState(0)

	useEffect(() => {
		setStatementWords(data.statement2.split(' '))
		setAnswerOptions(data.answerOptions)
		countNumberOfSelectComponents()
	}, [data])

	function countNumberOfSelectComponents() {
		let number = 0

		statementWords.map((word) =>
			answerOptions.find(
				(option) =>
					option.answerText.toUpperCase() ===
					removePunctuation(word.toUpperCase())
			)
				? number + 1
				: null
		)

		setNumSelectComponents(number)
	}

	const handleSelectChange = (isCorrect) => {
		if (isCorrect === false && numCorrectAnswers > 0)
			setNumCorrectAnswers(numCorrectAnswers - 1)
		else if (isCorrect && numCorrectAnswers < numSelectComponents)
			setNumCorrectAnswers(numCorrectAnswers + 1)

		if (numSelectComponents === numCorrectAnswers) handleTaskFeedback(true)
		else handleTaskFeedback(false)

		handleTaskFeedback(isCorrect)
	}

	function renderStatementWords() {
		return statementWords.map((word, index) => {
			return (
				<StatementWord key={index}>
					{/* Every option that appears as a word of Statement 2 will be replaced as a select */}
					{answerOptions.find(
						(option) =>
							option.answerText.toUpperCase() ===
							removePunctuation(word.toUpperCase())
					) ? (
						<MissionSelect
							answerWord={word}
							callback={handleSelectChange}
							options={answerOptions}
						/>
					) : (
						word
					)}
				</StatementWord>
			)
		})
	}

	return (
		<Wrapper>
			<Statement2Container>
				{answerOptions && renderStatementWords()}
			</Statement2Container>
		</Wrapper>
	)
}
