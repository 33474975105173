import styled, { createGlobalStyle } from 'styled-components'
import BackgroundImage from './assets/images/loginBackground.png'

const primary = '#0061f2'

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
		scrollbar-width: thin;
        ${'' /* scrollbar-color: #393f97 #fefefe; */}
    /*     font-family: 'Source Sans Pro', sans-serif; */
    }

    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

	/* width */
	::-webkit-scrollbar {
        width: 8px;
		height: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
`

export const Container = styled.div`
	z-index: 1;
	width: 100%;
	/* max-width: 1300px; */
	margin-right: auto;
	margin-left: auto;
	padding-right: 50px;
	padding-left: 50px;

	@media screen and (max-width: 991px) {
		padding-right: 30px;
		padding-left: 30px;
	}
`

export const Button = styled.button`
	font-weight: normal;
	text-decoration:none;
	text-align:center;
	border-radius: 4px;
	background: ${({ primary }) => (primary ? '#4b59f7' : '#0467fb')};
	white-space: normal;
	padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
	color: #fff;
	font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
	outline: none;
	border: none;
	cursor: pointer;
	align-self: center;

	&:disabled {
		background-color: ${(props) => props.theme.colors.darkGray};
		cursor: not-allowed;

		&:hover {
			transition: all 0.3s ease-out;
			background: #fff;
			background: ${({ primary }) => (primary ? '#58595B' : '#a0a2a7d7')};
		}
	}

	&:hover {
		transition: all 0.3s ease-out;
		background: #fff;
		background: ${({ primary }) => (primary ? '#0467fb' : '#4b59f7')};
	}

	@media screen and (max-width: 960px) {
		width: 100%;
	}

`

export const OutlineButton = styled(Button)`
	border: 1px solid ${(props) => props.theme.colors.mediumGray};
	padding: 0.5rem;
	margin: 0.2rem;

	background-color: ${(props) => props.theme.colors.white};
	/* color: ${(props) => props.theme.colors.mediumGray}; */
	background: ${({ primary }) => (primary ? '#0467fb' : '#4b59f7')};

	cursor: pointer;

	&:hover {
		transition: all 0.3s ease-out;
		color: ${(props) => props.theme.colors.white};
		background: ${({ primary }) => (primary ? '#0467fb' : '#4b59f7')};
	}
`

export const StyledBackground = styled.div`
	background-color: ${primary};
	background:linear-gradient(135deg, ${primary} 10%, #6900C7 70%);
`

export default GlobalStyle
