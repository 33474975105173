module.exports = {
	formTitle: 'Suggestions',
	formSubtitle: 'Got any feedback? Please let us know!',
	placeholderTitle: 'Title',
	placeholderBody: 'Insert your suggestions...',
	inputFilePlaceholder: 'Submit file',
	submitLabel: 'Submit',
	uploadFileLabel: 'Upload',
	success: "Suggestion sended successfully!",
	tryAgain:"Try again",
	sendOneMore:"Send one more",


	errors: {
		FileNotSelected: 'Please select File',
		FileTooLarge: 'File size cannot exceed more than 5MB',
		default:"The suggestion can't be sended.. Try again later!",

	},
}
