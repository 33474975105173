/** @format */

import { useState, useContext } from "react";
import { QuestionType, QuestionInstruction } from "../Question-styled";
import { FaRegHandPeace, FaTimesCircle, FaArrowsAltH } from "react-icons/fa";

import {
	Wrapper,
	PairsTable,
	NewPairContainer,
	PairContainer,
	PairText,
	RemoveButton,
} from "./Pairs-styled";

import Input from "../../../../common/Input/Input";
import { Button } from "../../../../GenericComponents";

import language from "../../../../../services/defineLanguage";
import {
	createPair,
	deletePair,
	editTask,
} from "../../../../../services/Instructor";
import DynamicFaIcon from "../../../../common/DynamicFaIcon";
import { IconInputWrapper } from "../../Mission-styled";
import { IconInput } from "../../../../common/IconInput/IconInput";
import { EagleContext } from "../../../../../contexts/EagleContext";
import { ButtonWithLoading } from "../../../../common/ButtonWithLoading/ButtonWithLoading";
import { FloatingMessageBox } from "../../../../common/FlaotingBox/FloatingMessageBox/FloatingMessageBox";
const texts = require(`../../../../../texts/${language}/instructor/pairs`);

export default function Pairs({ taskId, pairs, initialIcon, callback }) {
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [newPairA, setNewPairA] = useState("");
	const [icon, setIcon] = useState(initialIcon || "");
	const [newPairB, setNewPairB] = useState("");
	const [deletePairResponse, setDeletePairResponse] = useState({});
	const [saveIconResponse, setSaveIconResponse] = useState({});
	const [addPairResponse, setAddPairResponse] = useState({});

	async function tryAddPair() {
		let response = await createPair(taskId, newPairA, newPairB);
		if (response.success) {
			callback();
			setNewPairA("");
			setNewPairB("");
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
		}

		setAddPairResponse({ ...response, order: Date.now() });
	}
	async function addPair(event) {
		if (event) {
			event.preventDefault();
		}
		if (newPairA.length < 1 || newPairB.length < 1) {
			return;
		}

		tryAddPair();
		setAddPairResponse({ isLoading: true });
	}

	async function trySaveNewIcon() {
		let response = await editTask(taskId, undefined, icon);
		if (response.success) {
			callback();
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
			setIcon(initialIcon || "");
		}

		setSaveIconResponse({ ...response, order: Date.now() });
	}
	async function saveNewIcon() {
		trySaveNewIcon();
		setSaveIconResponse({ isLoading: true });
	}

	async function tryRemovePair(pairId) {
		let response = await deletePair(pairId);
		if (response.success) {
			callback();
		}

		setDeletePairResponse({ ...response, order: Date.now() });
	}
	async function removePair(pairId) {
		tryRemovePair(pairId);
		setDeletePairResponse({ isLoading: true, pairId });
	}

	function handleOnKeyPressed(e) {
		if (e.key == "Enter") {
			e.preventDefault();
			if (newPairA.length < 1 || newPairB.length < 1) {
				return;
			}
			addPair();
		}
	}

	function renderOptions() {
		return pairs.map((pair, index) => (
			<PairContainer key={index}>
				<ButtonWithLoading
					ButtonTemplate={RemoveButton}
					onClick={(e) => {
						if (e) {
							e.preventDefault();
						}
						removePair(pair.id);
					}}
					disabled={
						deletePairResponse.isLoading &&
						deletePairResponse.pairId !== pair.id
					}
					isloading={
						deletePairResponse.isLoading &&
						deletePairResponse.pairId === pair.id
					}
				>
					<FaTimesCircle />
				</ButtonWithLoading>
				<PairText>{pair.a}</PairText>
				<span>
					<FaArrowsAltH size={20} />
				</span>
				<PairText>{pair.b}</PairText>
			</PairContainer>
		));
	}

	return (
		<>
			<DynamicFaIcon
				name={initialIcon}
				size={50}
			/>
			<IconInputWrapper>
				<IconInput
					value={icon}
					setValue={setIcon}
				/>
			</IconInputWrapper>
			<ButtonWithLoading
				margin={"10px 0 0 0"}
				width={"80px"}
				type="button"
				onClick={saveNewIcon}
				disabled={initialIcon === icon}
				isloading={saveIconResponse.isLoading}
			>
				{" "}
				{texts.save}
			</ButtonWithLoading>
			<QuestionType>{texts.type}</QuestionType>
			<QuestionInstruction>{texts.instruction}</QuestionInstruction>
			<Wrapper>
				<PairsTable>
					{renderOptions()}
					<NewPairContainer>
						<Input
							value={newPairA}
							setValue={setNewPairA}
							placeholder={texts.firstElement}
							onKeyPress={handleOnKeyPressed}
						/>
						<span>
							<FaArrowsAltH size={20} />
						</span>
						<Input
							value={newPairB}
							setValue={setNewPairB}
							placeholder={texts.secondElement}
							onKeyPress={handleOnKeyPressed}
						/>
						<ButtonWithLoading
							type="button"
							width={"100px"}
							onClick={addPair}
							disabled={newPairA === "" || newPairB === ""}
							isloading={addPairResponse.isLoading}
						>
							{texts.add}
						</ButtonWithLoading>
					</NewPairContainer>
				</PairsTable>
			</Wrapper>
			{addPairResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={addPairResponse.order - 1707521092301}
				>
					{texts.errors[addPairResponse.error] ?? texts.errors.default}
				</FloatingMessageBox>
			)}
			{saveIconResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={saveIconResponse.order - 1707521092301}
				>
					{texts.errors[saveIconResponse.error] ?? texts.errors.default}
				</FloatingMessageBox>
			)}
			{deletePairResponse.success === false && (
				<FloatingMessageBox
					type="error"
					zIndex={deletePairResponse.order - 1707521092301}
				>
					{texts.errors[deletePairResponse.error] ?? texts.errors.default}
				</FloatingMessageBox>
			)}
		</>
	);
}
