import styled from 'styled-components'

export const Container = styled.div`
	flex-grow:1;
	display: grid;
	grid-template-columns:  ${(props) => props.isStorytellingAvailable? "25% 75%" : "100%"};
	grid-template-rows: 180px auto;
	max-width: 100vw;
	overflow-x: hidden;

	background-color: ${(props) => props.theme.colors.gray};

	@media screen and (max-width: 1080px) {
		grid-template-columns: 100%;
		grid-template-rows: 160px auto auto;
	}
`
