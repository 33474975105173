/** @format */

import { useState, useContext, useEffect } from "react";
import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import { GamificationContainer, Title, SubTitle } from "./Gamification-styled";
import GamificationElement from "./GamificationElement/GamificationElement";

import language from "../../../services/defineLanguage";
import basicGamificationSettings from "../../../constants/basicGamificationSettings";
import { InstructorContext } from "../../../contexts/InstructorContext";
import {
	getGamificationSettings,
	updateGamificationSettings,
} from "../../../services/Instructor";
import { EagleContext } from "../../../contexts/EagleContext";
const texts = require(`../../../texts/${language}/gamificationBasics`);

export default function Basics() {
	const { currentCourseId } = useContext(InstructorContext);
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [settings, setSettings] = useState({});
	const [reload, setReload] = useState({});

	const forceReload = () => {
		setReload({});
	};

	const loadData = async () => {
		try {
			let newSettings = await getGamificationSettings(currentCourseId);
			setSettings(newSettings);
		} catch (err) {
			console.log("Get GamificationSettings error!");
		}
	};

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		loadData();
	}, [reload]);

	async function handleChangeBasicSettings(basicSettingName) {
		let currentBasicSettingValue = verifyIfIsOn(basicSettingName);
		let settingToUpdate = {};
		for (var key in basicGamificationSettings[basicSettingName]) {
			settingToUpdate[basicGamificationSettings[basicSettingName][key]] =
				!currentBasicSettingValue;
		}
		let response = await updateGamificationSettings(
			currentCourseId,
			settingToUpdate
		);

		if (response.success) {
			forceReload();
		} else if (response.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
		}
	}

	function verifyIfIsOn(basicSettingsName) {
		for (var key in basicGamificationSettings[basicSettingsName]) {
			if (!settings[basicGamificationSettings[basicSettingsName][key]])
				return false;
		}
		return true;
	}

	return (
		<InstructorStructure>
			<GamificationContainer>
				<Title>{texts.basicSettings}</Title>
				<SubTitle>{texts.settingsDescription}</SubTitle>
				<GamificationElement
					title={texts.performance.title}
					checked={verifyIfIsOn("performance")}
					setChecked={() => handleChangeBasicSettings("performance")}
					description={texts.performance.description}
				/>
				<GamificationElement
					title={texts.ecological.title}
					checked={verifyIfIsOn("ecological")}
					setChecked={() => handleChangeBasicSettings("ecological")}
					description={texts.ecological.description}
				/>
				<GamificationElement
					title={texts.social.title}
					checked={verifyIfIsOn("social")}
					setChecked={() => handleChangeBasicSettings("social")}
					description={texts.social.description}
				/>
				<GamificationElement
					title={texts.personal.title}
					checked={verifyIfIsOn("personal")}
					setChecked={() => handleChangeBasicSettings("personal")}
					description={texts.personal.description}
				/>
				<GamificationElement
					title={texts.fictional.title}
					checked={verifyIfIsOn("fictional")}
					setChecked={() => handleChangeBasicSettings("fictional")}
					description={texts.fictional.description}
				/>
			</GamificationContainer>
		</InstructorStructure>
	);
}
