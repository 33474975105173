/** @format */
import { Wrapper, EmojiImage, Description } from "../Message-styled";
import StatusMessage from "../StatusMessage/StatusMessage";

import welcomeBackEmojiEn from "../../../../assets/images/emojis/welcomeBacken.svg";
import welcomeBackEmojiPt from "../../../../assets/images/emojis/welcomeBackpt.svg";

import language from "../../../../services/defineLanguage";

const messagesTexts = require(`../../../../texts/${language}/messages`);

const emojis = {
	en: welcomeBackEmojiEn,
	pt: welcomeBackEmojiPt,
};

function WelcomeBackContent({}) {
	return (
		<Wrapper>
			<EmojiImage src={emojis[language]} />
			<Description>{messagesTexts.welcomeBack_desc}</Description>
		</Wrapper>
	);
}

export default {
	content: WelcomeBackContent,
	title: messagesTexts.welcomeBack_title,
};
