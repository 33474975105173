/** @format */

module.exports = {
	typeHere: "Type here...",
	save: "Save",
	delete: "Delete subject",
	confirmDelete: "Do you really want to delete this subject?",
	title: "Title",
	description: "Description",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
