import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DashboardContainer } from './Course-styled'
import InstructorStructure from '../../environments/instructor/InstructorStructure/InstructorStructure'

// Page Sections
import CourseOverview from './Sections/CourseOverview.jsx'
import Subjects from './Sections/Subjects.jsx'

import { getCourse } from '../../../services/Instructor'

export default function Course() {
    // Get course id from url
    let { courseId } = useParams()
    const [course, setCourse] = useState({})
    const [reload, setReload] = React.useState();
    
    const forceReload = () =>{setReload({})}

    const loadData = async () => {
        try {
            let currentCourse = await getCourse(courseId)
            // Finding the course by its id
            setCourse(currentCourse)
        } catch (err) {
            console.log('Get course Error!')
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        loadData()
    }, [reload])


    return (
        <InstructorStructure>
            <DashboardContainer>
                <CourseOverview data={course} forceReload={forceReload} />
                <Subjects data={course.Subjects || []} courseId={course.id} forceReloadCallback={forceReload}/>
            </DashboardContainer>
        </InstructorStructure>
    )
}
