/** @format */

import Modal2 from "../Modal2/Modal2";
import Chest from "../Chest/Chest";
import {
	ChanceExplanation,
	ChestsContainer,
	ChanceFooter,
	ChestWrapper,
} from "./chance-styled.js";
import { Button } from "../../GenericComponents";
import { useState, useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";
import StatusMessage from "../Message/StatusMessage/StatusMessage";
export default function Chance({ onClose }) {
	const { openBonusChest } = useContext(EagleContext);
	const [chestsStates, setChestsStates] = useState([
		"locked",
		"locked",
		"locked",
	]);
	const [coinsEarned, setCoinsEarned] = useState(null);

	async function handleOpenChest(selectedChest) {
		if (coinsEarned === null) {
			let coinsEarned = await openBonusChest(selectedChest);
			setChestsStates((lastState) => {
				let newState = [...lastState];
				if (coinsEarned <= 5) {
					newState[selectedChest] = "small";
				} else if (coinsEarned <= 10) {
					newState[selectedChest] = "medium";
				} else {
					newState[selectedChest] = "large";
				}

				return newState;
			});
			setCoinsEarned(coinsEarned);
		}
	}

	return (
		<Modal2
			title={"Você ganhou um baú bônus!"}
			show={true}
			// minWidth={"80vw"}
			disableCloseButton={true}
			onClose={() => {
				if (coinsEarned !== null) onClose();
			}}
			footer={
				<ChanceFooter active={coinsEarned !== null}>
					<>
						<span>
							You earned{" "}
							<StatusMessage
								type="economy"
								value={coinsEarned}
							/>
							!
						</span>
						<Button onClick={onClose}> Continue </Button>
					</>
				</ChanceFooter>
			}
			disableFooter={coinsEarned === null}
		>
			<ChanceExplanation>
				Selecione um dos três baús para ganhar uma recompensa!
			</ChanceExplanation>
			<ChestsContainer>
				<ChestWrapper
					onClick={() => {
						handleOpenChest(0);
					}}
				>
					<Chest state={chestsStates[0]} />
				</ChestWrapper>
				<ChestWrapper
					onClick={() => {
						handleOpenChest(1);
					}}
				>
					<Chest state={chestsStates[1]} />
				</ChestWrapper>
				<ChestWrapper
					onClick={() => {
						handleOpenChest(2);
					}}
				>
					<Chest state={chestsStates[2]} />
				</ChestWrapper>
			</ChestsContainer>
		</Modal2>
	);
}
