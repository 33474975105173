/** @format */

import styled from "styled-components";

export const Wrapper = styled.div`
	overflow-x: auto;
	padding: 10px;
	margin: 25px 0;
`;

export const TableContainer = styled.table`
	border-collapse: collapse;
	border-spacing: 0;

	width: 100%;

	overflow: hidden;

	border-radius: ${(props) =>
		props.withBottomBorder ? "0.5em 0.5em 0 0" : "0.5em 0.5em 0.5em 0.5em"};

	margin: 0;
	font-size: 0.9em;
	font-family: sans-serif;
`;

export const HeaderContainer = styled.thead`
	& > tr {
		background-color: ${(props) => props.color || props.theme.colors.secondary};
		color: ${(props) => props.theme.colors.white};
		text-align: left;
	}
`;

export const HeaderCell = styled.th`
	padding: 12px 15px;
	cursor: pointer;
`;

export const Row = styled.tr``;

export const BodyContainer = styled.tbody`
	border-bottom: 1px solid #dddddd;

	& > tr:nth-of-type(even) {
		background-color: ${(props) => props.theme.colors.gray};
	}

	& > tr:last-of-type {
		border-bottom: ${(props) =>
			props.withBottomBorder
				? " 2px solid " + (props.color || props.theme.colors.primary)
				: "none"};
	}
`;

export const DataCell = styled.td`
	padding: 12px 15px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
