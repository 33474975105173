/** @format */

module.exports = {
	type: "Selecione a opção adequada",
	instruction:
		"Digite abaixo o enunciado da questão. Adicione também itens de resposta e indique a alternativa correta.",
	typeHere: "Digite aqui...",
	newOption: "Nova alternativa...",
	add: "Adicionar",
	save: "Salvar",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
