/** @format */

module.exports = {
	courseName: "Nome do curso",
	courseDescription: "Descrição do curso",
	typeHere: "Digite aqui...",
	create: "Criar",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
