module.exports = {
    courses: 'Courses',
    english: 'English',
    addCourse: 'Add course',
    students: 'Students',
    newRegistrations: 'New registrations',
    yourStudents: 'Your students',
    performance: 'Performance',
    courseStats: 'Course statistics',
    gamification: 'Gamification',
    basicSettings: 'Basic settings',
    advancedSettings: 'Advanced settings',
    support: 'Support',
    tutorials: 'Tutorials',
}
