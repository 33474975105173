/** @format */

module.exports = {
	completedSubjects: "Completed subjects:",
	completedMissions: "Completed missions:",
	completedTasks: "Completed tasks:",
	subjects: "Subjects",
	missions: "Missions",
	attempt: "Attempt",
	attempts: "Attempts",
	tasks: "Tasks",
	noSubjects: "No subjects found.",
	noMissions: "No missions on this subject.",
	noTasks: "No tasks on this mission.",
	done: "Done",
	correct: "Correct",
	pending: "Pending",
	notFinished: "Not Finished",
	notDone: "Not Done",
	wrong: "Wrong",
	inProgress: "In Progress",
	skips: "Skips",
};
