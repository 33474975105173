/** @format */
import { Wrapper, BadgeContainer, Description } from "../Message-styled";
import { Button } from "../../../../globalStyles";
import { Link } from "react-router-dom";

import language from "../../../../services/defineLanguage";

const messagesTexts = require(`../../../../texts/${language}/messages`);

function TeamSuccessContent({ extraData }) {
	// '<span>'.$messages_strings['congratulations'].$messages_strings['teamSuccess_desc1'].$this->messageString.$messages_strings['teamSuccess_desc2']."</b>".'</span></br>';
	//             $str .= "</br>";
	//             $str .= "<a className='btn btn-primary' href='./course.php?id=$this->messageInt' >".$messages_strings['goToCourse']."</a></div>";
	return (
		<Wrapper>
			<BadgeContainer>
				<Description>
					<span>
						{messagesTexts.congratulations} {messagesTexts.teamSuccess_desc1}{" "}
						{extraData.course.title} {messagesTexts.teamSuccess_desc2}
					</span>
				</Description>
			</BadgeContainer>
			<Button
				as={Link}
				to={`/course/${extraData.course.id}`}
			>
				{" "}
				{messagesTexts.goToCourse}
			</Button>
		</Wrapper>
	);
}

export default {
	content: TeamSuccessContent,
	title: messagesTexts.congratulations,
};
