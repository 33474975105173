/** @format */

module.exports = {
	store: "Loja",
	description: "Compre novos itens para a sua coleção!",
	rarity: "Raridade",
	novelty: "Itens festivos",
	boost: "Impulsione seus estudos",

	items: {
		GoldFeather: {
			name: "Pena de ouro",
			description: "Uma linda pena de ouro para sua coleção",
		},

		MetalClaws: {
			name: "Garras de metal",
			description: "Uma imponente garra de metal para ostentar",
		},

		LuckyNail: {
			name: "Unha da sorte",
			description: "Linda unha de águia para lhe trazer sorte",
		},

		DiamondEgg: {
			name: "Ovo de diamante",
			description: "Maravilhoso ovo de diamante para guardar",
		},

		ChristmasEagle: {
			name: "Águia natalina",
			description: "Sua águia preparada para o Natal",
		},

		RomanticEagle: {
			romantic: "Águia romântica",
			description: "Linda águia apaixonada <3",
		},

		NurseEagle: {
			name: "Águia enfermeira",
			description: "Águia enfermeira para cuidar de você",
		},

		TeacherEagle: {
			name: "Águia professora",
			description: "Águia professora para inspirar você",
		},

		DoubleOrNothing: {
			name: "Dobro ou nada",
			description: "Receba o dobro de pontos a cada missão realizada",
		},

		BigDay: {
			name: "Grande dia",
			description: "Passe o dia ganhando o triplo de pontos",
		},

		SuperWeek: {
			name: "Super semana",
			description: "Passe a semana ganhando o dobro de pontos",
		},

		MyMonth: {
			name: "Meu mês",
			description: "Passe o mês ganhando o dobro de pontos e moedas",
		},
	},
	buy: "Comprar",
	ok: "Ok",
	confirmMessage1: "Você tem certeza que deseja comprar ",
	confirmMessage2: "Se você comprar vai restar-lhe ",
	cantBuyTitle: "Você não tem o suficiente",
	cantBuy: "Você não tem dinehiro suficiente para comprar o item ",
	youOnlyHave: "Você tem apenas",
};
