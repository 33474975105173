/** @format */

module.exports = {
	back: "Voltar",
	editSubject: "Editar assunto",
	missions: "Missões",
	newMission: "Nova missão",
	createNewMission: "Criar nova missão",
	edit: "Editar",
	sm: "MS",
	delete: "Excluir",
	editMission: "Editar missão",
	confirmDelete: "Deseja realmente excluir este assunto?",
	confirmDeleteMission: "Deseja realmente excluir este missão?",
	supportMaterials: "Materiais de suporte",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
