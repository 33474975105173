/** @format */

module.exports = {
	gamification: "Gamificação",
	advancedSettings: "Configurações avançadas de gamificação",
	settingsDescription:
		"Habilite ou desabilite elementos específicos da gamificação no seu curso.",
	acknowledgment: {
		title: "Reconhecimento",
		description:
			"Todo tipo de feedback que reconhece/parabeniza as ações específicas dos jogadores. O elemento Reconhecimento é exibido em todas as páginas (fixo no cabeçalho) e é destacado na Página Inicial e na página Perfil, sendo representado pela exibição de emblemas/medalhas dos estudantes. Seis emblemas diferentes podem ser alcançados através de sequências especiais no sistema: a)completar uma missão sem cometer nenhum erro (patente de especialista), b)completar 5 missões sem cometer nenhum erro (patente de super conquistador), c)completar 10 missões sem cometer nenhum erro (classificação de herói), d)subir três divisões em sequência (classificação de ótimo pesquisador), e)liderar a classificação por 2 semanas seguidas (Classificação de grande líder) f)desbloquear todas as missões (Classificação de grande chefe). Quando Reconhecimento está desabilitado, emblemas não aparecem na interface do estudante.",
		icon: "FaGamepad",
	},
	level: {
		title: "Nível",
		description:
			"Camadas hierárquicas presentes em um jogo que fornece uma maneira gradual para o jogador ganhar novas vantagens à medida que ele progride. O elemento Nível é exibido na página inicial e é representado pelas fases (Bronze, Prata, Ouro, Diamante e Rubi, respectivamente). Todos os estudantes começam na fase Bronze. Os dois primeiros lugares na classificação semanal sobem de nível e os dois últimos retornam para a divisão anterior (a cada divisão, o nível das atividades fica mais difícil). Além disso, cada estudante recebe 5 moedas para compras na loja sempre que avançam de fase ou 2 moedas sempre que eles permanecem na fase (ver a implementação do elemento Economia).Quando Nível é desabilitado, as fases não aparecem na interface do estudantes, e os estudantes não ganham essas moedas especificas.",
		icon: "FaGamepad",
	},
	point: {
		title: "Pontos",
		description:
			"Unidades para medir o desempenho do usuário. O elemento Ponto é exibido em todas as páginas (fixo no cabeçalho) e é representado por pontos de experiencia (XP). O estudante ganha sete pontos para cada atividade finalizada e dois pontos extras se a missão foi finalizada sem erros (acertar todas as atividades, sem cometer erros). A pontuação é atualizada cada vez que o estudante completa um grupo de atividade (missão). O total de pontos são também destacados na página Perfil. Quando Pontos são desabilitados, os pontos não aparecem na interface dos estudantes e a classificação é atualizada com base no número de tarefas realizadas (cada tarefa equivale a dois pontos).",
		icon: "FaGamepad",
	},
	progression: {
		title: "Progressão",
		description:
			"Permite que os jogadores se localizem (acompanhem seu progresso) dentro de um jogo. O elemento Progressão é exibido nas páginas Inicio e de Aprender e é representado como uma barra de progresso. Na página Inicio é representado por uma barra de progresso na árvore de atividades (ver a implementação do elemento Objetivos) indicando quanto cada grupo de atividadejá foi concluída e quanto falta para ser concluída. A página Aprender é representada por uma barra deprogresso que mostra quanto já foi concluída e quanto cada grupo de atividade falta para ser concluída. Quando Progressão está desabilitada, as barras de progresso não aparecem na interface dos estudantes.",
		icon: "FaGamepad",
	},
	stats: {
		title: "Estatísticas",
		description:
			"Informações visíveis usadas pelo jogador, relacionadas aos resultados do jogo dele. O elemento Estatística é representado na página Perfil (bem como na sub-página Amigos e no cabeçalho) e é representado por todas as informações de progresso do usuário (Pontos, Conquistas, Economia,Seguindo e Seguidores), que por padrão já é exibido na página Perfil. Quando Estatísticas é desabilitado, as informações mencionadas não aparecem na interface dos estudantes.",
		icon: "FaGamepad",
	},
	chance: {
		title: "Chance",
		description:
			"Características de aleatoriedade e probabilidade de aumentar ou diminuir as chances de determinadas ações e resultados. O elemento Chance é exibido na página Aprender. Os Estudantes tem diferentes tipos de escolhas para fazer, por exemplo, escolher entre baús com prêmios. Em cada baú, diferentes prêmios podem ser dados, por exemplo, moedas, pontos, ou emblemas surpresa. Quando Chance é desabilitado, os estudantes não serão mais capazes de fazer as escolhas mencionadas, pois elas não aparecerão na interface dos estudantes.",
		icon: "FaGamepad",
	},
	economy: {
		title: "Economia",
		description:
			"Transações dentro do jogo, monetizando os valores do jogo e outros elementos. O elemento Economia aparece em todas a páginas (fixo no cabeçalho) e é representados por moedas que podem ser usadas para fazer compras no jogo (ver a implementação do elemento Raridade). Moedas podem ser ganhas através dos elementos de Competição e Cooperação (ver suas respectivas implementações). Além disso, a cada três missões, os estudantes podem escolher abrir um baú que tem um valor aleatório entre 1 ou 10 moedas (ver a implementação do elemento de Escolha Imposta). O total de moedas é também destacado na página Perfil. Quando Economia é desabilitada o estudante para deganhar moedas e a página Loja não aparece mais na interface dos estudantes.",
		icon: "FaGamepad",
	},
	imposedChoice: {
		title: "Escolha imposta",
		description:
			"Decisões necessárias que o jogador deve tomar para avançar no jogo. O elemento Chance aparece na página Aprender e é representado pela opção aleatória oferecida ao usuário para aumentar seu prêmio. Em determinados momentos (aleatórios), antes de iniciar uma missão, o usuário é informado que se completar aquela missão específica sem nenhum erro, ele ganha 2 moedas extras erecebe o dobro dos pontos, porém, se ele cometer um erro em alguma tarefa, ele não ganhará nenhum ponto e nenhuma moeda. Quando Escolha Imposta está desabilitada, os estudantes não terão essas opções de escolha em sua interface.",
		icon: "FaGamepad",
	},
	rarity: {
		title: "Raridade",
		description:
			"Recursos limitados e colecionáveis. O elemento Raridade aparece na página Loja e é representado como uma série de escudos. Itens raros estão disponíveis no sistema da loja com valores de compra que podem ser adquiridos por meio de moedas (ver Implementação do elemento Economia). Quando a Raridade está desabilitada, os itens raros não aparecem no estoque da loja.",
		icon: "FaGamepad",
	},
	timePressure: {
		title: "Pressão do tempo",
		description:
			"Pressão de tempo dentro do jogo/sistema. O elemento Pressão de tempo está presente na página inicial e é representado por uma contagem regressiva semanal (assim associada ao elemento Competição). A contagem regressiva começa a cada semana e é exibida no formato de dia/hora.Quando Pressão do Tempo está desabilitada, o relógio de classificação não aparecena interface dos estudantes.",
		icon: "FaGamepad",
	},
	competition: {
		title: "Competição",
		description:
			"Ocorre quando dois ou mais jogadores competem entre si por um objetivo em comum. O elemento Competição é destacado na página inicial e é representado por um ranking semanal (tabela de classificação com até 10 estudantes). A classificação é baseada nos pontos ganhos (veja a implementação do elemento Pontos), porém é redefinida semanalmente. O primeiro colocado em cada classificação semanal ganha 50 moedas para compras na loja (ver a implementação do elemento Economia). O segundo colocado ganha 40 moedas, terceiro colocado 30, quarto colocado 20 e quinto colocado 10. Quando Competição é desabilitada, a classificação não aparece na interface dos estudantese para de gerar suas moedas específicas.",
		icon: "FaGamepad",
	},
	cooperation: {
		title: "Cooperação",
		description:
			"Quando dois ou mais jogadores colaboram para atingir um objetivo em comum. O elemento Cooperação está destacado na página inicial e é representado pela formação aleatórias de equipes de até 5 estudantes. Todos os pontos ganhos individualmente por cada estudante são simultaneamente acumulados individualmente e para a equipe à qual foram atribuídos durante a semana. Além disso, sempre que todos os estudantes de uma equipe completam as mesmas atividades, a equipe ganha cinco pontos extras (somente a equipe). Se a equipe atingir a meta semanal (definida a cada semana), todos os membros ganham 10 moedas para compras na loja (ver a implementação do elemento Economia). Quando Cooperação está desabilitada, o quadro da equipe não aparece na interface dos estudantes.",
		icon: "FaGamepad",
	},
	reputation: {
		title: "Reputação",
		description:
			"Títulos que o jogador acumula no jogo. É mostrada na página inicial e é representada por títulos que o estudante ganha conforme ele conclui as atividades. Existem 5 títulos, começando no “Iniciante” e terminando no “Grande rei”. Quando a Reputação está desabilitada, os títulos não aparecem na interface dos estudantes.",
		icon: "FaGamepad",
	},
	socialPressure: {
		title: "Pressão social",
		description:
			"Pressão por meio de interações sociais com outro(s) jogador(es). O elemento Pressão Social é exibido na página Perfil e é representado por uma mensagem de alerta sempreque outro estudante termina uma sequência de atividades que ele/ela ainda não concluiu. Quando PressãoSocial for desabilitada, os estudantes não receberão mais mensagens de pressão.",
		icon: "FaGamepad",
	},
	novelty: {
		title: "Novidade",
		description:
			"Informações novas e atualizadas apresentadas ao jogador continuamente. O elemento Novidade aparece na página Loja. Ele é representado pela venda de objetos especiais de acordo com as datas comemorativas anuais (e.g., Natal ou Páscoa). Quando Novidade está desabilitada, os itens especiais não aparecem na loja.",
		icon: "FaGamepad",
	},
	objectives: {
		title: "Objetivos",
		description:
			"Orientar as ações dos jogadores. Quantificáveis ou espaciais, de curto a longo prazo. O elemento Objetivos é exibido na página inicial e é representado por uma árvore de missão. Esta árvore mostra toda a sequência de missões que o estudante possui no sistema, destacando o que já foi feito eo que ainda pode ser feito. Quando Objetivos está desabilitado, a árvore de atividades não aparece na interface dos estudantes e o estudante só tem acesso à próxima atividade.",
		icon: "FaGamepad",
	},
	puzzles: {
		title: "Quebra-cabeças",
		description:
			"Desafios no jogo/sistema que devem fazer o jogador/estudante pensar. O elemento Quebra-Cabeças é apresentado na página Aprender e é representado por propostas de desafios surpresa, relacionados com a matéria em estudo (selecionados aleatoriamente pelo sistema, a partir dasmissões cadastradas pelo instrutor / professor). Para cada desafio aceito, o usuário ganha 10 pontos e se resolvido com sucesso, o usuário ganha 5 moedas extras. Quando Quebra-Cabeças está desabilitado, as missões com esses desafios especiais não aparecem para os estudantes.",
		icon: "FaGamepad",
	},
	renovation: {
		title: "Renovação",
		description:
			"Quando os jogadores podem refazer/reiniciar uma ação. O elemento Renovação é exibido na página Aprender e é representado através da possibilidade de os estudantes executarem atividades em que eles erram. Não há custo para os estudantes refazerem as atividades que perderam. Cada missão só é concluída quando o estudante conclui todas as atividades. Quando Renovação está desabilitada, sempre que um usuário erra uma atividade, ele não pode mais fazer a mesma atividade.",
		icon: "FaGamepad",
	},
	sensation: {
		title: "Sensação",
		description:
			"Uso dos sentidos dos jogadores para criar novas experiências. O elemento Sensação é exibido na página Aprender e é representado por um retorno imediato (visual e sonoro) em relação a cada ação / resposta dos usuários, indicando se eles acertaram ou erraram cada questão. Quando Sensação está desabilitada, o estudante não recebe retorno visual ou sonoro sobre as atividades, apenas um retorno textual normal.",
		icon: "FaGamepad",
	},
	narrative: {
		title: "Narrativa",
		description:
			"Ordem dos eventos que acontecem em um jogo. O elemento Narrativa está presente na página Aprender e é representado pela habilidade do usuário de realizar atividades extras. Às vezes (aleatoriamente), quando o usuário conclui uma missão, ele pode escolher ir para outras páginasdo sistema ou imediatamente fazer uma nova missão com a premissa de ganhar 2 moedas extras. Quando Narrativa está desabilitada, a história não aparece na interface dos estudantes.",
		icon: "FaGamepad",
	},
	storytelling: {
		title: "História",
		description:
			"Como a história do jogo é contada (como um roteiro). Ele é contado dentro do jogo por meio de texto, voz ou capacidades sensoriais. O elemento História está presente na página Perfil e é representado pela evolução do avatar (e sua história) que representa cada estudante. A cada cinco dias ativos no sistema (utilizando o sistema em 5 dias diferentes), uma nova evolução do avatar é disponibilizada. Ao todo, 15 evoluções de avatares estão disponíveis no sistema. Quando História édesabilitada, os avatares não aparecem na interface dos estudantes.",
		icon: "FaGamepad",
	},
};
