/** @format */

import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import {
	SubjectContainer,
	SubjectTitle,
	MissionsTitle,
	ControllButton,
	NewMissionButton,
	Overview,
	ControllsWrapper,
} from "./Subject-styled";
import LinkWithIcon from "../../common/LinkWithIcon/LinkWithIcon";
import Modal2 from "../../common/Modal2/Modal2";
import NewMission from "../NewMission/NewMission";
import EditSubject from "../EditSubject/EditSubject";
import { FaPlus, FaPencilRuler, FaTrash, FaBook } from "react-icons/fa";
import MissionLink from "./MissionLink/MissionLink";
import { useHistory } from "react-router-dom";

import language from "../../../services/defineLanguage.js";
import { deleteSubject, getSubject } from "../../../services/Instructor";

import DynamicFaIcon from "../../common/DynamicFaIcon";
import SupportMaterialsEditor from "../../common/SupportMaterialsEditor/SupportMaterialsEditor";
import { ButtonWithLoading } from "../../common/ButtonWithLoading/ButtonWithLoading.jsx";
import { FloatingMessageBox } from "../../common/FlaotingBox/FloatingMessageBox/FloatingMessageBox.jsx";
const texts = require(`../../../texts/${language}/instructor/subject`);

export default function Subject() {
	let { subjectId } = useParams();
	const [subject, setSubject] = useState({});
	const [showNewMissionModal, setNewMissionModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [reload, setReload] = React.useState();
	const [supportMaterialEditor, setSupportMaterialEditor] = useState(false);
	const [deleteSubjectResponse, setDeleteSubjectResponse] = useState({});
	const history = useHistory();

	const forceReload = () => {
		setReload({});
	};

	function openSupportMaterials() {
		setSupportMaterialEditor(true);
	}

	function handleEditSubject(forceReloadNeeded) {
		if (forceReloadNeeded) {
			forceReload();
		}
		setShowEditModal(false);
	}

	function handleCreateMission(forceReloadNeeded) {
		if (forceReloadNeeded) {
			forceReload();
		}
		setNewMissionModal(false);
	}

	const loadData = async () => {
		try {
			let subjectResult = await getSubject(subjectId);
			setSubject(subjectResult);
		} catch (err) {
			console.log("Get Subject error!");
		}
	};

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		loadData();
	}, [reload]);

	async function tryDelete() {
		let response = await deleteSubject(subjectId);
		if (response.success) {
			history.push(`/instructor/course/${subject.CourseId}`);
		}
		setDeleteSubjectResponse({ ...response, order: Date.now() });
	}
	async function handleDelete() {
		if (!window.confirm(texts.confirmDelete)) {
			return;
		}

		tryDelete();
		setDeleteSubjectResponse({ isLoading: true });
	}

	return (
		<InstructorStructure>
			<SubjectContainer>
				<LinkWithIcon
					iconName="FaArrowLeft"
					to={"/instructor/course/" + subject.CourseId}
					instructorStyle
				>
					{texts.back}
				</LinkWithIcon>

				<Overview>
					<SubjectTitle>
						<DynamicFaIcon name={subject.icon} />
						{subject.title}
					</SubjectTitle>
					{subject.id && (
						<ControllsWrapper>
							<ControllButton onClick={() => setShowEditModal(true)}>
								<FaPencilRuler /> {texts.edit}
							</ControllButton>
							<ControllButton onClick={() => openSupportMaterials()}>
								<FaBook /> {texts.sm}
							</ControllButton>
							<ButtonWithLoading
								isloading={deleteSubjectResponse.isLoading}
								ButtonTemplate={ControllButton}
								onClick={handleDelete}
							>
								<FaTrash /> {texts.delete}
							</ButtonWithLoading>
						</ControllsWrapper>
					)}
				</Overview>

				<Modal2
					title={texts.editSubject}
					show={showEditModal}
					onClose={() => setShowEditModal(false)}
					disableFooter={true}
				>
					<EditSubject
						initialTitle={subject.title}
						initialIcon={subject.icon}
						initialDescription={subject.description}
						subjectId={subject.id}
						callback={handleEditSubject}
					/>
				</Modal2>

				<Modal2
					show={supportMaterialEditor}
					onClose={() => setSupportMaterialEditor(false)}
					title={texts.supportMaterials}
					disableFooter={true}
				>
					{supportMaterialEditor && (
						<SupportMaterialsEditor
							onClose={() => {
								setSupportMaterialEditor(false);
							}}
							subjectId={subjectId}
						/>
					)}
				</Modal2>

				<MissionsTitle>{texts.missions}</MissionsTitle>
				{subject &&
					subject.Missions &&
					subject.Missions.map((mission) => (
						<MissionLink
							key={mission.id}
							icon={mission.icon}
							courseId={subject.CourseId}
							subjectId={subjectId}
							missionId={mission.id}
							callback={forceReload}
							title={mission.title}
							description={mission.description}
						/>
					))}
				<NewMissionButton onClick={() => setNewMissionModal(true)}>
					<FaPlus />
					{texts.newMission}
				</NewMissionButton>
				<Modal2
					title={texts.createNewMission}
					show={showNewMissionModal}
					onClose={() => setNewMissionModal(false)}
					disableFooter={true}
				>
					<NewMission
						subjectId={subjectId}
						callback={handleCreateMission}
					/>
				</Modal2>
			</SubjectContainer>
			{deleteSubjectResponse.success === false && (
					<FloatingMessageBox
						type="error"
						zIndex={deleteSubjectResponse.order - 1707521092301}
					>
						{texts.errors[deleteSubjectResponse.error] ?? texts.errors["default"]}
					</FloatingMessageBox>
				)}
		</InstructorStructure>
	);
}
