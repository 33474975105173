/** @format */

import { useState, useContext } from "react";
import { ThemeContext } from "styled-components";
import { FaGift, FaExclamationTriangle } from "react-icons/fa";
import {
	ItemContainer,
	Icon,
	NotificationInfo,
	NotificationText,
	DateAndTime,
	NotificationRead,
	ReadContainer,
} from "./NotificationItem-styled";

import { OptionItem } from "../../../../common/OptionsButton/OptionsButton-styled";
import OptionsButton from "../../../../common/OptionsButton/OptionsButton";

//Import language and notification messages
import language from "../../../../../services/defineLanguage";
const texts = require(`../../../../../texts/${language}/notificationMessages`);

export default function NotificationItem({
	type,
	date,
	checked,
	showOptions,
	onClick,
	onCheck,
}) {
	const themeContext = useContext(ThemeContext);
	const [isDeleted, setIsDeleted] = useState(false);

	function getIcon() {
		if (type === 11 || type === 12) {
			return <FaExclamationTriangle />;
		} else {
			return <FaGift />;
		}
	}

	function getIconColor() {
		if (type >= 1 && type <= 5) {
			return themeContext.colors.green;
		} else if (type === 11 || type === 12) {
			return themeContext.colors.warning;
		} else {
			return themeContext.colors.primary;
		}
	}

	return (
		<ItemContainer onClick={onClick}>
			<Icon color={getIconColor()}>{getIcon()}</Icon>
			<NotificationInfo>
				<NotificationText>
					{texts.find((element) => element.type === type).message}
				</NotificationText>
				<DateAndTime>{date}</DateAndTime>
			</NotificationInfo>
			<ReadContainer onMouseDown={() => onCheck()}>
				{!checked && <NotificationRead />}
			</ReadContainer>
			{showOptions && (
				<OptionsButton>
					<OptionItem onMouseDown={() => onCheck()}>
						Marcar como lido
					</OptionItem>
					{/* <OptionItem onMouseDown={() => onCheck()}>
                        Marcar como não lido
                    </OptionItem> */}
				</OptionsButton>
			)}
		</ItemContainer>
	);
}
