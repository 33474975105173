/** @format */

const courseGamificationSettings = {
	progression:"progression", //All progression bars
	points:"points", //points
	stats:"stats", // points,coins,acknowledgments on header and sidebar
	competition:"competition", //Ranking
	timePressure:"timePressure", //Time in ranking
	narrative:"narrative", //Avatar
	level:"level", //level
	economy:"economy", //Coins and Store
	chance:"chance", //Bonus chest
	imposedChoice:"imposedChoice", //Double or nothing
	novelty:"novelty", // some items on store
	rarity:"rarity", // some items on store
	objectives:"objectives", // show subjects or not
	renovation:"renovation", // if choose a wrong answer, try again in the end
	sensation:"sensation", // sounds on tasks
	socialPressure:"socialPressure", // poke and message ()
	acknowledgment:"acknowledgment", //acknowledgment
	cooperation:"cooperation", //Teams
	reputation:"reputation", // Not Implemented
	puzzles:"puzzles", //Not Implemented
	storytelling:"storytelling"
};

export default courseGamificationSettings;
