/** @format */

import React, { useEffect, useState, useRef } from "react";
import {
	CloseButton,
	TextWrapper,
	FloatingBoxWrapper,
	CloseButtonWrapper,
} from "./FloatingBox-styled";
import { FaTimes } from "react-icons/fa";
import { theme } from "../../../contexts/theme/Theme";

export function FloatingBox({ color, backgroundColor, children, zIndex }) {
	let showStyle = { transform: "translate(0, 120%)" };
	const [show, setShow] = useState(false);
	const timeout = useRef(null);

	useEffect(() => {
		console.log(zIndex);

		setTimeout(() => {
			setShow(true);
		}, 100);
		return () => {
			if (timeout.current) {
				clearInterval(timeout.current);
			}
			setShow(false);
		};
	}, []);

	useEffect(() => {
		if (show) {
			timeout.current = setTimeout(() => {
				setShow(false);
			}, 15000);
		} else {
			if (timeout.current) {
				clearInterval(timeout.current);
			}
		}
	}, [show]);

	return (
		<FloatingBoxWrapper
			style={show ? showStyle : {}}
			color={color || theme.colors.primary}
			backgroundColor={backgroundColor || theme.colors.lightBlue}
			zIndex={zIndex}
		>
			{" "}
			<TextWrapper>
				<p>{children}</p>
			</TextWrapper>
			<CloseButtonWrapper>
				<CloseButton
					onClick={() => {
						setShow(false);
					}}
				>
					<FaTimes
						color={color || theme.colors.primary}
						size={"30"}
					/>
				</CloseButton>
			</CloseButtonWrapper>
		</FloatingBoxWrapper>
	);
}
