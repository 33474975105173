/** @format */

import { useEffect, useState, useContext } from "react";
import {
	DashboardContainer,
	LeftSideSection,
	RightSideSection,
} from "./course-styling";
import AppStructure from "../../environments/app/AppStructure";
import { useParams } from "react-router-dom";

// Page Sections
import CourseOverview from "./Sections/CourseOverview.jsx";
import Team from "./Sections/Team.jsx";
import Ranking from "./Sections/Ranking.jsx";
import Achievements from "./Sections/Achievements.jsx";
import Level from "./Sections/Level.jsx";
import Subjects from "./Sections/Subjects.jsx";

import { EagleContext } from "../../../contexts/EagleContext";

export default function Course({}) {
	let { courseId } = useParams();
	const {
		user,
		setNewCurrentCourse,
		loadRanking,
		loadTeams,
		currentCourse,
		getCurrentCourseSetting,
		incrementSessionUpdates
	} = useContext(EagleContext);
	const [teamMateCards, setTeamMateCards] = useState({ students: [] });
	const [RankingPositionCards, setRankingPositionCards] = useState([]);
	
	let settings = getCurrentCourseSetting();

	const loadData = async () => {
		try {
			if (settings.cooperation) {
				setTeamMateCards(await loadTeams(courseId));
			}
			if (settings.competition) {
				setRankingPositionCards(await loadRanking(courseId));
			}
		} catch (err) {
			console.log(err)
		}
	};

	useEffect(() => {
		incrementSessionUpdates("course", "access")
	},[])
	
	useEffect(async () => {
		setNewCurrentCourse(courseId);
	}, [courseId]);

	useEffect(async () => {
		// settings = getCurrentCourseSetting();
		await loadData();
	}, [currentCourse]);

	let course =
		user && user.courses
			? user.courses[
					user.courses.findIndex((course) => {
						return course.courseInfo.id == courseId;
					})
			  ]
			: { courseInfo: {}, subjects: [], level: 1 };

	return (
		<AppStructure>
			<DashboardContainer>
				<LeftSideSection>
					<CourseOverview data={course.courseInfo} />
					<Subjects data={course.subjects} />
				</LeftSideSection>
				{(settings.competition ||
					settings.timePressure ||
					settings.cooperation ||
					settings.level ||
					settings.acknowledgment) && (
					<RightSideSection>
						{(settings.competition || settings.timePressure) && (
							<Ranking
								data={RankingPositionCards}
								competitionOn={settings.competition}
								timePressureOn={settings.timePressure}
							/>
						)}
						{settings.cooperation && <Team data={teamMateCards} />}
						{settings.level && <Level level={course.level} />}
						{settings.acknowledgment && (
							<Achievements achievementsAchieved={course.achievements} />
						)}
					</RightSideSection>
				)}
			</DashboardContainer>
		</AppStructure>
	);
}
