/** @format */

import { useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import {
	FaGraduationCap,
	FaChevronRight,
	FaChevronDown,
	FaPlus,
} from "react-icons/fa";

const SidebarItemContainer = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 2.5rem;
	padding: 0 5px;
	font-size: 1.2rem;
	font-weight: 600;
	color: ${(props) => props.theme.colors.white};

	background: none;
	border: none;

	cursor: pointer;

	&:hover,
	&:focus {
		background-color: rgba(204, 204, 204, 0.15);
	}
`;

const ToggleIconRight = styled(FaChevronRight)`
	font-size: 0.9rem;
	margin-left: auto;
`;

const ToggleIconDown = styled(FaChevronDown)`
	font-size: 0.9rem;
	margin-left: auto;
`;

const ToggleSubstitution = styled.div`
	font-size: 0.9rem;
	margin-left: auto;
`;

const LinkContainer = styled.div`
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const SidebarLink = styled(Link)`
	text-decoration: none;
	display: flex;
	align-items: center;

	width: 100%;
	padding: 5px;
	height: 2.5rem;

	color: ${(props) => props.theme.colors.white};

	&:hover,
	&:focus {
		background-color: rgba(204, 204, 204, 0.15);
	}
`;

// Default style properties of the SidebarItem icon
const iconStyle = {
	fontSize: "1.7rem",
	marginRight: "3px",
};

export default function SidebarItem(props) {
	const [isHidden, setIsHidden] = useState(true);

	function handleIcon() {
		const icon = props.icon;
		if (icon === "courses") {
			return <FaGraduationCap style={iconStyle} />;
		} else if (icon === "plus") {
			return <FaPlus style={iconStyle} />;
		} else {
			return;
		}
	}

	function handleClick() {
		if(props.onClick){
			props.onClick()
			return
		}
		props.openSidebar();
		props.isSidebarHidden ? setIsHidden(false) : setIsHidden(!isHidden);
			
	}

	return (
		<li>
			<SidebarItemContainer onClick={handleClick}>
				{handleIcon()}
				{!props.isSidebarHidden && <span>{props.name}</span>}
				{!props.isSidebarHidden && !!props.children ? (
					isHidden ? (
						<ToggleIconRight />
					) : (
						<ToggleIconDown />
					)
				) : (
					!props.isSidebarHidden && <ToggleSubstitution />
				)}
			</SidebarItemContainer>

			{!props.isSidebarHidden && !isHidden && props.children && (
				<LinkContainer>{props.children}</LinkContainer>
			)}
		</li>
	);
}
