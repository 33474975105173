import { useState } from 'react'
import styled from 'styled-components'

import Navbar from './navbar/Navbar'
import Sidebar from './sidebar/Sidebar'
import PlataformAccessManager from '../../common/PlataformAccessManager'

const AppContainer = styled.div`
    display: flex;

    @media screen and (max-width: 660px) {
        overflow-x: hidden;
    }
`

const AppSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    min-height: 100vh;
`

const ContentContainer = styled.div`
    display: flex;
    flex-grow: 1;
    background-color: ${(props) => props.theme.colors.gray};
`

export default function AppStructure(props) {
    const [isSidebarHidden, setIsSidebarHidden] = useState(true)

    function toggleSidebar() {
        setIsSidebarHidden(!isSidebarHidden)
    }

    function openSidebar() {
        setIsSidebarHidden(false)
    }

    return (
        <PlataformAccessManager>
            <AppContainer>
                <Sidebar
                    isSidebarHidden={isSidebarHidden}
                    openSidebar={openSidebar}
                />
                <AppSubContainer>
                    <Navbar toggleSidebar={toggleSidebar} />
                    <ContentContainer>{props.children}</ContentContainer>
                </AppSubContainer>
            </AppContainer>
        </PlataformAccessManager>
    )
}
