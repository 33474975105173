export function validateEmail(string) {
	const indexAt = string.indexOf("@")

	// If string does not have '@', it is not an email
	if (indexAt === -1) {
		return false
	}

	// Verifying characters after '@' and preventing 'email@.com' or 'email@com.'
	const indexDot1 = string.indexOf(".", indexAt)

	if (
		indexDot1 === -1 ||
		indexDot1 === indexAt + 1 ||
		string[string.length - 1] === "."
	) {
		return false
	}

	return true
}

export function validatePasswordStrength(password) {
	if (
		password.match(/[a-z]+/) &&
		password.match(/[A-Z]+/) &&
		password.match(/[0-9]+/) &&
		password.match(/[$@#&!*']+/) &&
		password.length >= 8
	) {
		return true
	}
	return false
}

export function validateName(string) {
	return (
		!/[0123456789~`!@#$%¨¬\^&*+=\-\[\]\';,/{}|\\":<>\?]/.test(string) &&
		string.length <= 100
	)
}

export function removeSpaceBeginEnd(string) {
	let parts = string.split(" ")
	let result = parts.filter((element) => !!element)
	return result.reduce((total, element) => {
		return total + " " + element
	})
}
