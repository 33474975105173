/** @format */

import { useState, useEffect } from "react";
import language from "../../../../../services/defineLanguage.js";
// const texts = require(`../../../../texts/${language}/mission`)
import shuffleAlternatives from "../../../../../constants/shuffleAlternatives.js";

import {
	Wrapper,
	OptionsTable,
	PairContainer,
	PairLabel,
} from "./pairs-styling";

export default function Pairs({
	data,
	isQuestionAnswered,
	verifyPairs,
	completePairs,
}) {
	const [answerOptions, setAnswerOptions] = useState(() => {
		return data
			.map((pair, index) => {
				return [
					{
						index: index * 2,
						text: pair.a,
						isAnsweredRight: false,
					},
					{
						index: index * 2 + 1,
						text: pair.b,
						isAnsweredRight: false,
					},
				];
			})
			.flat();
	});
	const [selectedPairs, setSelectedPairs] = useState([]);

	const [isWrong, setIsWrong] = useState(false);

	useEffect(() => {
		isComplete();
	}, []);

	useEffect(async () => {
		if (selectedPairs.length === 2) {
			await handlePairVerification(
				answerOptions.find((pair) => {
					return pair.index === selectedPairs[0];
				}).text,

				answerOptions.find((pair) => {
					return pair.index === selectedPairs[1];
				}).text
			);

      isComplete()
		}
	}, [selectedPairs]);

	function isComplete() {
		if (answerOptions) {
			let isCompleted = true;

			for (let i = 0; i < answerOptions.length; i++) {
				if (!answerOptions[i].isAnsweredRight) {
					isCompleted = false;
					break;
				}
			}

			if (isCompleted) {
        console.log("completed")
				completePairs();
			}
		}
	}

	function handleRightAnswer() {
		setAnswerOptions((prev) => {
			let newAnswerOptions = prev.slice();
			for (let i = 0; i < newAnswerOptions.length; i++) {
				if (selectedPairs.includes(newAnswerOptions[i].index)) {
					newAnswerOptions[i].isAnsweredRight = true;
				}
			}

			return newAnswerOptions;
		});
	}

	async function handlePairVerification(pairA, pairB) {
		let isAnswerRight = await verifyPairs(pairA, pairB);
		console.log(selectedPairs);
		if (isAnswerRight) {
			handleRightAnswer();
		} else {
			setIsWrong(true);
		}
		setSelectedPairs([]);
	}

	function handlePairClick(pairIndex) {
		let clickedPairRealIndex = answerOptions.findIndex((pair) => {
			return pair.index === pairIndex;
		});
		if (clickedPairRealIndex > -1) {
			let selectIndex = selectedPairs.indexOf(pairIndex);
			if (selectIndex > -1) {
				setSelectedPairs([]);
			} else {
				if (selectedPairs.length === 0) {
					setSelectedPairs([pairIndex]);
				} else if (selectedPairs.length === 1) {
					setSelectedPairs((old) => {
						return [...old, pairIndex];
					});
				}
			}
		}
	}

	function renderPairs() {
		return answerOptions.map((option) => {
			return (
				<PairContainer
					isQuestionAnswered={isQuestionAnswered}
					key={option.index}
					onClick={() => {
						handlePairClick(option.index);
					}}
					isSelected={selectedPairs.includes(option.index)}
					isAnsweredRight={option.isAnsweredRight}
					isWrongPair={isWrong}
				>
					<PairLabel htmlFor={option.index}>{option.text}</PairLabel>
				</PairContainer>
			);
		});
	}

	return (
		<Wrapper>
			<OptionsTable>{answerOptions && renderPairs()}</OptionsTable>
		</Wrapper>
	);
}
