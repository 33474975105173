import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FaExclamationCircle } from 'react-icons/fa'

export const FormBox = styled.form`
  background: #fff;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 500px;
  max-width: 95vw;
  box-shadow: 0 0 20px #000;
  border-radius: 10px;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
  
  width: 100%;

  @media screen and (max-width: 460px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  button{
    align-self:flex-end;
  }
`

export const FormImg = styled.img`
  width: 40vw;
  max-width: ${({ small }) => (small ? '150px' : '200px')};
`

export const FormTitle = styled.h1`
  background-color: ${props => props.theme.colors.primary};
  background-image: ${props => props.theme.gradients.title};
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`

export const FormSubtitle = styled.h4`
  margin-top: 1rem;
  background-color: ${props => props.theme.colors.secondary};
  background-image: ${props => props.theme.gradients.title};
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`

export const FormDivider = styled.hr`
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-image: ${props => props.theme.gradients.divider} 1;
  margin-top: 5px;
`

export const FormLabel = styled.label`
  font-weight: 600;
  color: ${props => props.theme.colors.font};
  margin-top: 15px;
`

export const FormButton = styled.button`
  display: block;

  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  width: 100%;
  min-height: 60px;

  font-weight: 600;
  font-size: 2rem;
  text-decoration:none;
  text-align:center;

  display:flex;
  align-items:center;
  justify-content:center;

  background-image: ${props => props.theme.gradients.button};
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 4px #000;

  transition: 0.5s;

  margin-top: 15px;

  &:hover,
  &:active {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  &:disabled{
    background-image: none;
		background-color:grey;
		cursor: default;

	}
`

export const FormLink = styled(Link)`
  text-decoration: none;
  font-weight: 800;
  color: ${props => props.theme.colors.font};
  margin-top: 15px;

  strong {
    font-weight: 800;
    color: ${props => props.theme.colors.primary};
  }
`