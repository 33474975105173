import styled from 'styled-components'

export const Wrapper = styled.form`
	margin: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	color: black;
`

export const Statement2Container = styled.div`
	margin-bottom: 3rem;
	margin-top: 2rem;

	font-weight: 500;
	color: black;

	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`
export const StatementWord = styled.div`
	margin: 0.1rem;
`

export const Blank = styled.div`
	padding: 0.4rem;
	margin-left: 0.1rem;
	margin-right: 0.1rem;

	border-bottom: 2px solid;
	width: ${(props) => props.length * 6 + 'px'};
	color: ${(props) => props.theme.colors.lightBlue};
`

export const InputWrapper = styled.div`
	width: 80%;
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

	Button {
		margin-top: 15px !important; // 15px so it is the same as from InputContainer
		margin-left: 5px;
		padding: 0.9rem;
		font-weight: bold;
		background-color: ${(props) => props.theme.colors.secondary};
	}

	@media screen and (max-width: 960px) {
		flex-direction: column;
		width: 100%;
	}
`

export const InputWord = styled.input`
	background: white;
	color: ${(props) => props.theme.colors.darkGray};
	border-radius: 0.5rem;
	padding: 0.1rem;
	outline: none;

	border: 1px solid
		${(props) => (props.isInvalid ? props.theme.colors.warning : '#cccccc')};
	box-shadow: ${(props) =>
		props.isInvalid && '0 0 1px 1px ' + props.theme.colors.warning};

	&:hover {
		cursor: pointer;
	}

	&:focus {
		transition: 0.2s;
		border: 1px solid ${(props) => props.theme.colors.primary};
		box-shadow: ${(props) => '0 0 1px 1px ' + props.theme.colors.primary};
	}
`
