/** @format */

import styled from "styled-components";
import { FaCheck, FaTimes } from "react-icons/fa";

export const VerificationText = styled.p`
	width: 100%;
	color: grey;
	text-align: center;
	margin-top: ${(props) => (props.scale ? 10 * props.scale : 10)}px;
	font-size: ${(props) => (props.scale ? 1.1 * props.scale : 1.1)}rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const IconStyles = `
    width: 100%;
    margin-top: 10px;
    align-items: center;
    display: flex; 
    justify-content: center; 
    flex-direction: column; 
    `;

export const CheckIcon = styled(FaCheck)`
	${IconStyles}
	color: green;
    font-size: ${(props) => props.scale ? 2 * props.scale : 2}rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;

export const TimesIcon = styled(FaTimes)`
	${IconStyles}
    font-size: ${(props) => props.scale ? 2 * props.scale : 2}rem;
	color: red;
`;
