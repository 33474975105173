import styled from 'styled-components'

export const InputContainer = styled.div`
  width: 100%;
  flex-grow: 1;

  border: 1px solid
    ${props => (props.isInvalid ? props.theme.colors.warning : '#cccccc')};
  box-shadow: ${props =>
    props.isInvalid && '0 0 1px 1px ' + props.theme.colors.warning};

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
  border-radius: 10px;
  margin: ${props => (props.margin ? props.margin : "15px 0px 0 0")};

  background-size: 200% auto;

  &:first-child {
    margin-left: 0;
  }

  svg {
    color: #cccccc;
  }

  &:hover,
  &:focus {
    transition: 0.2s;
    border: 1px solid ${props => props.theme.colors.primary};
    box-shadow: 0 0 2px 1px ${props => props.theme.colors.primary};
  }

  @media screen and (max-width: 460px) {
    width: 100%;
  }
`

export const FormInputText = styled.input`
  border: none;
  outline: none;

  background: transparent;
  height: 50px;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;

  margin-left: 10px;
  padding: 0px 10px;
`

export const FormTextArea = styled.textarea`
  border: none;
  outline: none;
  background: transparent;
  font-family: inherit;
  font-size: 1.2rem;

  /* overflow-y: hidden; */

  width: 100%;
  height: auto;

  margin-left: 10px;
  margin-top: 5px;
  padding: 0px 10px;
`

export const FileInputButton = styled.input`


  &::-webkit-file-selector-button {
    border: 2px solid ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.primary};
    padding: 8px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  &::-webkit-file-selector-button:hover {
    background-color: #e9e9e9;
    transition: all 0.3s ease-out;
  }

  &::-webkit-file-upload-button {
    border: 2px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    padding: 8px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  &::-webkit-file-upload-button:hover {
    background-color: #e9e9e9;
  }
`

export const FileInputContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
`
