/** @format */

import {
	TeamMateWrapper,
	PokeButton,
	TeamMateProfile,
} from "./team-mate-card-styling";
import defaultProfilePic from "../../../../../assets/images/defaultProfileImage2.png";
import TeamMateProgress from "../TeamMateProgress/TeamMateProgress";
import { useState, useContext } from "react";
import { EagleContext } from "../../../../../contexts/EagleContext";
import { Photo } from "../../../../GenericComponents";

/**
 * TODO:
 * 1. Make it so that equally worst scores get the same colour
 *
 *
 */

const TeamMateCard = ({
	id,
	profilePic,
	name,
	completedTasks,
	numTasks,
	mainUser,
}) => {
	const [wasPoked, setWasPoked] = useState(false);
	const { poke, getCurrentCourseSetting } = useContext(EagleContext);
	function handlePoke() {
		setWasPoked(true);
		poke(id);
	}

	let settings = getCurrentCourseSetting();
	return (
		<TeamMateWrapper socialPressure={settings.socialPressure}>
			<TeamMateProfile>
				{" "}
				<Photo
					size={40}
					src={profilePic || defaultProfilePic}
					alt={"Profile Pic"}
				/>
				<span>{name}</span>
			</TeamMateProfile>
			<TeamMateProgress
				completedTasks={completedTasks < numTasks ? completedTasks : numTasks}
				numTasks={numTasks}
			/>
			{settings.socialPressure && (
				<PokeButton
					onClick={handlePoke}
					disabled={mainUser || completedTasks >= numTasks || wasPoked}
				>
					!
				</PokeButton>
			)}
		</TeamMateWrapper>
	);
};

export default TeamMateCard;
