import styled from 'styled-components'

export const ModalContentWrapper = styled.div`
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:column;
`

export const ModalContentText = styled.p`
	margin-bottom:10px;
`