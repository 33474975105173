import React, { useState, useEffect, useRef } from 'react'
import {
    CarouselContainer,
    ContentContainer,
    ControlButton,
    ControlButtonBox,
    Content,
    Text,
} from './Carousel-styled'
import { H2 } from '../../GenericComponents'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'

export default function Carousel({
    spaceBetween,
    children,
    header,
    footer,
    elementsDescription,
    elementsWidth,
    img,
    handleEndOfSlides,
    handleNext = ()=>{},
    handlePrev= ()=>{}
}) {
    const [contentNumber, setContentNumber] = useState(0)
    const [elementsPerSlide, setElementsPerSlide] = useState(10)
    const contentRef = useRef(null)
    const nextButtonRef = useRef(null)
    const myChildren = children ? children : []

    useEffect(() => {
        if (
            handleEndOfSlides &&
            contentNumber === myChildren.length - elementsPerSlide
        ) {
            handleEndOfSlides()
        }
    }, [contentNumber])

    useEffect(() => {
        if (handleEndOfSlides) {
            if(!nextButtonRef.current){
                handleEndOfSlides()
            }
        }
    }, [children])

    function calculateElementsPerSlide() {
        if (img) {
			return 1
		} else {
			let ContentSize = contentRef.current.clientWidth
            let space = spaceBetween ? spaceBetween : 0 
			let cardsPerContent = Math.trunc(ContentSize / (elementsWidth + space))
			return cardsPerContent
		}
    }

    function handleResize() {
        setElementsPerSlide(() => calculateElementsPerSlide())
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleResize)
        setElementsPerSlide(calculateElementsPerSlide())
        return () => window.removeEventListener('resize', handleResize)
    })

    function next() {
        if (contentNumber < myChildren.length - 1) {
            setContentNumber(contentNumber + 1)
            handleNext(contentNumber + 1)
        }
    }

    function previous() {
        if (contentNumber !== 0) {
            setContentNumber(contentNumber - 1)
            handlePrev(contentNumber - 1)
        }
    }

    let transform = `translateX(${contentNumber * -100}%)`
    let padding = `10px calc(${100.0 / (2.0 * elementsPerSlide)}% - ${
                elementsWidth / 2.0
    }px)`

    let contentsList = myChildren.map((content, index) => {
        return img ? (
            React.cloneElement(
                content,
                {
                    key: `image carousel ${index}`,
                    style: { transform: transform },
                },
                null
            )
        ) : (
            <div
                key={`carousel item ${index}`}
                style={{ transform: transform, padding: padding }}
            >
                {content}
            </div>
        )
    })

    return (
        <CarouselContainer>
            {header}
            <ContentContainer>
                <ControlButtonBox>
                    {contentNumber !== 0 && (
                        <ControlButton  onClick={previous}>
                            <FaChevronLeft />
                        </ControlButton>
                    )}
                </ControlButtonBox>
                <Content ref={contentRef}>{contentsList}</Content>
                <ControlButtonBox>
                    {contentNumber !== myChildren.length - elementsPerSlide &&
                        myChildren.length > elementsPerSlide && (
                            <ControlButton ref={nextButtonRef}  onClick={next}>
                                <FaChevronRight/>
                            </ControlButton>
                        )}
                </ControlButtonBox>
            </ContentContainer>
            {img && elementsDescription && (
                <H2>{elementsDescription[contentNumber].name}</H2>
            )}
            {img && elementsDescription && (
                <Text>{elementsDescription[contentNumber].description}</Text>
            )}
            {footer}
        </CarouselContainer>
    )
}
