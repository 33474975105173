/** @format */
import achievement from "../../../../assets/images/achievements.svg";
import coin from "../../../../assets/images/coin.svg";
import point from "../../../../assets/images/point.svg";
import styled from "styled-components";

import language from "../../../../services/defineLanguage";

const statusMessageTexts = require(`../../../../texts/${language}/statusMessage`); // Importa as mensagens de notificação

let statusImageMap = {
	achievement,
	point,
	economy: coin,
};

const BoldWrapper = styled.b`
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
`;

const StatusImage = styled.img`
	max-width: 2.3rem;
	height: auto;
	margin-right: "2px";
`;

export default function StatusMessage({ value, type }) {
	// types: point and economy
	return (
		<span>
			<BoldWrapper>
				{" "}
				{value} <StatusImage src={statusImageMap[type]} />{" "}
				{statusMessageTexts[type + (value > 1 ? "s" : "")]}
			</BoldWrapper>
		</span>
	);
}
