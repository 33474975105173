module.exports = {
    title:"Terms and Conditions of Service",
    content:[
    {
        title: "1. General",
        texts: [{
            paragraphs:[
                `Eagle-edu websites (“Websites”) and mobile applications are operated by Eagle-edu, Inc. 
                (“Eagle-edu,” “us”, “we”, or “Service”). Access and use of the Service are subject to the 
                following Terms and Conditions of Service (“Terms and Conditions”). By accessing or using 
                any part of the Service, you represent that you have read, understood, and agree to be 
                bound by these Terms and Conditions including any future modifications. Eagle-edu may 
                amend, update or change these Terms and Conditions. If we do this, we will post a notice 
                that we have made changes to these Terms and Conditions on the Websites for at least 7 
                days after the changes are posted and will indicate at the bottom of the Terms and 
                Conditions the date these terms were last revised. Any revisions to these Terms and 
                Conditions will become effective the earlier of i) the end of such a 7-day period or ii) 
                the first time you access or use the Service after such changes. If you do not agree to 
                abide by these Terms and Conditions, you are not authorized to use, access or participate 
                in the Service.`,

                `Please note that these terms and conditions contain a mandatory arbitration of disputes 
                provision that requires the use of arbitration on an individual basis to resolve disputes 
                in certain circumstances, rather than jury trials or class action lawsuits.`
            ]
        }]
    },
    {
        title: "2. Description of Website and Service",
        texts: [{
            paragraphs:[
                `The Service allows users to access and use a variety of educational services, including 
                learning or practicing a topic, taking examinations in the Eagle-edu, and creating and 
                viewing new courses. Eagle-edu may, in its sole discretion and at any time, update, change, 
                suspend, make improvements to or discontinue any aspect of the Service, temporarily or permanently.`
            ]
        }]
    },
    {
        title: "3. Registration; Submission of Content",
        texts: [{
            subtitles:"a. Registration",
            paragraphs:[
                `In connection with registering for and using the Service, you agree i) to provide accurate, 
                current and complete information about you and/or your organization as requested by 
                Eagle-edu; ii) to maintain the confidentiality of your password and other information 
                related to the security of your account; iii) to maintain and promptly update any 
                registration information you provide to Eagle-edu, to keep such information accurate, 
                current and complete; and iv) to be fully responsible for all use of your account and 
                for any actions that take place through your account.`
            ]
        },
        {
            subtitles:"b. Course Contributor Submissions",
            paragraphs:[
                `If you are or become a Course Contributor, you may offer to translate or have 
                translated certain existing courses into other languages and you may offer to 
                contribute new courses to the Service, as agreed between you and Eagle-edu on a 
                case-by-case basis. Subject to any guidelines posted on the Service, you may perform 
                any such translations or create any such courses in accordance with your own schedule 
                and using your own facilities and resources. You are not required to become a Course 
                Contributor and you may cease your activities as a Course Contributor at any time. 
                You acknowledge that you do not desire and will not receive compensation for your 
                activities as a Course Contributor or for our use of any Course Contributor Materials 
                (as defined below) you submit. Any translation of an existing Eagle-edu course you 
                submit or have submitted and any new courses you submit or have submitted as a Course 
                Contributor (collectively, “Course Contributor Materials”) belong to Eagle-edu. By 
                submitting any Course Contributor Material, you grant us a fully paid-up, royalty-free, 
                perpetual, sub-licensable license to reproduce, display, perform, modify, create 
                derivative works of, distribute and otherwise use such Course Contributor Material 
                in any manner.`
            ]
        },
        {
            subtitles:"c. General Content",
            paragraphs:[
                `As a condition of submitting any ratings, reviews, information, data, text, photographs, 
                audio clips, audiovisual works, translations, flashcards or other materials on the 
                Services (“Content”), you hereby grant to Eagle-edu a royalty-free, perpetual, irrevocable, 
                worldwide, nonexclusive, transferable, and sublicensable license to use, reproduce, copy, 
                adapt, modify, merge, distribute, publicly display, create derivative works from, incorporate 
                such Content into other works; sublicense through multiple tiers the Content, and acknowledge 
                that this license cannot be terminated by you once your Content is submitted to the Services. 
                You represent that you own or have secured all legal rights necessary for the Content 
                submitted by you to be used by you, Eagle-edu, and others as described and otherwise contemplated 
                in these Terms and Conditions. You understand that other users will have access to the Content 
                and that neither they or Eagle-edu have any obligation to you or anyone else to maintain the 
                confidentiality of the Content.`
            ]
        },
        ]
    },
    {
        title: "4. Your Representations and Warranties",
        texts: [{
            paragraphs:[
                `You represent and warrant to Eagle-edu that your access and use of the Service will be 
                in accordance with these Terms and Conditions and with all applicable laws, rules, and 
                regulations of Brazil and any other relevant jurisdiction, including those regarding online 
                conduct or acceptable content, and those regarding the transmission of data or information 
                exported from Brazil and/or the jurisdiction in which you reside. You further represent and 
                warrant that you have created or own any material you submit via the Service (including 
                Translation Materials, Course Contributor Materials, Activity Materials, and Content) and 
                that you have the right, as applicable, to grant us a license to use that material as set 
                forth above or the right to assign that material to us as set forth below. You further 
                represent and warrant that you will carefully safeguard your password, user name, and account 
                information, will not share your password or user name with any third party, and will not 
                allow any third party to access your Eagle-edu English Test account for any purpose.`,

                `You represent and warrant that (1) you are not organized under the laws of, operating from, 
                or otherwise ordinarily resident in a country or territory that is the target or comprehensive 
                Brazil economic or trade sanctions (i.e., an embargo) or (2) identified on a list of prohibited 
                or restricted persons, such as the U.S. Treasury Department’s List of Specially Designated 
                Nationals and Blocked Persons, or (3) otherwise the target of Brazil sanctions.`
            ]
        }]
    },
    {
        title: "5. Inappropriate Use",
        texts: [{
            paragraphs:[
                `You will not upload, display or otherwise provide on or through the Service any content 
                that: i) is libelous, defamatory, abusive, threatening, harassing, hateful, offensive or 
                otherwise violates any law or infringes upon the right of any third party (including 
                copyright, trademark, privacy, publicity or other personal or proprietary rights); or ii) 
                in Eagle-edu’s sole judgment, is objectionable or which restricts or inhibits any other 
                person from using the Service or which may expose Eagle-edu or its users to any harm or 
                liability of any kind. You will not use the Eagle-edu to collect information about our 
                internal processes or to develop strategy, guides or other testing preparation material 
                or similar testing service, and you will not violate any rules applicable to the Eagle-edu.`
            ]
        }]
    },
    {
        title: "6. Indemnification of Eagle-edu",
        texts: [{
            paragraphs:[
                `You agree to defend, indemnify and hold harmless Eagle-edu and its directors, officers, 
                employees, contractors, agents, suppliers, licensors, successors and assigns, from and 
                against any and all losses, claims, causes of action, obligations, liabilities, and 
                damages whatsoever, including attorneys' fees, arising out of or relating to your 
                access or use of the Service, any false representation made to us (as part of these 
                Terms and Conditions or otherwise), your breach of any of these Terms and Conditions, 
                or any claim that any service we provide to you is inaccurate, inappropriate or 
                defective in any way whatsoever.`
            ]
        }]
    },
    {
        title: "7. License to Apps",
        texts: [{
            paragraphs:[
                `Subject to the terms of these Terms and Conditions, Eagle-edu grants you a 
                non-transferable, non-exclusive license to download, install, and use one copy 
                of each App in object code form only on an interactive wireless device that you 
                own or control. You may not derive or attempt to derive the source code of all 
                or any portion of any App, permit any third party to derive or attempt to 
                derive such source code, or reverse engineer, decompile, disassemble, or 
                translate any App or any part thereof. Eagle-edu and its licensors own and 
                shall retain all intellectual property rights and other rights in and to the 
                Apps, and any changes, modifications, or corrections thereto.`
            ]
        }]
    },
    {
        title: "8. In-App Purchases",
        texts: [{
            paragraphs:[
                `If you purchase an auto-renewing periodic subscription through the Service, your 
                Eagle-edu account will be billed continuously for the subscription until you terminate 
                it as set forth below. After your initial subscription period, and again after any 
                subsequent subscription period, your subscription will automatically renew for an 
                additional equivalent period. If you do not wish your subscription to renew 
                automatically, or if you want to change or terminate your subscription, you will 
                need to log in to your Eagle-edu account and follow instructions to terminate or 
                change your subscription, even if you have deleted your account.`,
                
                `In the Service, you may purchase, with “real world” money, a limited, personal, 
                non-transferable, non-sublicensable, revocable license to use (a) “virtual currency,” 
                including but not limited to virtual currency, solely for use in the Service, and (b) 
                “virtual in-app items” (together with “virtual currency,” “Virtual Items”). 
                You are allowed to purchase Virtual Items through the Service, and not in any other way.`,

                `Eagle-edu may manage, regulate, control, modify, or eliminate Virtual Items at any 
                time, with or without notice. Eagle-edu may update the pricing of Virtual Items at any 
                time in its sole discretion, and may add new Virtual Items for additional fees. 
                Eagle-edu shall have no liability to you or any third party in the event that Eagle-edu 
                exercises any such rights.`,

                `The transfer of Virtual Items is prohibited except where expressly authorized in the Service. 
                Other than as expressly authorized in the Service, you shall not sell, redeem or otherwise 
                transfer Virtual Items to any person or entity, including but not limited to Company, another 
                user, or any third party.`,

                `You agree to pay all fees and applicable taxes incurred by you or anyone using a Eagle-edu 
                account registered to you. Eagle-edu may revise the pricing for the goods and services 
                offered through the Service at any time. All information that you provide in connection 
                with a purchase or transaction or other monetary transaction interaction with the Service 
                must be accurate, complete, and current. You agree to pay all charges incurred by users 
                of your credit card, debit card, or other payment method used in connection with a purchase 
                or transaction or other monetary transaction interaction with the Service at the prices in 
                effect when such charges are incurred. You will pay any applicable taxes, if any, relating 
                to any such purchases, transactions or other monetary transaction interactions.`
            ]
        }]
    },
    {
        title: "9. Payment Processors",
        texts: [{
            paragraphs:[
                `All financial transactions made in connection with the Service will be processed 
                by a third party in accordance with their respective terms of use, privacy policy, 
                and/or any applicable payment terms and conditions. We encourage you to learn about 
                the practices of such third party. In no event will Eagle-edu be responsible for 
                the actions or inactions of any third party payment processor, including, but not 
                limited to, system downtime or payment service outages.`
            ]
        }]
    },
    {
        title: "10. Refund Policy",
        texts: [{
            paragraphs:[
                `In the event that Eagle-edu suspends or terminates your use of the Service or these 
                Terms and Conditions or you close your account voluntarily, you understand and 
                agree that you will receive no refund or exchange of any kind, including for any 
                unused virtual currency or other Virtual Item, any Content or data associated 
                with your use of the Service, or for anything else.`
            ]
        }]
    },
    {
        title: "11. Third-Party Links, Sites, and Services",
        texts: [{
            paragraphs:[
                `The Service may contain links to third-party websites, advertisers, services, 
                special offers, or other events or activities that are not owned or controlled 
                by Eagle-edu. We do not endorse or assume any responsibility for any such 
                third-party sites, information, materials, products, or services. If you access 
                any third-party website, service, or content from Eagle-edu, you understand that 
                these Terms and Conditions and our Privacy Policy do not apply to your use of 
                such sites. You expressly acknowledge and agree that Eagle-edu shall not be 
                responsible or liable, directly or indirectly, for any damage or loss arising 
                from your use of any third-party website, service, or content.`,

                `The Service may include advertisements, which may be targeted to the Content 
                or information on the Service, or other information. The types and extent of 
                advertising by Eagle-edu on the Service are subject to change. In consideration 
                for Eagle-edu granting you access to and use of the Service, you agree that 
                Eagle-edu and its third-party providers and partners may place such advertising 
                in connection with the display of content or information submitted by you or others.`
            ]
        }]
    },

    {
        title: "12. NO REPRESENTATIONS OR WARRANTIES BY EAGLE-EDU",
        texts: [{
            paragraphs:[
                `The service, including all images, audio files and other content therein, and any 
                other information, property, and rights granted or provided to you by eagle-edu are 
                provided to you on an “as is” basis. Eagle-edu and its suppliers make no 
                representations or warranties of any kind concerning the service, either express or 
                implied and all such representations and warranties, including warranties of 
                merchantability, fitness for a particular purpose or non-infringement, are expressly 
                disclaimed. Without limiting the generality of the foregoing, Eagle-edu does not 
                make any representation or warranty of any kind relating to accuracy, service 
                availability, completeness, informational content, error-free operation, results 
                to be obtained from use, or non-infringement. Access and use of the service may be 
                unavailable during periods of peak demand, system upgrades, malfunctions or scheduled 
                or unscheduled maintenance or for other reasons. Some jurisdictions do not allow 
                the exclusion of implied warranties, so the above exclusion may not apply to you.`
            ]
        }]
    },
    {
        title: "13. LIMITATION ON TYPES OF DAMAGES/LIMITATION OF LIABILITY",
        texts: [{
            paragraphs:[
                `In no event will Eagle-edu be liable to you or any third-party claiming through 
                you (whether based in contract, tort, strict liability or other theory) for 
                indirect, incidental, special, consequential or exemplary damages arising out 
                of or relating to the access or use of, or the inability to access or use, the 
                service or any portion thereof, including but not limited to the loss of use of 
                the service, inaccurate results, loss of profits, business interruption, or 
                damages stemming from loss or corruption of data or data being rendered inaccurate, 
                the cost of recovering any data, the cost of substitute services or claims by 
                third parties for any damage to computers, software, modems, telephones or other 
                property, even if eagle-edu has been advised of the possibility of such damages. 
                Eagle-edu's liability to you or any third-party claiming through you for any cause 
                whatsoever, and regardless of the form of the action, is limited to the amount 
                paid, if any, by you to Eagle-edu for the service in the 12 months before the 
                initial action giving rise to liability. This is an aggregate limit. The existence 
                of more than one claim hereunder will not increase this limit.`
            ]
        }]
    },
    {
        title: "14. Termination",
        texts: [{
            paragraphs:[
                `Eagle-edu may terminate your access and use of the Service immediately at any time, 
                for any reason, and at such time you will have no further right to use the Service. 
                You may terminate your Eagle-edu account at any time by following the instructions 
                available through the Service. The provisions of these Terms and Conditions relating 
                to the protection and enforcement of Eagle-edu's proprietary rights, your representations, 
                and warranties, disclaimer of representations and warranties, release and indemnities, 
                limitations of liability and types of damages, ownership of data and information, 
                governing law and venue, and miscellaneous provisions shall survive any such termination.`
            ]
        }]
    },
    {
        title: "15. Proprietary Rights in Service Content and Activity Materials",
        texts: [{
            paragraphs:[
                `All content available through the Service, including designs, text, graphics, images, 
                information, software, audio and other files, and their selection and arrangement (the 
                "Service Content"), are the proprietary property of Eagle-edu or its licensors. No 
                Service Content may be modified, copied, distributed, framed, reproduced, republished, 
                downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any means, 
                in whole or in part, other than as expressly permitted in these Terms and Conditions. 
                You may not use any data mining, robots, scraping or similar data gathering or extraction 
                methods to obtain Service Content. As between you and Eagle-edu, all data, information, 
                and materials generated from your access and use of the educational activities made 
                available on or through the Service, including translated content generated by you 
                (collectively, the “Activity Materials”), shall be exclusively owned by Eagle-edu, and 
                you shall not have any right to use such Activity Materials except as expressly 
                authorized by these Terms and Conditions. By using the Service, you hereby assign to 
                Eagle-edu any rights, title, and interest, including any intellectual property rights 
                or proprietary rights, in the Activity Materials. All rights of Eagle-edu or its 
                licensors that are not expressly granted in these Terms and Conditions are reserved 
                to Eagle-edu and its licensors.`
            ]
        }]
    },
    {
        title: "16. Trademarks",
        texts: [{
            paragraphs:[
                `Eagle-edu and all other trademarks, service marks, graphics and logos used in 
                connection with the Service are trademarks or service marks of Eagle-edu or their 
                respective owners, and certain of them are registered with Brazil or United States 
                Patent and Trademark Office. Access and use of the Service do not grant or provide 
                you with the right or license to reproduce or otherwise use the Eagle-edu name or 
                any Eagle-edu or third-party trademarks, service marks, graphics or logos.`
            ]
        }]
    },
    {
        title: "17. Privacy",
        texts: [{
            paragraphs:[
                `Use of the Service is also governed by our Privacy Policy, a copy of which is 
                located at www.eagle-edu.com.br/privacy. By using the Service, you consent to 
                the terms of the Privacy Policy.`
            ]
        }]
    },
    {
        title: "18. Notice for Claims of Copyright Violations and Agent for Notice",
        texts: [{
            paragraphs:[
                `If you are a copyright owner and have a good faith belief that any material 
                available through the Service infringes upon your copyrights, you may submit 
                a copyright infringement notification to Eagle-edu pursuant to the Digital 
                Millennium Copyright Act by providing us with the following information in writing:`
            ],
            list:[
                `an electronic or physical signature of the copyright owner or the person authorized 
                to act on behalf of the owner of the copyright interest;`,

                `a description of the copyrighted work that you claim has been infringed;`,

                `a description of where the material that you claim is infringing is located on the 
                Service, with enough detail that we may find it on the Service;`,

                `your address, telephone number, and email address;`,

                `a statement by you that you have a good faith belief that the disputed use is not 
                authorized by the copyright owner, its agent, or the law; and`,

                `a statement by you, made under penalty of perjury, that the above information in 
                your notice is accurate and that you are the copyright owner or are authorized to 
                act on the copyright owner's behalf.`
            ]
        }]
    },
    {
        title: "19. Governing Law and Arbitration; No Class Action",
        texts: [{
            paragraphs:[
                `These Terms and Conditions, its subject matter and Eagle-edu's and your 
                respective rights under these Terms and Conditions, as well as and any claim, 
                cause of action or dispute (“claim”) arising out of or related to these Terms 
                and Conditions, shall be governed by and construed under the laws of Brazil, 
                excluding the conflict of law provisions of that or any other jurisdiction, 
                regardless of your country of origin or where you access the Service. ANY DISPUTE 
                OR CLAIM RELATING IN ANY WAY TO THESE TERMS AND CONDITIONS OR THE SERVICE WILL BE 
                RESOLVED BY BINDING ARBITRATION, RATHER THAN IN COURT, except for Eagle-edu's right 
                to seek injunctive relief as set forth below. Unless otherwise expressly required by 
                applicable law, each party shall bear its own attorneys’ fees without regard to which 
                party is deemed the prevailing party in the arbitration proceeding.`,

                `If you do not want to arbitrate disputes with Eagle-edu and you are an individual, 
                you may opt out of this arbitration agreement by sending an email to 
                legal@eagle-edu.com.br within 30 days of the day you first access or use the Service.`,

                `If you intend to seek arbitration you must first send written notice to Eagle-edu’s 
                Administration Office of your intent to arbitrate (“Notice”). The Notice to Eagle-edu 
                should be sent by the electronic mail to legal@eagle-edu.com.br. The Notice must (x) 
                describe the nature and basis of the claim or dispute; and (y) set forth the specific 
                relief sought; and (z) set forth your name, address and contact information. If we 
                intend to seek arbitration against you, we will send any notice of the dispute to 
                you at the contact information we have for you.`,

                `To the fullest extent permitted by applicable law, YOU AND Eagle-edu EACH AGREE 
                THAT ANY DISPUTE RESOLUTION PROCEEDING WILL BE CONDUCTED ONLY ON AN INDIVIDUAL 
                BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION. If for any reason 
                a claim proceeds in court rather than in arbitration, YOU AND Eagle-edu EACH WAIVE 
                ANY RIGHT TO A JURY TRIAL. If a court of competent jurisdiction finds the foregoing 
                arbitration provisions invalid or inapplicable, you and Eagle-edu agree that all 
                claims arising out of or related to these Terms and Conditions must be resolved 
                exclusively by a state or federal court located in Brazil, and you and Eagle-edu 
                each agree to submit to the exercise of personal jurisdiction of such courts for 
                the purpose of litigating all such claims. Notwithstanding the above, you agree 
                that Eagle-edu shall still be allowed to apply for and obtain injunctive remedies 
                (or an equivalent type of urgent legal relief) in any jurisdiction.`

            ]
        }]
    },
    {
        title: "20. Language",
        texts: [{
            paragraphs:[
                `This agreement was originally written in Brazilian Portuguese (PT-BR). To the extent 
                any translated version of this agreement conflicts with the Brazilian Portuguese 
                version, the Brazilian Portuguese version controls.`
            ]
        }]
    },
    {
        title: "21. Miscellaneous",
        texts: [{
            paragraphs:[
                `These Terms and Conditions constitute the entire agreement between 
                Eagle-edu and you concerning the subject matter hereof. If any of the 
                Terms and Conditions are held by a court or other tribunal of 
                competent jurisdiction to be unenforceable, such provisions shall 
                be limited or eliminated to the minimum extent necessary so that 
                these Terms and Conditions shall otherwise remain in full force and 
                effect. A waiver by Eagle-edu or you of any provision of these Terms 
                and Conditions or any breach thereof, in any one instance, will not 
                waive such term or condition or any subsequent breach thereof. Eagle-edu 
                may assign its rights or obligations under these Terms and Conditions 
                without condition. These Terms and Conditions will be binding upon 
                and will inure to the benefit of Eagle-edu and you, and Eagle-edu’s 
                and your respective successors and permitted assigns.`
            ]
        }]
    }
],
footer:"Última revisão em 20 de abril de 2020"
}