import styled from 'styled-components';
import { CenterBox } from "../../../GenericComponents";
import PasswordInput from "../../../forms/input/PasswordInput";
import { FormButton } from "../../../forms/FormsElements";

export const DivPasswordRecovery = styled.div`
  width: 100%;
  display: flex; /* Display items in a row */
  align-items: center; /* Vertically center items */
  justify-content: space-between; /* Space between items */
`;

export const ButtonPasswordRecovery = styled(FormButton)`
  width: 100%;
  max-width: 300px;
  font-size: 20px;
  margin-top: 25px;
  align-self: center;
`;

export const PasswordRecovery = styled(PasswordInput)`
  width: 100%;
`;

export const VerificationContentBox = styled(CenterBox)`
  width: 90vw;
  max-width: 900px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h2`
  color: black;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 0;
  text-align: center;
`;

export const Paragraph = styled.p`
  color: grey;
  margin: 10px 0;
  font-size: 1.1rem;
  text-align: right;
`;
