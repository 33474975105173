import styled from 'styled-components'

export const ColleaguesListWrapper = styled.div`
    margin-top: 1rem;

    header {
        font-weight: 500;
        display: flex;
        flex-direction: row;
        width: 100%;

        align-items: center;
        justify-content: space-evenly;
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-bottom: 1.5px solid ${(props) => props.theme.colors.gray};
        border-top: 1px solid ${(props) => props.theme.colors.gray};
    }
`
export const TeamWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
