/** @format */

module.exports = {
	type: "Selecione os pares",
	instruction:
		"Adicione os pares que seus alunos deverão relacionar corretamente.",
	firstElement: "Primeiro elemento...",
	secondElement: "Segundo elemento...",
	add: "Adicionar",
	save: "Salvar",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
