/** @format */

import api from "./api";

export async function updateSupportMaterial(supportMaterialId, text) {
	try {
		let res = await api.patch(`/instructor/course/subject/support-material`, {
			supportMaterialId,
			text,
		});
		if (res.data?.data) return { success: true };
	} catch (err) {
		if (err.response?.data?.id >= 400 && err.response?.data?.id < 500) {
			return { success: false, error: "INSTRUCTOR_PLAN_WITHOUT_ACCESS" };
		}
	}
	return { success: false };
}
