/** @format */

import styled from "styled-components";

export const CenterBox = styled.form`
	background: #fff;
	padding: 30px 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	width: 1000px;
	max-width: 95vw;
	box-shadow: 0 0 20px #000;
	border-radius: 10px;
`;

export const H2 = styled.h2`
	padding: ${(props) => props.padding || "10px 0 0 0"};
	font-size: 1.5rem;
	color: ${(props) => props.theme.colors.black};
`;

export const Title = styled.h1`
	padding-bottom: 5px;
	font-size: 1.5rem;
	color: ${(props) => props.theme.colors.black};
`;

export const Button = styled.button`
	width: ${(props) => (props.width ? props.width : "default")};
	padding: 10px;
	margin: ${(props) => (props.margin ? props.margin : "0 0 5px 0")};

	border: none;
	border-radius: 5px;
	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.lightBlue};
	background-image: linear-gradient(
		90deg,
		rgba(0, 34, 34, 0.1) 0%,
		rgba(34, 34, 34, 0.2) 100%
	);

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => props.theme.colors.primary};
	}

	&:disabled {
		background-color: grey;
		cursor: default;
	}
`;

export const SecondaryButton = styled(Button)`
	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.darkGray};

	&:hover {
		background-color: ${(props) => props.theme.colors.mediumGray};
	}
`;

export const Photo = styled.img`
	width: ${(props) => (props.size ? `${props.size}px` : "50px")} !important;
	height: ${(props) => (props.size ? `${props.size}px` : "50px")} !important;
	border-radius: 50%;
	object-fit: cover;
`;

export const Divider = styled.hr`
	width: 100%;
	border-style: solid;
	border-width: 1px;
	border-image: ${(props) => props.theme.gradients.divider} 1;
	margin: 5px 0;
`;

export const PhotoButtonBox = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledText = styled.span`
	font-size: ${(props) => props.fontSize || "1rem"};
	text-decoration: ${(props) => props.textDecoration || "none"};
	color: ${(props) => props.color || props.theme.colors.black};
	cursor: ${(props) => props.cursor || "context-menu"};
`;

export const SimpleLoader = styled.div`
	border: ${({ size }) => (size ? size * 0.1 : 2)}px solid ${(props) => props.secondaryColor ? props.secondaryColor : props.theme.colors.white};
	border-radius: 50%;
	border-top: ${({ size }) => (size ? size * 0.1 : 2)}px solid
		${(props) => props.primaryColor ? props.primaryColor : props.theme.colors.primary};
	width: ${({ size }) => (size ? size : 20)}px;
	height: ${({ size }) => (size ? size : 20)}px;
	-webkit-animation: spin 1.5s linear infinite; /* Safari */
	animation: spin 1.5s linear infinite;

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
