import styled from 'styled-components'
import { Button } from '../../../GenericComponents'

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;

    padding: 10px 15px;

    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 10px;
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.15);
`

export const CardName = styled.h4`
    text-align: center;
    font-size: 1.2rem;
    margin-top: 10px;
`

export const CardDescription = styled.p`
    text-align: center;
    color: ${(props) => props.theme.colors.black};
`

export const CardImage = styled.img`
    max-height: 200px;
    max-width: 100%;
    margin: auto 0;
    filter: ${props => props.wasBought ? 'blur(0) grayscale(0%)' : 'blur(2px) grayscale(100%)'};
`

export const BuyButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`

export const PriceText = styled.span`
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-left: 5px;
    border-left: 1px solid ${(props) => props.theme.colors.white};
`

export const CoinImage = styled.img`
    max-height: 25px;
    margin-left: 2px;
`
