module.exports = {
    tutorials: 'Tutoriais',
    instruction: 'Clique nos cards abaixo para baixar uma série de tutoriais para lhe ajudar a usar o Eagle-edu.',
    editCourse: 'editar_curso',
    insertContent: 'inserir_conteudo',
    createTask: 'criar_tarefas',
    howEditCourse: 'Como editar um curso',
    editCouseInstruction: 'Nesse tutorial, vamos aprender como editar um curso!',
    howInsertContent: 'Como inserir conteúdo',
    insertContentInstruction: 'Nesse tutorial, vamos aprender como inserir Assuntos, Missões e Tarefas em um curso!',
    howCreateTask: 'Como criar tarefas',
    createTaskInstruction: 'Nesse tutorial, vamos aprender como criar Tarefas!',
}