/** @format */

module.exports = {
	1: {
		name: "Bronze",
		description:
			"Você está no nível 1 (Bronze)! Agora, você precisa ser um dos melhores no ranking semanal para alcançar o segundo nível (Prata).",
	},
	2: {
		name: "Prata",
		description:
			"Ótimo, você está no nível 2 (Prata)! Agora, você precisa ser um dos melhores no ranking semanal para alcançar o nível 3 (Ouro). Mas cuidado, se você ficar entre os piores do ranking semanal, voltará ao nível anterior.",
	},
	3: {
		name: "Ouro",
		description:
			"Maravilha, você está no nível 3 (Ouro)! Agora, você precisa ser um dos melhores no ranking semanal para alcançar o nível 4 (Rubi). Mas cuidado, se você ficar entre os piores do ranking semanal, voltará ao nível anterior.",
	},
	4: {
		name: "Rubi",
		description:
			"Fantástico, você está no nível 4 (Rubi)! Agora, você precisa ser um dos melhores no ranking semanal para alcançar o nível 5 (Diamante). Mas cuidado, se você ficar entre os piores do ranking semanal, voltará ao nível anterior.",
	},
	5: {
		name: "Diamante",
		description:
			"Parabéns, você está no nível 5 (Diamante)! Este é o último nível, continiue se esforçando para não cair de nível.",
	},
};
