module.exports = {
    login:"LOGIN",
    placeholderEmail:"Digite o seu e-mail",
    placeholderPassword:"Digite a sua senha",
    keepMeConnected:"Mantenha-me conectado",
    signin:"Entrar",
    forgotYourPassword:["Esqueceu sua ","senha?"],
    registerNow:[ "Cadastre-se ", "agora!" ],
    
    errors:{

        userNotFound:"Não foi possível encontrar um usuário com o email inserido!",
        incorrectPassword:"Senha incorreta!",
        unableToLogin:["Não foi possível efetuar o login (",")."]
        
    }


}