/** @format */

import React from "react";
import ReactDOM from "react-dom";

import Theme from "./contexts/theme/Theme";
import Routes from "./routes/Routes";

/* import UserSessionContext from './contexts/UserSession/UserSession' */

ReactDOM.render(
	<React.StrictMode>
		<Theme>
			<Routes />
		</Theme>
	</React.StrictMode>,
	document.getElementById("root")
);
