module.exports = {
    tutorials: 'Tutorials',
    instruction: 'Click on the cards below to download a series of tutorials to help you use Eagle-edu.',
    editCourse: 'edit_course',
    insertContent: 'insert_content',
    createTask: 'create_task',
    howEditCourse: 'How to edit a course',
    editCouseInstruction: 'In this tutorial, we will learn how to edit a course!',
    howInsertContent: 'How to insert content',
    insertContentInstruction: 'In this tutorial, we are going to learn how to insert Subjects, Missions and Tasks in a course!',
    howCreateTask: 'How to create tasks',
    createTaskInstruction: 'In this tutorial, we will learn how to create Tasks!',
}