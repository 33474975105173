import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 500;

    svg {
        margin-right: 10px;
    }

    &:hover {
        background-color: ${(props) =>
            props.hover && props.theme.hover.primary};
        color: ${(props) => props.hover && props.theme.colors.white};
    }
`
