import styled from 'styled-components'

export const DashboardContainer = styled.div`
	width: 100%;
	overflow-x: hidden;

	display: flex;

	background-color: ${(props) => props.theme.colors.gray};

	@media screen and (max-width: 1080px) {
		flex-direction: column;
	}
`

export const LeftSideSection = styled.div`
	flex-grow: 1;
`

export const RightSideSection = styled.div`
	width: 30%;

	@media screen and (max-width: 1080px) {
		width: 100%;
	}
`

export const CourseTitle = styled.h1`
	// white-space: nowrap;
	overflow: hidden;
	text-align:center;
	text-overflow: ellipsis;

	color: ${(props) => props.theme.colors.primary};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction:column;

	margin: 1rem;

	// p {
	// 	margin-left: 0.5rem;
	// }
`

export const CoursePercentage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.theme.colors.primary};
`
