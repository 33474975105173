/** @format */

import Background from "../entrance/Background";
import Footer from "../entrance/Footer";
import Navbar from "./navbar/Navbar";
import NonCourseAccessManager from "../../common/NonCourseAccessManager.jsx";

export default function NonCourseEnv({ children }) {
	return (
		<NonCourseAccessManager>
			<Background>
				<Navbar />
				{children}
				<Footer />
			</Background>
		</NonCourseAccessManager>
	);
}
