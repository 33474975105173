import styled from 'styled-components'
import { Button } from '../../GenericComponents'

export const Text = styled.h2`
    font-size: 1.2rem;
    text-align: center;
    color: ${(props) => props.theme.colors.black};
`

export const CreateButton = styled(Button)`
    margin-top: 10px;
    max-width: 150px;

    &:disabled {
        background: ${(props) => props.theme.colors.mediumGray};
        cursor: not-allowed;
    }
`
