import styled from 'styled-components'

export const RankingWrapper = styled.div.attrs((props) => ({
	values: props.values,
	length: props.length,
}))`
	margin-top: 1rem;

	div:nth-last-child(-n + 2) {
		background: ${(props) => props.theme.colors.lightRed};
		color: ${(props) => props.theme.colors.darkRed};
	}

    div:nth-child(-n + 2) {
        background: ${(props) => props.theme.colors.lightGreen};
        color: ${(props) => props.theme.colors.darkGreen};
    }

	div:nth-last-child(-n + 1) {
		background: ${(props) => props.theme.colors.lightRed};
		color: ${(props) => props.theme.colors.darkRed};
	}

    div:nth-child(-n + 1) {
        background: ${(props) => props.theme.colors.lightGreen};
        color: ${(props) => props.theme.colors.darkGreen};
    }


`
