/** @format */

import styled from "styled-components";

export const SubjectContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	margin: 15px;
	padding: 30px;

	background: ${(props) => props.theme.colors.white};
	border-radius: 10px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

export const Overview = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media screen and (max-width: 720px) {
		flex-direction: column;
	}
`;
export const ControllsWrapper = styled.div`
	display: flex;
	// justify-content:flex-end;
	align-items: center;
`;

export const SubjectTitle = styled.div`
	font-size: 2rem;
	font-weight: 500;
	color: ${(props) => props.theme.colors.black};
	margin: 10px 0;

	display: flex;
	align-items: center;

	@media screen and (max-width: 720px) {
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}
`;

export const MissionsTitle = styled.h2`
	font-size: 1.5rem;
	font-weight: 500;
	color: ${(props) => props.theme.colors.black};
	margin: 10px 0;
`;

export const ControllButton = styled.button`
	margin-right: 5px;
	border: none;

	font-size: 1.2rem;
	font-weight: 500;

	cursor: pointer;
	color: ${(props) => props.theme.colors.darkGray};
	background: transparent;
`;

export const NewMissionButton = styled.button`
	display: flex;
	align-items: center;

	cursor: pointer;

	border: 1px solid ${(props) => props.theme.colors.black};
	border-radius: 5px;

	margin: 5px 0;
	padding: 10px 5px;

	text-decoration: none;
	font-size: 1.2rem;
	font-weight: 400;
	color: ${(props) => props.theme.colors.black};
	background: ${(props) => props.theme.colors.white};

	&:hover {
		color: ${(props) => props.theme.colors.white};
		background: ${(props) => props.theme.colors.primary};
	}

	svg {
		margin-right: 5px;
	}
`;
