import { useState } from 'react'
import SettingsContent from '../SettingsContent/SettingsContent'
import Select from '../../../common/Select/Select'
import { MainContentInner } from '../SettingsContent/SettingsContent-styled'
import ToggleButton from '../../../common/ToggleButton/ToggleButton'

import language from '../../../../services/defineLanguage'
const texts = require(`../../../../texts/${language}/settingsCustomization`)

export default function SettingsCustomization() {
    const [theme, setTheme] = useState(1)
    const [fontSize, setFontSize] = useState(2)
    const [notificationsCheck, setNotificationsCheck] = useState(true)

    return (
            <SettingsContent title={texts.customization}>
                <MainContentInner margin="0 0 0 10px">
                    <Select
                        label={texts.theme}
                        value={theme}
                        setValue={setTheme}
                        style={{ width: '200px' }}
                    >
                        <option value={1}>{texts.light}</option>
                        <option value={2}>{texts.dark}</option>
                        <option value={2}>{texts.colorBlind}</option>
                    </Select>
                    <Select
                        label={texts.fontSize}
                        value={fontSize}
                        setValue={setFontSize}
                        style={{ width: '200px' }}
                    >
                        <option value={1}>{texts.small}</option>
                        <option value={2}>{texts.medium}</option>
                        <option value={2}>{texts.big}</option>
                    </Select>
                </MainContentInner>
                <MainContentInner margin="0 0 0 10px">
                    <ToggleButton
                        label={texts.notifications}
                        checked={notificationsCheck}
                        setChecked={setNotificationsCheck}
                    />
                </MainContentInner>
            </SettingsContent>
    )
}
