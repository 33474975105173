import { useState } from 'react'
import {
    SidebarItemContainer,
    ToggleIconRight,
    ToggleIconDown,
    LinkContainer,
} from './SidebarItem-styled'
import DynamicFaIcon from '../../../../common/DynamicFaIcon'

// Default style properties of the SidebarItem icon
const iconStyle = {
    fontSize: '1.7rem',
    marginRight: '3px',
}

export default function SidebarItem(props) {
    const [isHidden, setIsHidden] = useState(true)

    function handleIcon() {
        return <DynamicFaIcon name={props.icon} style={iconStyle} />
    }

    function handleClick() {
        props.openSidebar()
        props.isSidebarHidden ? setIsHidden(false) : setIsHidden(!isHidden)
    }

    return (
        <li>
            <SidebarItemContainer onClick={handleClick}>
                {handleIcon()}
                {!props.isSidebarHidden && <span>{props.name}</span>}
                {!props.isSidebarHidden &&
                    (isHidden ? <ToggleIconRight /> : <ToggleIconDown />)}
            </SidebarItemContainer>

            {!props.isSidebarHidden && !isHidden && (
                <LinkContainer>{props.children}</LinkContainer>
            )}
        </li>
    )
}
