/** @format */

module.exports = {
	title: "Course statistics",
	description: "View your course statistics",
	studentsTitle: "Students",
	name: "Name",
	missions: "Missions",
	tasks: "Tasks",
	courseProgress: "Course progress",
	points: "Points",
	achievements: "Achievements",
	economy: "Coins",
	studentsCompleted: "Total students completed",
	numberStudents: "Number of students",
	studentsStarted: "Total students started",
};
