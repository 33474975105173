/** @format */

import React, { useState, useContext, useEffect } from "react";
import Background from "../../../environments/entrance/Background";
import Footer from "../../../environments/entrance/Footer";
import Header from "../../../environments/entrance/Header";
import EntraceAccessManager from "../../../common/EntraceAccessManager";
import {
	Title,
	SubTitle,
	TitleContainer,
	VerificationContentBox,
} from "./AccountVerificationRequest-styled";
//import LoadingIcon from "../../common/LoadingIcon/LoadingIcon";
import LocalLoader from "../../../common/LocalLoader/LocalLoader";
import { EagleContext } from "../../../../contexts/EagleContext";
import { useParams, Link } from "react-router-dom";
import language from "../../../../services/defineLanguage.js";
const texts = require(`../../../../texts/${language}/accountVerification.js`);

function AccountVerificationRequest({}) {
	const { verifyAccount } = useContext(EagleContext);
	const [accountVerification, setAccountVerification] = useState({
		verified: null,
	});
	let { code } = useParams();

	useEffect(async () => {
		setAccountVerification(await verifyAccount(code));
	}, []);

	return (
		<Background>
			<EntraceAccessManager>
				<Header />
				<VerificationContentBox>
					<TitleContainer>
						<Title>{texts.accountVerificationTitle}</Title>
					</TitleContainer>
					<LocalLoader
						isLoading={accountVerification.verified === null}
						wasSuccessful={accountVerification.verified}
						message={
							accountVerification.verified === null ? (
								texts.verifyingWaitASecond
							) : accountVerification.verified ? (
								<>
									<p>
										{texts.accountVerified1}
										<Link to="/signin">{texts.login}</Link>
										{texts.accountVerified2}
									</p>
								</>
							) : (
								<p>
									{texts.accountNotVerified}{" "}
									{texts.errors[accountVerification.error]}
								</p>
							)
						}
					/>
					<SubTitle></SubTitle>
				</VerificationContentBox>
				<Footer />
			</EntraceAccessManager>
		</Background>
	);
}

export default AccountVerificationRequest;
