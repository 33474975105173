/** @format */

import React from "react";
import { Route } from "react-router-dom";
import AccountRecoveryRequest from "./Request/AccountRecoveryRequest";
import AccountRecoveryWarning from "./Warning/AccountRecoveryWarning";
import AccountRecoveryChangePassword from "./ChangePassword/AccountRecoveryChangePassword";

function AccountVerification() {
	

	return (
		<>
			<AccountRecoveryRequest />
			<Route
				path={`/accountrecovery/:code`}
				component={AccountRecoveryChangePassword}
			/>
			<Route
				path={`/accountrecovery/warning`}
				component={AccountRecoveryWarning}
			/>
		</>
	);
}

export default AccountVerification;
