/** @format */

import SettingsContent from "../SettingsContent/SettingsContent";
import {
	MainContentInner,
	PictureBox,
	InputLabel,
	CameraBox,
} from "../SettingsContent/SettingsContent-styled";
import Input from "../../../common/Input/Input";
import Select from "../../../common/Select/Select";

import language from "../../../../services/defineLanguage";

import photo from "../../../../assets/images/defaultProfileImage2.png";

import { useState } from "react";
import { createSelectOptions, createSelectOptionsFromJson } from "../../../../services/createSelectOptions";
import { Camera } from "../../../common/CoverBox/CoverBox-styled";
import ChangePicture from "../../../common/ChangePicture/ChangePicture";
import { updateProfileImage } from "../../../../services/User";
const genders = require(`../../../../texts/${language}/genders`);
let gendersOptions = createSelectOptions(genders, "", true, 1);
const countries = require(`../../../../texts/${language}/countries`);
let countriesOptions = createSelectOptionsFromJson(countries);
const texts = require(`../../../../texts/${language}/settingsProfile`);

export default function SettingsProfileView({
	name,
	setName,
	surname,
	setSurname,
	gender,
	setGender,
	country,
	setCountry,
	birthday,
	setBirthDay,
	onSubmit,
	pictureURL,
	updateProfilePic,
	response,
}) {
	const [showChangeProfilePicModal, setShowChangeProfilePicModal] =
		useState(null);
	return (
		<>
			<ChangePicture
				onClose={() => {
					setShowChangeProfilePicModal(false);
				}}
				changePicture={updateProfileImage}
				show={showChangeProfilePicModal}
				title={"Change Profile Pic"}
				initialImage={pictureURL}
				callback={updateProfilePic}
			/>

			<SettingsContent
				title={texts.profile}
				onSubmit={onSubmit}
				isLoading={response.isLoading}
				errorMessage={
					response.success === false &&
					(texts.errors[response.error] ?? texts.errors.default)
				}
			>
				<MainContentInner margin="0 0 0 10px">
					<InputLabel>{texts.picture}</InputLabel>
					<PictureBox>
						<img
							src={pictureURL || photo}
							alt=""
						/>
						<CameraBox
							onClick={() => {
								setShowChangeProfilePicModal(true);
							}}
						>
							<Camera />
						</CameraBox>
					</PictureBox>
					{/* <MainContentInner margin="0 0 0 10px"> */}
					<Input
						label={texts.name}
						value={name}
						setValue={setName}
						required
						style={{ width: "200px" }}
					/>
					<Input
						label={texts.surname}
						value={surname}
						setValue={setSurname}
						required
						style={{ width: "200px" }}
					/>
					{/* </MainContentInner> */}
				</MainContentInner>
				<MainContentInner margin="0 0 0 10px">
					<Select
						label={texts.gender}
						value={gender}
						setValue={setGender}
						required
						style={{ width: "200px" }}
					>
						{gendersOptions}
					</Select>
					<Select
						label={texts.country}
						value={country}
						setValue={setCountry}
						required
						style={{ width: "200px" }}
					>
						{countriesOptions}
					</Select>
					<Input
						type="date"
						label={texts.birthday}
						value={birthday}
						setValue={setBirthDay}
						required
						style={{ width: "200px" }}
					/>
				</MainContentInner>
			</SettingsContent>
		</>
	);
}
