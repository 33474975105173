/** @format */

import { useState, useEffect } from "react";
import language from "../../../../../services/defineLanguage.js";
// const texts = require(`../../../../texts/${language}/mission`)
import shuffleAlternatives from "../../../../../constants/shuffleAlternatives.js";

import {
	OptionsTable,
	Wrapper,
	OptionImage,
	OptionLabel,
	OptionContainer,
} from "./quiz-styling";

export default function Quiz({
	isQuestionAnswered,
	data,
	setSelectedAnswer,
	selectedAnswer,
}) {
	const [answerOptions, setAnswerOptions] = useState([]);

	useEffect(() => {
		setAnswerOptions(shuffleAlternatives(data));
	}, [data]);

	function renderAnswerOptions() {
		return data.map((option, index) => {
			return (
				<OptionContainer
					onClick={() => {
						setSelectedAnswer(option.id);
					}}
					isSelected={option.id === selectedAnswer}
					isQuestionAnswered={isQuestionAnswered}
					isCorrect={false}
					key={index}
				>
					<OptionLabel>
						{option.text}
						{option.answerImgURL && <OptionImage src={option.answerImgURL} />}
					</OptionLabel>
				</OptionContainer>
			);
		});
	}

	return (
		<Wrapper>
			<OptionsTable isQuestionAnswered={isQuestionAnswered}>
				{answerOptions && renderAnswerOptions()}
			</OptionsTable>
		</Wrapper>
	);
}
