/** @format */

module.exports = {
	profile: "Profile",
	name: "Name",
	surname: "Surname",
	gender: "Gender",
	country: "Country",
	birthday: "Birthday",
	uploadNewPhoto: "Upload new photo",
	picture: "Picture",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
