/** @format */

module.exports = {
	chooseYourAvatar: "Choose your avatar",
	chooseThisAvatar: "Choose this avatar",
	avatarModalDescription:
		"This avatar will represent you in the system (once chosen, you won't be able to change it).",
	GoldenEagle: {
		name: "Real Eagle",
		description: "The Real Eagle draw attention for his large dimensions, up to two and a half meters. When it flies down, diving, it reaches a speed of 250 km/h. It usually hunts rodents, as well as medium-sized animals such as young boars or deer.",
		levels: [
			"The Real Eagle does not even know that it will be born, but its parents are already preparing for its arrival and have built a great nest.",
			"The Real Eagle is close to being born. He is protected from the dangers inside a beautiful and grandiose egg.",
			"Now the Real Eagle is much closer to being born. It's possible to see his egg starting to crack.",
			"Finally! The Real Eagle was able to see the world for the first time.",
			"Shortly after birth, it is already possible to see the Real Eagle covered by its white fluff.",
		],
	},
	Harpia: {
		name: "Harpia",
		description: "The Harpia lives in tropical forests and is one of the largest and most powerful of the species. It's plumage is grayish with darker bands and stripes on the wings and head.",
		levels: [
			"The Harpia still don't even know it will be born, but its parents are already preparing for their arrival and have built a beautiful nest.",
			"The Harpia is close to being born. Her beautiful egg guards her against the dangers of the jungle.",
			"Now the Harpia is very close to seeing the world for the first time. It's can already see your egg starting to crack.",
			"Now yes!!! The Harpia was able to get to know the world.",
			"The Harpia was born and it is already possible to perceive its striking characteristic of white fluff with a beautiful black beak.",
		],
	},
	StellerEagle: {
		name: "Steller Eagle",
		description: "The Steller Eagle is native to Japan, Korea, and North China and loves to eat salmon. It can hunt in flight or even dive into the water. With its large orange beak, it is extraordinary and original.",
		levels: [
			"The Steller Eagle does not even imagine that will be born, but it parents are already ready for his/her birth and have built a wonderful nest for his/her arrival.",
			"The Steller Eagle is close to being born. It is protected inside a beautiful egg.",
			"Now the Steller Eagle is very close to being born and its egg is already cracking.",
			"Now yes!!! The Steller Eagle is beginning to see the world.",
			"The Steller Eagle was born! Since was a child already stands out for his beautiful orange beak.",
		],
	},

	confirmTitle: "Confirm your avatar choice",
	confirmHeader1: "Are you sure you want to pick ",
	confirmHeader2: " as your avatar?",
	confirmDesc: "Once chosen, you may not change your avatar.",
	confirmButton: "Confirm",
};
