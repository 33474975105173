import styled from 'styled-components'

export const CarouselContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 15px;
    padding: 15px 5px;
    overflow: hidden;

    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.white};
`

export const ControlButtonBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 40px;
    height: 100%;
`

export const ControlButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1rem;

    height: 50px;
    width: 30px;

    border: 1px solid black;
    border-radius: 5px;

    color: black;
    background-color: white;
    color: black;

    transition: 0.3s;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
        color: white;
    }
`

export const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;

    img {
        flex: none;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: 0.8s;
    }

    div {
        flex: none;
        box-sizing: border-box;
        padding: 10px;
        max-height: 100%;

        transition: 0.8s;
    }
`

export const Text = styled.p`
    box-sizing: border-box;

    min-height: 5.5rem;
    padding: 10px 30px;
    text-align: justify;
`
