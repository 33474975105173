/** @format */

module.exports = {
	rankingTitle: "Ranking Semanal",
	explanation:
		"Os dois primeiros no ranking semanal avançam para o próximo nível. Mas,tome cuidado, pois os dois últimos serão rebaixados ao nível anterior.",
	remainingTime: "Tempo Restante:",
	timeOver: "Acabou o tempo!",
	teamTitle: "Seu Time",
	levelTitle: "Nível",
	teamColumnName: "Nome",
	teamColumnProgress: "Progresso",
	teamColumnPoke: "Poke",
	teamPokeTooltip:
		"Envie uma notificação para um colega ao pressionar o botão azul",
	teamGreetings: "Olá,",
	teamThisWeek: "Nessa semana, você está no time ",
	teamIfAllMembers: "Se todos os membros do seu time completarem",
	teamMissionUntilWeekend:
		"missões até o término do fim de semanamissions, cada membro do time vai ganhar ",
	teamCoins: "moedas",
	achievementsTitle: "Conquistas",
	achievementsDescription:
		"You have no achievements yet. But calm down, just do the missions that you will soon have your first achievement.",
	you: "Você",
	teamsNames: ["Águia Real", "Harpia", "Águia Steller"],
	youAreNotOnAnyTeam:"Você não está em nenhuma time!",

	errors: {},
};
