/** @format */

import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { EagleContext } from "../../../contexts/EagleContext";
import AppStructure from "../../environments/app/AppStructure";
import {
	StoreContainer,
	StoreTitle,
	StoreSubtitle,
	StoreContent,
	SectionTitle,
	BuyModalContainer,
} from "./Store-styled";
import StoreCard from "./StoreCard/StoreCard";
import storeItems from "../../../constants/storeItems";
import itemsNamesList from "../../../constants/itemsNamesList";

import Modal2 from "../../common/Modal2/Modal2";

import language from "../../../services/defineLanguage";
import Chest from "../../common/Chest/Chest";
import { Button } from "../../GenericComponents";
import StatusMessage from "../../common/Message/StatusMessage/StatusMessage";
const texts = require(`../../../texts/${language}/store`);

export default function Store() {
	const [selectedItem, setSelectedItem] = useState(null);
	const history = useHistory();
	const { getCurrentCourse, buyItem, getCurrentCourseSetting } =
		useContext(EagleContext);
	let course = getCurrentCourse();
	let settings = getCurrentCourseSetting();

	function verifyIfCanBuy() {
		if (selectedItem === null || course === null) return false;
		return course.economy - storeItems.get(selectedItem).price >= 0;
	}

	return (
		<>
			{settings.economy ? (
				<AppStructure>
					<StoreContainer>
						<StoreTitle>{texts.store}</StoreTitle>
						<StoreSubtitle>{texts.description}</StoreSubtitle>
						{settings.rarity && (
							<>
								<SectionTitle>{texts.rarity}</SectionTitle>
								<StoreContent>
									{itemsNamesList.slice(0, 4).map((itemName) => {
										return (
											<StoreCard
												key={itemName}
												imagePath={storeItems.get(itemName).image}
												name={texts.items[itemName].name}
												description={texts.items[itemName].description}
												price={storeItems.get(itemName).price}
												onBuy={() => setSelectedItem(itemName)}
												wasBought={
													course &&
													course.purchasedItems.findIndex((purchasedItem) => {
														return purchasedItem.item === itemName;
													}) !== -1
												}
											/>
										);
									})}
								</StoreContent>
							</>
						)}
						{settings.novelty && (
							<>
								<SectionTitle>{texts.novelty}</SectionTitle>
								<StoreContent>
									{itemsNamesList.slice(4, 8).map((itemName) => {
										return (
											<StoreCard
												key={itemName}
												imagePath={storeItems.get(itemName).image}
												name={texts.items[itemName].name}
												description={texts.items[itemName].description}
												price={storeItems.get(itemName).price}
												onBuy={() => setSelectedItem(itemName)}
												wasBought={
													course &&
													course.purchasedItems.findIndex((purchasedItem) => {
														return purchasedItem.item === itemName;
													}) !== -1
												}
											/>
										);
									})}
								</StoreContent>
							</>
						)}
						{/* <SectionTitle>{texts.boost}</SectionTitle>
						<StoreContent>
							{itemsNamesList.slice(8).map((itemName) => {
								return (
									<StoreCard
										key={itemName}
										imagePath={storeItems.get(itemName).image}
										name={texts.items[itemName].name}
										description={texts.items[itemName].description}
										price={storeItems.get(itemName).price}
										onBuy={() => setSelectedItem(itemName)}
										wasBought={
											course &&
											course.purchasedItems.findIndex((purchasedItem) => {
												return purchasedItem.item === itemName;
											}) !== -1
										}
									/>
								);
							})}
						</StoreContent> */}
					</StoreContainer>
					{course !== null && (
						<Modal2
							show={selectedItem !== null}
							title={
								verifyIfCanBuy()
									? `${texts.buy} ${texts.items[selectedItem].name}`
									: texts.cantBuyTitle
							}
							onClose={() => {
								setSelectedItem(null);
							}}
							disableFooter={true}
						>
							<BuyModalContainer>
								{selectedItem !== null && verifyIfCanBuy() ? (
									<>
										<span style={{ paddingBottom: "10px" }}>
											{texts.confirmMessage1} {texts.items[selectedItem].name}?
										</span>
										<Chest state={"medium"} />
										<span style={{ paddingBottom: "10px" }}>
											{texts.confirmMessage2}
											<StatusMessage
												value={
													course.economy - storeItems.get(selectedItem).price
												}
												type={"economy"}
											/>
											.
										</span>
										<Button
											onClick={() => {
												buyItem(
													selectedItem,
													storeItems.get(selectedItem).price
												);
												setSelectedItem(null);
											}}
										>
											{" "}
											{texts.buy}{" "}
										</Button>
									</>
								) : (
									<>
										<span style={{ paddingBottom: "10px" }}>
											{texts.cantBuy}{" "}
											{texts.items[selectedItem] !== undefined &&
												texts.items[selectedItem].name}
											!
										</span>
										<Chest state={"empty"} />
										<span style={{ paddingBottom: "10px" }}>
											{texts.youOnlyHave}{" "}
											<StatusMessage
												value={course.economy}
												type={"economy"}
											/>
											.
										</span>
										<Button
											onClick={() => {
												setSelectedItem(null);
											}}
										>
											{texts.ok}
										</Button>
									</>
								)}
							</BuyModalContainer>
						</Modal2>
					)}
				</AppStructure>
			) : (
				history.push("/profile")
			)}
		</>
	);
}
