/** @format */

import {
	Container,
	Name,
	FollowButton,
	UnfollowButton,
} from "./FriendCard-styled";
import { Photo } from "../../GenericComponents";
import photo from "../../../assets/images/defaultProfileImage2.png";
import { useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";

import language from "../../../services/defineLanguage";
const texts = require(`../../../texts/${language}/friends`);

export default function Card({ name, imagePath, id, isFollowed }) {
	const { follow, unfollow } = useContext(EagleContext);
	return (
		<Container>
			<Photo
				src={imagePath || photo}
				alt="Profile picture"
				style={{ marginTop: "5px" }}
				size={60}
			/>
			<Name title={name}>{name}</Name>
			{isFollowed ? (
				<UnfollowButton
					onClick={() => {
						unfollow(id);
					}}
				>
					{texts.unfollow}
				</UnfollowButton>
			) : (
				<FollowButton
					onClick={() => {
						follow(id);
					}}
				>
					{texts.follow}
				</FollowButton>
			)}
		</Container>
	);
}
