/** @format */

module.exports = {
	completedSubjects: "Assuntos completos:",
	completedMissions: "Missões completas:",
	completedTasks: "Tarefas completas:",
	subjects: "Assuntos",
	missions: "Missões",
	attempt: "Tentativa",
	attempts: "Tentativas",
	tasks: "Tarefas",
	noSubjects: "Nenhum assunto encontrado.",
	noMissions: "Nenhuma missão dentro desse assunto.",
	noTasks: "Nenhum tarefa dentro dessa missão",
	done: "Concluído",
	correct: "Correto",
	pending: "Pendente",
	notFinished: "Não Finalizado",
	notDone: "Não Acabado",
	wrong: "Errado",
	inProgress: "Em Andamento",
	skips: "Pulos",
};
