/** @format */

import React from "react";
import ReactDOM from "react-dom";
import {
	Overlay,
	ModalContainer,
	ModalHeader,
	CloseModal,
	ModalTitle,
	ModalContent,
	ModalFooter,
} from "./Modal2-styled";
import { FaTimes } from "react-icons/fa";

export default function Modal2({
	children,
	title,
	footer,
	show,
	onClose,
	minWidth,
	disableCloseButton,
	disableFooter
}) {
	if (!show) return null;

	return ReactDOM.createPortal(
		<>
			<Overlay onClick={onClose} />
			<ModalContainer style={{ minWidth }} hasFooter={!disableFooter}>
				<ModalHeader alignCetner={disableCloseButton}>
					{title ? <ModalTitle>{title}</ModalTitle> : <div></div>}
					{!disableCloseButton && (
						<CloseModal onClick={onClose}>
							<FaTimes size={25} />
						</CloseModal>
					)}
				</ModalHeader>
				<ModalContent hasFooter={!disableFooter}>{children}</ModalContent>
				{footer}
			</ModalContainer>
		</>,
		document.getElementById("modal-root")
	);
}
