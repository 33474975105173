import styled from 'styled-components'

export const Wrapper = styled.form`
	margin: 1rem;
	width: 100%;

	justify-content: center;
	display: flex;
	align-items: center;

	color: black;
`
export const OptionsTable = styled.div`
	width: 35%;

	display: flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	margin: 1rem 2rem 2rem 2rem;

	@media screen and (max-width: 960px) {
		width: 100%;
		column-gap: 0;
	}
`

export const PairContainer = styled.div`
	background-color: ${(props) =>
		props.isAnsweredRight
			? props.theme.colors.darkGreen
			: props.isSelected
			? props.theme.colors.lightBlue
			: props.theme.colors.gray};

	color: ${(props) =>
		props.isAnsweredRight
			? props.theme.colors.white
			: props.isSelected
			? props.theme.colors.white
			: props.theme.colors.black};

	border-radius: 25px;

	padding: 0.4rem 0.7rem 0.4rem 0.7rem;
	margin: 0.2rem;

	justify-self: center;
	width: fit-content;
	text-align: center;

	/* TODO: show green colour then make it pair disappear */
	/* display: ${(props) => (props.isAnsweredRight ? 'none' : 'inline')}; */

	/* TODO: ADD RED EFFECT WHEN PAIR IS WRONG */
	/* background-color: ${(props) =>
		props.isWrongPair ? props.theme.colors.warning : null} !important;
	transition: 0.2s */

	&:hover,
	&:focus-within {
		background-color: ${(props) =>
			props.isAnsweredRight
				? props.theme.colors.darkGreen
				: props.theme.colors.darkGray};
		color: ${(props) => (props.isAnsweredRight ? 'inhert' : 'white')};

		transition: 0.5s;
	}

	cursor: ${(props) => {
		if (props.isQuestionAnswered === false) {
			if (props.isAnsweredRight === true) {
				return 'default'
			} else {
				return 'pointer'
			}
		} else {
			return 'not-allowed'
		}
	}};
`

export const PairLabel = styled.span``
