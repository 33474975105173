/** @format */

module.exports = {
	title: "Título",
	description: "Descrição",
	typeHere: "Digite aqui...",
	save: "Salvar",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
