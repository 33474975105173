/** @format */

import { Wrapper, PatentImg } from "./dynamic-patent-styling";
import achievementsPatentImgMap from "../../../../../constants/patentBadgeNameUsingMap";
import ReactTooltip from "react-tooltip";

import language from '../../../../../services/defineLanguage'
const texts = require(`../../../../../texts/${language}/achievements`);

const DynamicPatentBadge = ({ badgeId, enable }) => {
	const badgeScrIdentifier = badgeId * 2 - (enable ? 0 : 1) - 1;
	let achievement = texts[badgeId] || {name:"unkonw", description:"unkonw"}

	let tip =
		`<span style="
      text-align:center;
      display:flex;
      flex-direction:column;
      justify-content:center;"
    > 
      <b>${achievement.name}</b>
      <span>${achievement.description}</span>
    </span>`;


	return (
		<Wrapper>
			<PatentImg
				data-html="true"
				data-tip={tip}
				src={achievementsPatentImgMap[badgeScrIdentifier]}
			/>
			<ReactTooltip />
		</Wrapper>
	);
};

export default DynamicPatentBadge;
