module.exports = {
    createYourAccount:"Create your account",
    placeholderName:"Name",
    placeholderLastName:"Last name",
    placeholderEmail:"Email",
    placeholderPassword:"Enter your password",
    placeholderPasswordConfirmation:"Confirm your password",
    birthday:"Birthday",
    gender:"Gender",
    country:"Country",
    acceptTerms:[ "I agree to the ", "Terms & Conditions"],
    signup:"Sign up",
    successMessage:"Account successfully created! We sent an email to verify your account.",

    errors:{
        
        nameInvalid:"First and last names cannot contain numbers or special characters or exceed 100 characters.",
        emailInvalid:"The email entered is not valid!",
        differentPasswords:"The passwords entered do not match!",
        weakPassword:"The password must be at least 8 characters long, including uppercase and lowercase letters, numbers and special characters ( ! @ # ? $ *).",
        termsNotChecked:"You must agree to the Terms & Conditions to register.",

        
        emailAlreadyRegistered:"The email entered is already registered.",
        unableToCreateAccount:[ "Não foi possível criar a conta (", ")."]

        
    }
}