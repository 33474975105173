import styled from 'styled-components'

export const Wrapper = styled.form`
	margin: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	color: black;
`

export const TextArea = styled.textarea`
	width: 100%;

	padding: 0.3rem;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 10px;
	outline: none;

	@media screen and (max-width: 960px) {
		wrap: off;
	}

	&:focus {
		transition: 0.2s;
		border: 1px solid ${(props) => props.theme.colors.primary};
		box-shadow: ${(props) => '0 0 1px 1px ' + props.theme.colors.primary};
	}
`
