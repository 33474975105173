/** @format */

import { FaQuestionCircle } from "react-icons/fa";
import TextInput from "../../forms/input/TextInput";
import ReactTooltip from "react-tooltip";
import { InputLabel } from "../Input/Input-styled";
import { IconContext } from "react-icons";
import { theme } from "../../../contexts/theme/Theme";

import language from "../../../services/defineLanguage.js";
const texts = require(`../../../texts/${language}/instructor/iconInput`);

export function IconInput({ value, setValue, margin }) {
	return (
		<IconContext.Provider
			value={{
				color: theme.colors.primary,
				size: "30",
			}}
		>
			<InputLabel>{texts.icon}</InputLabel>
			<div
				style={{
					display: "flex",
					justifyContent: "cente",
					alignItems: "center",
					width: "100%",
					margin: margin,
				}}
			>
				<TextInput
					icon={value || "FaGraduationCap"}
					onChange={(event) => {
						setValue(event.target.value);
					}}
					placeholder={"Ex:FaBaby, FaAt, ..."}
					value={value}
					margin={"0px"}
					inputLabelMargin={"0px"}
					required={false}
				/>
				<a
					href="https://react-icons.github.io/react-icons/icons/fa/"
					target="_blank"
					style={{ margin: "0 10px" }}
				>
					<FaQuestionCircle data-tip={texts.iconTip} />
				</a>
				<ReactTooltip
					// place=""
					type="dark"
					effect="solid"
				/>
			</div>
		</IconContext.Provider>
	);
}
