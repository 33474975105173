/** @format */

import styled, { css } from "styled-components";
import { BaseContainer } from "../../environments/courses-board/BoardSectionContainers";
import { Button } from "../../../globalStyles";

export const SubjectContainer = styled(BaseContainer)`
	h3 {
		font-size: 24px;
		font-weight: 400;
		margin-bottom: 10px;
	}

	@media screen and (max-width: 375px) {
		margin: 1rem 1rem 1.5rem 1rem;
	}
`;

export const Wrapper = styled.div`
	width: 100%;
	overflow-x: hidden;

	background-color: ${(props) => props.theme.colors.gray};
`;

export const SubjectBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	p {
		margin-bottom: 1rem;
	}

	h1 {
		margin-bottom: 1.5rem;
	}

	img {
		max-width: 100%;
		height: auto;
	}
`;

export const SubjectBodyMessage = styled.h2`
	text-align:center;
	margin-bottom:16px;
`;
export const SubjectTitle = styled.h1`
	color: ${(props) => props.theme.colors.primary};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;

	white-space: wrap;
	overflow: hidden; 
	text-overflow: ellipsis;
	p {
		margin-left: 0.5rem;
	}
	@media screen and (max-width: 375px) {
		flex-direction:column;
	}
`;

export const SubjectHeader = styled.div`
	display: grid;
	place-items: center;
	grid-template-columns: 99% 1%;

	width: 100%;

	@media screen and (max-width: 375px) {
		grid-template-columns: 100%;
	}
`;

export const InfoButton = styled.button`
	border-radius: 50%;
	display: block;
	height: 40px;
	width: 40px;

	background: ${({ primary }) => (primary ? "#4b59f7" : "#0467fb")};
	white-space: nowrap;
	color: #fff;
	font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
	outline: none;
	border: none;
	cursor: pointer;

	&:hover {
		transition: all 0.3s ease-out;
		background: #fff;
		background: ${({ primary }) => (primary ? "#0467fb" : "#4b59f7")};
	}
`;

export const UnreadCircle = styled.span`
	/* Make the badge float in the top right corner of the button */
	background-color: #fa3e3e;
	border-radius: 50%;
	height: 15px;
	width: 15px;
	color: blacks;

	padding: 1px 3px;
	font-size: 10px;

	position: absolute; /* Position the badge within the relatively positioned button */
	top: 1;
	right: 1;
`;

export const MissionsListContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin-bottom: 16px;

	min-width: 400px;

	@media screen and (max-width: 960px) {
		width: 100%;
		min-width: 0px;
	}
`;
export const MissionsListTitle = styled.h4`
	border: solid 1px ${(props) => props.theme.colors.primary};
	border-radius: 5px 5px 0px 0px;
	margin: 0px !important;
	padding: 5px;
	width: 100%;

	text-align: center;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
`;

export const MissionsList = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;

	list-style-type: none;
	li:last-child {
		border-radius: 0px 0px 5px 5px;
	}
`;

export const MissionsListItem = styled.li`
	width: 100%;
	padding: 5px;
	border: solid 1px ${(props) => props.theme.colors.primary};
	border-width: 0px 1px 1px 1px;

	text-align: left;

	background-color: ${(props) =>
		props.isSelected ? props.theme.colors.primary : props.theme.colors.white};
	color: ${(props) =>
		props.isSelected ? props.theme.colors.white : props.theme.colors.black};

	display: flex;
	justify-content: center;
	align-items: center;

	${(props) =>
		!props.isSelected &&
		css`
			&:hover {
				// transform: scale(1.1);
				cursor: pointer;
				color: ${(props) => props.theme.colors.primary};
				// border: solid 1px white;
			}
		`}

	transition-duration:0.3s;
`;

export const MissionListItemContent = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-right: 20px;

	overflow: hidden; 
	text-overflow: ellipsis;
`;

export const MissionIconWrapper = styled.div`
	margin-right: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const SubjectButton = styled(Button)`
	min-width: 400px;
	@media screen and (max-width: 960px) {
		min-width: 0px;
	}
	white-space: wrap;
`;
