/** @format */

module.exports = {
	title: "Missão",
	skipTaskButtonLabel: "Pular questão",
	checkAnswerButtonLabel: "Verificar resposta",
	sendAnswerButtonLabel: "Enviar resposta",
	nextButtonLabel: "Próxima",
	questionIsRightTitleText: "Muito bem!",
	questionIsRightBodyText: "Você respondeu corretamente!",
	questionIsWrongTitleText: "É uma pena!",
	questionIsWrongBodyText:
		"Não se preocupe, você terá uma nova oportunidade em breve...",
	pairInstruction: "Selecione os pares",
	quizInstruction: "Escolha a opção adequada",
	finishedMissionTitle: "Parabéns! Você terminou a missão!",
	finishedMissionButton: "Voltar ao curso",
	errors: {},
};
