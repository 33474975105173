module.exports = {
	success: "New profile pic sended successfully!",
	tryAgain:"Try again",
	finish: "Finalizar",


	errors: {
		FileNotSelected: 'Please select File',
		FileTooLarge: 'File size cannot exceed more than 5MB',
		default:"The profile pic can't be sended.. Try again later!",
	},
}
