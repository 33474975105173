/** @format */

import { useState } from "react";
import {
	TextAreaContent,
	TextAreaLabel,
	TextAreaWrapper,
} from "./TextArea-styled";

export default function TextArea({
	name,
	label,
	rows,
	cols,
	placeholder,
	maxLength,
	value,
	setValue,
	style,
	required,
	disabled,
}) {
	const [isOnFocus, seIsOnFocus] = useState(false);
	return (
		<>
			<TextAreaLabel>{label}</TextAreaLabel>
			<TextAreaWrapper style={style} isOnFocus={isOnFocus}>
				<TextAreaContent
					id={label}
					name={name}
					rows={rows}
					cols={cols}
					placeholder={placeholder}
					maxLength={maxLength}
					value={value}
					onChange={(event) => setValue(event.target.value)}
					required={required}
					disabled={disabled}
					onFocus={() => {
						seIsOnFocus(true);
					}}
					onBlur={() => {
						seIsOnFocus(false);
					}}
				/>
			</TextAreaWrapper>
		</>
	);
}
