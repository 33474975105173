module.exports = {
    courses: 'Cursos',
    english: 'Inglês',
    addCourse: 'Adicionar curso',
    students: 'Estudantes',
    newRegistrations: 'Novas matrículas',
    yourStudents: 'Seus estudantes',
    performance: 'Desempenho',
    courseStats: 'Estatísticas do curso',
    gamification: 'Gamificação',
    basicSettings: 'Configurações básicas',
    advancedSettings: 'Configurações avançadas',
    support: 'Apoio',
    tutorials: 'Tutoriais',
}
