/** @format */

module.exports = {
	noMaterials: "Nenhum material!",
	writeHTML: "Escreva o HTML dos materiais de suporte aqui...",
	save: "Salvar",
	reset: "Reiniciar",
	savedSuccessfully: "Salvo com sucesso!",
	errors: {
		default: "Algo deu errado... tente mais tarde.",
	},
};
