import { useState, useEffect } from 'react'

import { getCourses } from '../../../services/Instructor'
import AllCoursesView from './AllCoursesView'

export default function AllCoursesContainer() {
    const [courses, setCourses] = useState([])

    useEffect(async () => {
		let courses = await getCourses();
		setCourses(courses);
	}, []);

    return (
        <>
         <AllCoursesView courses={courses} />
        </>
    )
}
