/** @format */

module.exports = {
	noMaterials: "No materials!",
	writeHTML: "Write the HTML of supporting materials here...", // Escreva o HTML dos materiais de suporte aqui
	save: "Save",
	reset: "Reset",
	savedSuccessfully: "Saved successfully!",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
