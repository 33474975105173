/** @format */

module.exports = {
	title: "Title",
	description: "Description",
	typeHere: "Type here...",
	save: "Save",
	errors: {
		default: "Something went wrong... try again later.",
	},
};
