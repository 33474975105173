/** @format */

import React from "react";
import { theme } from "../../../../contexts/theme/Theme";
import { FloatingBox } from "../FloatingBox";

let messagesTypes = {
	success: {
		backgroundColor: theme.colors.lightGreen,
		color: theme.colors.darkGray,
	},
	error: {
		backgroundColor: theme.colors.lightRed,
		color: theme.colors.darkRed,
	},
	warning: {
		backgroundColor: theme.colors.lightOrange,
		color: theme.colors.darkOrange,
	},
};

export function FloatingMessageBox({ type, children, zIndex }) {
	return (
		<FloatingBox
			zIndex={zIndex}
			backgroundColor={messagesTypes[type]?.backgroundColor}
			color={messagesTypes[type]?.color}
		>
			{children}
		</FloatingBox>
	);
}
