/** @format */

import { useState, useEffect, useContext } from "react";
import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import { Link } from "react-router-dom";
import {
	StudentsContainer,
	Title,
	SubTitle,
	AccessButton,
} from "./Students-styled";
import Table from "../../common/Table/Table";

import photo from "../../../assets/images/defaultProfileImage2.png";

import language from "../../../services/defineLanguage.js";
import { InstructorContext } from "../../../contexts/InstructorContext";
import { getCourseStudents } from "../../../services/Instructor";
import { Photo } from "../../GenericComponents";
const countries = require(`../../../texts/${language}/countries`);
const texts = require(`../../../texts/${language}/instructor/students`);
const genders = require(`../../../texts/${language}/genders2`);

export default function Current() {
	const [students, setStudents] = useState([]);
	const { currentCourseId } = useContext(InstructorContext);

	useEffect(async () => {
		let newStudents = await getCourseStudents(currentCourseId);
		newStudents = newStudents.map((student) => {
			let finalStudent = {};
			finalStudent[texts.picture] = (
				<Photo
					src={
						student.pictureURL ==
							"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
						student.pictureURL?.length === 0
							? photo
							: student.pictureURL
					}
				/>
			);
			finalStudent[texts.name] = student.name;
			finalStudent[texts.country] =
				countries[student.country] || student.country;
			finalStudent[texts.gender] = genders[student.gender];
			finalStudent[texts.lastAccess] =
				student.lastAccess !== null
					? student.lastAccess.split("T")[0]
					: "---------------";
			finalStudent[texts.studentPage] = createAccessButton(student.studentId);
			return finalStudent;
		});
		setStudents(newStudents);
	}, []);

	function createAccessButton(studentId) {
		return (
			<AccessButton>
				<Link to={"/instructor/students/current/" + studentId}>
					{texts.access}
				</Link>
			</AccessButton>
		);
	}

	return (
		<InstructorStructure>
			<StudentsContainer>
				<Title>{texts.yourStudents}</Title>
				{students ? (
					<>
						<SubTitle>{texts.instructionCurrent}</SubTitle>
						<Table
							repeatHeader={true}
							data={{
								columns: [
									texts.picture,
									texts.name,
									texts.country,
									texts.gender,
									texts.lastAccess,
									texts.studentPage,
								],
								rows: students,
							}}
						></Table>
					</>
				) : (
					<SubTitle>{texts.noStudents}</SubTitle>
				)}
			</StudentsContainer>
		</InstructorStructure>
	);
}
