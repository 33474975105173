import { useState } from 'react'
import { Wrapper, TextArea } from './open-question-styling'

export default function OpenQuestion ({ callback: handleTaskFeedback }) {
  const [answerText, setAnswerText] = useState('')

  function handleText (text) {
    setAnswerText(text)
    handleTaskFeedback(true)
  }

  return (
    <Wrapper>
      <TextArea
        placeholder='Type here you answer...'
        wrap='soft'
        value={answerText}
        onChange={e => handleText(e.target.value)}
      />
    </Wrapper>
  )
}
