import styled from 'styled-components'

export const CountdownWrapper = styled.div`
    background: ${(props) => props.theme.gradients.countdown};
    color: ${(props) => props.theme.colors.white};
    padding: 2rem;
    margin-bottom: 1.5rem;

    h3 {
        font-size: 24px;
        margin-bottom: 5px;
    }

    h4 {
        font-weight: 500;
        font-size: 18px;
    }

    span {
        /* color: ${(props) => props.theme.colors.warning}; */
    }
`
