import SettingsStructure from './SettingsStructure/SettingsStructure'
import SettingsPrivacy from './SettingsPrivacy/SettingsPrivacy'
import SettingsCustomization from './SettingsCustomization/SettingsCustomization'
import SettingsGamification from './SettingsGamification/SettingsGamification'
import { Route, useLocation, useHistory } from 'react-router-dom'

import { useEffect} from 'react'
import DynamicStructur from '../../environments/DynamicStructur'
import SettingsProfileContainer from './SettingsProfile/SettingsProfileContainer'
import SettingsLanguageContainer from './SettingsLanguage/SettingsLanguageContainer'
import SettingsSecurityCotainer from './SettingsSecurity/SettingsSecurityCotainer'



export default function StudentSettings() {
    const {pathname} = useLocation();
    const history = useHistory()

    useEffect(() => {
        if(pathname === "/settings" || pathname === "/settings/" ){
            history.push("/settings/profile")
        }
    },[pathname])


    return (
      <DynamicStructur>
        <SettingsStructure>
        <Route
				path={`/settings/profile`}
				component={SettingsProfileContainer}
        />
        <Route
				path={`/settings/language`}
				component={SettingsLanguageContainer}
        />
        <Route
				path={`/settings/security`}
				component={SettingsSecurityCotainer}
        />
        <Route
				path={`/settings/privacy`}
				component={SettingsPrivacy}
        />
        <Route
				path={`/settings/customization`}
				component={SettingsCustomization}
        />
        <Route
				path={`/settings/gamification`}
				component={SettingsGamification}
        />
           
        </SettingsStructure>
        </DynamicStructur>
    )
}
