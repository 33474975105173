/** @format */

import styled from "styled-components";
import { Button } from "../../../globalStyles";

export const AddCourseWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const ButtonSearchCourse = styled(Button)`
	margin-top: ${(props) => (props.marginTop ? props.marginTop : "10px")};
	margin-bottom: 10px;
	background-color: #3050e4;
	height: 50px;
	font-size: 1.2rem;
	font-weight: bold;

	width: 50%;
	@media screen and (max-width: 720px) {
		width: 60%;
	}

	@media screen and (max-width: 480px) {
		width: 75%;
	}

	@media screen and (max-width: 320px) {
		width: 90%;
	}
`;

export const TitleSearchCourse = styled.h1`
	text-align: center;
	color: ${(props) => props.theme.colors.primary};
	text-weigth: bold;
	font-size: 1.5rem;
	margin-bottom: 10px;
`;

export const SubTitleSearchCourse = styled.h3`
	text-align: start;
	margin-top: 10px;
	${(props) => props.theme.colors.grey};
	font-size: 1.2rem;
	font-weight: bold;

	width: 50%;
	@media screen and (max-width: 720px) {
		width: 60%;
	}

	@media screen and (max-width: 480px) {
		width: 75%;
	}

	@media screen and (max-width: 320px) {
		width: 90%;
	}
`;

export const DivLine = styled.div`
	width: 100%;
	border-bottom: 0.1px solid #ccc;
	box-shadow: 0px 0px 0.1px 0px rgba(0, 0, 0, 0.2);
	padding: 0;

	@media screen and (max-width: 1080px) {
		border-bottom-width: 0.05px;
		box-shadow: 0px 0px 0.05px 0px rgba(0, 0, 0, 0.2);
	}
`;

export const InputWrapper = styled.div`
	height: 50px;
	width: 50%;
	margin-bottom: 10px;

	@media screen and (max-width: 720px) {
		width: 60%;
	}

	@media screen and (max-width: 480px) {
		width: 75%;
	}

	@media screen and (max-width: 320px) {
		width: 90%;
	}
`;

export const SearchResults = styled.div`
	${(props) => props.theme.colors.grey};
	margin-top: 10px;
	font-size: 1.2rem;

	width: 50%;
	@media screen and (max-width: 720px) {
		width: 60%;
	}

	@media screen and (max-width: 480px) {
		width: 75%;
	}

	@media screen and (max-width: 320px) {
		width: 90%;
	}
	color: ${(props) => (props.courseFound ?  props.theme.colors.darkGreen : props.theme.colors.warning) };
`;

export const ButtonConfirmInscription = styled(Button)`
	flex: 1;
	height: 50px;
	background-color: ${(props) => props.theme.colors.darkGreen };

	font-size: 1.2rem;
	font-weight: bold;
	margin-right: 5px;

	@media screen and (max-width: 720px) {
		margin-right: 0px;
	}
`;

export const ButtonCancelInscription = styled(Button)`
	flex: 1;
	height: 50px;

	font-size: 1.2rem;
	background-color: ${(props) => props.theme.colors.warning };
	font-weight: bold;
	margin-left: 5px;

	@media screen and (max-width: 720px) {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 400px;
	margin: 10px 0;

	height: 50px;
	font-size: 1.2rem;
	font-weight: bold;

	width: 50%;
	@media screen and (max-width: 720px) {
		width: 60%;
		flex-direction: column;
		align-items: center;
		height: 100%;
	}

	@media screen and (max-width: 480px) {
		width: 75%;
	}

	@media screen and (max-width: 320px) {
		width: 90%;
	}
`;

export const ResultMessage = styled.p`
	text-align: center;
`;
