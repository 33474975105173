/** @format */

import Background from "../../../environments/entrance/Background";
import Footer from "../../../environments/entrance/Footer";
import Header from "../../../environments/entrance/Header";
import EntraceAccessManager from "../../../common/EntraceAccessManager";
import {
	Title,
	Paragraph,
	SubTitle,
	TitleContainer,
	VerificationContentBox,
    ResendCodeButton
} from "./AccountVerificationWarning-styled";
import { useContext } from "react";
import { EagleContext } from "../../../../contexts/EagleContext";

import language from '../../../../services/defineLanguage.js'
const texts = require(`../../../../texts/${language}/accountVerification.js`)

function AccountVerificationWarning({ children }) {
	const { user, resendCode } = useContext(EagleContext);
	return (
		<Background>
			<EntraceAccessManager>
				<Header />
				{children}
				<VerificationContentBox>
					<TitleContainer>
						<Title>{texts.accountVerificationTitle}</Title>
					</TitleContainer>
					<SubTitle>
						{texts.description}
						<strong>
							{user.email
								? `${user.email.slice(
										0,
										user.email.length / 6
								  )}********${user.email.slice((5 * user.email.length) / 6)}`
								: "********"}
						</strong>
						.
					</SubTitle>
					<Paragraph>
						{texts["case_1.1"]} <ResendCodeButton onClick={resendCode}>{texts["case_1.2"]}</ResendCodeButton> {texts["case_1.3"]}
					</Paragraph>
					<Paragraph>
					    {texts.case_2}
					</Paragraph>
					<Paragraph>
                        {texts.case_3}
						<strong>contact@eagle-edu.com.br</strong>.
					</Paragraph>
				</VerificationContentBox>
				<Footer />
			</EntraceAccessManager>
		</Background>
	);
}

export default AccountVerificationWarning;
