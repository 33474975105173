/** @format */

import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import {
	HomeWrapper,
	HomeTitle,
	HomeSubTitle,
	SeeAll,
	CardsContainer,
	NewCourseButton,
} from "./Home-styled";
import Modal2 from "../../common/Modal2/Modal2";
import { FaPlusCircle } from "react-icons/fa";
import Card from "../../common/Card/Card";


import NewCourse from "../NewCourse/NewCourse";

import language from "../../../services/defineLanguage.js";
const texts = require(`../../../texts/${language}/instructor/home`);


export function HomeView({courses, isModalOpen, openModal, closeModal}) {

    function createCards(courses) {
		// Only the first 4 courses will be shown on the homepage
		courses = courses.slice(0, 4);
		let cards = [];

		for (let i = 0; i < courses.length; i++) {
			cards[i] = (
				<Card
					key={courses[i].courseInfo.title}
					title={courses[i].courseInfo.title}
					link={"/instructor/course/" + courses[i].courseInfo.id}
				>
					{courses[i].courseInfo.description}
				</Card>
			);
		}

		return cards;
	}
	return (
		<InstructorStructure>
			<HomeWrapper>
				<HomeTitle>{texts.yourCourses}</HomeTitle>

				{courses.length !== 0 ? (
					<>
						<HomeSubTitle>{texts.instruction1}</HomeSubTitle>
						<CardsContainer>{createCards(courses)}</CardsContainer>
						<SeeAll to="/instructor/allCourses">{texts.seeAll}</SeeAll>
					</>
				) : (
					<HomeSubTitle>{texts.noCourses}</HomeSubTitle>
				)}

				<HomeTitle>{texts.newCourse}</HomeTitle>
				<HomeSubTitle>{texts.instruction2}</HomeSubTitle>
				<NewCourseButton onClick={openModal}>
					<FaPlusCircle style={{ marginRight: "5px" }} />
					{texts.newCourse}
				</NewCourseButton>
				<Modal2
					title={texts.createNewCourse}
					show={isModalOpen}
					onClose={closeModal}
                    disableFooter={true}
				>
					<NewCourse onClose={closeModal}/>
				</Modal2>
			</HomeWrapper>
		</InstructorStructure>
	);
}
