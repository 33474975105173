import { useState } from 'react'
import {
    FaLock,
    FaEye,
    FaEyeSlash
} from 'react-icons/fa'

import { InputContainer, FormInputText } from './InputContainer'


export default function PasswordInput(props) {

    const [isInvalid, setIsInvalid] = useState(false)
    const [isShown, setIsShown] = useState(false);

    const handleError = () => {
        if (!(props.value)) {
            setIsInvalid(true)
        } else {
            setIsInvalid(false)
        }
    }

    return (
        <InputContainer margin={props.margin} isInvalid={isInvalid}>
            {<FaLock />}
            <FormInputText
                placeholder={props.placeholder}
                name={props.name}
                type={isShown ? "text" : "password"}
                value={props.value}
                onChange={props.onChange}
                onBlur={handleError}
                required
            />

            {isShown ?
                <FaEyeSlash onClick={e => setIsShown(false)} /> :
                <FaEye onClick={e => setIsShown(true)} />
            }

        </InputContainer>
    )
}
