import styled from "styled-components"
import {Link} from "react-router-dom"

export const SidebarContainer = styled.div`
	display: flex;
	flex-direction: column;

	max-width: ${(props) =>
		props.isHidden
			? props.theme.width.hideSidebar
			: props.theme.width.sidebar};

	min-width: ${(props) =>
		props.isHidden
			? props.theme.width.hideSidebar
			: props.theme.width.sidebar};

	@media screen and (max-width: 660px) {
		min-width: 0;
		max-width: 0;
	}
`

export const SidebarFooter = styled.div`
	font-size: 0.9rem;
	font-weight: 600;

	padding: 5px;
	margin-top: auto;

	color: ${(props) => props.theme.colors.white};
`

export const SidebarContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	height: 100%;

	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.primary};

	@media screen and (max-width: 660px) {
		display: ${(props) => (props.isHidden ? "none" : "flex")};

		width: 100vw;
		
		z-index: 1;

		margin-top: ${(props) => props.theme.height.navbar};
	}
`

export const SidebarContent = styled.ul`
	width: 100%;
`

export const LogoContainer = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;

	min-height: ${(props) => props.theme.height.navbar};
	width: 100%;
	padding: 5px;

	background-color: ${(props) => props.theme.colors.white};

	@media screen and (max-width: 660px) {
		display: none;
	}
`
