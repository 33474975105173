/** @format */

import { useState, useContext } from "react";
import { Form, TextCount, SaveButton } from "./EditMission-styled";
import Input from "../../common/Input/Input";

import language from "../../../services/defineLanguage.js";
import TextArea from "../../common/TextArea/TextArea";
import { editMission } from "../../../services/Instructor";
import { IconInput } from "../../common/IconInput/IconInput";
import { EagleContext } from "../../../contexts/EagleContext";
import { ErrorMessage } from "../../forms/FormsMessages";
import { ButtonWithLoading } from "../../common/ButtonWithLoading/ButtonWithLoading";
const texts = require(`../../../texts/${language}/instructor/editMission`);

export default function EditMission({
	missionId,
	initialTitle,
	initialIcon,
	initialDescription,
	callback,
}) {
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [title, setTitle] = useState(initialTitle || "");
	const [icon, setIcon] = useState(initialIcon || "");
	const [description, setDescription] = useState(initialDescription || "");
	const [response, setResponse] = useState({});

	async function tryEditMission() {
		let finalTitle = title !== initialTitle ? title : undefined;
		let finalDescription =
			description !== initialDescription ? description : undefined;
		let finalIcon = icon !== initialIcon ? icon : undefined;

		let newResponse = await editMission(
			missionId,
			finalTitle,
			finalDescription,
			finalIcon
		);

		if (newResponse.success) {
			callback(true);
		} else if (newResponse.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
			callback(false);
		}

		setResponse(newResponse);
	}
	async function onSubmit(event) {
		event.preventDefault();
		setResponse({ isLoading: true });
		tryEditMission();
	}

	return (
		<>
			<Form onSubmit={onSubmit}>
				<Input
					label={texts.title}
					value={title}
					setValue={setTitle}
					placeholder={texts.typeHere}
					maxLength={50}
					required
				/>
				<TextCount>{title.length}/50</TextCount>
				<IconInput
					value={icon}
					setValue={setIcon}
					margin="0 0 10px 0"
				/>
				<TextArea
					label={texts.description}
					value={description}
					setValue={setDescription}
					placeholder={texts.typeHere}
					maxLength={255}
					required
				/>
				<TextCount>{title.length}/255</TextCount>

				{response.success === false && (
					<ErrorMessage style={{ marginBottom: "10px" }}>
						{texts.errors[response.error] ?? texts.errors["default"]}
					</ErrorMessage>
				)}

				<ButtonWithLoading
					width={"110px"}
					isloading={response?.isLoading}
				>
					{texts.save}
				</ButtonWithLoading>
			</Form>
		</>
	);
}
