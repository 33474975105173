import { Children, cloneElement, useState } from 'react'
import { Wrapper } from './ToggleList-styled'

export default function List({ children }) {
    const [selectedItem, setSelectedItem] = useState(-1)

    function handleItems() {
        return Children.map(children, (item, index) => {
            return cloneElement(item, {
                selectedItem,
                setSelectedItem,
                itemId: index,
            })
        })
    }

    return <Wrapper>{handleItems()}</Wrapper>
}
