/** @format */

module.exports = {
	title: "Mission",
	skipTaskButtonLabel: "Skip task",
	checkAnswerButtonLabel: "Check answer",
	sendAnswerButtonLabel: "Send answer",
	nextButtonLabel: "Next",
	questionIsRightTitleText: "Well done!",
	questionIsWrongTitleText: "I'ts a pity!",
	questionIsRightBodyText: "You answered correctly!",
	questionIsWrongBodyText:
		"Don't worry, you will have a new opportunity soon...",
	pairInstruction: "Select the Pairs",
	quizInstruction: "Choose the adequate option",
	finishedMissionTitle: "Congrats! You have finished the mission!",
	finishedMissionButton: "Back to the course",
	errors: {},
};
