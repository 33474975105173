import styled from 'styled-components'

export const Wrapper = styled.form`
	margin: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	color: black;
`

export const OptionImage = styled.img`
	width: 15%;
	height: auto; // CHANGE
	margin-left: auto;
	margin-right: auto;
	display: block;
`

export const OptionContainer = styled.div`
	border: 1px solid ${(props) => props.theme.colors.mediumGray};
	border-radius: 10px;
	margin-bottom: 0.5rem;
	width: 100%;
	padding: 0.3rem;
	display: flex;
	align-items: center;

	background-color: ${(props) =>
		props.isSelected
			? props.theme.colors.lightBlue
			: props.theme.colors.white};

	border: 1px solid
		${(props) => (props.isInvalid ? props.theme.colors.warning : '#cccccc')};
	box-shadow: ${(props) =>
		props.isInvalid && '0 0 1px 1px ' + props.theme.colors.warning};

	color: ${(props) =>
		props.isSelected ? props.theme.colors.white : props.theme.colors.black};

	&:hover {
		cursor: pointer;
	}
`

export const Statement2Container = styled.div`
	margin-bottom: 3rem;
	margin-top: 2rem;

	font-weight: 500;
	color: black;

	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`

export const StatementWord = styled.div`
	margin: 0.1rem;
`
