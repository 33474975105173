import styled from 'styled-components'

export const PatentsContainer = styled.div`
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 33% 33% 33%;
	justify-items: center;

	padding-left: 18%;
	padding-right: 18%;

	@media screen and (max-width: 1540px) {
		padding: 0;
		margin: 0px -24px;
	}
`
export const Wrapper = styled.div`
	@media screen and (max-width: 1540px) {
		padding: 0;
		margin: 0;
	}
`
