/** @format */

import styled from "styled-components";
import { H2 } from "../../GenericComponents";

export const ChanceFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;

	overflow: hidden;

	button {
		
		margin: 5px;
	}

	background: ${(props) => props.theme.colors.white};

	border-radius: 0 0 10px 10px;
	margin-top: -1px;
	margin-bottom: -1px;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: cetner;
	padding: 20px;
`;

export const SubTitle = styled.h2`
	font-size: 1.8rem;
	text-align: center;
`;

export const Description = styled.div`
	font-weight: normal;
	font-size: 1.3rem;
	text-align: center;
`;

export const EmojiImage = styled.img`
	max-width: 350px;
	height: auto;

	@media screen and (max-width: 768px) {
		max-width: 300px;
	}

	@media screen and (max-width: 460px) {
		max-width: 250px;
	}
`;
