/** @format */

import { useContext } from "react";
import { EagleContext } from "../../../contexts/EagleContext";
import { FaMapMarkerAlt } from "react-icons/fa";

import photo from "../../../assets/images/defaultProfileImage2.png";
import { Divider } from "../../GenericComponents";
import {
	ContentBox,
	PictureBox,
	Text,
	Title,
} from "./ProfileBox-styled";

import language from "../../../services/defineLanguage";
import DynamicLevelBadge from "../../environments/courses-board/Level/DynamicLevelBadge";
const texts = require(`../../../texts/${language}/profileBox`);
const levelsTexts = require(`../../../texts/${language}/levels`);
const countries = require(`../../../texts/${language}/countries`);

export default function ProfileBox() {
	const { user, getCurrentCourse, getCurrentCourseSetting } =
		useContext(EagleContext);

	let course = getCurrentCourse() || { level: 1 };
	let settings = getCurrentCourseSetting();
	return (
		<ContentBox>
			<PictureBox>
				<img
					src={
						user.pictureURL ==
							"https://eagle-repository.s3.sa-east-1.amazonaws.com/profile/defaultProfileImageMale.png" ||
						user.pictureURL?.length === 0
							? photo
							: user.pictureURL
					}
					alt=""
				/>
				{/* <CameraBox>
					<Camera />
				</CameraBox> */}
			</PictureBox>
			<Title>
				{" "}
				{user.name} {user.lastName}
			</Title>
			<Text>
				<FaMapMarkerAlt />{" "}
				{countries[user.country] ? countries[user.country] : user.country}
			</Text>
			<Divider />
			{/* <SocialDataContainer>
				<SocialData>
					<FaUserFriends />
					<div>
						<SlimText>1230</SlimText>
						<SlimText>{texts.following}</SlimText>
					</div>
				</SocialData>
				<SocialData>
					<FaUsers />
					<div>
						<SlimText>1230</SlimText>
						<SlimText>{texts.followers}</SlimText>
					</div>
				</SocialData>
			</SocialDataContainer>
			<Divider />
			<Friends href="/friends">
				<FaUser />
				<span>{texts.friends}</span>
			</Friends> */}
			{settings.level && (
				<>
					<DynamicLevelBadge levelId={course.level} />
					<h1>{levelsTexts[course.level].name}</h1>
					<p style={{ textAlign: "justify" }}>
						{levelsTexts[course.level].description}
					</p>
				</>
			)}
		</ContentBox>
	);
}
