/** @format */

const basicGamificationSettings = {
	performance: {
		acknowledgment: "acknowledgment", //acknowledgment
		level: "level", //level
		points: "points", //points
		progression: "progression", //All progression bars
		stats: "stats", // points,coins,acknowledgments on header and sidebar
	},
	social: {
		competition: "competition", //Ranking
		cooperation: "cooperation", //Teams
		reputation: "reputation", // Not Implemented
		socialPressure: "socialPressure", // poke and message ()
	},
	ecological: {
		chance: "chance", //Bonus chest
		economy: "economy", //Coins and Store
		imposedChoice: "imposedChoice", //Double or nothing
		rarity: "rarity", // some items on store
		timePressure: "timePressure", //Time in ranking
	},
	personal: {
		novelty: "novelty", // some items on store
		objectives: "objectives", // show subjects or not
		puzzles: "puzzles", //Not Implemented
		renovation: "renovation", // if choose a wrong answer, try again in the end
		sensation: "sensation", // sounds on tasks
	},
	fictional: {
		narrative: "narrative", //Avatar
		storytelling: "storytelling", //Not Implemented
	},
};

export default basicGamificationSettings;
