import BronzeBadge from '../assets/images/levels/bronze-level.svg'
import DiamondBadge from '../assets/images/levels/diamond-level.svg'
import GoldBadge from '../assets/images/levels/gold-level.svg'
import RubyBadge from '../assets/images/levels/ruby-level.svg'
import SilverBadge from '../assets/images/levels/silver-level.svg'

const levelBadgeImgMap = [
    BronzeBadge,
    SilverBadge,
    GoldBadge,
    RubyBadge,
    DiamondBadge,
]

export default levelBadgeImgMap
