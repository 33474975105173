/** @format */

import { useEffect, useState } from "react";
import { EagleContext } from "../../contexts/EagleContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import Loader from "./Loader/Loader";

export default function NonCourseAccessManager({ children }) {
	const history = useHistory();
	const { isLoadingSystem, isLoadingCourses, user } = useContext(EagleContext);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (!user.id) {
			history.push("/signin");
		} else if (!user.verified) {
			history.push("/accountverification");
		} else {
			setLoading(isLoadingSystem);
			if (
				isLoadingSystem &&
				isLoadingCourses &&
				user.courses &&
				user.courses.length > 0
			) {
				history.push("/profile");
			}
		}
	}, [isLoadingSystem, isLoadingCourses]);

	// useEffect(() => {
	// 	if (isLoadingSystem) {
	// 		if (!user.id) {
	// 			history.push("/signin");
	// 		} else if (!user.verified) {
	// 			// console.log(user.verified)
	// 			history.push("/accountverification");
	// 		} else {
	// 			let isLoadingTemp = isLoadingSystem || true;
	// 			setLoading(isLoadingTemp);
	// 			if (!isLoadingTemp) {
	// 				if (user.courses.length === 0) {
	// 					history.push("/enrollcourse");
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [isLoadingSystem]);

	return loading ? <Loader /> : <>{children}</>;
}
