/** @format */

import { useState, useContext } from "react";
import { Form, TextCount, CreateButton } from "./NewMission-styled";
import Input from "../../common/Input/Input";

import language from "../../../services/defineLanguage.js";
import { createMission } from "../../../services/Instructor";
import { IconInput } from "../../common/IconInput/IconInput";
import { EagleContext } from "../../../contexts/EagleContext";
import { ErrorMessage } from "../../forms/FormsMessages";
import { ButtonWithLoading } from "../../common/ButtonWithLoading/ButtonWithLoading";
const texts = require(`../../../texts/${language}/instructor/newMission`);

export default function NewMission({ subjectId, callback }) {
	const { insertFakeInstantMessage } = useContext(EagleContext);
	const [title, setTitle] = useState("");
	const [icon, setIcon] = useState("");
	const [description, setDescription] = useState("");
	const [response, setResponse] = useState({});

	async function tryCreateMission() {
		let newResponse = await createMission(subjectId, title, description, icon);

		if (newResponse.success) {
			callback(true);
		} else if (newResponse.error === "INSTRUCTOR_PLAN_WITHOUT_ACCESS") {
			insertFakeInstantMessage(31, 0, 0, {});
			callback(false);
		}

		setResponse(newResponse);
	}
	async function onSubmit(event) {
		event.preventDefault();

		tryCreateMission();
		setResponse({ isLoading: true });
	}
	return (
		<>
			<Form onSubmit={onSubmit}>
				<Input
					label={texts.title}
					value={title}
					setValue={setTitle}
					placeholder={texts.typeHere}
					maxLength={50}
					required
				/>
				<TextCount>{title.length}/50</TextCount>
				<IconInput
					value={icon}
					setValue={setIcon}
					margin={"0 0 10px 0"}
				/>
				<Input
					label={texts.description}
					value={description}
					setValue={setDescription}
					placeholder={texts.typeHere}
					maxLength={255}
					required
				/>
				<TextCount>{title.length}/255</TextCount>

				{response.success === false && (
					<ErrorMessage style={{ marginBottom: "10px" }}>
						{texts.errors[response.error] ?? texts.errors["default"]}
					</ErrorMessage>
				)}

				<ButtonWithLoading
					width={"110px"}
					isloading={response?.isLoading}
				>
					{texts.create}
				</ButtonWithLoading>
			</Form>
		</>
	);
}
