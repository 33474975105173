import styled from 'styled-components'

export const InstructorContainer = styled.div`
	display: flex;

	@media screen and (max-width: 660px) {
		overflow-x: hidden;
	}
`

export const InstructorSubContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	overflow-x: auto;
	min-height: 100vh;
`

export const ContentContainer = styled.div`
	display: flex;
	flex-grow: 1;
	background-color: ${(props) => props.theme.colors.gray};
`
