/** @format */

import { useEffect, useState, useContext, useRef } from "react";
import {
	Container,
	Title,
	Subtitle,
	SectionTitle,
} from "./CourseStatistics-styled";
import InstructorStructure from "../../environments/instructor/InstructorStructure/InstructorStructure";
import ProgressBar from "../../common/ProgressBar/ProgressBar";

import Table from "../../common/Table/Table";

import language from "../../../services/defineLanguage";
import { InstructorContext } from "../../../contexts/InstructorContext";
import { getCourseStudentsStatistics } from "../../../services/Instructor";
const texts = require(`../../../texts/${language}/courseStatistics`);

export default function CourseStatistics({}) {
	// Get course id from url
	const { currentCourseId } = useContext(InstructorContext);
	const [statistics, setStatistics] = useState([]);
	const [rawStatistics, setRawStatistics] = useState([]);
	const [
		numberOfStudentsThatCompletedCourse,
		setNumberOfStudentsThatCompletedCourse,
	] = useState(0);
	const [
		numberOfStudentsThatStartedCourse,
		setNumberOfStudentsThatStartedCourse,
	] = useState(0);
	const numberOfMissions = useRef(0);

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		let newStatistics = rawStatistics.map((statistic) => {
			let newStatistic = {};

			newStatistic[texts.name] = statistic[texts.name];
			newStatistic[texts.courseProgress] = createProgressCell(
				Math.round(statistic[texts.courseProgress]),
				100
			);

			newStatistic[texts.points] = statistic[texts.points];
			newStatistic[texts.economy] = statistic[texts.economy];
			newStatistic[texts.achievements] = createProgressCell(
				statistic[texts.achievements],
				6
			);

			return newStatistic;
		});
		setStatistics(newStatistics);
	}, [rawStatistics]);

	function createProgressCell(value, maxValue) {
		return (
			<>
				<span>{value + "/" + maxValue}</span>
				<ProgressBar completionRate={(value / maxValue) * 100} />
			</>
		);
	}

	const loadData = async () => {
		try {
			let response = await getCourseStudentsStatistics(currentCourseId);
			let completed = 0;
			let started = 0;

			let newRawStatistics = [];
			numberOfMissions.current = response.numberOfMissions;
			response.statistics.forEach((statistic) => {
				let newRawStatistic = {};

				newRawStatistic[texts.name] = statistic.name;

				newRawStatistic[texts.courseProgress] = Math.round(
					(statistic.numberOfMissions / response.numberOfMissions) * 100
				);

				newRawStatistic[texts.points] = statistic.points;

				newRawStatistic[texts.economy] = statistic.economy;

				newRawStatistic[texts.achievements] = statistic.numberOfAchievements;

				if (newRawStatistic[texts.courseProgress] === 100) completed++;
				if (newRawStatistic[texts.courseProgress]) started++;
				newRawStatistics.push(newRawStatistic);
			});
			setRawStatistics(newRawStatistics);
			setNumberOfStudentsThatCompletedCourse(completed);
			setNumberOfStudentsThatStartedCourse(started);
		} catch (err) {
			console.log("Get statistics error!");
		}
	};

	return (
		<InstructorStructure>
			<Container>
				<Title>{texts.title}</Title>
				<Subtitle>{texts.description}</Subtitle>
				{/* <SectionTitle>Geral</SectionTitle>
				<ContentContainer></ContentContainer> */}
				<SectionTitle>{texts.studentsTitle}</SectionTitle>
				<b style={{ margin: "25px 0 0 15px" }}>
					{texts.numberStudents}: {statistics.length}
				</b>
				<b style={{ margin: "5px 0 0 15px" }}>
					{texts.studentsStarted}: {numberOfStudentsThatStartedCourse}
				</b>
				<b style={{ margin: "5px 0 0 15px" }}>
					{texts.studentsCompleted}: {numberOfStudentsThatCompletedCourse}
				</b>
				
				<Table
					headerCallback={(column) => {
						setRawStatistics((prevStatistics) => {
							let ordered = [...prevStatistics];
							if (column === texts.name) {
								ordered.sort((a, b) => {
									return a[column].localeCompare(b[column]);
								});
								return ordered;
							}

							ordered.sort((a, b) => {
								return a[column] <= b[column]
									? a[column] === b[column]
										? 0
										: 1
									: -1;
							});
							return ordered;
						});
					}}
					repeatHeader={true}
					data={{
						columns: [
							texts.name,
							texts.courseProgress,
							texts.points,
							texts.economy,
							texts.achievements,
						],
						rows: statistics,
					}}
				></Table>
			</Container>
		</InstructorStructure>
	);
}
